import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { themeCustomizer } from "../ButtonTheme";
import Webcam from "react-webcam";
interface OpenCamera {
  isCamera: boolean;
  webcamRef: any;
  index: number;
  itemId: number;
  //   message: string;
  onCloseCamera: () => void;
  onSubmitPressed: () => void;
  //   updateMessage: () => void;
}

const OpenCamera: FC<OpenCamera> = (props: OpenCamera) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 480,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Modal
      open={props.isCamera}
      onClose={props.onCloseCamera}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          // width: 480,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography fontSize={12} align="left" fontWeight={700}>
          Take Picture
        </Typography>
        <Webcam
          audio={false}
          ref={props.webcamRef}
          screenshotFormat="image/jpeg"
          style={{ width: 400, height: 400 }}
        />
        <ThemeProvider theme={themeCustomizer}>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              marginTop: 10,
              zIndex: 1,
            }}
            onClick={() => props.onSubmitPressed()}
          >
            Click
          </Button>
        </ThemeProvider>
        <p
          style={{
            fontSize: "10px",
            lineHeight: "15px",
            color: "rgb(253, 60, 79)",
            marginTop: 5,
            marginLeft: 5,
          }}
        >
          {/* {props.message} */}
        </p>
      </Box>
    </Modal>
  );
};

export default OpenCamera;
