import React, { FC, useState, useRef } from "react";
import { ThemeProvider } from "@emotion/react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { themeCustomizer } from "../ButtonTheme";
import SignatureCanvas from "react-signature-canvas";
import Webcam from "react-webcam";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";
import download from "downloadjs";
interface SignaturePopUp {
  isSignature: boolean;
  index: number;
  itemId: number;
  signPad: any;
  webcamRef: any;
  signatureBy: any;

  //   message: string;
  // onSubmitPressed: (index: number, id: number) => void;
  // onSubmitClick: (index: number, id: number) => void;
  handleSignature: (signature: any) => void;
  onClearSignaturePad: () => void;
  onCloseSignaturePad: () => void;
  onsaveSignaturePressed: (val: any, index: number, option: number) => void;
  onChangeOption: (option: number) => void;
  //   updateMessage: () => void;
}

const SignaturePopUp: FC<SignaturePopUp> = (props: SignaturePopUp) => {
  const textRef = useRef<any>(null);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [textWidth, setTextWidth] = useState<any>("auto");
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleConvertToImage = () => {
    if (textRef.current) {
      const text = textRef.current;

      const tempDiv = document.createElement("div");
      tempDiv.style.display = "inline-block";
      tempDiv.style.width = "auto"; // Set initially to 'auto' to measure content width
      tempDiv.style.whiteSpace = "nowrap"; // Prevent text from wrapping
      tempDiv.innerText = text;
      document.body.appendChild(tempDiv);

      const measuredWidth = tempDiv.clientWidth;
      setTextWidth(`${measuredWidth}px`); // Set the text width based on measurement

      html2canvas(tempDiv, {
        scale: 4,
        useCORS: true,
      })
        .then((canvas) => {
          const dataUrl = canvas.toDataURL("image/png");
          setImageSrc(dataUrl);
          props.signPad.current = dataUrl;
          document.body.removeChild(tempDiv);
        })
        .catch((error) => {
          console.error("Error converting text to image:", error);
        });
    } else {
      setImageSrc(null);
    }
  };

  const onChangeText = (event: any) => {
    textRef.current = event.target.value;
    setTextWidth("auto"); // Reset the text width on text change
    if (event.target.value === "") {
      props.signPad.current = null;
      textRef.current = null;
      setImageSrc(null);
    }
    console.log("this is on change text:", props.signPad.current);
  };
  return (
    <div>
      <Modal
        open={props.isSignature}
        onClose={props.onCloseSignaturePad}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            // width: 480,
            width: { sm: "100%", md: "50%", lg: "30%", xl: "30%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={12} align="left" fontWeight={700}>
            Draw signature below
          </Typography>
          <div className="m-tabs">
            <div
              onClick={() => props.onChangeOption(1)}
              className={
                props.signatureBy.option1
                  ? "tab l-border active"
                  : "tab l-border"
              }
            >
              By pen
            </div>
            <div
              onClick={() => props.onChangeOption(2)}
              className={
                props.signatureBy.option2
                  ? "tab r-border active"
                  : "tab r-border"
              }
            >
              By text
            </div>
          </div>
          {/* <Webcam
            audio={false}
            ref={props.webcamRef}
            screenshotFormat="image/jpeg"
            style={{ width: 450, height: 400 }}
            // onUserMedia={}
          /> */}
          {props.signatureBy.option2 === true && (
            <div className="by-text-container">
              <div className="input-area">
                <input
                  placeholder="Enter text "
                  maxLength={50}
                  onChange={onChangeText}
                />
                <button onClick={handleConvertToImage}>Sign</button>
              </div>
              <div className="image-container">
                {imageSrc && <img src={imageSrc} />}
              </div>
              <div
                className="m-btn-wraper"
                style={{ justifyContent: "flex-end" }}
              >
                <div className="cs-btn">
                  <div
                    className="cancelButton p-btn"
                    onClick={() => {
                      props.onCloseSignaturePad();
                      textRef.current = null;
                      setImageSrc(null);
                    }}
                  >
                    Cancel
                  </div>
                  <ThemeProvider theme={themeCustomizer}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "32px",
                        lineHeight: "unset",
                        height: "30px",
                      }}
                      // onClick={() => props.onSubmitClick(props.index, props.itemId)}
                      onClick={() => {
                        props.onsaveSignaturePressed(
                          props.signPad,
                          props.index,
                          2
                        );
                        setImageSrc(null);
                      }}
                    >
                      Save
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          )}
          {props.signatureBy.option1 && (
            <>
              <div className="sigCanvas">
                <SignatureCanvas
                  ref={props.signPad}
                  onOK={(sign: any) => props.handleSignature(sign)}
                  descriptionText="Sign"
                  onClear={props.onClearSignaturePad}
                  penColor="rgba(25, 73, 136, 1)"
                  dotSize={1}
                  canvasProps={{
                    width: window.innerWidth > 520 ? 430 : 300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
              </div>
              <div className="m-btn-wraper">
                <div
                  onClick={props.onClearSignaturePad}
                  className="clearDesc p-btn"
                >
                  Clear
                </div>
                <div className="cs-btn">
                  <div
                    className="cancelButton p-btn"
                    onClick={props.onCloseSignaturePad}
                  >
                    Cancel
                  </div>
                  <ThemeProvider theme={themeCustomizer}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "32px",
                        lineHeight: "unset",
                        height: "30px",
                      }}
                      // onClick={() => props.onSubmitClick(props.index, props.itemId)}
                      onClick={() => {
                        props.onsaveSignaturePressed(
                          props.signPad,
                          props.index,
                          1
                        );
                        setImageSrc(null);
                      }}
                    >
                      Save
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </>
          )}

          <p
            style={{
              fontSize: "10px",
              lineHeight: "15px",
              color: "rgb(253, 60, 79)",
              marginTop: 5,
              marginLeft: 5,
            }}
          >
            {/* {props.message} */}
          </p>
        </Box>
      </Modal>
    </div>
  );
};

export default SignaturePopUp;
