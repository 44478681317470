import React, { FC } from "react";
import { useState, useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  TextSnippet,
  BusinessCenter,
  NotificationsNone,
  Settings,
  ArrowDropDown,
  ArrowDropUp,
  Add,
  Group,
  Logout,
  Person,
  Assignment,
} from "@mui/icons-material";
import { Divider, List, ListItem } from "@mui/material";
import UserContext from "../../Utils/UserContext";
import { useNavigate } from "react-router-dom";
import {
  LOGIN,
  FORGOTPASSWORD,
  DASHBOARD,
  CREATEUSER,
  MANAGEMENT,
  EDITUSER,
  USERS,
  CREATEPDF,
  REPORTS,
  PROFILE,
  ASSIGNED,
  TEMPLATE,
  APPLYTEMPLATE,
} from "../../Utils/Constants";
import "../../Styles/Common.scss";
import InfoPopUp from "./PopUp/InfoPopUp";
interface MyCustomDrawer {
  roles: any;
  allowedRoles: any;
  showSetting: boolean;
}
export const MyCustomDrawer: FC<MyCustomDrawer> = (props: MyCustomDrawer) => {
  const context = useContext(UserContext);
  const { tabIndex, updateTabIndex, updateMenuButton } = context;

  const [open, setOpen] = React.useState(false);
  const [isInfo, setIsInfo] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const navigate = useNavigate();
  // console.log("brooo", props.allowedRoles);

  const onCreateWorkFlowPressed = () => {
    if (window.innerWidth > 991) {
      navigate(CREATEPDF);
      updateTabIndex(1);
      updateMenuButton(true);
    } else {
      setIsInfo(true);
      setMessage("Please use desktop to create work flow");
    }
  };
  const onInfoClosedPressed = () => {
    setIsInfo(false);
    setMessage("");
  };

  const onOkPressed = () => {
    setIsInfo(false);
    setMessage("");
  };
  return (
    <>
      <InfoPopUp
        isInfo={isInfo}
        message={message}
        onInfoClosedPressed={onInfoClosedPressed}
        onOkPressed={onOkPressed}
      />
      <List component="nav" className="right-menu">
        {props.roles === "admin" ? (
          <>
            {/*dashboard tab */}
            <ListItemButton
              style={{
                backgroundColor: tabIndex === 0 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={() => {
                navigate(DASHBOARD);
                updateTabIndex(0);
                window.innerWidth > 991
                  ? updateMenuButton(true)
                  : updateMenuButton(false);
              }}
            >
              <ListItemIcon>
                <DashboardIcon
                  style={{
                    color: tabIndex === 0 ? "white" : "#7A86A1",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 0 ? "white" : "#7A86A1",
                }}
                primary="Dashboard"
              />
            </ListItemButton>
            {/*Create Workflowtab */}

            <ListItemButton
              style={{
                backgroundColor: tabIndex === 1 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={onCreateWorkFlowPressed}
            >
              <ListItemIcon
                style={{
                  color: tabIndex === 1 ? "white" : "#7A86A1",
                }}
              >
                <BusinessCenter />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 1 ? "white" : "#7A86A1",
                }}
                primary="Create workflow"
              />
            </ListItemButton>

            {/*Reports tab */}
            <ListItemButton
              style={{
                backgroundColor: tabIndex === 3 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={() => {
                console.log("this is current tab index", tabIndex);
                navigate(REPORTS);
                updateTabIndex(3);
                window.innerWidth > 991
                  ? updateMenuButton(true)
                  : updateMenuButton(false);
              }}
            >
              <ListItemIcon
                style={{
                  color: tabIndex === 3 ? "white" : "#7A86A1",
                }}
              >
                <TextSnippet />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 3 ? "white" : "#7A86A1",
                }}
                primary="Reports"
              />
            </ListItemButton>
            {/*Profile tab */}
            <ListItemButton
              style={{
                backgroundColor: tabIndex === 8 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={() => {
                navigate(PROFILE);
                updateTabIndex(8);
                window.innerWidth > 991
                  ? updateMenuButton(true)
                  : updateMenuButton(false);
              }}
            >
              <ListItemIcon>
                <Person
                  style={{
                    color: tabIndex === 8 ? "white" : "#7A86A1",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 8 ? "white" : "#7A86A1",
                }}
                primary="Profile"
              />
            </ListItemButton>

            {/*Assigned tab */}
            <ListItemButton
              style={{
                backgroundColor: tabIndex === 9 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={() => {
                navigate(ASSIGNED);
                updateTabIndex(9);
                window.innerWidth > 991
                  ? updateMenuButton(true)
                  : updateMenuButton(false);
              }}
            >
              <ListItemIcon>
                <Assignment
                  style={{
                    color: tabIndex === 9 ? "white" : "#7A86A1",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 9 ? "white" : "#7A86A1",
                }}
                primary="Assigned workflows"
              />
            </ListItemButton>
            {/** Create Template */}
            <ListItemButton
              style={{
                backgroundColor: tabIndex === 10 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={() => {
                navigate(TEMPLATE);
                updateTabIndex(10);
                window.innerWidth > 991
                  ? updateMenuButton(true)
                  : updateMenuButton(false);
              }}
            >
              <ListItemIcon>
                <Assignment
                  style={{
                    color: tabIndex === 10 ? "white" : "#7A86A1",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 10 ? "white" : "#7A86A1",
                }}
                primary="Create Template"
              />
            </ListItemButton>

            {/** Select Template */}
            <ListItemButton
              style={{
                backgroundColor: tabIndex === 11 ? "#6149CD" : "transparent",
                borderRadius: "20px",
              }}
              onClick={() => {
                navigate(APPLYTEMPLATE);
                updateTabIndex(11);
                window.innerWidth > 991
                  ? updateMenuButton(true)
                  : updateMenuButton(false);
              }}
            >
              <ListItemIcon>
                <Assignment
                  style={{
                    color: tabIndex === 11 ? "white" : "#7A86A1",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: tabIndex === 11 ? "white" : "#7A86A1",
                }}
                primary="Apply Template"
              />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <SettingsButton roles={props.roles} />
            {/**Logout tab */}
            <ListItemButton
              style={{
                borderRadius: "20px",
              }}
              onClick={() => {
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("userId");
                localStorage.removeItem("userEmail");
                localStorage.removeItem("mobileNumber");
                localStorage.removeItem("userName");

                window.location.reload();
              }}
            >
              <ListItemIcon>
                <Logout
                  style={{
                    color: "#ff3030",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: "#ff3030",
                }}
                primary="Logout"
              />
            </ListItemButton>
          </>
        ) : (
          <>
            {/**Conditiona tabs */}
            {props.allowedRoles.map((item: any, index: number) => {
              return item.id === 1 ? (
                <ListItemButton
                  key={index}
                  style={{
                    backgroundColor: tabIndex === 0 ? "#6149CD" : "transparent",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(DASHBOARD);
                    updateTabIndex(0);
                    window.innerWidth > 991
                      ? updateMenuButton(true)
                      : updateMenuButton(false);
                  }}
                >
                  <ListItemIcon>
                    <DashboardIcon
                      style={{
                        color: tabIndex === 0 ? "white" : "#7A86A1",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: tabIndex === 0 ? "white" : "#7A86A1",
                    }}
                    primary="Dashboard"
                  />
                </ListItemButton>
              ) : item.id === 2 ? (
                <>
                  {/* {window.innerWidth > 991 && ( */}
                  <ListItemButton
                    style={{
                      backgroundColor:
                        tabIndex === 1 ? "#6149CD" : "transparent",
                      borderRadius: "20px",
                    }}
                    // onClick={() => {
                    //   navigate(CREATEPDF);
                    //   updateTabIndex(1);
                    //   window.innerWidth > 991
                    //     ? updateMenuButton(true)
                    //     : updateMenuButton(false);
                    // }}
                    onClick={onCreateWorkFlowPressed}
                  >
                    <ListItemIcon
                      style={{
                        color: tabIndex === 1 ? "white" : "#7A86A1",
                      }}
                    >
                      <BusinessCenter />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color: tabIndex === 1 ? "white" : "#7A86A1",
                      }}
                      primary="Create workflow"
                    />
                  </ListItemButton>
                  {/* )} */}
                </>
              ) : item.id === 3 ? (
                <ListItemButton
                  style={{
                    backgroundColor: tabIndex === 3 ? "#6149CD" : "transparent",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(REPORTS);
                    updateTabIndex(3);
                    window.innerWidth > 991
                      ? updateMenuButton(true)
                      : updateMenuButton(false);
                  }}
                >
                  <ListItemIcon
                    style={{
                      color: tabIndex === 3 ? "white" : "#7A86A1",
                    }}
                  >
                    <TextSnippet />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: tabIndex === 3 ? "white" : "#7A86A1",
                    }}
                    primary="Reports"
                  />
                </ListItemButton>
              ) : item.id === 8 ? (
                <ListItemButton
                  style={{
                    backgroundColor: tabIndex === 8 ? "#6149CD" : "transparent",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(PROFILE);
                    updateTabIndex(8);
                    window.innerWidth > 991
                      ? updateMenuButton(true)
                      : updateMenuButton(false);
                  }}
                >
                  <ListItemIcon>
                    <Person
                      style={{
                        color: tabIndex === 8 ? "white" : "#7A86A1",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: tabIndex === 8 ? "white" : "#7A86A1",
                    }}
                    primary="Profile"
                  />
                </ListItemButton>
              ) : item.id === 9 ? (
                <ListItemButton
                  style={{
                    backgroundColor: tabIndex === 9 ? "#6149CD" : "transparent",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(ASSIGNED);
                    updateTabIndex(9);
                    window.innerWidth > 991
                      ? updateMenuButton(true)
                      : updateMenuButton(false);
                  }}
                >
                  <ListItemIcon>
                    <Assignment
                      style={{
                        color: tabIndex === 9 ? "white" : "#7A86A1",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: tabIndex === 9 ? "white" : "#7A86A1",
                    }}
                    primary="Assigned workflows"
                  />
                </ListItemButton>
              ) : item.id === 10 ? (
                <ListItemButton
                  style={{
                    backgroundColor:
                      tabIndex === 10 ? "#6149CD" : "transparent",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(TEMPLATE);
                    updateTabIndex(10);
                    window.innerWidth > 991
                      ? updateMenuButton(true)
                      : updateMenuButton(false);
                  }}
                >
                  <ListItemIcon>
                    <Assignment
                      style={{
                        color: tabIndex === 10 ? "white" : "#7A86A1",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: tabIndex === 10 ? "white" : "#7A86A1",
                    }}
                    primary="Create Template"
                  />
                </ListItemButton>
              ) : item.id === 11 ? (
                <ListItemButton
                  style={{
                    backgroundColor:
                      tabIndex === 11 ? "#6149CD" : "transparent",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    navigate(APPLYTEMPLATE);
                    updateTabIndex(11);
                    window.innerWidth > 991
                      ? updateMenuButton(true)
                      : updateMenuButton(false);
                  }}
                >
                  <ListItemIcon>
                    <Assignment
                      style={{
                        color: tabIndex === 11 ? "white" : "#7A86A1",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: tabIndex === 11 ? "white" : "#7A86A1",
                    }}
                    primary="Apply Template"
                  />
                </ListItemButton>
              ) : null;
            })}
            {props.allowedRoles && props.showSetting && (
              <>
                <Divider sx={{ my: 1 }} />
                <SettingsButton
                  allowedRoles={props.allowedRoles}
                  role={props.roles}
                />
              </>
            )}
            <>
              <ListItemButton
                style={{
                  // backgroundColor: tabIndex === 0 ? "#6149CD" : "transparent",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  localStorage.removeItem("isLoggedIn");
                  localStorage.removeItem("token");
                  localStorage.removeItem("role");
                  localStorage.removeItem("userId");
                  localStorage.removeItem("userEmail");
                  localStorage.removeItem("mobileNumber");
                  localStorage.removeItem("userName");
                  window.location.reload();
                }}
              >
                <ListItemIcon>
                  <Logout
                    style={{
                      color: "#ff3030",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: "#ff3030",
                  }}
                  primary="Logout"
                />
              </ListItemButton>
            </>
          </>
        )}
      </List>
    </>
  );
};
const SettingsButton = (props: any) => {
  const context = useContext(UserContext);
  const { tabIndex, permissionsRolesList, updateTabIndex, updateMenuButton } =
    context;
  const [openSettings, setOpenSettings] = useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (tabIndex >= 5) {
      setOpenSettings(true);
    }
    // console.log("props.openSettings", props);
    if (permissionsRolesList.length === 0) {
    }
    // console.log("permissionsRolesList", permissionsRolesList);
  }, [tabIndex, permissionsRolesList]);

  return (
    <>
      <ListItemButton onClick={() => setOpenSettings(!openSettings)}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {openSettings === false ? (
          <ListItemIcon>
            <ArrowDropDown />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <ArrowDropUp />
          </ListItemIcon>
        )}
      </ListItemButton>
      {openSettings && (
        <>
          {permissionsRolesList.length > 0 && props.roles !== "admin" ? (
            permissionsRolesList.map((item: any, index: number) => (
              <>
                {item.id === 5 && (
                  <ListItemButton
                    onClick={() => {
                      navigate(CREATEUSER);
                      updateTabIndex(5);
                      window.innerWidth > 991
                        ? updateMenuButton(true)
                        : updateMenuButton(false);
                    }}
                    style={{
                      backgroundColor:
                        tabIndex === 5 ? "#6149CD" : "transparent",
                      borderRadius: "20px",
                    }}
                    key={index}
                  >
                    <ListItemIcon>
                      <Add
                        style={{
                          color: tabIndex === 5 ? "white" : "#7A86A1",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color: tabIndex === 5 ? "white" : "#7A86A1",
                      }}
                      primary="Create User"
                    />
                  </ListItemButton>
                )}
                {item.id === 6 && (
                  <ListItemButton
                    onClick={() => {
                      navigate(USERS);
                      updateTabIndex(6);
                      window.innerWidth > 991
                        ? updateMenuButton(true)
                        : updateMenuButton(false);
                    }}
                    style={{
                      backgroundColor:
                        tabIndex === 6 ? "#6149CD" : "transparent",
                      borderRadius: "20px",
                    }}
                  >
                    <ListItemIcon>
                      <Add
                        style={{
                          color: tabIndex === 6 ? "white" : "#7A86A1",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color: tabIndex === 6 ? "white" : "#7A86A1",
                      }}
                      primary="Users"
                    />
                  </ListItemButton>
                )}
                {item.id === 7 && (
                  <ListItemButton
                    onClick={() => {
                      navigate(MANAGEMENT);
                      updateTabIndex(7);
                      window.innerWidth > 991
                        ? updateMenuButton(true)
                        : updateMenuButton(false);
                    }}
                    style={{
                      backgroundColor:
                        tabIndex === 7 ? "#6149CD" : "transparent",
                      borderRadius: "20px",
                    }}
                  >
                    <ListItemIcon>
                      <Add
                        style={{
                          color: tabIndex === 7 ? "white" : "#7A86A1",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color: tabIndex === 7 ? "white" : "#7A86A1",
                      }}
                      primary="Role Management"
                    />
                  </ListItemButton>
                )}
              </>
            ))
          ) : props.roles === "admin" ? (
            <>
              {console.log("in case of admin")}
              <ListItemButton
                onClick={() => {
                  navigate(CREATEUSER);
                  updateTabIndex(5);
                  window.innerWidth > 991
                    ? updateMenuButton(true)
                    : updateMenuButton(false);
                }}
                style={{
                  backgroundColor: tabIndex === 5 ? "#6149CD" : "transparent",
                  borderRadius: "20px",
                }}
              >
                <ListItemIcon>
                  <Add
                    style={{
                      color: tabIndex === 5 ? "white" : "#7A86A1",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: tabIndex === 5 ? "white" : "#7A86A1",
                  }}
                  primary="Create User"
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate(USERS);
                  updateTabIndex(6);
                  window.innerWidth > 991
                    ? updateMenuButton(true)
                    : updateMenuButton(false);
                }}
                style={{
                  backgroundColor: tabIndex === 6 ? "#6149CD" : "transparent",
                  borderRadius: "20px",
                }}
              >
                <ListItemIcon>
                  <Add
                    style={{
                      color: tabIndex === 6 ? "white" : "#7A86A1",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: tabIndex === 6 ? "white" : "#7A86A1",
                  }}
                  primary="Users"
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate(MANAGEMENT);
                  updateTabIndex(7);
                  window.innerWidth > 991
                    ? updateMenuButton(true)
                    : updateMenuButton(false);
                }}
                style={{
                  backgroundColor: tabIndex === 7 ? "#6149CD" : "transparent",
                  borderRadius: "20px",
                }}
              >
                <ListItemIcon>
                  <Add
                    style={{
                      color: tabIndex === 7 ? "white" : "#7A86A1",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: tabIndex === 7 ? "white" : "#7A86A1",
                  }}
                  primary="Role Management"
                />
              </ListItemButton>
            </>
          ) : null}
        </>
      )}
    </>
  );
};
