import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import "../../../Styles/templatePopup.scss";
import {
  Box,
  Button,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { themeCustomizer } from "../ButtonTheme";
import {
  AllControlsList,
  SelectTemplateControl,
  TemplateList,
} from "../../../Types/Types";
import { pdfjs, Document, Page } from "react-pdf";
import textInputImage from "../../../Assets/Text box-50x50.png";
import multiSelectionImage from "../../../Assets/Multi selection-50x50.png";
import closeicon from "../../../Assets/close-icon.png";
import imageBoxImage from "../../../Assets/Image box-50x50.png";
import signatureBoxIamge from "../../../Assets/Signature Box-50x50.png";
import initalsBoxImage from "../../../Assets/Initials -50x50.png";
import radioButtonImage from "../../../Assets/Radio Button -50x50.png";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Camera,
  Close,
  CameraAlt,
  CheckBox,
  FontDownload,
  TranslateSharp,
  RadioButtonCheckedSharp,
} from "@mui/icons-material";

interface SelectTemplatePopUp {
  isTemplate: boolean;
  templateList: TemplateList[];
  allControlList: AllControlsList[];
  pdfUrl: string;
  pdfScale: number;
  pageNumber: number;
  isPrivewPdf: boolean;
  activeTemplateIndex: number;
  selectedTemplate: SelectTemplateControl[];
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onCloseTemplate: () => void;
  onClickTemplateItem: (index: number) => void;
  onTemplateItemClick: (index: number) => void;
  onApplyTemplate: () => void;
  onNextPagePressed: () => void;
  onPreviousePressed: () => void;
}
const SelectTemplatePopUp: FC<SelectTemplatePopUp> = (
  props: SelectTemplatePopUp
) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: "90%",
    overflowY: "scroll",
  };
  return (
    <div className="st-wraper">
      <Modal
        className="pdf-preview-modal"
        open={props.isTemplate}
        onClose={props.onCloseTemplate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="st-top">
            <div className="sp-close" onClick={props.onCloseTemplate}>
              <img src={closeicon} alt="" />
            </div>
          </div>
          <div className="st-innerwraper">
            <div className="st-rightside">
              <p className="templates">Preview</p>

              <Document file={props.pdfUrl} onLoadSuccess={props.onLoadSuccess}>
                {props.selectedTemplate.length > 0 &&
                  props.selectedTemplate.map(
                    (item: SelectTemplateControl, index: number) => (
                      <>
                        {item.pageNum === props.pageNumber && (
                          <Draggable
                            disabled={true}
                            key={index}
                            cancel=".react-resizable-handle"
                            position={{
                              x: item.x,
                              y: item.y,
                            }}
                            bounds="parent"
                          >
                            <div
                              key={index}
                              style={{
                                zIndex: 1,
                                position: "absolute",
                              }}
                            >
                              {props.isPrivewPdf === false ? (
                                <>
                                  {item.name !== "dds-select" ? (
                                    <div
                                      style={{
                                        width: item.width,
                                        height: item.height,
                                      }}
                                    >
                                      <div>
                                        <IconButton
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border: "2px solid #6149cd",
                                            zIndex: 1,
                                            backgroundColor: "white",
                                          }}
                                        >
                                          {item.name === "dds-txt" ? (
                                            <FontDownload
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,
                                                cursor: !props.isPrivewPdf
                                                  ? "move"
                                                  : "default",
                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          ) : item.name === "dds-img" ? (
                                            <CameraAlt
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,
                                                cursor: !props.isPrivewPdf
                                                  ? "move"
                                                  : "default",
                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          ) : item.name === "dds-sign" ? (
                                            <TranslateSharp
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,
                                                cursor: !props.isPrivewPdf
                                                  ? "move"
                                                  : "default",
                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          ) : item.name === "dds-dnt-sign" ? (
                                            <ResizableBox
                                              width={item.width}
                                              height={item.height}
                                              minConstraints={[80, 20]}
                                            >
                                              <div
                                                style={{
                                                  width: item.width,
                                                  height: item.height,
                                                  textAlign: "center",
                                                  cursor: !props.isPrivewPdf
                                                    ? "move"
                                                    : "default",
                                                  border: "2px solid #6149cd",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                date and time
                                              </div>
                                            </ResizableBox>
                                          ) : item.name === "dds-initials" ? (
                                            <TranslateSharp
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,
                                                cursor: !props.isPrivewPdf
                                                  ? "move"
                                                  : "default",
                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          ) : item.name === "dds-sign-combo" ? (
                                            <TranslateSharp
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,
                                                cursor: !props.isPrivewPdf
                                                  ? "move"
                                                  : "default",
                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          ) : item.name === "dds-img-combo" ? (
                                            <CameraAlt
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,

                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          ) : item.name === "dds-dnt-combo" ? (
                                            <ResizableBox
                                              width={item.width}
                                              height={item.height}
                                              minConstraints={[80, 20]}
                                            >
                                              <div
                                                style={{
                                                  width: item.width,
                                                  height: item.height,
                                                  textAlign: "center",
                                                  // borderRadius: 10,
                                                  cursor: !props.isPrivewPdf
                                                    ? "move"
                                                    : "default",
                                                  border: "2px solid #6149cd",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                date and time
                                              </div>
                                            </ResizableBox>
                                          ) : (
                                            <RadioButtonCheckedSharp
                                              style={{
                                                width: item.width,
                                                height: item.height,
                                                borderRadius: 10,
                                                cursor: !props.isPrivewPdf
                                                  ? "move"
                                                  : "default",
                                                border: "2px solid #6149cd",
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                    >
                                      <IconButton>
                                        <CheckBox
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border: "2px solid #6149cd",
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.name !== "dds-select" ? (
                                    <>
                                      <div>
                                        <img
                                          src={
                                            item.name === "dds-txt"
                                              ? textInputImage
                                              : item.name === "dds-img"
                                              ? imageBoxImage
                                              : item.name === "dds-sign"
                                              ? signatureBoxIamge
                                              : item.name === "dds-initials"
                                              ? initalsBoxImage
                                              : radioButtonImage
                                          }
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        zIndex: 1,
                                        position: "absolute",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={multiSelectionImage}
                                          // onClick={}
                                          style={{
                                            borderRadius: 10,
                                            fontSize: 18,
                                            color: "black",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Draggable>
                        )}
                      </>
                    )
                  )}
                <Page scale={props.pdfScale} pageNumber={props.pageNumber} />
                <IconButton
                  onClick={props.onPreviousePressed}
                  className="prevPdf"
                >
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  onClick={props.onNextPagePressed}
                  className="nextPdf"
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Document>
            </div>
          </div>
          <Button
            className="onApplytemplate"
            variant="contained"
            // disabled={props.activeTemplateIndex === -1 ? true : false}
            style={{
              backgroundColor: "#6149CD",
            }}
            onClick={() => props.onApplyTemplate()}
          >
            Apply
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default SelectTemplatePopUp;
