import React, { useEffect, useRef, useState, useContext, FC } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { PDFDocument } from "pdf-lib";

import {
  AllControlsList,
  Control,
  Hierarchy,
  IReceipentList,
  ITemplateConrolsList,
  IZoneList,
  TemplateList,
  UserList,
  WorkFlow,
} from "../Types/Types";
import { ApiName } from "../Utils/ApiNames";
import { fetchGet, fetchPost, fetchPut } from "../FetchApi/FetchApi";
import UserContext from "../Utils/UserContext";
import CreateWorkFlow from "../Components/CreateWorkFlow";
import textInputImage from "../Assets/Text box-50x50.png";
import multiselectionImage from "../Assets/Multi selection-50x50.png";
import imageBoxImage from "../Assets/Image box-50x50.png";
import signatureBoxImage from "../Assets/Signature Box-50x50.png";
import initialsBoxImage from "../Assets/Initials -50x50.png";
import radioBoxImage from "../Assets/Radio Button -50x50.png";
import "../Styles/CreateWorkFlow.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { CREATEPDF, DASHBOARD } from "../Utils/Constants";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import { stat } from "fs";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface CreateWorkFlowScreen {
  elementRef: any;
}

let file = require("../sample.pdf");
let pdfWidth = 0;
let pdfHeight = 0;
let orignialPdf = "";
let workFlowTitle = "";
let workFlowDescription = "";
let isUpdate = true;
let emailMissing = -1;
let isEditUser = false;
let targetObject: any;

let pdfCenter = 0;
const CreateWorkFlowScreen: FC<CreateWorkFlowScreen> = (
  props: CreateWorkFlowScreen
) => {
  const context = useContext(UserContext);
  const { token, userList, updateTabIndex } = context;
  const { state } = useLocation();
  //hooks
  const [pdf, setPdf] = useState("");
  const [numPages, setNumPages] = useState<number>(-1);
  const [pageNumber, setPageNumber] = useState(1);

  const [locked, setLocked] = useState(false);
  const [width, setWidth] = useState(150);
  const [height, setHeight] = useState(150);

  const [zoom, setZoom] = useState(0.7);
  const [activeControlIndex, setActiveControlIndex] = useState(-1);

  const [viewControl, setOpenControl] = useState(false);

  const [controlId, setControlId] = useState(-1);
  const [mouseDrag, setMouseDrag] = useState(-1);

  const [pdfLoading, setPdfLoading] = useState({
    loading: true,
    message: "loading PDF...",
  });
  const [allControlsList, setAllControlsList] = useState<AllControlsList[]>([]);
  const [templateControlsList, setTemplateControlsList] = useState<
    AllControlsList[]
  >([]);
  const [isTemplateRolePopUp, setIsTemplateRolePopUp] = useState(false);
  const [message, setMessage] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(true);
  const [isInfo, setInfo] = useState(false);
  const [documentDetailsShow, setDocumentDetailsShow] = useState(true);
  const [activeItem, setActiveItem] = useState(-1);
  const [modifiedPdfBase64, setModifiedPdfBase64] = useState("");
  const [isPreviewPdf, setIsPrivewPdf] = useState(false);
  const [pdfScale, setPdfScale] = useState(1);
  const [emailError, setEmailError] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [nameSuggestionArray, setNameSuggestionArray] = useState<any>([]);
  const [emails, setEmails] = useState<any>([]);
  const [instructions, setInstructions] = useState("");
  const [activeHirearchyNumber, setActiveHirearchyNumber] = useState(0);
  const [contactList, setContactList] = useState<UserList[]>([]);
  const [templateList, setTemplateList] = useState<TemplateList[]>([]);
  const [isTemplate, setIsTemplate] = useState(false);
  const [activeTemplateIndex, setActiveTemplateIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplateList, setSelectiveTemplateList] = useState<
    AllControlsList[]
  >([]);
  const [receipentList, setReceipentList] = useState<IReceipentList[]>([]);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [filteredList, setFilteredList] = useState<AllControlsList[]>([]);
  const [filterSelectList, setSelectFilterList] = useState<IReceipentList[]>(
    []
  );
  const [isRecepient, setIsRecipent] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [editingRecipientId, setEditingRecipientId] = useState<string | null>(
    null
  );
  const [editingRecipient, setEditingRecipient] = useState<number>(-1);
  const [expiry, setExpiry] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [zoneList, setZoneList] = useState<IZoneList[]>([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [templateCreationControlsList, setTemplateCreationControlsList] =
    useState<ITemplateConrolsList[]>([]);
  const navigate = useNavigate();

  const [selectedEmail, setSelectedEmail] = useState("");

  const setActionThroughEmail = () => {
    targetObject = filterSelectList.find(
      (item) => item.email === selectedEmail
    );

    const targetEmail = targetObject?.id;
    const targetIndex = receipentList.findIndex(
      (item) => item?.id === targetEmail
    );

    if (targetIndex !== -1) {
      const targetObject = receipentList.splice(targetIndex, 1)[0];
      receipentList.unshift(targetObject);
    }
  };
  const pdfLength = async () => {
    const pdfDoc = await PDFDocument.load(orignialPdf);
    const pages = pdfDoc.getPages();

    const { width, height } = pages[0].getSize();
    pdfCenter = width / 2;
  };

  // Pdf dimensions

  // let elementRef = useRef(null);
  let hireacrhyList = [
    { id: 1, name: "H1" },
    { id: 2, name: "H2" },
    { id: 3, name: "H3" },
  ];
  const zoomPercentage = Math.round(zoom * 100);
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitReciepent(values.name, values.email);
      formik.resetForm();
      // onReciepentClosedPressed(false);
    },
  });
  useEffect(() => {
    if (token.length > 0) {
      if (state !== null) {
        getPdf(state.originalPdfBase64String);
        if (state.receipentList) {
          setReceipentList(state.receipentList);
          // let _tempObject = {
          //   id: -1,
          //   name: "All Users",
          //   email: "All Users",
          //   color: "25, 118, 210",
          //   borderColor: "rgb(105, 217, 248)",
          // };
          // let _filterSelectList = [_tempObject, ...state.receipentList];
          // setSelectFilterList(_filterSelectList);
          setSelectFilterList(state.receipentList);

          getZoneList();
          pdfLength();
        }
      } else {
        navigate(CREATEPDF);
      }
      getContactList();
      getTemplateList();
    }
  }, [token, userList]);
  useEffect(() => {
    setActionThroughEmail();
  }, [selectedEmail]);

  const getZoneList = async () => {
    try {
      let response = await fetchGet(token, ApiName.zoneList);
      if (response.status === 0) {
        setZoneList(response.response);
      }
    } catch (error) {
      console.log("getZoneList error: ", error);
    }
  };

  const getPdf = async (pdfBase64: any) => {
    try {
      if (state.fromScreen === "dashBoard") {
        const response = await fetch(pdfBase64);
        const pdfBlob = await response.blob();

        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64String = reader.result.split(",")[1]; // Extract base64 data from the result
            setPdf("data:application/pdf;base64," + base64String);
            orignialPdf = base64String;
          }
        };
        reader.readAsDataURL(pdfBlob);
        setPdfLoading({ loading: false, message: "" });
        setControls(
          state.editableData[0].controls,
          state.editableData[0].hierarchies
        );
        workFlowTitle = state.editableData[0].title;
        workFlowDescription = state.editableData[0].description;
        setEmailNotification(state.editableData[0].email_notification);
        setInstructions(state.editableData[0].instructions);
        isUpdate = true;
        getZoneList();
      } else if (state.fromScreen === "createWorkFlow") {
        if (state.template) {
          let updateControls = [];
          if (targetObject) {
            for (let i = 0; i < state.controlsList.length; i++) {
              updateControls.push({
                ...state.controlsList[i],
                borderColor: targetObject.borderColor,
                color: targetObject.color,
                userName: targetObject.name,
                userEmail: targetObject.email,
                hierarchyNumber: 1,
                mandatory: false,
              });
            }
          } else {
            for (let i = 0; i < state.controlsList.length; i++) {
              updateControls.push({
                ...state.controlsList[i],
                borderColor: state.receipentList[0].borderColor,
                color: state.receipentList[0].color,
                userName: state.receipentList[0].name,
                userEmail: state.receipentList[0].email,
                hierarchyNumber: 1,
                mandatory: false,
              });
            }
          }
          setAllControlsList(updateControls);
        }
        {
          setPdf("data:application/pdf;base64," + pdfBase64);
          orignialPdf = pdfBase64;
          console.log("this is pdf1", state);
          setPdfLoading({ loading: false, message: "" });
          isUpdate = false;
          workFlowTitle = "";
          workFlowDescription = "";
        }
      } else if (state.fromScreen === "createTemplate") {
        setPdf("data:application/pdf;base64," + pdfBase64);
        orignialPdf = pdfBase64;
        console.log("this is pdf2", state);
        setPdfLoading({ loading: false, message: "" });
        isUpdate = false;
        workFlowTitle = state.name;
        workFlowDescription = "";
      }
    } catch (error) {
      console.log("getUploadedDocument error: " + error);
    }
  };
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handleStop = () => {
    // setActiveItem(-1);
  };

  const handleLockToggle = () => {
    setLocked(!locked);
  };

  const onNextPagePressed = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const onPreviousePressed = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };
  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => prevZoom - 0.1);
  };
  const onControlClicked = (
    index: number,
    controlId: number,
    pageNumber: number,
    id: number
  ) => {
    try {
      if (state.fromScreen === "createTemplate") {
        console.log("onControlClicked", index, controlId, pageNumber, id);
        emailMissing = -1;
        let _index = templateCreationControlsList.findIndex(
          (item: ITemplateConrolsList) => item.id === id
        );
        setControlId(controlId);
        setActiveItem(templateCreationControlsList[_index].id);
        setPageNumber(templateCreationControlsList[_index].pageNum);

        // console.log("states", activeControlIndex, viewControl);
        if (id === activeControlIndex) {
          setOpenControl(!viewControl);
        } else {
          setActiveControlIndex(id);
          setOpenControl(true);
        }
      } else {
        console.log("onControlClicked", index, controlId, pageNumber, id);
        emailMissing = -1;
        let _index = allControlsList.findIndex(
          (item: AllControlsList) => item.id === id
        );
        setControlId(controlId);
        setActiveItem(allControlsList[_index].id);
        setPageNumber(allControlsList[_index].pageNum);

        // console.log("states", activeControlIndex, viewControl);
        if (id === activeControlIndex) {
          setOpenControl(!viewControl);
        } else {
          setActiveControlIndex(id);
          setOpenControl(true);
        }
      }
    } catch (error) {
      console.log("onControlClicked error: " + error);
    }
  };

  const onControlPdfClicked = (index: number) => {
    console.log("this is the control click", index);
    if (state.fromScreen === "createTemplate") {
      //  setControlId(controlId);
      setActiveItem(templateCreationControlsList[index].id);
      setPageNumber(templateCreationControlsList[index].pageNum);
      scrollToItem(index);

      // console.log("states", activeControlIndex, viewControl);
      if (index === activeControlIndex) {
        setOpenControl(true);
      } else {
        setActiveControlIndex(index);
        setOpenControl(true);
      }
      scrollToItem(index);
    } else {
      //  setControlId(controlId);
      setActiveItem(allControlsList[index].id);
      setPageNumber(allControlsList[index].pageNum);
      scrollToItem(index);

      // console.log("states", activeControlIndex, viewControl);
      if (index === activeControlIndex) {
        setOpenControl(true);
      } else {
        setActiveControlIndex(index);
        setOpenControl(true);
      }
      scrollToItem(index);
    }
  };

  const handleMouseDown = (id: number) => {
    setMouseDrag(id);
  };

  const handleMouseMovement = (event: any) => {
    const { clientX, clientY } = event;

    if (mouseDrag === 1) {
      console.log("first mouse", mouseDrag, receipentList);
      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;

        let _id = allControlsList.length - 1;
        let updateAllControlsList: AllControlsList[];

        if (targetObject) {
          updateAllControlsList = [
            ...allControlsList,
            {
              id:
                _id !== -1
                  ? allControlsList[_id].id + 1
                  : allControlsList.length + 1,
              name: "dds-txt",
              x: offsetX,
              y: offsetY,
              width: 150,
              height: 25,
              pageNum: pageNumber,
              mandatory: false,
              hierarchyNumber: 1,
              userName: targetObject.name,
              userEmail: targetObject.email,
              color: targetObject.color,
              borderColor: receipentList[0].borderColor,
            },
          ];
        } else {
          updateAllControlsList = [
            ...allControlsList,
            {
              id:
                _id !== -1
                  ? allControlsList[_id].id + 1
                  : allControlsList.length + 1,
              name: "dds-txt",
              x: offsetX,
              y: offsetY,
              width: 150,
              height: 25,
              pageNum: pageNumber,
              mandatory: false,
              hierarchyNumber: 1,
              userName: receipentList[0].name,
              userEmail: receipentList[0].email,
              color: receipentList[0].color,
              borderColor: receipentList[0].borderColor,
            },
          ];
        }
        setActiveItem(allControlsList.length + 1);

        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 2) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let _id = allControlsList.length - 1;
        console.log(_id);
        const updateAllControlsList = [
          ...allControlsList,
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 1
                : allControlsList.length + 1,
            name: "dds-select",
            x: offsetX,
            y: offsetY,
            width: 30,
            height: 30,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
        ];
        setActiveItem(allControlsList.length + 1);
        console.log("allControlsList: ", updateAllControlsList);
        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 3) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let _id = allControlsList.length - 1;
        console.log(_id);
        const updateAllControlsList = [
          ...allControlsList,
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 1
                : allControlsList.length + 1,
            name: "dds-img",
            x: offsetX,
            y: offsetY,
            width: 50,
            height: 50,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
        ];
        setActiveItem(allControlsList.length + 1);
        console.log("allControlsList: ", updateAllControlsList);
        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 4) {
      console.log("singature mouse", mouseDrag);
      console.log(pdfCenter);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let datetimeoffset;
        if (offsetX > pdfCenter) {
          console.log("this is offset" + offsetX);
          datetimeoffset = offsetX - 145;
        } else {
          console.log("this is offset" + offsetX);
          datetimeoffset = offsetX + 145;
        }

        let _id = allControlsList.length - 1;
        console.log(_id);
        const updateAllControlsList = [
          ...allControlsList,
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 1
                : allControlsList.length + 1,
            name: "dds-sign",
            x: offsetX,
            y: offsetY,
            width: width,
            height: height,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 2
                : allControlsList.length + 2,
            name: "dds-dnt-sign",
            x: datetimeoffset,
            y: offsetY + 20,
            width: width,
            height: 50,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
        ];
        setActiveItem(allControlsList.length + 1);
        console.log("allControlsList: ", updateAllControlsList);
        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 5) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let _id = allControlsList.length - 1;
        console.log(_id);
        const updateAllControlsList = [
          ...allControlsList,
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 1
                : allControlsList.length + 1,
            name: "dds-initials",
            x: offsetX,
            y: offsetY,
            width: width,
            height: height,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
        ];
        setActiveItem(allControlsList.length + 1);
        console.log("allControlsList: ", updateAllControlsList);
        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 6) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;

        let _id = allControlsList.length - 1;
        console.log(_id);
        const updateAllControlsList = [
          ...allControlsList,
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 1
                : allControlsList.length + 1,
            name: "dds-radio",
            x: offsetX,
            y: offsetY,
            width: 30,
            height: 30,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
        ];
        setActiveItem(allControlsList.length + 1);
        console.log("allControlsList: ", updateAllControlsList);
        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 7) {
      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let datetimeoffset;
        if (offsetX > pdfCenter) {
          console.log("this is offset" + offsetX);
          datetimeoffset = offsetX - 145;
        } else {
          console.log("this is offset" + offsetX);
          datetimeoffset = offsetX + 145;
        }

        let _id = allControlsList.length - 1;
        console.log(_id);
        const updateAllControlsList = [
          ...allControlsList,
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 1
                : allControlsList.length + 1,
            name: "dds-sign-combo",
            x: offsetX,
            y: offsetY,
            width: width,
            height: height,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 2
                : allControlsList.length + 2,
            name: "dds-img-combo",
            x: datetimeoffset,
            y: offsetY + 100,
            width: width,
            height: height,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
          {
            id:
              _id !== -1
                ? allControlsList[_id].id + 3
                : allControlsList.length + 3,
            name: "dds-dnt-combo",
            x: datetimeoffset,
            y: offsetY + 20,
            width: width,
            height: 50,
            pageNum: pageNumber,
            mandatory: false,
            hierarchyNumber: 1,
            // userId: userList.length > 0 ? userList[0].id : -1,
            userName: receipentList[0].name,
            userEmail: receipentList[0].email,
            color: receipentList[0].color,
            borderColor: receipentList[0].borderColor,
          },
        ];
        setActiveItem(allControlsList.length + 1);
        console.log("allControlsList: ", updateAllControlsList);
        setAllControlsList(updateAllControlsList);
        setMouseDrag(-1);
      }
    }
  };

  const onMandatoryPressed = (index: number): void => {
    const updateAllControlsList = [...allControlsList];
    updateAllControlsList[index] = {
      ...updateAllControlsList[index],
      mandatory: !allControlsList[index].mandatory,
    };
    setAllControlsList(updateAllControlsList);
  };

  const savePdfData = async () => {
    try {
      const originalPdfBytes = await fetch(pdf).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(originalPdfBytes);

      const pages = pdfDoc.getPages();

      const textInputBytes = await fetch(textInputImage).then((res) =>
        res.arrayBuffer()
      );
      const textInputPngImage = await pdfDoc.embedPng(textInputBytes);

      const multiselectionImageBuffer = await fetch(multiselectionImage).then(
        (res) => res.arrayBuffer()
      );
      const multiSelectionPngImage = await pdfDoc.embedPng(
        multiselectionImageBuffer
      );

      const imageBoxImageBuffer = await fetch(imageBoxImage).then((res) =>
        res.arrayBuffer()
      );
      const imageBoxPngImage = await pdfDoc.embedPng(imageBoxImageBuffer);

      const signatureBoxImageBuffer = await fetch(signatureBoxImage).then(
        (res) => res.arrayBuffer()
      );
      const signatureBoxImageBufferPngImage = await pdfDoc.embedPng(
        signatureBoxImageBuffer
      );

      const initialsBoxImageBuffer = await fetch(initialsBoxImage).then((res) =>
        res.arrayBuffer()
      );
      const initialBoxImageBufferPngImage = await pdfDoc.embedPng(
        initialsBoxImageBuffer
      );

      const radioBoxImageBuffer = await fetch(radioBoxImage).then((res) =>
        res.arrayBuffer()
      );

      const radioBoxImageBufferPngImage = await pdfDoc.embedPng(
        radioBoxImageBuffer
      );
      if (allControlsList.length > 0) {
        let i: number = 0;
        while (i < allControlsList.length) {
          // console.log("allControlsList", allControlsList[i]);
          const currentPage = pages[allControlsList[i].pageNum - 1];
          // currentPage.scale(0.7, 0.7);
          pdfWidth = currentPage.getWidth();
          pdfHeight = currentPage.getHeight();

          let pdfX = allControlsList[i].x;
          let pdfY = allControlsList[i].y;
          let ratio = 1 / pdfScale;
          pdfX = pdfX * ratio;
          pdfY = pdfY * ratio;
          // pdfY = pdfHeight - pdfY - textInputPngImage.height;
          // + allControlsList[i].y * 0.3
          // console.warn("X", pdfX, "Y", pdfY);
          // if (pdfX > 416.5 / 2) pdfX = pdfX + 178;
          pdfY = pdfHeight - pdfY - allControlsList[i].height * ratio; // image height
          // if (pdfY > 589.5 / 2) pdfY = pdfY + 53;
          // console.warn("pdfX", pdfX, "pdfY", pdfY);

          // if (allControlsList[i].name === "dds-txt") {
          //   currentPage.drawImage(textInputPngImage, {
          //     x: pdfX,
          //     y: pdfY,
          //     width: allControlsList[i].width,
          //     height: allControlsList[i].height,
          //   });
          // } else

          // if (allControlsList[i].name === "dds-select") {
          //   currentPage.drawImage(multiSelectionPngImage, {
          //     x: pdfX,
          //     y: pdfY,
          //     width: allControlsList[i].width,
          //     height: allControlsList[i].height,
          //   });
          // } else

          // if (allControlsList[i].name === "dds-img") {
          //   currentPage.drawImage(imageBoxPngImage, {
          //     x: pdfX,
          //     y: pdfY,
          //     width: allControlsList[i].width * ratio,
          //     height: allControlsList[i].height * ratio,
          //   });
          // }
          //  else if (allControlsList[i].name === "dds-sign") {
          //   currentPage.drawImage(signatureBoxImageBufferPngImage, {
          //     x: pdfX,
          //     y: pdfY,
          //     width: allControlsList[i].width,
          //     height: allControlsList[i].height,
          //   });
          // }
          //  else if (allControlsList[i].name === "dds-initials") {
          //   currentPage.drawImage(initialBoxImageBufferPngImage, {
          //     x: pdfX,
          //     y: pdfY,
          //     width: allControlsList[i].width,
          //     height: allControlsList[i].height,
          //   });
          // }
          // else if (allControlsList[i].name === "dds-radio") {
          //   currentPage.drawImage(radioBoxImageBufferPngImage, {
          //     x: pdfX,
          //     y: pdfY,
          //     width: allControlsList[i].width,
          //     height: allControlsList[i].height,
          //   });
          // }
          i++;
        }
      }

      var pdfBase = await pdfDoc.saveAsBase64();
      console.warn("pdfBase ::", pdfBase);
      setModifiedPdfBase64("data:application/pdf;base64," + pdfBase);
      return pdfBase;
    } catch (error) {}
  };

  const onDragControlPressed = (
    e: any,
    data: { x: number; y: number },
    index: number
  ) => {
    try {
      // console.log("allControls", allControlsList[index]);
      if (state.fromScreen === "createTemplate") {
        emailMissing = -1;
        const { x, y } = data;
        const _templateCreationControlsList = [...templateCreationControlsList];
        _templateCreationControlsList[index] = {
          ..._templateCreationControlsList[index],
          x,
          y,
        };
        setTemplateCreationControlsList(_templateCreationControlsList);
        setActiveItem(templateCreationControlsList[index].id);
      } else {
        emailMissing = -1;
        const { x, y } = data;
        const updateAllControlsList = [...allControlsList];
        updateAllControlsList[index] = {
          ...updateAllControlsList[index],
          x,
          y,
        };
        setAllControlsList(updateAllControlsList);
        setActiveItem(allControlsList[index].id);
      }
    } catch (error) {
      console.log("onDragControlPressed error: " + error);
    }
  };

  const onResizeControlsPressed = (
    event: any,
    { size }: { size: { width: number; height: number } },
    index: number
  ) => {
    const { width, height } = size;
    // console.log("width: " + width + " height: " + height);

    if (state.fromScreen === "createTemplate") {
      const _templateCreationControlsList = [...templateCreationControlsList];
      _templateCreationControlsList[index] = {
        ..._templateCreationControlsList[index],
        // isSaved: false,
        height: height,
        width: width,
      };
      setTemplateCreationControlsList(_templateCreationControlsList);
    } else {
      const updatedAllControlList = [...allControlsList];
      updatedAllControlList[index] = {
        ...updatedAllControlList[index],
        // isSaved: false,
        height: height,
        width: width,
      };
      setAllControlsList(updatedAllControlList);
    }
  };

  const onHireacrhyDropDownPressed = (id: any, index: number) => {
    const updateAllControlsList = [...allControlsList];
    updateAllControlsList[index] = {
      ...updateAllControlsList[index],
      hierarchyNumber: parseInt(id),
    };
    setAllControlsList(updateAllControlsList);
  };
  // const onChangeUserId = (id: any, index: number) => {
  //   const updateAllControlsList = [...allControlsList];
  //   updateAllControlsList[index] = {
  //     ...updateAllControlsList[index],
  //     userId: parseInt(id),
  //   };
  //   setAllControlsList(updateAllControlsList);
  // };
  const workFlowData = async (
    workFlowTitle: string,
    workFlowDescription: string
  ) => {
    setFilteredList([]);
    setIsLoading(true);
    let _allControlList = [...allControlsList];
    _allControlList = _allControlList.sort(
      (a, b) => a.hierarchyNumber - b.hierarchyNumber
    );

    let modifiedPdfData = await savePdfData();
    let data = {
      title: workFlowTitle,
      description: workFlowDescription,
      originalPdfBase64: orignialPdf,
      //for time i am using same original pdf base64
      modifiedPdfBase64: modifiedPdfData,
      // saveAsTemplate: saveAsTemplate,
      // templateName: templateName,
      emailNotification: emailNotification,
      smsNotification: smsNotification,
      instructions: instructions,
      hierarchies: [] as any[],
      controls: [] as Control[],
      expiry: {
        mandatory: expiry,
        dateTime: dateTime,
        timeZone: selectedZone,
      },
    };

    let result = await checkHirearchyOrder(_allControlList);
    console.log("resultt", result);
    if (result.result === false) {
      if (result.value === 1) {
        setIsLoading(false);
        setInfo(true);
        // setMessage(`hierarchy${result.value} is missing`);
        setMessage(`Incorrect Order Sequence`);
      } else {
        setIsLoading(false);
        setInfo(true);
        // setMessage(`hierarchy${result.value} is missing`);
        setMessage(`Incorrect Order Sequence`);
      }
    } else if (result.result === true) {
      let i = 0;
      let noEmailFound = false;
      while (i < allControlsList.length) {
        console.log("allControls email", _allControlList[i].userEmail.length);
        if (_allControlList[i].userEmail.length === 0) {
          setIsLoading(false);
          setInfo(true);
          setMessage(`control number ${i + 1} requires email`);
          setActiveControlIndex(i);
          setActiveItem(allControlsList[i].id);
          setPageNumber(allControlsList[i].pageNum);
          emailMissing = allControlsList[i].id;
          setOpenControl(true);
          noEmailFound = true;
          break;
        } else {
          data.controls.push({
            id: _allControlList[i].id,
            name: _allControlList[i].name,
            description: "",
            pageNum: _allControlList[i].pageNum,
            x: _allControlList[i].x,
            y: _allControlList[i].y,
            width: _allControlList[i].width,
            height: _allControlList[i].height,
            color: _allControlList[i].color,
            borderColor: _allControlList[i].borderColor,
          });
        }

        i++;
      }

      // Group the data by hierarchyNumber
      interface User {
        email: string;
        name: string;
        actions: { controlId: number; mandatory: boolean }[];
      }
      if (noEmailFound === false) {
        let groupedData: any[] = _allControlList.reduce(
          (acc: any[], current) => {
            const { hierarchyNumber, userName, userEmail, mandatory, id } =
              current;
            const user: User = {
              email: userEmail,
              name: userName,
              actions: [],
            };

            // Check if the hierarchyNumber already exists in the accumulator (acc) array
            const existingHierarchy = acc.find(
              (item) => item.hierarchyNumber === hierarchyNumber
            );

            if (existingHierarchy) {
              // Check if the user already exists in the hierarchy
              const existingUser = existingHierarchy.users.find(
                (u: any) => u.email === userEmail
              );

              if (existingUser) {
                // If the user exists, add the action to the existing user's actions
                existingUser.actions.push({ controlId: id, mandatory });
              } else {
                // If the user does not exist, add the user to the hierarchy
                user.actions.push({ controlId: id, mandatory });
                existingHierarchy.users.push(user);
              }
            } else {
              // If the hierarchyNumber doesn't exist, create a new hierarchy entry with the user
              user.actions.push({ controlId: id, mandatory });
              acc.push({ hierarchyNumber, users: [user] });
            }

            return acc;
          },
          []
        );
        console.log("groupedData", groupedData);

        data.hierarchies = [...groupedData];
        console.log("workflowdata", data);
        if (isUpdate) {
          editWorkFlow(data);
        } else {
          initiateWorkFlow(data);
        }
      }
    }
  };

  const checkHirearchyOrder = (_allControlList: AllControlsList[]) => {
    let notFound = -1;
    let returnObj: any = {};
    if (
      _allControlList.length === 0 ||
      _allControlList[0].hierarchyNumber !== 1
    ) {
      returnObj = { result: false, value: 1 };
      return returnObj;
    }

    const highestHirearchyNumber =
      _allControlList[_allControlList.length - 1].hierarchyNumber;
    const lowestHirearchyNumber = _allControlList[0].hierarchyNumber;

    for (let i = lowestHirearchyNumber + 1; i < highestHirearchyNumber; i++) {
      if (!_allControlList.some((item) => item.hierarchyNumber === i)) {
        console.log("not found", i); // There is a missing number
        // setInfo(true);
        // setMessage(`hierarchy${i} is missing`);
        returnObj = { result: false, value: i };
        return returnObj;
      }
    }
    // console.log("notfound", notFound);
    returnObj = { result: true, value: -1 };
    return returnObj;
  };

  const initiateWorkFlow = async (data: any) => {
    try {
      console.log("initiateWorkFlowdata", data);
      if (allControlsList.length > 0) {
        if (data.controls.length === 0) {
          setIsLoading(false);
          setInfo(true);
          setMessage("please add at least one control");
        } else {
          setMessage("");
          let response = await fetchPost(ApiName.initiateWorkFlow, token, data);
          console.log("response", response);

          if (response.status === 0) {
            console.log("response", response);
            // navigate(DASHBOARD);
            setIsPrivewPdf(true);
            setZoomLevel(1);
          } else if (response.status === 1) {
            console.log("response", response);
            if (
              response.response === "Provided template name is already in use"
            ) {
              setIsLoading(false);
              setMessage("Template name already exists");
              setIsTemplateRolePopUp(true);
            } else if (
              response.response ===
              "One or more required fields are missing or empty"
            ) {
              setIsLoading(false);
              setInfo(true);
              setMessage("Please fill all properties");
            } else if (
              response.response === "A workflow with this Title already exists"
            ) {
              setIsLoading(false);
              setInfo(true);
              setMessage("workflow title already exists");
            }
          }
        }
      } else {
        setIsLoading(false);
        setInfo(true);
        setMessage("please add at least one control");
      }
    } catch (error) {
      console.log("initiateWorkFlow error", error);
    }
  };
  const onAddTemplatePressed = () => {
    setIsTemplateRolePopUp(!isTemplateRolePopUp);
    setMessage("");
    if (templateName.length > 0) {
      setSaveAsTemplate(false);
    }
  };
  const onSubmitTemplateName = async (name: string) => {
    console.log("template name", name);
    setTemplateName(name);
    setIsTemplateRolePopUp(false);
    let data = {
      name: name,
      pdfBase64: orignialPdf,
      controls: [] as Control[],
    };
    for (let i = 0; i < allControlsList.length; i++) {
      data.controls.push({
        id: allControlsList[i].id,
        name: allControlsList[i].name,
        description: "",
        pageNum: allControlsList[i].pageNum,
        x: allControlsList[i].x,
        y: allControlsList[i].y,
        width: allControlsList[i].width,
        height: allControlsList[i].height,
        color: allControlsList[i].color,
        borderColor: allControlsList[i].borderColor,
      });
    }
    let res = await fetchPost(ApiName.saveTemplate + "/withFile", token, data);
    if (res.status === 0) {
      setMessage("Template Saved Successfully");
      setInfo(true);
      // getTemplateList();
    } else if (res.status === 1) {
      setMessage(`${res.response}`);
      setInfo(true);
    }
  };
  const updateMessage = () => {
    setMessage("");
  };

  const onEmailNotificationPressed = () => {
    setEmailNotification(!emailNotification);
  };
  const onSmsNotificationPressed = () => {
    setSmsNotification(!smsNotification);
  };
  const onCreateTemplatePressed = () => {
    setSaveAsTemplate(true);
    setIsTemplateRolePopUp(!isTemplateRolePopUp);
    setMessage("");
  };

  const onInfoClosedPressed = () => {
    setInfo(false);
    setMessage("");
  };

  const onOkPressed = () => {
    setInfo(false);
    setMessage("");
  };
  const onDoucmentDetailsPressed = () => {
    setDocumentDetailsShow(!documentDetailsShow);
  };

  const onHidePreview = () => {
    setIsPrivewPdf(false);
    setZoomLevel(1);
    navigate(DASHBOARD);
    updateTabIndex(0);
  };
  const onChangeControlUserName = (
    val: string,
    index: number,
    email: string
  ) => {
    const updateAllControlsList = [...allControlsList];
    for (let i = 0; i < updateAllControlsList.length; i++) {
      if (updateAllControlsList[i].userEmail !== "" && email !== "")
        if (updateAllControlsList[i].userEmail === email) {
          updateAllControlsList[i].userName = val;
        }
    }
    updateAllControlsList[index] = {
      ...updateAllControlsList[index],
      userName: val,
    };
    setAllControlsList(updateAllControlsList);
  };
  const onChangeControlUserEmail = (val: string, index: number) => {
    console.log("there are emails", emails);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (regex.test(val)) {
      let _emails = [...emails];
      if (!_emails.includes(val)) {
        _emails.push(val);
      }
      console.log("new emails", _emails);
      setEmails(_emails);
      setEmailError("");
    } else {
      setEmailError("please enter proper email");
    }

    const updateAllControlsList = [...allControlsList];
    if (updateAllControlsList[index].name === "dds-sign-combo") {
      updateAllControlsList[index] = {
        ...updateAllControlsList[index],
        userEmail: val,
      };
      updateAllControlsList[index + 1] = {
        ...updateAllControlsList[index + 1],
        userEmail: val,
      };
      updateAllControlsList[index + 2] = {
        ...updateAllControlsList[index + 2],
        userEmail: val,
      };
    } else {
      updateAllControlsList[index] = {
        ...updateAllControlsList[index],
        userEmail: val,
      };
    }
    // for (let i = 0; i < updateAllControlsList.length; i++) {
    //   if (updateAllControlsList[i].id === updateAllControlsList[index].id) {
    //     console.log("change bro", updateAllControlsList[i].id);
    //     updateAllControlsList[i].userEmail = val;
    //     updateAllControlsList[i+1].userEmail = val;
    //   }
    // }
    setAllControlsList(updateAllControlsList);
    sameEmailcheck(updateAllControlsList);
  };

  const onLeaveFocusEmail = () => {
    if (emailError.length > 0) {
      setShowEmailError(true);
    } else {
      setShowEmailError(false);
    }
  };

  const fitlerUserData = (data: UserList[]) => {
    let tempEmail = [];
    let tempNames = [];
    for (let i = 0; i < data.length; i++) {
      tempEmail.push(data[i].email);
      tempNames.push(data[i].name);
    }
    setEmails(tempEmail);
    setNameSuggestionArray(tempNames);
  };

  const sameEmailcheck = (updateAllControlsList: AllControlsList[]) => {
    const emailMap: { [email: string]: string } = {};
    // console.log(updateAllControlsList, "bro");
    const updatedData = updateAllControlsList.map((obj) => {
      if (!emailMap[obj.userEmail]) {
        emailMap[obj.userEmail] = obj.userName;
      } else if (emailMap[obj.userEmail] !== obj.userName) {
        // If the email already exists with a different name, update the name
        return { ...obj, userName: emailMap[obj.userEmail] };
      }
      return obj;
    });
    console.log(updatedData);
    setAllControlsList(updatedData);
  };

  const onChangeInstructions = (value: string) => {
    setInstructions(value);
  };

  const getContactList = async () => {
    try {
      let response = await fetchGet(token, ApiName.contactList);
      if (response.status === 0) {
        if (response.response.length > 0) {
          // console.log("response", response);
          setContactList(response.response);
          fitlerUserData(response.response);
        }
      }
    } catch (error) {}
  };
  const onDoucmentDetailsPressed2 = () => {
    setDocumentDetailsShow(true);
  };
  const onAddHierarchyButton = (index: number) => {
    const updatedAllControlList = [...allControlsList];
    if (updatedAllControlList[index].name === "dds-sign-combo") {
      // updatedAllControlList[index] = {
      //   ...updatedAllControlList[index],
      //   hierarchyNumber: updatedAllControlList[index].hierarchyNumber + 1,
      // };
      updatedAllControlList[index].hierarchyNumber =
        updatedAllControlList[index].hierarchyNumber + 1;
      updatedAllControlList[index + 1].hierarchyNumber =
        updatedAllControlList[index + 1].hierarchyNumber + 1;
      updatedAllControlList[index + 2].hierarchyNumber =
        updatedAllControlList[index + 2].hierarchyNumber + 2;
    } else {
      updatedAllControlList[index] = {
        ...updatedAllControlList[index],
        hierarchyNumber: updatedAllControlList[index].hierarchyNumber + 1,
      };
    }
    console.log("onAddHierarchyButton", updatedAllControlList);
    setAllControlsList(updatedAllControlList);
  };
  const onSubtractHierarchyButton = (index: number) => {
    const updatedAllControlList = [...allControlsList];
    if (updatedAllControlList[index].name === "dds-sign-combo") {
      updatedAllControlList[index] = {
        ...updatedAllControlList[index],
        hierarchyNumber:
          updatedAllControlList[index].hierarchyNumber > 1
            ? updatedAllControlList[index].hierarchyNumber - 1
            : updatedAllControlList[index].hierarchyNumber,
      };
      updatedAllControlList[index + 1] = {
        ...updatedAllControlList[index + 1],
        hierarchyNumber:
          updatedAllControlList[index + 1].hierarchyNumber > 1
            ? updatedAllControlList[index + 1].hierarchyNumber - 1
            : updatedAllControlList[index + 1].hierarchyNumber,
      };

      updatedAllControlList[index + 2] = {
        ...updatedAllControlList[index + 2],
        hierarchyNumber:
          updatedAllControlList[index + 2].hierarchyNumber > 1
            ? updatedAllControlList[index + 2].hierarchyNumber - 1
            : updatedAllControlList[index + 2].hierarchyNumber,
      };
    } else {
      updatedAllControlList[index] = {
        ...updatedAllControlList[index],
        hierarchyNumber:
          updatedAllControlList[index].hierarchyNumber > 1
            ? updatedAllControlList[index].hierarchyNumber - 1
            : updatedAllControlList[index].hierarchyNumber,
      };
    }
    console.log("onSubtractHierarchyButton", updatedAllControlList);

    setAllControlsList(updatedAllControlList);
  };
  const getTemplateList = async () => {
    try {
      let response = await fetchGet(token, ApiName.templateList);
      let updateAllControlsList: TemplateList[] = [];
      if (response.status === 0) {
        updateAllControlsList = [...response.response];
        setTemplateList(updateAllControlsList);
      }
    } catch (error) {
      console.log("getTemplateList error" + error);
    }
  };

  const openTemplateClick = () => {
    setIsTemplate(true);
  };
  const onCloseTemplate = () => {
    // setAllControlsList([]);
    setIsTemplate(false);
    setActiveTemplateIndex(-1);
    setPageNumber(1);
  };
  const onClickTemplateItem = (index: number) => {
    let _foundTemplate = templateList[index].controls;
    console.log("foundTemplate", _foundTemplate);
    if (templateList[index].maxPages > numPages) {
      setMessage(
        "The document contains fewer pages than the template's specified page count."
      );
      setInfo(true);
    } else {
      setTemplateControls(_foundTemplate);

      // setIsTemplate(false);
    }
  };

  const onTemplateItemClick = (index: number) => {
    setActiveTemplateIndex(index);
    onClickTemplateItem(index);
  };

  const onApplyTemplate = () => {
    setIsTemplate(false);
    setPageNumber(1);
    setAllControlsList(templateControlsList);
  };

  const setControls = (_foundTemplate: any, actions: any) => {
    console.log("setControls", _foundTemplate, "actions", actions);

    const data: any = {
      hierarchies: actions,
      controls: _foundTemplate,
    };
    // Create an object to store the mapped data
    const mappedData: any = [];

    // Loop through each hierarchy and its users
    data.hierarchies.forEach((hierarchy: any) => {
      hierarchy.users.forEach((user: any) => {
        // Loop through each action of the user
        user.actions.forEach((action: any) => {
          // Find the corresponding control using controlId
          const control = data.controls.find(
            (control: any) => control.id === action.controlId
          );

          // If a matching control is found, map the data
          if (control) {
            const mappedItem = {
              id: control.id,
              name: control.name,
              x: control.x,
              y: control.y,
              width: control.width,
              height: control.height,
              pageNum: control.pageNum,
              mandatory: action.mandatory,
              hierarchyNumber: hierarchy.hierarchyNumber,
              userName: user.name || "",
              userEmail: user.email || "",
              borderColor: control.borderColor,
              color: control.color,
            };
            mappedData.push(mappedItem);
          }
        });
      });
    });

    // Print the mapped data
    console.log(mappedData);
    let userArray: any = [];
    data.hierarchies.forEach((hierarchy: any) => {
      hierarchy.users.forEach((user: any) => {
        // Check if user ID is already in the userArray
        let existingUser = userArray.find(
          (existingUser: any) => existingUser.id === user.id
        );

        if (!existingUser) {
          // If user not in userArray, add it
          existingUser = {
            id: user.id,
            name: user.name,
            email: user.email,
            color: null, // Initialize color property
            borderColor: null, // Initialize borderColor property
          };

          userArray.push(existingUser);
        }

        // Iterate through user's actions and find matching controls
        user.actions.forEach((action: any) => {
          let matchingControl = data.controls.find(
            (control: any) => control.id === action.controlId
          );

          if (matchingControl) {
            // Add relevant control information to the existing user directly
            existingUser.color = matchingControl.color;
            existingUser.borderColor = matchingControl.borderColor;
          }
        });
      });
    });

    console.log(userArray);
    setReceipentList(userArray);
    // let _tempObject = {
    //   id: -1,
    //   name: "All Users",
    //   email: "All Users",
    //   color: "25, 118, 210",
    //   borderColor: "rgb(105, 217, 248)",
    // };
    // let _filterSelectList = [_tempObject, ...userArray];
    // setSelectFilterList(_filterSelectList);
    setSelectFilterList(userArray);
    // setContactList(mappedData);
    setAllControlsList(mappedData.sort((a: any, b: any) => a.id - b.id));
  };

  const setTemplateControls = (_foundTemplate: any) => {
    console.log("setTemplateControls", _foundTemplate);
    let updateAllControlsList = [];
    for (let i = 0; i < _foundTemplate.length; i++) {
      if (_foundTemplate[i].name === "dds-txt") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-txt",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-select") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-select",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-img") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-img",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-sign") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-sign",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-initials") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-initials",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-radio") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-radio",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-sign-combo") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-sign-combo",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-img-combo") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-img-combo",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-dnt-combo") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-dnt-combo",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-dnt-sign") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-dnt-sign",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
          mandatory: false,
          hierarchyNumber: 1,
          userName: receipentList[0].name,
          userEmail: receipentList[0].email,
          color: receipentList[0].color,
          borderColor: receipentList[0].borderColor,
        });
        //  setAllControlsList(updateAllControlsList);
        setTemplateControlsList(updateAllControlsList);
      }
    }
  };

  const editWorkFlow = async (data: any) => {
    try {
      data.id = state.editableData[0].id;
      console.log("editWorkFlow", data);
      if (allControlsList.length > 0) {
        if (data.controls.length === 0) {
          setIsLoading(false);
          setInfo(true);
          setMessage("please add at least one control");
        } else {
          setMessage("");
          let response = await fetchPut(token, ApiName.editWorkFlow, data);
          console.log("response", response);

          if (response.status === 0) {
            console.log("response", response);
            // navigate(DASHBOARD);
            setIsPrivewPdf(true);
          } else if (response.status === 1) {
            console.log("response", response);
            if (
              response.response === "Provided template name is already in use"
            ) {
              setIsLoading(false);
              setMessage("Template name already exists");
              setIsTemplateRolePopUp(true);
            } else if (
              response.response ===
              "One or more required fields are missing or empty"
            ) {
              setIsLoading(false);
              setInfo(true);
              setMessage("Please fill all properties");
            } else if (
              response.response === "A workflow with this Title already exists"
            ) {
              setIsLoading(false);
              setInfo(true);
              setMessage("workflow title already exists");
            } else if (
              response.response === "Edit no longer allowed for this workflow"
            ) {
              setIsLoading(false);
              setMessage("Editing is no longer permitted for this workflow.");
              setInfo(true);
            }
          }
        }
      } else {
        setIsLoading(false);
        setInfo(true);
        setMessage("please add at least one control");
      }
    } catch (error) {
      console.log("initiateWorkFlow error", error);
    }
  };

  const onDeleteControlPressed = (id: number) => {
    console.log("deleteControlPressed", id);
    if (state.fromScreen === "createTemplate") {
      let _templateCreationControlsList = [...templateCreationControlsList];
      console.log("updateAllControlsList", _templateCreationControlsList);
      let deletIndex = _templateCreationControlsList.findIndex(
        (item: ITemplateConrolsList) => item.id === id
      );
      console.log(_templateCreationControlsList[deletIndex]);
      if (_templateCreationControlsList[deletIndex].name === "dds-sign-combo") {
        console.log(
          "here",
          _templateCreationControlsList[deletIndex],
          _templateCreationControlsList[deletIndex + 1]
        );
        _templateCreationControlsList.splice(deletIndex, 3);
      } else if (
        _templateCreationControlsList[deletIndex].name === "dds-sign"
      ) {
        console.log(
          "here",
          _templateCreationControlsList[deletIndex],
          _templateCreationControlsList[deletIndex + 1]
        );
        _templateCreationControlsList.splice(deletIndex, 2);
      } else {
        _templateCreationControlsList.splice(deletIndex, 1);
      }
      console.log(
        "_templateCreationControlsList",
        _templateCreationControlsList
      );
      _templateCreationControlsList.sort((a, b) => a.id - b.id);
      console.log(
        "_templateCreationControlsList",
        _templateCreationControlsList
      );
      setTemplateCreationControlsList(_templateCreationControlsList);
    } else {
      let updateAllControlsList = [...allControlsList];
      console.log("updateAllControlsList", updateAllControlsList);
      let deletIndex = updateAllControlsList.findIndex(
        (item: AllControlsList) => item.id === id
      );
      console.log(updateAllControlsList[deletIndex]);
      if (updateAllControlsList[deletIndex].name === "dds-sign-combo") {
        console.log(
          "here",
          updateAllControlsList[deletIndex],
          updateAllControlsList[deletIndex + 1]
        );
        updateAllControlsList.splice(deletIndex, 3);
      } else if (updateAllControlsList[deletIndex].name === "dds-sign") {
        console.log(
          "here",
          updateAllControlsList[deletIndex],
          updateAllControlsList[deletIndex + 1]
        );
        updateAllControlsList.splice(deletIndex, 2);
      } else {
        updateAllControlsList.splice(deletIndex, 1);
      }
      console.log("updateAllControlsList", updateAllControlsList);
      updateAllControlsList.sort((a, b) => a.id - b.id);
      console.log("updateAllControlsList", updateAllControlsList);
      setAllControlsList(updateAllControlsList);
    }
  };

  const scrollToItem = (index: number) => {
    console.log("scrollToItem", index, listContainerRef);
    setTimeout(() => {
      if (listContainerRef.current) {
        const itemElement = listContainerRef.current.children[index];
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 500);
  };

  const onSelectRecipientPressed = (_userEmail: any, _controlId: number) => {
    try {
      console.log("onSelectRecipientPressed ::", _userEmail, _controlId);
      let _updateControlList = [...allControlsList];
      // Find the index of the item in _updateControlList with the matching _userEmail
      const selectedIndex = _updateControlList.findIndex(
        (item: AllControlsList) => item.id === _controlId
      );
      console.log("selectedIndex", selectedIndex);

      if (selectedIndex !== -1) {
        // Find the corresponding recipient in the recipient list

        if (_updateControlList[selectedIndex].name == "dds-sign-combo") {
          console.log(
            "this is img combo",
            _updateControlList[selectedIndex].name
          );
          const matchingRecipient = receipentList.find(
            (recipient: IReceipentList) => recipient.email === _userEmail
          );
          if (matchingRecipient) {
            // Update the userEmail of the item at the found index
            _updateControlList[selectedIndex].userEmail =
              matchingRecipient.email;
            _updateControlList[selectedIndex].userName = matchingRecipient.name;
            _updateControlList[selectedIndex].color = matchingRecipient.color;
            _updateControlList[selectedIndex].borderColor =
              matchingRecipient.borderColor;
            ///
            _updateControlList[selectedIndex + 1].userEmail =
              matchingRecipient.email;
            _updateControlList[selectedIndex + 1].userName =
              matchingRecipient.name;
            _updateControlList[selectedIndex + 1].color =
              matchingRecipient.color;
            _updateControlList[selectedIndex + 1].borderColor =
              matchingRecipient.borderColor;
            // Do something with the updated _updateControlList
            //////
            _updateControlList[selectedIndex + 2].userEmail =
              matchingRecipient.email;
            _updateControlList[selectedIndex + 2].userName =
              matchingRecipient.name;
            _updateControlList[selectedIndex + 2].color =
              matchingRecipient.color;
            _updateControlList[selectedIndex + 2].borderColor =
              matchingRecipient.borderColor;
            console.log("Updated _updateControlList:", _updateControlList);
            setAllControlsList(_updateControlList);
          }
        } else if (_updateControlList[selectedIndex].name == "dds-sign") {
          const matchingRecipient = receipentList.find(
            (recipient: IReceipentList) => recipient.email === _userEmail
          );
          if (matchingRecipient) {
            // Update the userEmail of the item at the found index
            _updateControlList[selectedIndex].userEmail =
              matchingRecipient.email;
            _updateControlList[selectedIndex].userName = matchingRecipient.name;
            _updateControlList[selectedIndex].color = matchingRecipient.color;
            _updateControlList[selectedIndex].borderColor =
              matchingRecipient.borderColor;
            ///
            _updateControlList[selectedIndex + 1].userEmail =
              matchingRecipient.email;
            _updateControlList[selectedIndex + 1].userName =
              matchingRecipient.name;
            _updateControlList[selectedIndex + 1].color =
              matchingRecipient.color;
            _updateControlList[selectedIndex + 1].borderColor =
              matchingRecipient.borderColor;

            console.log("Updated _updateControlList:", _updateControlList);
            setAllControlsList(_updateControlList);
          }
        } else {
          const matchingRecipient = receipentList.find(
            (recipient: IReceipentList) => recipient.email === _userEmail
          );

          if (matchingRecipient) {
            // Update the userEmail of the item at the found index
            _updateControlList[selectedIndex].userEmail =
              matchingRecipient.email;
            _updateControlList[selectedIndex].userName = matchingRecipient.name;
            _updateControlList[selectedIndex].color = matchingRecipient.color;
            _updateControlList[selectedIndex].borderColor =
              matchingRecipient.borderColor;

            // Do something with the updated _updateControlList
            console.log("Updated _updateControlList:", _updateControlList);
            setAllControlsList(_updateControlList);
          }
        }
      }
    } catch (error) {
      console.log("onSelectRecipientPressed error ::", error);
    }
  };

  const onFilterListPressed = (_email: any) => {
    try {
      if (_email === "All Users") {
        setFilteredList([]);
      } else {
        let _filterArrayList = [];
        _filterArrayList = allControlsList.filter(
          (control: AllControlsList) => control.userEmail === _email
        );
        console.log(_filterArrayList);
        setFilteredList(_filterArrayList);
      }
    } catch (error) {
      console.log("onFilterListPressed", error);
    }
  };

  const onAddReceipentPressed = (_isEditUser: boolean) => {
    console.log("onAddReceipentPressed ::", _isEditUser);
    formik.resetForm();
    setEditingRecipient(-1);
    isEditUser = _isEditUser;
    setIsRecipent(!isRecepient);
    setMessage("");
  };

  const onSubmitReciepent = (name: string, email: string) => {
    let updatedRecipientList = [...receipentList];

    if (editingRecipient !== -1) {
      // Editing an existing recipient
      const existingRecipient = updatedRecipientList.find(
        (recipient) => recipient.id === editingRecipient
      );

      if (existingRecipient) {
        // Check if the updated email already exists in the list
        const emailExists = updatedRecipientList.some(
          (recipient) =>
            recipient.email === email && recipient.id !== editingRecipient
        );

        if (emailExists) {
          // Handle the case where the updated email already exists
          setMessage("Email already exists!");
          return;
        }

        // Update the recipient with the new values
        updatedRecipientList = updatedRecipientList.map((recipient) =>
          recipient.id === editingRecipient
            ? { ...recipient, name, email }
            : recipient
        );

        // Clear the editing state
        setEditingRecipient(-1);
        setMessage("");
      }
    } else {
      // Adding a new recipient
      const emailExists = updatedRecipientList.some(
        (recipient) => recipient.email === email
      );

      if (emailExists) {
        // Handle the case where email already exists
        setMessage("Email already exists!");
        return;
      }

      // Generate a unique ID starting from the last used ID + 1
      const lastUsedId =
        updatedRecipientList.length > 0
          ? Math.max(...updatedRecipientList.map((obj) => obj.id))
          : 0;
      const newId = lastUsedId + 1;

      // Generate a unique color code
      const newColor = generateUniqueRandomColor(
        updatedRecipientList.map((recipient) => recipient.color)
      );
      const { backgroundColor, borderColor } = newColor;

      // Create a new recipient object
      const newRecipient = {
        id: newId,
        name: name,
        email: email,
        color: backgroundColor,
        borderColor: borderColor,
      };

      // Add the new recipient to the list
      updatedRecipientList.push(newRecipient);
      setMessage("Recipient Successfully Added");
    }

    setReceipentList(updatedRecipientList);
    // let _tempObject = {
    //   id: -1,
    //   name: "All Users",
    //   email: "All Users",
    //   color: "25, 118, 210",
    //   borderColor: "rgb(105, 217, 248)",
    // };
    // let _filterSelectList = [_tempObject, ...updatedRecipientList];
    setSelectFilterList(updatedRecipientList);
    // setSelectFilterList(_filterSelectList);
    // updateReciepientList(updatedRecipientList);

    // updateReciepientList(updatedRecipientList);
    onAddReceipentPressed(false);
  };

  const generateUniqueRandomColor = (existingColors: string[]) => {
    const getRandomLightValue = () => Math.floor(100 + Math.random() * 156); // Adjust the range for a wider range of colors
    const getRandomDarkValue = () => Math.floor(Math.random() * 100); // Adjust the range for darker colors

    let backgroundColor;
    let borderColor;

    // Use a while loop to ensure the generated colors are unique
    while (true) {
      const r = getRandomLightValue();
      const g = getRandomLightValue();
      const b = getRandomLightValue();
      const alpha = Math.random().toFixed(2);

      backgroundColor = `${r}, ${g}, ${b}`;

      // Generate a darker color for the border
      // const borderR = getRandomDarkValue();
      // const borderG = getRandomDarkValue();
      // const borderB = getRandomDarkValue();

      borderColor = `rgb(${r}, ${g}, ${b})`;

      // Check if the colors are unique and the background is light; if so, break the loop
      if (
        !existingColors.includes(backgroundColor) &&
        !existingColors.includes(borderColor) &&
        isColorLight(r, g, b)
      ) {
        break;
      }
    }

    return { backgroundColor, borderColor };
  };

  const isColorLight = (r: number, g: number, b: number) => {
    // Use a simple brightness calculation
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 150; // Adjust the threshold as needed
  };
  const onIncreaseZoom = () => setZoomLevel((prevZoom) => prevZoom + 0.1);
  const onDecreaseZoom = () => setZoomLevel((prevZoom) => prevZoom - 0.1);
  const onEditRecipentPressed = (recipientId: number) => {
    // Find the recipient in the list by ID
    const recipientToEdit = receipentList.find(
      (recipient) => recipient.id === recipientId
    );

    // Set the recipient to be edited in the form fields
    formik.setValues({
      name: recipientToEdit?.name || "",
      email: recipientToEdit?.email || "",
    });

    // Set the recipient ID being edited in the state
    setEditingRecipient(recipientId);
  };

  const handleDelete = (recipientId: number, _email: string) => {
    console.log("Deleting recipient with ID:", recipientId);

    // Filter out the recipient with the given ID and update the list
    const updatedRecipientList = receipentList.filter(
      (recipient) => recipient.id !== recipientId
    );

    console.log("Updated Recipient List:", updatedRecipientList);

    if (updatedRecipientList.length === 0) {
      console.log("Cannot delete the last item.");
      setMessage("There must be one user in the list.");
      return;
    }

    const updatedControlsList = allControlsList.filter(
      (control) => control.userEmail !== _email
    );
    setAllControlsList(updatedControlsList);
    setReceipentList(updatedRecipientList);
    let _tempObject = {
      id: -1,
      name: "All Users",
      email: "All Users",
      color: "25, 118, 210",
      borderColor: "rgb(105, 217, 248)",
    };
    let _filterSelectList = [_tempObject, ...updatedRecipientList];
    // setSelectFilterList(_filterSelectList);
    setSelectFilterList(updatedRecipientList);
    formik.resetForm();
    setEditingRecipient(-1);

    // If deleting the recipient being edited, cancel the edit and reset the form
    if (recipientId === editingRecipient) {
      formik.resetForm();
      setMessage("");
      setEditingRecipient(-1);
    }
  };

  const handleCancelEdit = () => {
    // If editing a recipient, cancel the edit and reset the form
    if (editingRecipient !== -1) {
      formik.resetForm();
      setMessage("");
      setEditingRecipient(-1);
    }
  };

  const onExpiryPressed = (_val: boolean) => {
    setExpiry(!expiry);
  };

  const onSetDateTimeValue = (val: string) => {
    console.log("setDateValue", val);
    setDateTime(val);
  };

  const onSelectZonePressed = (val: string) => {
    console.log("onSelectZonePressed", val);
    setSelectedZone(val);
  };

  const handleMouseMovementForTemplate = (event: any) => {
    const { clientX, clientY } = event;
    if (mouseDrag === 1) {
      console.log("first mouse", mouseDrag, receipentList);
      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;

        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-txt",
            x: offsetX,
            y: offsetY,
            width: 150,
            height: 25,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 2) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-select",
            x: offsetX,
            y: offsetY,
            width: 30,
            height: 30,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 3) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-img",
            x: offsetX,
            y: offsetY,
            width: 50,
            height: 50,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 4) {
      console.log("singature mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;

        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-sign",
            x: offsetX,
            y: offsetY,
            width: width,
            height: height,
            pageNum: pageNumber,
          },
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 2
                : templateCreationControlsList.length + 2,
            name: "dds-dnt-sign",
            x: offsetX - 100,
            y: offsetY + 50,
            width: width,
            height: height,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 5) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;
        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-initials",
            x: offsetX,
            y: offsetY,
            width: width,
            height: height,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 6) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;

        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-radio",
            x: offsetX,
            y: offsetY,
            width: 30,
            height: 30,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    } else if (mouseDrag === 7) {
      console.log("first mouse", mouseDrag);

      const divRect = document
        .getElementById("pdfContainer")
        ?.getBoundingClientRect();
      if (divRect) {
        const offsetX = event.clientX - divRect.left;
        const offsetY = event.clientY - divRect.top;

        let _id = templateCreationControlsList.length - 1;
        console.log(_id);
        const updateTemplateCreationControlsList = [
          ...templateCreationControlsList,
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 1
                : templateCreationControlsList.length + 1,
            name: "dds-sign-combo",
            x: offsetX,
            y: offsetY,
            width: width,
            height: height,
            pageNum: pageNumber,
          },
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 2
                : templateCreationControlsList.length + 2,
            name: "dds-img-combo",
            x: offsetX + 50,
            y: offsetY + 50,
            width: width,
            height: height,
            pageNum: pageNumber,
          },
          {
            id:
              _id !== -1
                ? templateCreationControlsList[_id].id + 3
                : templateCreationControlsList.length + 3,
            name: "dds-dnt-combo",
            x: offsetX,
            y: offsetY + 50,
            width: width,
            height: height,
            pageNum: pageNumber,
          },
        ];
        setActiveItem(templateCreationControlsList.length + 1);
        console.log(
          "updateTemplateCreationControlsList: ",
          updateTemplateCreationControlsList
        );
        setTemplateCreationControlsList(updateTemplateCreationControlsList);
        setMouseDrag(-1);
      }
    }
  };

  const onSaveCreatedTemplate = async (title: string) => {
    try {
      if (templateCreationControlsList.length > 0) {
        let data = {
          name: title,
          controls: templateCreationControlsList,
          pdfBase64: orignialPdf,
        };
        setIsLoading(true);
        let response = await fetchPost(ApiName.createTemplate, token, data);
        if (response.status === 0) {
          if (response.response === "Success") {
            console.log("posted", response);
            setIsPrivewPdf(true);
            setZoomLevel(1);
          }
        } else if (response.status === 1) {
          if (
            response.response === "Provided template name is already in use"
          ) {
            setMessage(
              "Provided template name is already in use \n please use different name"
            );
            setInfo(true);
            setIsLoading(false);
          }
        }
      } else {
        setIsLoading(false);
        setMessage("please add at least one control");
        setInfo(true);
      }
    } catch (error) {
      console.log("onSaveCreatedTemplate error", error);
    }
  };
  return (
    <>
      <CreateWorkFlow
        pdfLoading={pdfLoading}
        // elementRef={elementRef}
        pdfUrl={pdf}
        pdfScale={pdfScale}
        mouseDrag={mouseDrag}
        pageNumber={pageNumber}
        pdfWidth={pdfWidth}
        numPages={numPages}
        viewControl={viewControl}
        controlId={controlId}
        activeControlIndex={activeControlIndex}
        allControlsList={
          filteredList.length > 0 ? filteredList : allControlsList
        }
        // filtereList={filteredList}
        hireacrhyList={hireacrhyList}
        userList={userList}
        isTemplatePopUp={isTemplateRolePopUp}
        message={message}
        emailNotification={emailNotification}
        smsNotification={smsNotification}
        isInfo={isInfo}
        documentDetailsShow={documentDetailsShow}
        activeItem={activeItem}
        isPrivewPdf={isPreviewPdf}
        modifiedPdfBase64={modifiedPdfBase64}
        emailError={emailError}
        showEmailError={showEmailError}
        EmailSuggestionArray={emails}
        nameSuggestionArray={nameSuggestionArray}
        instructions={instructions}
        activeHirearchyNumber={activeHirearchyNumber}
        isTemplate={isTemplate}
        templateList={templateList}
        activeTemplateIndex={activeTemplateIndex}
        workFlowTitle={workFlowTitle}
        workFlowDescription={workFlowDescription}
        isLoading={isLoading}
        isUpdate={isUpdate}
        emailMissing={emailMissing}
        templateControlsList={templateControlsList}
        listContainerRef={listContainerRef}
        receipentList={receipentList}
        filterSelectList={filterSelectList}
        isRecepient={isRecepient}
        isEditUser={isEditUser}
        zoomLevel={zoomLevel}
        formik={formik}
        editingRecipientId={editingRecipientId}
        editingRecipient={editingRecipient}
        dateTime={dateTime}
        expiry={expiry}
        timezone={timezone}
        zoneList={zoneList}
        selectedZone={selectedZone}
        toHide={state.fromScreen === "createTemplate" ? true : false}
        templateCreationControlsList={templateCreationControlsList}
        onIncreaseZoom={onIncreaseZoom}
        onDecreaseZoom={onDecreaseZoom}
        // listRef={listRef}
        handleStop={handleStop}
        onLoadSuccess={onDocumentLoadSuccess}
        handleMouseDown={handleMouseDown}
        handleMouseMovement={handleMouseMovement}
        onPreviousePressed={onPreviousePressed}
        onNextPagePressed={onNextPagePressed}
        onControlClicked={onControlClicked}
        onDragControlPressed={onDragControlPressed}
        onResizeControlsPressed={onResizeControlsPressed}
        onMandatoryPressed={onMandatoryPressed}
        savePdfData={workFlowData}
        // savePdfData={savePdfData}
        onHireacrhyDropDownPressed={onHireacrhyDropDownPressed}
        // onChangeUserId={onChangeUserId}
        onAddTemplatePressed={onAddTemplatePressed}
        onSubmitTemplateName={onSubmitTemplateName}
        updateMessage={updateMessage}
        onEmailNotificationPressed={onEmailNotificationPressed}
        onSmsNotificationPressed={onSmsNotificationPressed}
        onCreateTemplatePressed={onCreateTemplatePressed}
        onInfoClosedPressed={onInfoClosedPressed}
        onOkPressed={onOkPressed}
        onDoucmentDetailsPressed={onDoucmentDetailsPressed}
        onHidePreview={onHidePreview}
        onChangeControlUserName={onChangeControlUserName}
        onChangeControlUserEmail={onChangeControlUserEmail}
        onLeaveFocusEmail={onLeaveFocusEmail}
        onChangeInstructions={onChangeInstructions}
        onDoucmentDetailsPressed2={onDoucmentDetailsPressed2}
        onAddHierarchyButton={onAddHierarchyButton}
        onSubtractHierarchyButton={onSubtractHierarchyButton}
        openTemplateClick={openTemplateClick}
        onCloseTemplate={onCloseTemplate}
        onClickTemplateItem={onClickTemplateItem}
        onTemplateItemClick={onTemplateItemClick}
        onApplyTemplate={onApplyTemplate}
        onDeleteControlPressed={onDeleteControlPressed}
        onControlPdfClicked={onControlPdfClicked}
        // scrollToItem={scrollToItem}
        onSelectRecipientPressed={onSelectRecipientPressed}
        onFilterListPressed={onFilterListPressed}
        onAddReceipentPressed={onAddReceipentPressed}
        onSubmitReciepent={onSubmitReciepent}
        onEditRecipentPressed={onEditRecipentPressed}
        handleCancelEdit={handleCancelEdit}
        handleDelete={handleDelete}
        onExpiryPressed={onExpiryPressed}
        onSetDateTimeValue={onSetDateTimeValue}
        onSelectZonePressed={onSelectZonePressed}
        handleMouseMovementForTemplate={handleMouseMovementForTemplate}
        onSaveCreatedTemplate={onSaveCreatedTemplate}
        setSelectedEmail={setSelectedEmail}
      />
    </>
  );
};

export default CreateWorkFlowScreen;
