import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import { pdfjs, Document, Page } from "react-pdf";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreivewWorkFlow {
  isPreviewPdf: boolean;
  privewWorkFlowPdf: string;
  pageNumber: number;
  scale: number;
  onClosePreviewPdf: () => void;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onNextPagePressed: () => void;
  onPreviousePressed: () => void;
}

const PreivewWorkFlow: FC<PreivewWorkFlow> = (props: PreivewWorkFlow) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 767 ? 650 : "90%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 1.5,
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    // overflowY: "scroll",
  };
  return (
    <Modal
      //   className="pdf-preview-modal"
      open={props.isPreviewPdf}
      onClose={props.onClosePreviewPdf}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <Document
          file={props.privewWorkFlowPdf}
          //   onLoadSuccess={props.}
          onLoadSuccess={props.onLoadSuccess}
          className="pdfDocument"
          style={{ position: "relative" }}
        >
          <Page scale={props.scale} pageNumber={props.pageNumber} />
        </Document>
        <IconButton onClick={props.onPreviousePressed} className="prevPdf">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={props.onNextPagePressed} className="nextPdf">
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default PreivewWorkFlow;
