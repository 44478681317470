import React, { useContext, useState, useEffect, useRef } from "react";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CreateWorkFlowScreen from "../Screens/CreateWorkFlowScreen";
//Screens
import SignUp from "../Screens/SignUpScreen";
import ForgotPasswordScreen from "../Screens/ForgotPasswordScreen";
import CreateUserScreen from "../Screens/CreateUserScreen";
import DashBoardScreen from "../Screens/ReportsScreen";
import MangementScreen from "../Screens/MangementScreen";
import EditScreen from "../Screens/EditScreen";
import {
  FORGOTPASSWORD,
  DASHBOARD,
  CREATEUSER,
  MANAGEMENT,
  EDITUSER,
  CREATEWORKFLOW,
  USERS,
  CREATEPDF,
  LOGIN,
  HOME,
  REGISTER,
  EDITCONTROLS,
  WORKFLOWBYEMAIL,
  EMAILVERIFICATION,
  REPORTS,
  PROFILE,
  ASSIGNED,
  TEMPLATE,
  APPLYTEMPLATE,
} from "../Utils/Constants";
import { MyCustomDrawer } from "../Components/Common/listItems";
import sideMenuIcon from "../Assets/SideMenu/right-bar-logo.webp";
import sideMenuBottomImg from "../Assets/SideMenu/right-bar-bottom.webp";
import UserContext from "../Utils/UserContext";
import { useStyles } from "../Components/Common/SideMenuStyles";
import UserScreen from "../Screens/UserScreen";
import CreatePdfScreen from "../Screens/CreatePdfScreen";
import { fetchGet } from "../FetchApi/FetchApi";
import HomeScreen from "../Screens/HomeScreen";
import RegisterScreen from "../Screens/RegisterScreen";
import EidtControlScreen from "../Screens/EidtControlScreen";
import WorkFlowEmailScreen from "../Screens/WorkFlowEmailScreen";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VerifyEmailScreen from "../Screens/VerifyEmailScreen";
import ReportsScreen from "../Screens/DashBoardScreen";
import ProfileScreen from "../Screens/ProfileScreen";
import AssignedWorkFlowScreen from "../Screens/AssignedWorkFlowScreen";
import CreateTemplateScreen from "../Screens/CreateTemplateScreen";
import SelectTemplateScreen from "../Screens/SelectTemplateScreen";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const drawerWidth: number = 220;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
let roleId = -1;
let showSetting = false;
export default function Dashboard() {
  const [open, setOpen] = useState(true);
  // const [tabIndex, setTabIndex] = useState(0);
  const context = useContext(UserContext);
  const {
    token,
    tabIndex,
    permissionsRolesList,
    isOpen,
    updateTabIndex,
    updateCurrentUserPremissios,
    updateMenuButton,
  } = context;
  const classes = useStyles;
  let localLoggedIn = localStorage.getItem("isLoggedIn");
  let rolesObject: null | string = localStorage.getItem("role");
  let userId: null | string = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState("");
  const [allowedRoles, setAllowedRoles] = useState<any>([]);
  let elementRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (rolesObject !== null) {
      if (rolesObject) {
        let tempRoles = JSON.parse(rolesObject);
        if (tempRoles[0].name.toLowerCase() === "admin") {
          if (roles.length === 0) setRoles(tempRoles[0].name.toLowerCase());
          roleId = tempRoles[0].id;
          if (token && localLoggedIn) {
            if (location.pathname === DASHBOARD) {
              updateTabIndex(0);
            } else if (
              location.pathname === CREATEPDF ||
              location.pathname === CREATEWORKFLOW
            ) {
              updateTabIndex(1);
            } else if (location.pathname === REPORTS) {
              updateTabIndex(3);
            } else if (location.pathname === CREATEUSER) {
              updateTabIndex(5);
            } else if (location.pathname === USERS) {
              updateTabIndex(6);
            } else if (location.pathname === MANAGEMENT) {
              updateTabIndex(7);
            } else if (location.pathname === PROFILE) {
              updateTabIndex(8);
            } else if (location.pathname === ASSIGNED) {
              updateTabIndex(9);
            } else if (location.pathname === TEMPLATE) {
              updateTabIndex(10);
            } else if (location.pathname === APPLYTEMPLATE) {
              updateTabIndex(11);
            }
          }
        } else {
          setRoles(tempRoles[0].name.toLowerCase());
          roleId = tempRoles[0].id;
          // console.log("tempRoles", tempRoles[0].name.toLowerCase());
          if (token && localLoggedIn) {
            if (location.pathname === DASHBOARD) {
              // console.log("DASHBOARD", DASHBOARD);
              updateTabIndex(0);
            } else if (
              location.pathname === CREATEPDF ||
              location.pathname === CREATEWORKFLOW
            ) {
              updateTabIndex(1);
              // console.log("management", MANAGEMENT);
            } else if (location.pathname === REPORTS) {
              updateTabIndex(3);
            } else if (location.pathname === CREATEUSER) {
              updateTabIndex(5);
            } else if (location.pathname === USERS) {
              updateTabIndex(6);
            } else if (location.pathname === MANAGEMENT) {
              updateTabIndex(7);
            } else if (location.pathname === PROFILE) {
              updateTabIndex(8);
            } else if (location.pathname === ASSIGNED) {
              updateTabIndex(9);
            } else if (location.pathname === TEMPLATE) {
              updateTabIndex(10);
            } else if (location.pathname === APPLYTEMPLATE) {
              updateTabIndex(11);
            }
          }
          getCurrentPermissionsForRole();
        }
      }
    }
    // console.log(location.pathname);
  }, [token, roles]);
  const toggleDrawer = () => {
    // setOpen(!open);
    updateMenuButton(false);
  };
  const getCurrentPermissionsForRole = async () => {
    try {
      let response = await fetchGet(token, `permission/role`);
      if (response.status === 0) {
        setAllowedRoles(response.response);
        let list = response.response;
        for (let i = 0; i < list.length; i++) {
          console.log("babar", list[i]);
          if (
            list[i].name === "Create user" ||
            list[i].name === "Users" ||
            list[i].name === "Role management"
          ) {
            showSetting = true;
          }
        }
        updateCurrentUserPremissios(response.response);
      }
    } catch (error) {
      console.log("getCurrentPermissionsForRole", error);
    }
  };
  // const onTabClick = (index: number) => {
  //   if (tabIndex !== index) {
  //     setTabIndex(index);
  //   }
  // };
  return (
    <div ref={elementRef}>
      {localLoggedIn === "true" && location.pathname !== "/createuser" ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          {isOpen && (
            <Drawer
              className="right-section"
              variant="permanent"
              open={isOpen}
              PaperProps={{ sx: classes.container }}
            >
              <>
                <div className="btm-img">
                  <img src={sideMenuBottomImg} />
                  <div className="cp-right">©Copyright 2024</div>
                </div>

                <Toolbar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 3,
                    px: [5],
                  }}
                  className="sb-image"
                >
                  <img src={sideMenuIcon} style={{ width: "100%" }} />
                  <div onClick={toggleDrawer} className="close-icon">
                    x
                  </div>
                </Toolbar>
                <MyCustomDrawer
                  roles={roles}
                  allowedRoles={allowedRoles}
                  showSetting={showSetting}
                />
              </>
            </Drawer>
          )}
          <Box
            component="main"
            sx={{
              backgroundColor: "white",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container
              style={{ maxWidth: window.innerWidth > 991 ? "100%" : "auto" }}
              maxWidth="lg"
              sx={{ mt: 4, mb: 4 }}
            >
              <Grid
                container
                spacing={3}
                style={{
                  display:
                    location.pathname === PROFILE || ASSIGNED
                      ? "block"
                      : "flex",
                }}
              >
                <Routes>
                  <Route
                    path={DASHBOARD}
                    element={
                      <DashBoardScreen
                        roles={roles}
                        roleId={roleId}
                        userId={userId}
                      />
                    }
                  />
                  <Route
                    path={REPORTS}
                    element={
                      <ReportsScreen
                        roles={roles}
                        roleId={roleId}
                        userId={userId}
                      />
                    }
                  />
                  <Route path={MANAGEMENT} element={<MangementScreen />} />
                  <Route path={EDITUSER} element={<EditScreen />} />
                  <Route
                    path={CREATEWORKFLOW}
                    element={<CreateWorkFlowScreen elementRef={elementRef} />}
                  />
                  <Route path={CREATEUSER} element={<CreateUserScreen />} />
                  <Route path={USERS} element={<UserScreen />} />
                  <Route path={CREATEPDF} element={<CreatePdfScreen />} />
                  <Route
                    path={EDITCONTROLS}
                    element={<EidtControlScreen elementRef={elementRef} />}
                  />
                  <Route
                    path={WORKFLOWBYEMAIL}
                    element={<WorkFlowEmailScreen />}
                  />
                  <Route
                    path={PROFILE}
                    element={<ProfileScreen roles={roles} />}
                  />
                  <Route
                    path={ASSIGNED}
                    element={
                      <AssignedWorkFlowScreen
                        roles={roles}
                        roleId={roleId}
                        userId={userId}
                      />
                    }
                  />
                  <Route path={TEMPLATE} element={<CreateTemplateScreen />} />
                  <Route
                    path={APPLYTEMPLATE}
                    element={<SelectTemplateScreen />}
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to={DASHBOARD} />}
                  />
                </Routes>
              </Grid>
            </Container>
          </Box>
        </Box>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Navigate to={LOGIN} />} />
            <Route path={LOGIN} element={<SignUp />} />
            <Route path={FORGOTPASSWORD} element={<ForgotPasswordScreen />} />
            <Route path={REGISTER} element={<RegisterScreen />} />
            <Route path={WORKFLOWBYEMAIL} element={<WorkFlowEmailScreen />} />
            <Route
              path={EDITCONTROLS}
              element={<EidtControlScreen elementRef={elementRef} />}
            />
            <Route path={EMAILVERIFICATION} element={<VerifyEmailScreen />} />

            <Route path="*" element={<Navigate replace to={LOGIN} />} />
          </Routes>
        </>
      )}
    </div>
  );
}
