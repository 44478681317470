import React, { useContext, useState, useEffect, FC } from "react";
import Profile from "../Components/Profile";
import UserContext from "../Utils/UserContext";
import { fetchGet, fetchPost, fetchPut } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
interface ProfileScreen {
  roles: string;
}
const ProfileScreen: FC<ProfileScreen> = (props: ProfileScreen) => {
  const context = useContext(UserContext);
  const {
    token,
    // userName,
    userList,
    isOpen,
    updateTabIndex,
    updateUserList,
    updateMenuButton,
  } = context;
  const [userName, setUserName] = useState<any>();
  const [userEmail, setUserEmail] = useState<any>();

  const [mobileNumber, setMobileNumber] = useState<any>();
  const [updateUserName, setUpdateUserName] = useState<any>(
    localStorage.getItem("userName")
  );
  const [editEmail, setEditEmail] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [message, setMessage] = useState("");
  const [editPassword, setEditPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    document.title = "Profile";
    if (token.length > 0) getProfile();
  }, [token]);

  const getProfile = async () => {
    try {
      let res = await fetchGet(token, ApiName.getProfile);
      if (res.status === 0) {
        setUserName(res.response.name);
        setUserEmail(res.response.email);
        setMobileNumber(res.response.mobileNumber);
        await localStorage.setItem("userName", res.response.name);
        await localStorage.setItem("userEmail", res.response.email);
        await localStorage.setItem("mobileNumber", res.response.mobileNumber);
        setShowBox(false);
        setEditName(false);
        setEditEmail(false);
        setEditPassword(false);
      }
    } catch (error) {
      console.log("getProfile error: " + error);
    }
  };
  const onToggleDrawer = () => {
    updateMenuButton(!isOpen);
  };

  const onEditEmailPressed = () => {
    setEditEmail(!editEmail);
    setEditName(false);
    setEditPhone(false);
    setEditPassword(false);
  };

  const onEditNamePressed = () => {
    setEditEmail(false);
    setEditName(!editName);
    setEditPhone(false);
    setShowBox(false);
    setEditPassword(false);
  };

  const onEditNumberPressed = () => {
    setEditEmail(false);
    setEditName(false);
    setEditPhone(!editPhone);
    setShowBox(false);
  };

  const onChangeName = (name: string) => {
    setUpdateUserName(name);
  };

  const onCancelName = () => {
    setUpdateUserName(userName);
    setEditName(false);
  };

  const onCancelEmail = () => {
    // setUpdateUserName(userName);
    setEditEmail(false);
    setShowBox(false);
  };
  const onSubmitName = async () => {
    try {
      if (updateUserName.length > 0) {
        let data = {
          name: updateUserName,
        };
        //make api call
        let res = await fetchPut(token, ApiName.updateProfile, data);
        if (res.status === 0) {
          setUserName(updateUserName);
          await localStorage.setItem("userName", updateUserName);
          setEditName(false);
        }
      } else {
        setUpdateUserName(userName);
        setEditName(false);
      }
    } catch (error) {
      console.log("onSubmitName error: " + error);
    }
  };

  const onSubmitEmail = async (updatedEmail: string) => {
    try {
      if (updatedEmail === userEmail) {
        setEditEmail(false);
      } else {
        let data = {
          email: updatedEmail,
        };
        let res = await fetchPost(ApiName.updateEmail, token, data);
        if (res.status === 0) {
          setShowBox(true);
        } else if (res.status === 1) {
          if (res.response === "Provided email is already in use") {
            setShowBox(false);
            setEditEmail(false);
            setMessage("Provided email is already in use");
          }
        }
      }
    } catch (error) {
      console.log("onSubmitEmail error: " + error);
    }
  };

  const updateMessage = () => {
    setMessage("");
  };

  const onSubmitPin = async (pin: any) => {
    try {
      let data = {
        name: userName,
        mobileNumber: mobileNumber,
        pin: pin,
      };
      let res = await fetchPut(token, ApiName.updateProfile, data);
      if (res.status === 0) {
        await getProfile();
      } else if (res.status === 1) {
        if (res.response === "No email change request found") {
          setShowBox(false);
        }
      }
    } catch (error) {
      console.log("onSubmitPin error" + error);
    }
  };

  const onEditPassword = () => {
    setEditPassword(!editPassword);
    setEditEmail(false);
    setEditName(false);
    setShowBox(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const onChangePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      let data = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };
      let res = await fetchPost(ApiName.changePassword, token, data);
      if (res.status === 0) {
        setEditPassword(false);
      } else if (res.status === 1) {
        if (res.response === "Current password is incorrect") {
          setMessage("Current password is incorrect");
        }
      }
    } catch (error) {}
  };
  return (
    <div>
      <Profile
        isOpen={isOpen}
        role={props.roles}
        userEmail={userEmail}
        userName={userName}
        mobileNumber={mobileNumber}
        editEmail={editEmail}
        editName={editName}
        editPhone={editPhone}
        updateUserName={updateUserName}
        showBox={showBox}
        message={message}
        editPassword={editPassword}
        showPassword={showPassword}
        showPassword1={showPassword1}
        showPassword2={showPassword2}
        handleClickShowPassword={handleClickShowPassword}
        handleClickShowPassword1={handleClickShowPassword1}
        handleClickShowPassword2={handleClickShowPassword2}
        onToggleDrawer={onToggleDrawer}
        onEditEmailPressed={onEditEmailPressed}
        onEditNamePressed={onEditNamePressed}
        onEditNumberPressed={onEditNumberPressed}
        onChangeName={onChangeName}
        onSubmitName={onSubmitName}
        onCancelName={onCancelName}
        onSubmitEmail={onSubmitEmail}
        onCancelEmail={onCancelEmail}
        updateMessage={updateMessage}
        onSubmitPin={onSubmitPin}
        onEditPassword={onEditPassword}
        onChangePassword={onChangePassword}
      />
    </div>
  );
};

export default ProfileScreen;
