import React, { FC } from "react";
import { timeConvertor } from "../Utils/commonFunction";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { themeCustomizer } from "./Common/ButtonTheme";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
  Typography,
  Icon,
} from "@mui/material";
import { Visibility, Create, Save } from "@mui/icons-material";

import ListItemIcon from "@mui/material/ListItemIcon";
import { Edit, Delete, Link } from "@mui/icons-material";
import { primaryButton, primaryHeading } from "../Utils/Constants";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import { WorkFlow } from "../Types/Types";
import "../Styles/Dashboard.scss";
import searchicon from "../Assets/search-icon.png";
import prevnext from "../Assets/prev-next.png";
import ReactPaginate from "react-paginate";
import PreivewWorkFlow from "./Common/PopUp/PreivewWorkFlow";
import InfoPopUp from "./Common/PopUp/InfoPopUp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import PreviewInfo from "./Common/PopUp/PreviewInfo";
import TrackingInfo from "./Common/PopUp/TrackingInfo";
import InfoIcon from "@mui/icons-material/Info";

interface AssignedWorkflow {
  userList: any;
  workFLowData: WorkFlow[];
  pageCount: number;
  currentPage: number;
  startIndex: number;
  endIndex: number;
  filterData: WorkFlow[];
  errorMessage: string;
  isPreviewPdf: boolean;
  privewWorkFlowPdf: string;
  pageNumber: number;
  scale: number;
  isInfo: boolean;
  message: string;
  isOpen: boolean;
  roles: string;
  trackingData: any;
  documentName: any;
  openModal: boolean;
  openTrackingModal: boolean;
  workFlowLog: any;

  onPageChange: (index: number) => void;
  onSearchItem: (val: string) => void;
  onPreviewPdfPressed: (index: number, pdf: string) => void;
  onClosePreviewPdf: () => void;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onEditPressed: (index: number) => void;
  onEditFilteredPressed: (index: number) => void;
  onOkPressed: () => void;
  onInfoClosedPressed: () => void;
  onToggleDrawer: () => void;
  onNextPagePressed: () => void;
  onPreviousePressed: () => void;
  onTitlePressed: (index: number) => void;
  handleClose: () => void;
  CloseTrackingModal: () => void;
  handlePreviewInfo: (index: number, title: string) => void;
}

const AssignedWorkflow: FC<AssignedWorkflow> = (props: AssignedWorkflow) => {
  return (
    <>
      <TrackingInfo
        openTrackingModal={props.openTrackingModal}
        CloseTrackingModal={props.CloseTrackingModal}
        documentName={props.documentName}
        workFlowLog={props.workFlowLog}
      />
      <PreviewInfo
        trackingData={props.trackingData}
        handleClose={props.handleClose}
        openModal={props.openModal}
        documentName={props.documentName}
      />
      <InfoPopUp
        isInfo={props.isInfo}
        message={props.message}
        onInfoClosedPressed={props.onInfoClosedPressed}
        onOkPressed={props.onOkPressed}
      />
      <PreivewWorkFlow
        isPreviewPdf={props.isPreviewPdf}
        privewWorkFlowPdf={props.privewWorkFlowPdf}
        pageNumber={props.pageNumber}
        scale={props.scale}
        onClosePreviewPdf={props.onClosePreviewPdf}
        onLoadSuccess={props.onLoadSuccess}
        onNextPagePressed={props.onNextPagePressed}
        onPreviousePressed={props.onPreviousePressed}
      />
      <Container className="pdRight">
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <div className="flex">
            <IconButton
              className="menuBar"
              color="inherit"
              aria-label="open drawer"
              onClick={props.onToggleDrawer}
              edge="start"
              sx={{
                mr: 2,
                // , ...(open && { display: "none" })
              }}
            >
              {props.isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              fontSize={25}
              fontWeight={300}
              marginTop={2}
              marginBottom={2}
              color={"#7A86A1"}
              className="d-head"
            >
              Assigned Workflows
            </Typography>
          </div>
          <div className="tableInnerWrapper">
            <div className="search-wraper">
              <input
                type="text"
                placeholder="Search"
                className="search__input"
                onChange={(val) => props.onSearchItem(val.target.value)}
              />
              <img src={searchicon} alt="" className="search-icon" />
            </div>

            <div className="table-info">
              <table className="d-table">
                <thead>
                  <tr>
                    <th style={{ width: "170px" }}>Title</th>
                    <th>Initiator</th>
                    {/* <th>Description</th> */}
                    {/* <th>Instructions</th> */}
                    <th>Last Updated</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {props.workFLowData.length === 0 &&
                props.filterData.length === 0 &&
                props.errorMessage.length === 0 ? (
                  <tbody>
                    <tr className="norecord">
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <tbody>
                      {props.workFLowData.length > 0 &&
                        props.filterData.length === 0 &&
                        props.errorMessage.length === 0 &&
                        props.workFLowData
                          .slice(props.startIndex, props.endIndex)
                          .map((item, index) => (
                            <>
                              <tr key={index}>
                                <td className="itemtitle">
                                  <div className="td-name">Title:</div>
                                  <span
                                    className="tittleSpan"
                                    onClick={() => props.onTitlePressed(index)}
                                  >
                                    {item.title}
                                  </span>
                                </td>
                                <td>
                                  <div className="td-name">Initiator:</div>
                                  {item.initiator.name}
                                </td>

                                {/* <td>
                                  <div className="td-name">Description:</div>
                                  <div className="desc-info">
                                    <div className="td-description">
                                      {item.description}
                                    </div>
                                    <span className="desc-tooltiptext">
                                      {item.description}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="desc-info">
                                    <div className="td-name">Instructions:</div>
                                    <div className="td-description">
                                      {item.instructions}
                                    </div>
                                    <span className="desc-tooltiptext">
                                      {item.instructions}
                                    </span>
                                  </div>
                                </td> */}
                                <td>
                                  <div className="td-name">Updated at:</div>
                                  {timeConvertor(item.updated_at)}
                                </td>
                                <td>
                                  <div className="td-name">Status:</div>
                                  {item.status}
                                </td>

                                <td>
                                  <div className="td-name">Actions:</div>
                                  <div className="action-btns abf">
                                    <button
                                      onClick={() =>
                                        props.onPreviewPdfPressed(
                                          index,
                                          item.modified_pdf_url
                                        )
                                      }
                                    >
                                      <Visibility className="btn-img" />
                                    </button>
                                    {/* {props.roles === "admin" && ( */}
                                    <>
                                      <button
                                        // className="d-desk"
                                        onClick={() =>
                                          props.onEditPressed(index)
                                        }
                                      >
                                        <Link className="btn-img" />
                                      </button>
                                    </>
                                    {/* )} */}
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                      {props.filterData.length > 0 &&
                        props.filterData
                          .slice(props.startIndex, props.endIndex)
                          .map((item, index) => (
                            <>
                              <tr key={index}>
                                <td className="itemtitle">
                                  <div className="td-name">Title:</div>
                                  <span
                                    className="tittleSpan"
                                    onClick={() => props.onTitlePressed(index)}
                                  >
                                    {item.title}
                                  </span>
                                </td>

                                <td>
                                  <div className="td-name">Initiator:</div>
                                  {item.initiator.name}
                                </td>

                                {/* <td>
                                  <div className="td-name">Description:</div>
                                  <div className="desc-info">
                                    <div className="td-description">
                                      {item.description}
                                    </div>
                                    <span className="desc-tooltiptext">
                                      {item.description}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="td-name">Instructions:</div>
                                  <div className="desc-info">
                                    <div className="td-description">
                                      {item.instructions}
                                    </div>
                                    <span className="desc-tooltiptext">
                                      {item.instructions}
                                    </span>
                                  </div>
                                </td> */}

                                <td>
                                  <div className="td-name">Updated at:</div>
                                  {item.updated_at}
                                </td>
                                <td>
                                  <div className="td-name">Status:</div>
                                  {item.status}
                                </td>
                                <td>
                                  <div className="td-name">Action:</div>
                                  <div className="action-btns abf">
                                    <button
                                      onClick={() =>
                                        props.onPreviewPdfPressed(
                                          index,
                                          item.modified_pdf_url
                                        )
                                      }
                                    >
                                      <Visibility className="btn-img" />
                                    </button>
                                    <>
                                      <button
                                        // className="d-desk"
                                        onClick={() =>
                                          props.onEditFilteredPressed(index)
                                        }
                                      >
                                        <Link className="btn-img" />
                                      </button>
                                    </>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                    </tbody>
                  </>
                )}
              </table>
            </div>
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              {" "}
              {props.errorMessage.length > 0 && props.errorMessage}
            </p>
            {/* PAGINATION */}
            <ReactPaginate
              className="pagination"
              activeClassName="active"
              pageClassName="pageclassList li.active"
              breakLabel={window.innerWidth >= 425 ? "..." : null}
              nextLabel={<img src={prevnext} alt="" />}
              onPageChange={(event) => props.onPageChange(event.selected)}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              pageCount={props.pageCount}
              forcePage={props.currentPage}
              previousLabel={<img src={prevnext} alt="" />}
              renderOnZeroPageCount={null}
            />
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default AssignedWorkflow;
