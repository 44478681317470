// import { SECONDARY } from "./../../utils/constants";
export const useStyles = {
  container: {
    background: "#FAFAFA",
    height: "100vh",
    alignItems: "center",
  },
  ListItemHeight: {
    // display: "flex",
    // justifyContent: "center",
  },
  logos: {
    marginRight: 25,
  },
  sideMenuText: {
    color: "#CCCCCC",
  },
  navLink: {
    textDecoration: "none",
  },
  navbar: {
    height: "100%",
  },
};
