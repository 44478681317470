import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { themeCustomizer } from "../ButtonTheme";
interface AddRole {
  isAddRole: boolean;
  message: string;
  onAddRolePressed: () => void;
  onSubmitPressed: (name: string) => void;
  updateMessage: () => void;
}

const AddRolePopUp: FC<AddRole> = (props: AddRole) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth>767 ? 400:"85%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmitPressed(values.name);
      formik.resetForm();
    },
  });
  return (
    <Modal
      open={props.isAddRole}
      onClose={props.onAddRolePressed}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style,  }}>
        <Typography fontSize={12} align="left" fontWeight={700}>
          Enter Role Name
        </Typography>
        <TextField
          style={{ width: "100%", marginTop: 10 }}
          size="small"
          name="name"
          placeholder={"Role name"}
          onChange={(e) => {
            formik.handleChange(e);
            props.updateMessage();
          }}
          value={formik.values.name}
          inputMode="text"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
          inputProps={{ maxLength: 50 }}
          onBlur={formik.handleBlur}
          error={(formik.errors.name && formik.touched.name) || false}
          helperText={
            formik.errors.name && formik.touched.name && formik.errors.name
          }
        />
        <ThemeProvider theme={themeCustomizer}>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              marginTop: 10,
              zIndex: 1,
            }}
            onClick={() => formik.handleSubmit()}
          >
            Create Role
          </Button>
        </ThemeProvider>
        <p
          style={{
            fontSize: "10px",
            lineHeight: "15px",
            color: "rgb(253, 60, 79)",
            marginTop: 5,
            marginLeft: 5,
          }}
        >
          {props.message}
        </p>
      </Box>
    </Modal>
  );
};

export default AddRolePopUp;
