import React from "react";
import "../../../Styles/TrackingInfoPopup.scss";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { themeCustomizer } from "../ButtonTheme";
import {
  FontDownload,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CameraAlt,
  CheckBoxOutlineBlank,
  CheckBox,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Close,
} from "@mui/icons-material";

import { timeConvertor } from "../../../Utils/commonFunction";
interface InfoPopUp {
  isInfo: boolean;
  message: string;
  onInfoClosedPressed: () => void;
  onOkPressed: () => void;
}

type Props = {
  trackingData: any;
  handleClose: () => void;
  openModal: boolean;
  documentName: string;
};

function PreviewInfo(props: Props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 991 ? 400 : "80%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const hierarchies = props.trackingData.hierarchies;

  const firstHierarchyWithFalseStatus = hierarchies?.find((hierarchy: any) =>
    hierarchy.users.some((user: any) => user.status === false)
  );
  const testing = (controlId: number, performId: any) => {
    if (performId === null) {
      return <CheckBoxOutlineBlank className="radio-btn" />;
    } else {
      for (let i = 0; i < performId?.length; i++) {
        if (performId[i] === controlId) {
          return <CheckBox className="radio-btn" />;
        } else if (performId === null) {
          return <CheckBoxOutlineBlank className="radio-btn" />;
        } else {
          if (performId?.length - 1 === i) {
            console.log("no look");
            return <CheckBoxOutlineBlank className="radio-btn" />;
          }
        }
      }
    }
  };
  const getControlName = (controlId: number, performId: any) => {
    const control = props.trackingData.controls.find(
      (control: any) => control.id === controlId
    );

    if (control) {
      switch (control?.name) {
        case "dds-radio":
          return "Radio Button";
        case "dds-txt":
          return "Text Box";
        case "dds-select":
          return "Selection Box";
        case "dds-sign":
          return "Signature ";
        case "dds-img":
          return "Image";
        case "dds-initials":
          return "Initials";
        case "dds-sign-combo":
          return "Sign with Image";
        case "dds-img-combo":
          return "Unknown";
        default:
          return "Unknown";
      }
    }
  };

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            //  width: 400
            // width: { sm: "100%", md: "50%", lg: "30%", xl: "30%" },
          }}
          className="trackingBox"
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={() => props.handleClose()}
          >
            <Close style={{ height: 20, width: 20 }} />
          </div>
          <Typography
            fontSize={24}
            align="center"
            fontWeight={700}
            color={"#4a4a4a"}
            className="trackingInfo"
          >
            Preview
          </Typography>
          {/* <span>{props.documentName}</span> */}
          <div className="trackingwraper">
            <div className="table-info">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="td-name"> Title: </div>
                      {props.trackingData.title}
                    </td>
                    <td>
                      <div className="td-name"> Document Initiator: </div>
                      {props.trackingData?.initiator?.name}
                    </td>
                    <td>
                      <div className="td-name"> Initiator Role: </div>
                      {props.trackingData?.initiator?.role.name}
                    </td>
                    <td>
                      <div className="td-name"> Email: </div>
                      {props.trackingData?.initiator?.email}
                    </td>
                    <td>
                      <div className="td-name"> Created at: </div>
                      {timeConvertor(props.trackingData?.created_at)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {props.trackingData?.status?.toLowerCase() !== "complete" ? (
              <>
                <Typography
                  fontSize={24}
                  align="center"
                  fontWeight={700}
                  color={"#4a4a4a"}
                  className="userHead"
                >
                  Current User
                </Typography>
                <div className="table-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="td-name"> Hierarchy Number : </div>
                          {firstHierarchyWithFalseStatus?.hierarchyNumber}
                        </td>
                        {firstHierarchyWithFalseStatus?.users.map(
                          (userInfo: any) => (
                            <>
                              <td>
                                <div className="td-name">Name :</div>
                                {userInfo?.name}
                              </td>

                              <td>
                                <div className="td-name">Email:</div>
                                {userInfo?.email}
                              </td>
                            </>
                          )
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>{" "}
                {/* <Typography
                  fontSize={18}
                  align="left"
                  fontWeight={700}
                  color={"#4a4a4a"}
                >
                  Hierarchy Number{" "}
                  <span style={{ fontWeight: "500" }}>
                    {firstHierarchyWithFalseStatus?.hierarchyNumber}
                  </span>
                </Typography> */}
              </>
            ) : null}
            <Typography
              fontSize={24}
              align="center"
              fontWeight={700}
              color={"#4a4a4a"}
              className="userHead"
            >
              User
            </Typography>
            <div>
              {props.trackingData?.hierarchies?.length > 0 &&
                props.trackingData.hierarchies.map(
                  (hierarchy: any, index: any) => (
                    <div key={index}>
                      <div className="table-info">
                        <table
                          className={index % 2 === 0 ? "even-tr" : "odd-tr"}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <div className="td-name">
                                  {" "}
                                  Hierarchy Number :{" "}
                                </div>
                                {hierarchy?.hierarchyNumber}
                              </td>
                              {hierarchy.users.map((user: any) => (
                                <div key={user.id}>
                                  <td>
                                    <div className="td-name">Name:</div>
                                    {user.name}
                                  </td>
                                  <td>
                                    <div className="td-name">Email: </div>
                                    {user.email}
                                  </td>
                                  <td>
                                    <div className="td-name">Controls:</div>
                                    <ul className="ptable-ul">
                                      {user.actions.map((actionId: any) => (
                                        <li key={actionId.controlId}>
                                          {getControlName(
                                            actionId.controlId,
                                            user?.performedActions
                                          ) === "Unknown" ? null : (
                                            <>
                                              <div className="checkbx">
                                                {testing(
                                                  actionId.controlId,
                                                  user?.performedActions
                                                )}
                                              </div>
                                              <div className="checkbx-title">
                                                {" "}
                                                {getControlName(
                                                  actionId.controlId,
                                                  user?.performedActions
                                                )}{" "}
                                              </div>
                                            </>
                                          )}

                                          <Typography
                                            fontSize={14}
                                            align="left"
                                            fontWeight={500}
                                            color={"#4a4a4a"}
                                          ></Typography>
                                        </li>
                                      ))}
                                      <li></li>
                                    </ul>
                                  </td>
                                  {/* <Typography
                                    fontSize={18}
                                    align="center"
                                    fontWeight={700}
                                    color={"#4a4a4a"}
                                  >
                                    Controls
                                  </Typography> */}

                                  {/* {user?.actions.map((actionId: any) =>
                      
                      )} */}
                                </div>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                )}
            </div>
            {/* {props.trackingInfoData.hierarchies.map((hierarchy: any) => (
            <div key={hierarchy.hierarchyNumber}>
              <Typography
                fontSize={14}
                align="left"
                fontWeight={700}
                color={"#4a4a4a"}
              >
                Hierarchy: {hierarchy.hierarchyNumber}
              </Typography>
              <ul>
                {hierarchy.users.map((user: any) => (
                  <li key={user.id}>
                    <Typography
                      fontSize={14}
                      align="left"
                      fontWeight={500}
                      color={"#4a4a4a"}
                    >
                      User: {user.name}
                    </Typography>
                    <Typography
                      fontSize={14}
                      align="left"
                      fontWeight={500}
                      color={"#4a4a4a"}
                    >
                      {user.completed_at ? (
                        <>Completed Date: {user.completed_at}</>
                      ) : (
                        <>Not completed yet</>
                      )}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          ))} */}
            {/* 
          <Typography
            fontSize={18}
            align="left"
            fontWeight={700}
            color={"#4a4a4a"}
          >
            Users and Their Controllers
          </Typography> */}
            {/* {Object.keys(userControllers).map((userName) => (
            <div key={userName}>
              <Typography
                fontSize={18}
                align="left"
                fontWeight={700}
                color={"#4a4a4a"}
              >
                User: {userName}
              </Typography>

              <ul>
                {userControllers[userName].map((control: any) => (
                  <li key={control.id}>
                    <Typography
                      fontSize={14}
                      align="left"
                      fontWeight={500}
                      color={"#4a4a4a"}
                    >
                      Name: {control.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          ))} */}
            {/* <Typography
            fontSize={14}
            align="left"
            fontWeight={700}
            color={"#4a4a4a"}
          >
            // User Name: {props.trackingInfoData.initiator.name}
          </Typography> */}
          </div>
          <ThemeProvider theme={themeCustomizer}>
            <Button
              className="okBtn"
              variant="contained"
              color="primary"
              style={{
                width: "100%",
                marginTop: 10,
                zIndex: 1,
              }}
              onClick={() => {
                props.handleClose();
              }}
            >
              Ok
            </Button>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
}

export default PreviewInfo;
