import React from "react";
import "../../../Styles/TrackingInfoPopup.scss";
import { timeConvertor } from "../../../Utils/commonFunction";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import {
  FontDownload,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CameraAlt,
  CheckBoxOutlineBlank,
  CheckBox,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Close,
} from "@mui/icons-material";
import { themeCustomizer } from "../ButtonTheme";

interface InfoPopUp {
  isInfo: boolean;
  message: string;
  onInfoClosedPressed: () => void;
  onOkPressed: () => void;
}
type Props = {
  openTrackingModal: boolean;
  CloseTrackingModal: () => void;
  workFlowLog: any[] | undefined;
  documentName: string;
};
const TrackingInfo = (props: Props) => {
  console.log(props.workFlowLog);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 991 ? 400 : "80%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const getControlName = (name: string) => {
    if (name) {
      switch (name) {
        case "dds-radio":
          // return "Radio Button";
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Radio Button</div>
            </>
          );
        case "dds-txt":
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Text Box</div>
            </>
          );
        // return "Text Box";
        case "dds-select":
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Selection Box</div>
            </>
          );
        // return "Selection Box";
        case "dds-sign":
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Signature</div>
            </>
          );
        // return "Signature ";
        case "dds-img":
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Image</div>
            </>
          );
        // return "Image";
        case "dds-initials":
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Initials</div>
            </>
          );
        // return "Initials";
        case "dds-sign-combo":
          return (
            <>
              <div className="checkbx">
                <CheckBox className="radio-btn" />
              </div>
              <div className="checkbx-title">Sign with Image</div>
            </>
          );
        // return "Sign with Image";
        case "dds-img-combo":
          return "Unknown";
        default:
          return "Unknown";
      }
    }
  };
  return (
    <>
      <Modal
        open={props.openTrackingModal}
        onClose={props.CloseTrackingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            //  width: 400
            // width: { sm: "100%", md: "50%", lg: "30%", xl: "30%" },
          }}
          className="trackingBox"
        >
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={() => props.CloseTrackingModal()}
          >
            <Close style={{ height: 20, width: 20 }} />
          </div>
          <Typography
            fontSize={24}
            align="center"
            fontWeight={700}
            color={"#4a4a4a"}
            className="trackingInfo"
          >
            Tracking
            <span style={{ fontSize: 20 }}>{props.documentName}</span>
          </Typography>
          <div className="trackingwraper">
            {props.workFlowLog?.map((data: any, index: number) => (
              <div key={index}>
                <div className="table-info">
                  <table className={index % 2 === 0 ? "even-tr" : "odd-tr"}>
                    <tbody>
                      <tr>
                        <td>
                          <div className="td-name"> Activity : </div>
                          {data.activity}
                        </td>
                        <td>
                          <div className="td-name">Date & Time : </div>
                          {timeConvertor(data.happened_at)}
                        </td>
                        {data.action_names !== null && (
                          <td>
                            <div className="td-name">Controls : </div>
                          </td>
                        )}

                        <td>
                          <ul className="ptable-ul">
                            {data.action_names !== null &&
                              data.action_names?.map(
                                (actionName: any, index: number) => (
                                  <li key={index}>
                                    {getControlName(actionName) ===
                                    "Unknown" ? null : (
                                      <>{getControlName(actionName)}</>
                                    )}
                                  </li>
                                )
                              )}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default TrackingInfo;
