import React, { FC } from "react";
import backgroundImage from "../Assets/SingUp/background.webp";
import {
  Card,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import logo from "../Assets/SingUp/logo.webp";
import leftCircle from "../Assets/SingUp/leftCircle.webp";
import rightCircle from "../Assets/SingUp/rightCircle.webp";
import { primaryButton, primaryHeading } from "../Utils/Constants";
import "../Styles/SignUp.scss";
interface Register {
  showPassword: boolean;
  showPassword1: boolean;
  handleClickShowPassword1: () => void;
  onRejisterPress(): void;
  errorMessage: string;
  message: string;
  countryCode: any;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: any) => void;
  onRegisterationPressed: (
    userName: string,
    userEmail: string,
    userPassword: string,
    mobile_number: string
  ) => void;
  updateMessage: () => void;
}
const Register = (props: Register) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryButton, // Replace with your desired color
      },
    },
  });

  const passwordValidationSchema =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
    // password: Yup.string().matches(passwordValidationSchema, "invalidPassword"),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)"
      )
      .required("Password Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Password Required"),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    phone: Yup.string().matches(
      /^\+1[0-9]{10}$/,
      "Please enter a 10-digit phone number"
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      phone: "+1",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // loginAction(values);
      props.onRegisterationPressed(
        values.name,
        values.email,
        values.password,
        values.phone
      );
      console.warn("values", values);
    },
  });
  return (
    <div
      className="background-Image rightsectmob"
      style={{ position: "relative" }}
    >
      <Grid container xl={12} lg={12} md={12}>
        <Grid
          item
          md={6}
          xl={6}
          lg={6}
          sm={12}
          style={{ position: "relative" }}
          className="yellowBg"
        >
          <div style={{ position: "absolute", top: 30, left: 20 }}>
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="leftCircle">
            <img src={leftCircle} />
          </div>
          <div style={{ position: "absolute", bottom: 30, left: 20 }}>
            <p className="copyright">©Copyright 2024</p>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          xl={6}
          lg={6}
          sm={6}
          className="rightSection rightSectionmrgnTop"
        >
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="formDiv regformdiv">
              <div className="innerForm">
                <Typography
                  className="heading heyhello"
                  fontSize={35}
                  align="left"
                  fontWeight={500}
                  color={primaryHeading}
                  // marginBottom={25}
                >
                  Hey Hello!
                </Typography>

                <Typography
                  fontSize={15}
                  align="left"
                  fontWeight={700}
                  color={"#000000"}
                  // marginTop={2}
                >
                  Register
                  <div
                    style={{
                      width: "25px",
                      height: 1,
                      backgroundColor: "#C0BDCC",
                    }}
                  />
                </Typography>

                <Typography
                  className="sub-heading"
                  fontSize={11}
                  align="left"
                  fontWeight={300}
                  color={"#7A86A1"}
                  // marginTop={4}
                >
                  Enter your credentials to continue.
                </Typography>
                {/* <div
              style={{
                width: 20,
                height: 5,
                borderBottom: 5,
                borderWidth: 5,
                color: "black",
                borderRadius: 10,
              }}
            /> */}

                <div
                  className="c-input reg-input"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography fontSize={12} align="left" fontWeight={700}>
                    Your Name *
                  </Typography>
                  <TextField
                    style={{
                      width: "100%",
                      marginTop: 5,
                      borderRadius: "50px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "20px",
                        // height: 50,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    name="name"
                    placeholder={"John"}
                    onChange={(event) => {
                      formik.handleChange(event);
                      props.updateMessage();
                    }}
                    value={formik.values.name}
                    inputMode="text"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={(formik.errors.name && formik.touched.name) || false}
                    helperText={
                      formik.errors.name &&
                      formik.touched.name &&
                      formik.errors.name
                    }
                  />
                </div>

                <div
                  className="c-input reg-input"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography fontSize={12} align="left" fontWeight={700}>
                    Your Email *
                  </Typography>
                  <TextField
                    style={{
                      width: "100%",
                      marginTop: 5,
                      borderRadius: "50px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "20px",
                        // height: 50,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    name="email"
                    placeholder={"Tonynguyen@example.com"}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      props.updateMessage();
                    }}
                    value={formik.values.email}
                    inputMode="text"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      (formik.errors.email && formik.touched.email) || false
                    }
                    helperText={
                      formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    // marginTop: 10,
                    // justifyContent: "space-between",
                  }}
                >
                  <div className="c-input  reg-input" style={{ width: "100%" }}>
                    <Typography fontSize={12} align="left" fontWeight={700}>
                      Password *
                    </Typography>
                    <TextField
                      type={props.showPassword ? "text" : "password"}
                      style={{
                        width: "100%",
                        marginTop: 5,
                        zIndex: 1,
                      }}
                      name="password"
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        props.updateMessage();
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      inputMode="text"
                      error={
                        (formik.errors.password && formik.touched.password) ||
                        false
                      }
                      helperText={
                        formik.errors.password &&
                        formik.touched.password &&
                        formik.errors.password
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          console.warn("password enetered");
                          formik.handleSubmit();
                        }
                      }}
                      inputProps={{ maxLength: 255 }}
                      InputProps={{
                        style: {
                          borderRadius: "50px",
                          height: 40,
                        },
                        endAdornment: (
                          <InputAdornment position="end" style={{}}>
                            <IconButton
                              aria-placeholder="toggle password visibility"
                              onClick={props.handleClickShowPassword}
                              onMouseDown={props.handleMouseDownPassword}
                              edge="end"
                            >
                              {props.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="c-input reg-input" style={{ width: "100%" }}>
                    <Typography fontSize={12} align="left" fontWeight={700}>
                      Confirm Password *
                    </Typography>
                    <TextField
                      className="c-input "
                      // type={props.showPassword ? "text" : "password"}
                      type={props.showPassword1 ? "text" : "password"}
                      style={{ width: "100%", marginTop: 5, zIndex: 1 }}
                      name="confirmPassword"
                      onChange={(event) => {
                        formik.handleChange(event);
                        props.updateMessage();
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      inputMode="text"
                      error={
                        (formik.errors.confirmPassword &&
                          formik.touched.confirmPassword) ||
                        false
                      }
                      helperText={
                        formik.errors.confirmPassword &&
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          console.warn("password enetered");
                          formik.handleSubmit();
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "50px",
                          height: 40,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-placeholder="toggle password visibility"
                              onClick={props.handleClickShowPassword1}
                              edge="end"
                            >
                              {props.showPassword1 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="c-input" style={{ width: "100%", zIndex: 1 }}>
                    <Typography fontSize={12} align="left" fontWeight={700}>
                      Phone No
                    </Typography>
                    <TextField
                      type="text"
                      style={{
                        width: "100%",
                        marginTop: 5,
                        zIndex: 1,
                      }}
                      name="phone"
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        if (formik.values.phone === "") {
                          if (
                            event.target.value === "" ||
                            /^[0-9]+$/.test(event.target.value)
                          )
                            formik.setFieldValue("phone", event.target.value);
                        } else {
                          formik.handleChange(event);
                        }
                        props.updateMessage();
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      inputMode="tel"
                      error={
                        (formik.errors.phone && formik.touched.phone) || false
                      }
                      helperText={
                        formik.errors.phone &&
                        formik.touched.phone &&
                        formik.errors.phone
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          console.warn("password enetered");
                          formik.handleSubmit();
                        }
                      }}
                      inputProps={{ maxLength: 12 }}
                      InputProps={{
                        style: {
                          borderRadius: "50px",
                          height: 40,
                        },
                      }}
                    />
                  </div>
                  {props.errorMessage.length > 0 && (
                    <span className="errorMessage">{props.errorMessage}</span>
                  )}

                  {props.message.length > 0 && (
                    <div className="alert alert-primary">
                      {/* <p
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "15px",
                                  color: "rgb(253, 60, 79)",
                                }}
                              > */}
                      {props.message}
                      {/* </p> */}
                    </div>
                  )}

                  <div className="btnflex">
                    <Button
                      className="login__btn"
                      onClick={() => props.onRejisterPress()}
                    >
                      Login
                    </Button>
                    <ThemeProvider theme={theme}>
                      <Button
                        className="register__btn"
                        onClick={() => formik.handleSubmit()}
                      >
                        Register
                      </Button>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
      <img src={rightCircle} className="right__circle" alt="circle" />
    </div>
  );
};

export default Register;
