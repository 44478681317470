import moment from "moment-timezone";

export const timeConvertor = (currentTime: any) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateNew = moment
    .tz(currentTime, timeZone)
    .format("MMMM D, YYYY h:mm A");
  return dateNew;
};
