import React, { FC } from "react";
import backgroundImage from "../Assets/SingUp/background.webp";
import {
  Card,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  Button,
  Select,
  MenuItem,
  Stack,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../Styles/CreateUser.scss";
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup";
import logo from "../Assets/SingUp/logo.webp";
import leftCircle from "../Assets/SingUp/leftCircle.webp";
import rightCircle from "../Assets/SingUp/rightCircle.webp";
import { primaryButton, primaryHeading } from "../Utils/Constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import "../Styles/SignUp.scss";
import { rolesList } from "../Types/Types";

interface CreateUser {
  showPassword: boolean;
  rolesList: rolesList[];
  message: string;
  isOpen:boolean;
  onCreateUserPressed: (
    name: string,
    email: string,
    password: string,
    role: string,
    mobile_number: string
  ) => void;
  handleClickShowPassword: () => void;
  updateMessage: () => void;
  onToggleDrawer: () => void;
}
const CreateUser: FC<CreateUser> = (props: CreateUser) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryButton, // Replace with your desired color
      },
    },
  });

  const passwordValidationSchema =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
    // password: Yup.string().matches(passwordValidationSchema, "invalidPassword"),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)"
      )
      .required("Password Required"),
    role: Yup.string().required("Please select a role"),
    phone: Yup.string()
      .matches(
        /^\+1[0-9]{10}$/,
        "phone number must start with +1 and contains 10 digits after"
      )
      .required("Please add a phone number"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      role: props.rolesList[0]?.name,
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // loginAction(values);
      props.onCreateUserPressed(
        values.name,
        values.email,
        values.password,
        values.role,
        values.phone
      );
      // console.warn("values", values);
    },
  });

  return (
    <>
      <Container maxWidth="lg" className="pdRight">
        <div>
          <Grid
            // container
            spacing={2}
            item
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <div className="flex">
              <IconButton
                className="menuBar"
                color="inherit"
                aria-label="open drawer"
                onClick={props.onToggleDrawer}
                edge="start"
                sx={{
                  mr: 2,
                  // , ...(open && { display: "none" })
                }}
              >
                {props.isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
              <Typography
                fontSize={30}
                fontWeight={300}
                marginTop={2}
                marginBottom={3}
                color={"#7A86A1"}
                className="d-head"
              >
                Create User
              </Typography>
            </div>
            <Grid item sm={12} lg={12} md={12} xl={12}>
              <Stack direction="row">
                <Box
                  className="small-box"
                  sx={{
                    width: { sm: "100%", md: "50%", lg: "50%", xl: "50%" },
                    height: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
                    borderRadius: "20px",
                    backgroundColor: "white",
                    borderWidth: "10px",
                    border: "2px solid #F0F2F6",
                    // marginLeft: { sm: 10, md: 5, lg: 4, xl: 4 },
                  }}
                >
                  {/*role selection areas */}
                  <form className="user-form" onSubmit={formik.handleSubmit}>
                    <div className="c-input">
                      <Typography fontSize={12} align="left" fontWeight={700}>
                        Full name
                      </Typography>
                      <TextField
                        style={{
                          width: "100%",
                          marginTop: 5,
                          borderRadius: "50px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "20px",
                            // height: 50,
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        }}
                        inputProps={{ maxLength: 70 }}
                        size="small"
                        name="name"
                        placeholder={"John"}
                        onChange={(event) => {
                          formik.handleChange(event);
                          props.updateMessage();
                        }}
                        value={formik.values.name}
                        inputMode="text"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            formik.handleSubmit();
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          (formik.errors.name && formik.touched.name) || false
                        }
                        helperText={
                          formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name
                        }
                      />
                    </div>
                    <div className="c-input">
                      <Typography
                        fontSize={12}
                        align="left"
                        fontWeight={700}
                        marginTop={1}
                      >
                        Enter Phone No.
                      </Typography>

                      <TextField
                        style={{
                          width: "100%",
                          marginTop: 5,
                          borderRadius: "50px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "20px",
                            // height: 50,
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        }}
                        size="small"
                        name="phone"
                        placeholder={"Phone No."}
                        onChange={(event) => {
                          formik.handleChange(event);
                          props.updateMessage();
                        }}
                        value={formik.values.phone}
                        inputMode="text"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            formik.handleSubmit();
                          }
                        }}
                        inputProps={{ maxLength: 12 }}
                        onBlur={formik.handleBlur}
                        error={
                          (formik.errors.phone && formik.touched.phone) || false
                        }
                        helperText={
                          formik.errors.phone &&
                          formik.touched.phone &&
                          formik.errors.phone
                        }
                      />
                    </div>

                    <div
                      style={{
                        // height: "60%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography fontSize={12} align="left" fontWeight={700}>
                        Your email
                      </Typography>
                      <TextField
                        style={{
                          width: "100%",
                          marginTop: 5,
                          borderRadius: "50px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "20px",
                            // height: 50,
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        }}
                        inputProps={{ maxLength: 50 }}
                        size="small"
                        name="email"
                        placeholder={"Tonynguyen@example.com"}
                        onChange={(event) => {
                          formik.handleChange(event);
                          props.updateMessage();
                        }}
                        value={formik.values.email}
                        inputMode="text"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            formik.handleSubmit();
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          (formik.errors.email && formik.touched.email) || false
                        }
                        helperText={
                          formik.errors.email &&
                          formik.touched.email &&
                          formik.errors.email
                        }
                      />
                    </div>
                    <div className="c-input">
                      <Typography
                        fontSize={12}
                        align="left"
                        fontWeight={700}
                        marginTop={2}
                      >
                        Password
                      </Typography>

                      <TextField
                        type={props.showPassword ? "text" : "password"}
                        style={{
                          width: "100%",
                          marginTop: 5,
                          borderRadius: "50px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "20px",
                            // height: 50,
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                          endAdornment: (
                            <InputAdornment position="end" style={{}}>
                              <IconButton
                                aria-placeholder="toggle password visibility"
                                onClick={props.handleClickShowPassword}
                                edge="end"
                              >
                                {props.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 255 }}
                        size="small"
                        name="password"
                        // placeholder={"Password"}
                        onChange={(event) => {
                          formik.handleChange(event);
                          props.updateMessage();
                        }}
                        value={formik.values.password}
                        inputMode="text"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            formik.handleSubmit();
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          (formik.errors.password && formik.touched.password) ||
                          false
                        }
                        helperText={
                          formik.errors.password &&
                          formik.touched.password &&
                          formik.errors.password
                        }
                      />
                    </div>
                    <div className="c-input">
                      <Typography
                        fontSize={12}
                        align="left"
                        fontWeight={700}
                        marginTop={2}
                      >
                        Select a role
                      </Typography>
                      <TextField
                        style={{ width: "100%", marginTop: 5 }}
                        select
                        size="small"
                        value={formik.values.role}
                        name="role"
                        placeholder="Select role"
                        onChange={(event) => {
                          formik.handleChange(event);
                          props.updateMessage();
                        }}
                        error={
                          formik.errors.role && formik.touched.role
                            ? true
                            : false
                        }
                        helperText={
                          formik.errors.role &&
                          formik.touched.role &&
                          formik.errors.role
                        }
                        InputProps={{
                          style: {
                            borderRadius: "20px",
                            zIndex: 1,
                          },
                        }}
                      >
                        <MenuItem value={0} disabled selected>
                          Select role
                        </MenuItem>

                        {props.rolesList.length > 0 &&
                          props.rolesList.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      {props.message.length > 0 && (
                        <p
                          style={{
                            fontSize: "10px",
                            lineHeight: "15px",
                            color: "rgb(253, 60, 79)",
                            marginTop: 5,
                            marginLeft: 5,
                          }}
                        >
                          {props.message}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        // marginTop: 5,
                        justifyContent: "end",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            borderRadius: "50px",
                            padding: "10px 20px",
                            marginTop: 5,
                            zIndex: 1,
                          }}
                          className="createUser"
                          onClick={() => formik.handleSubmit()}
                        >
                          <p> Create user</p>
                        </Button>
                      </ThemeProvider>
                    </div>
                    <div>
                      <Stack direction="row" style={{ marginTop: 10 }}>
                        <Grid
                          item
                          md={6}
                          xl={6}
                          lg={6}
                          // className="formDiv"
                          style={{}}
                        >
                          <div
                            style={{
                              // height: "60%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          ></div>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xl={6}
                          lg={6}
                          // className="formDiv"
                          style={{}}
                        >
                          <img src={rightCircle} className="rightCircle" />
                        </Grid>
                      </Stack>
                    </div>
                  </form>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default CreateUser;
