import React, { FC } from "react";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
} from "@mui/material";
interface EditUser {
  userList: any;
  roleList: any;
}
const EditUser: FC<EditUser> = (props: EditUser) => {
  console.log("roleList", props.roleList);
  return (
    <Container>
      {props.userList.length > 0 ? (
        <div>
          <span>Existing user list</span>
          {props.userList.map((item: any) => (
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                name:{item.name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>Currently no user exists</div>
      )}
    </Container>
  );
};

export default EditUser;
