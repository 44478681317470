import React, { useRef, useState, useContext } from "react";
import CreatePdfs from "../Components/CreatePdfs";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import { useNavigate } from "react-router-dom";
import { ApiName } from "../Utils/ApiNames";
import UserContext from "../Utils/UserContext";
import { CREATEWORKFLOW } from "../Utils/Constants";
import html2pdf from "html2pdf.js";
import { IReceipentList } from "../Types/Types";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import CreateTemplate from "../Components/CreateTemplate";
let pdfBase64String: null | undefined | string = "";
const CreateTemplateScreen = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const { token, updateReciepientList } = context;
  const contentRef = useRef<any>(null);
  const [canvasData, setCanvasData] = useState<any>();
  const [radioButton, setRadioButton] = useState<any>({
    radioButton1: true,
    radioButton2: false,
  });
  const [fileSelectedButton, setFileSelectedButton] = useState(false);
  const [fileName, setFileName] = useState("");
  const [message, setMessage] = useState("");
  const [isRecepient, setIsRecipent] = useState<boolean>(false);
  const [receipentList, setReciepentList] = useState<IReceipentList[]>([]);
  const [editingRecipient, setEditingRecipient] = useState<number>(-1);
  const [editRecipientName, setEditRecipientName] = useState("");
  const [editRecipienEmail, setEditRecipientEmail] = useState("");
  const [generatePdf, setGeneratePdfbase64] = useState<any>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [isEditing, setIsEditing] = useState(true);
  const editorRef = useRef<any>(null);
  const fileInputRef = useRef<any>(null);

  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitTemplateName(values.name);
      formik.resetForm();
    },
  });

  const onRadioButtonPressed = (radioButtonId: number) => {
    setMessage("");
    if (radioButtonId === 1) {
      // setRadioButton(!radioButton.radioButton1);
      let tempRadio = {
        radioButton1: true,
        radioButton2: false,
      };
      setGeneratePdfbase64("");
      setReciepentList([]);
      setFileSelectedButton(false);

      setFileName("");
      formik.resetForm();
      pdfBase64String = "";
      setTemplateName("");
      setRadioButton(tempRadio);
    } else if (radioButtonId === 2) {
      let tempRadio = {
        radioButton1: false,
        radioButton2: true,
      };
      // fileSelectedButton;
      setFileSelectedButton(false);
      setReciepentList([]);
      setFileName("");
      formik.resetForm();
      pdfBase64String = "";
      setTemplateName("");
      setRadioButton(tempRadio);
    }
  };
  const onPdfFileSelect = (event: any) => {
    setMessage("");
    const file = event.target.files?.[0];
    console.log(file);

    if (file && file.type === "application/pdf") {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const base64String = event.target?.result?.toString()?.split(",")?.[1];
        // Handle the base64String as needed
        // console.log("Base64 string:", base64String);
        pdfBase64String = base64String;
      };
      reader.readAsDataURL(file);

      // console.log("valid", file);
      setFileSelectedButton(true);
      setIsEditing(true);
    } else {
      event.target.value = null;
      console.log("not valid");
      setMessage("please select pdf file only");
      setFileName("");
      setFileSelectedButton(false);
      // Invalid file selected, show an error message or take appropriate action
    }
  };
  const onSavePdfPressed = async () => {
    try {
      // console.warn("user email", userEmail, userPassword);

      if (
        pdfBase64String !== undefined &&
        pdfBase64String !== null &&
        pdfBase64String?.length > 0
      ) {
        navigate(CREATEWORKFLOW, {
          state: {
            fromScreen: "createTemplate",
            originalPdfBase64String: pdfBase64String,
            name: templateName,
          },
        });
      }
    } catch (error) {
      console.log("onSignInPressed", error);
    }
  };

  const onCreateWorkFlowPressed = () => {
    if (editorRef.current) {
      console.log("here");
      const htmlContent = editorRef.current.getContent(); // Replace this with your actual HTML content
      const element = document.createElement("div");
      element.innerHTML = htmlContent;
      const listItems = element.querySelectorAll("li");
      listItems.forEach((item) => {
        // item.style.listStyleType = "disc";
        item.style.marginLeft = "50px";
        item.style.padding = "5px";
      });
      // const paragraphs = element.querySelectorAll("p");
      // paragraphs.forEach((paragraph) => {
      //   const contentWrapper = document.createElement("div");
      //   contentWrapper.style.padding = "50px";
      //   contentWrapper.style.wordWrap = "break-word"; // Add this line for text wrapping
      //   while (paragraph.firstChild) {
      //     contentWrapper.appendChild(paragraph.firstChild);
      //   }
      //   paragraph.appendChild(contentWrapper);
      // });
      element.style.padding = "50px";
      // element.style.wordWrap = "break-word";

      html2pdf()
        .from(element)
        .output("blob")
        .then((pdfBlob: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            pdfBase64String = reader.result?.toString()?.split(",")[1];
            setGeneratePdfbase64(pdfBase64String);
            console.log(pdfBase64String);
            setFileSelectedButton(true);
            setIsEditing(true);
            // Base64 string of the generated PDF
            // Use the base64 string as needed
            // navigate(CREATEWORKFLOW, {
            //   state: {
            //     originalPdfBase64String: base64String,
            //     fromScreen: "createWorkFlow",
            //   },
            // });
          };
          reader.readAsDataURL(pdfBlob);
        });
    }
  };
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {};
      reader.readAsDataURL(file);
    }
  };

  const onAddReceipentPressed = () => {
    console.log("onAddReceipentPressed");
    setIsRecipent(!isRecepient);
    setMessage("");
  };

  const onOkPressed = () => {};

  const onSubmitReciepent = (name: string, email: string) => {
    let updatedRecipientList = [...receipentList];

    if (editingRecipient !== -1) {
      // Editing an existing recipient
      const existingRecipient = updatedRecipientList.find(
        (recipient) => recipient.id === editingRecipient
      );

      if (existingRecipient) {
        // Check if the updated email already exists in the list
        const emailExists = updatedRecipientList.some(
          (recipient) =>
            recipient.email === email && recipient.id !== editingRecipient
        );

        if (emailExists) {
          // Handle the case where the updated email already exists
          setMessage("Email already exists!");
          return;
        }

        // Update the recipient with the new values
        updatedRecipientList = updatedRecipientList.map((recipient) =>
          recipient.id === editingRecipient
            ? { ...recipient, name, email }
            : recipient
        );

        // Clear the editing state
        setEditingRecipient(-1);
        setMessage("");
      }
    } else {
      // Adding a new recipient
      const emailExists = updatedRecipientList.some(
        (recipient) => recipient.email === email
      );

      if (emailExists) {
        // Handle the case where email already exists
        setMessage("Email already exists!");
        return;
      }

      // Generate a unique ID starting from 0
      const newId = updatedRecipientList.length + 1;

      // Generate a unique color code
      const newColor = generateUniqueRandomColor(
        updatedRecipientList.map((recipient) => recipient.color)
      );
      const { backgroundColor, borderColor } = newColor;

      // Create a new recipient object
      const newRecipient = {
        id: newId,
        name: name,
        email: email,
        color: backgroundColor,
        borderColor: borderColor,
      };

      // Add the new recipient to the list
      updatedRecipientList.push(newRecipient);
    }

    // You can use setRecipientList(updatedRecipientList) to update the state if needed
    console.log(updatedRecipientList);
    setReciepentList(updatedRecipientList);
    updateReciepientList(updatedRecipientList);
  };

  const generateUniqueRandomColor = (existingColors: string[]) => {
    const getRandomLightValue = () => Math.floor(100 + Math.random() * 156); // Adjust the range for a wider range of colors
    const getRandomDarkValue = () => Math.floor(Math.random() * 100); // Adjust the range for darker colors

    let backgroundColor;
    let borderColor;

    // Use a while loop to ensure the generated colors are unique
    while (true) {
      const r = getRandomLightValue();
      const g = getRandomLightValue();
      const b = getRandomLightValue();
      const alpha = Math.random().toFixed(2);

      backgroundColor = `${r}, ${g}, ${b}`;

      // Generate a darker color for the border
      // const borderR = getRandomDarkValue();
      // const borderG = getRandomDarkValue();
      // const borderB = getRandomDarkValue();

      borderColor = `rgb(${r}, ${g}, ${b})`;

      // Check if the colors are unique and the background is light; if so, break the loop
      if (
        !existingColors.includes(backgroundColor) &&
        !existingColors.includes(borderColor) &&
        isColorLight(r, g, b)
      ) {
        break;
      }
    }

    return { backgroundColor, borderColor };
  };
  const isColorLight = (r: number, g: number, b: number) => {
    // Use a simple brightness calculation
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 150; // Adjust the threshold as needed
  };
  const onEditRecipentPressed = (recipientId: number) => {
    // Find the recipient in the list by ID
    const recipientToEdit = receipentList.find(
      (recipient) => recipient.id === recipientId
    );

    // Set the recipient to be edited in the form fields
    formik.setValues({
      name: recipientToEdit?.name || "",
    });

    // Set the recipient ID being edited in the state
    setEditingRecipient(recipientId);
  };

  const handleDelete = (recipientId: number) => {
    // Filter out the recipient with the given ID and update the list
    const updatedRecipientList = receipentList.filter(
      (recipient) => recipient.id !== recipientId
    );

    // You can use setRecipientList(updatedRecipientList) to update the state if needed
    console.log(updatedRecipientList);
    setReciepentList(updatedRecipientList);
    updateReciepientList(updatedRecipientList);

    // If deleting the recipient being edited, cancel the edit and reset the form
    if (recipientId === editingRecipient) {
      formik.resetForm();
      setMessage("");
      setEditingRecipient(-1);
    }
  };

  const handleCancelEdit = () => {
    // If editing a recipient, cancel the edit and reset the form

    formik.resetForm();
    setMessage("");
    setIsEditing(false);
  };
  const onUpdateMessage = () => {
    setMessage("");
  };

  const onGeneratedNextPdfPressed = () => {
    if (generatePdf) {
      navigate(CREATEWORKFLOW, {
        state: {
          originalPdfBase64String: generatePdf,
          fromScreen: "createWorkFlow",
          receipentList: receipentList,
        },
      });
    }
  };

  const onSubmitTemplateName = (name: string) => {
    console.log("this is name", name);
    setTemplateName(name);
    setIsEditing(false);
  };

  const onEditTemplateName = () => {
    setIsEditing(!isEditing);
    formik.setValues({
      name: templateName || "",
    });
  };
  return (
    <CreateTemplate
      radioButton={radioButton}
      fileSelectedButton={fileSelectedButton}
      message={message}
      fileInputRef={fileInputRef}
      fileName={fileName}
      editorRef={editorRef}
      isRecepient={isRecepient}
      receipentList={receipentList}
      editingRecipient={editingRecipient}
      formik={formik}
      pdfBase64String={generatePdf}
      templateName={templateName}
      isEditing={isEditing}
      onEditTemplateName={onEditTemplateName}
      onAddReceipentPressed={onAddReceipentPressed}
      onRadioButtonPressed={onRadioButtonPressed}
      onPdfFileSelect={onPdfFileSelect}
      onSavePdfPressed={onSavePdfPressed}
      onCreateWorkFlowPressed={onCreateWorkFlowPressed}
      handleFileInputChange={handleFileInputChange}
      onOkPressed={onOkPressed}
      onSubmitReciepent={onSubmitReciepent}
      onEditRecipentPressed={onEditRecipentPressed}
      handleCancelEdit={handleCancelEdit}
      handleDelete={handleDelete}
      onUpdateMessage={onUpdateMessage}
      onGeneratedNextPdfPressed={onGeneratedNextPdfPressed}
    />
  );
};

export default CreateTemplateScreen;
