import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { ApiName } from "../Utils/ApiNames";
import { fetchGet } from "../FetchApi/FetchApi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EDITCONTROLS,
  LOGIN,
  REGISTER,
  WORKFLOWBYEMAIL,
} from "../Utils/Constants";
import { Info, Login } from "@mui/icons-material";
import InfoPopUp from "../Components/Common/PopUp/InfoPopUp";
const WorkFlowEmailScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInvalid, setIsInvalid] = useState(false);
  const [isInfo, setInfo] = useState(false);
  const [message, setMessage] = useState("");
  const emailKey = searchParams.get("workflowId")
    ? searchParams.get("workflowId")
    : "";
  const navigate = useNavigate();
  useEffect(() => {
    if (emailKey) {
      confirmResetKey(emailKey);
    } else {
      navigate(LOGIN);
    }
  }, []);

  const confirmResetKey = async (resetKey: string) => {
    try {
      let userId: null | string = localStorage.getItem("userId");
      let endPoint = `${ApiName.workFlowEmail}?uuid=${resetKey}`;
      console.warn("emailkey", endPoint);
      const response = await fetchGet(null, endPoint);
      if (response.status === 0) {
        if (response.response === "Invalid workflow ID") {
          setInfo(true);
          setMessage("This link has expired");
        } else if (response.response.workflow.status === "Complete") {
          console.log("closed");
          setMessage("Work flow is completed");
          setInfo(true);
        } else {
          //check if loged in user id is same as email user id
          if (userId !== null) {
            if (JSON.parse(userId) !== response.response.user.id) {
              console.log("not same");
              localStorage.removeItem("isLoggedIn");
              localStorage.removeItem("token");
              localStorage.removeItem("role");
              localStorage.removeItem("userId");
              // window.location.reload();
              navigate(EDITCONTROLS, {
                state: {
                  data: [response.response.workflow],
                  roleId: response.response.user.role.id,
                  userId: response.response.user.id,
                  fromRoute: WORKFLOWBYEMAIL,
                  uuid: emailKey,
                },
              });
            } else {
              navigate(EDITCONTROLS, {
                state: {
                  data: [response.response.workflow],
                  roleId: response.response.user.role.id,
                  userId: response.response.user.id,
                  fromRoute: WORKFLOWBYEMAIL,
                  uuid: emailKey,
                },
              });
            }
          } else {
            console.log("same as logges in user");
            navigate(EDITCONTROLS, {
              state: {
                data: [response.response.workflow],
                roleId: response.response.user.role.id,
                userId: response.response.user.id,
                fromRoute: WORKFLOWBYEMAIL,
                uuid: emailKey,
              },
            });
          }
        }
      } else if (response.status === 1) {
        if (response.response === "Invalid workflow ID") {
          setInfo(true);
          setMessage("Invalid link");
        }
      }
    } catch (error) {}
  };
  const onInfoClosedPressed = () => {
    setInfo(false);
    setMessage("");
  };

  const onOkPressed = () => {
    setInfo(false);
    setMessage("");
    navigate(LOGIN);
  };
  return (
    <>
      <InfoPopUp
        isInfo={isInfo}
        message={message}
        onInfoClosedPressed={onInfoClosedPressed}
        onOkPressed={onOkPressed}
      />
    </>
  );
};

export default WorkFlowEmailScreen;
