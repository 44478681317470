import React, { useContext, useState, useEffect } from "react";
import Routing from "../Router/Routes";
import DashBoardRoute from "../Router/DashBoardRoute";
import UserContext from "../Utils/UserContext";
import { ApiName } from "../Utils/ApiNames";
import { fetchGet } from "../FetchApi/FetchApi";
import { IReceipentList, UserList, permissionsRolesList } from "../Types/Types";

const Root = () => {
  let localLoggedIn = localStorage.getItem("isLoggedIn");
  let localtoken = localStorage.getItem("token");
  //hooks
  const [userName, setUserName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [token, setToken] = useState<string>("");
  const [tabIndex, setTabIndex] = useState(0);
  const [userList, setUserList] = useState<UserList[]>([]);
  const [permissionsRolesList, setPermissionsRolesList] = useState<
    permissionsRolesList[]
  >([]);
  const [receipentList, setReceipentList] = useState<IReceipentList[]>([]);
  const [isOpen, setIsOpen] = useState(window.innerWidth > 767 ? true : false);

  useEffect(() => {
    if (localLoggedIn && localtoken) {
      validateToken(localtoken);
    } else {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      updateIsLogged(false);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    let loginTime = localStorage.getItem("loginTime");
    if (loginTime) {
      const targetDateTime = new Date(loginTime);
      if (Date.now() >= targetDateTime.getTime()) {
        localStorage.removeItem("loginTime");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("userId");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("mobileNumber");
        localStorage.removeItem("userName");
      }
    } else {
      const currentTime = new Date();
      const targetTime = new Date(currentTime.getTime() + 20 * 60 * 1000);
      localStorage.setItem("loginTime", targetTime.toISOString());
    }
  }, []);

  const updateUserName = (name: string) => {
    setUserName(name);
  };
  const updateIsLogged = (value: boolean) => {
    setIsLoggedIn(value);
  };
  const updateToken = (value: string) => {
    setToken(value);
  };
  const updateTabIndex = (val: number) => {
    setTabIndex(val);
  };
  const updateCurrentUserPremissios = (val: permissionsRolesList[]) => {
    setPermissionsRolesList(val);
  };

  const updateUserList = (val: UserList[]) => {
    setUserList(val);
  };

  const updateMenuButton = (val: boolean) => {
    setIsOpen(val);
  };
  const validateToken = async (localtoken: string) => {
    try {
      let response = await fetchGet(localtoken, ApiName.authenticateToken);
      // console.log("response", response);
      if (response.status === 401) {
        refreshToken(localtoken);
      } else if (response.valid === true) {
        if (localLoggedIn !== null) {
          updateIsLogged(JSON.parse(localLoggedIn));
        }
        updateToken(localtoken);
        getUserList(localtoken);
      }
    } catch (error) {
      console.log("validateToken", error);
    }
  };

  const refreshToken = async (localtoken: string) => {
    try {
      let response = await fetchGet(localtoken, ApiName.refreshToken);
      console.warn("refresh token", response);
      if (response.status === 401) {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        updateIsLogged(false);
      } else if (response.jwttoken) {
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("token", response.jwttoken);
      }
    } catch (error) {
      console.log("generateToken", error);
    }
  };

  const getUserList = async (localtoken: string) => {
    try {
      let resp = await fetchGet(localtoken, ApiName.userList);
      if (resp.status === 0) {
        setUserList(resp.response);
        updateUserList(resp.response);
      }
    } catch (error) {
      console.log("getUserList error: ", error);
    }
  };
  const updateReciepientList = (val: any) => {
    setReceipentList(val);
  };
  return (
    <UserContext.Provider
      value={{
        userName,
        isLoggedIn,
        token,
        tabIndex,
        permissionsRolesList,
        userList,
        isOpen,
        receipentList,
        updateUserName,
        updateIsLogged,
        updateToken,
        updateTabIndex,
        updateCurrentUserPremissios,
        updateUserList,
        updateMenuButton,
        updateReciepientList,
      }}
    >
      {/* {isLoggedIn === true ? <DashBoardRoute /> : <SignUpRoute />} */}
      <Routing />
    </UserContext.Provider>
  );
};

export default Root;
