import React, { useState, useEffect, useContext, FC } from "react";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import { UserList, WorkFlow, workFlowHierarchy } from "../Types/Types";
import UserContext from "../Utils/UserContext";
import DashBoard from "../Components/DashBoard";
import { useNavigate } from "react-router-dom";
import {
  CREATEPDF,
  DASHBOARD,
  EDITCONTROLS,
  CREATEWORKFLOW,
  WORKFLOWBYEMAIL,
} from "../Utils/Constants";
import axios from "axios";
interface ReportsScreen {
  roles: string;
  roleId: number;
  userId: any;
}
let documentName: string = "";
const ReportsScreen: FC<ReportsScreen> = (props: ReportsScreen) => {
  // const [userList, setUserList] = useState<UserList[]>([]);
  const context = useContext(UserContext);
  const {
    token,
    userName,
    userList,
    isOpen,
    updateUserName,
    updateTabIndex,
    updateUserList,
    updateMenuButton,
  } = context;

  const [workFLowData, setWorkFlowData] = useState<WorkFlow[]>([]);
  const [filterData, setFilterData] = useState<WorkFlow[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPreviewPdf, setPreviewPdf] = useState(false);
  const [privewWorkFlowPdf, setPreivewWorkFlowPdf] = useState("");
  const [numPages, setNumPages] = useState<number>(-1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isInfo, setInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [trackingData, setTrackingData] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [popIndex, setPopIndex] = useState<number>();
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [tackingPopup, setTrackingPopup] = useState<number>();
  const [workFlowLog, setWorkFlowLog] = useState<any[]>();
  const [isLoading2, setIsLoading2] = useState(false);

  const entries = 9;
  const navigate = useNavigate();
  // let token = localStorage.getItem("token");
  useEffect(() => {
    console.log(props);
    updateTabIndex(3);
    document.title = "Reports";
    if (token.length > 0) {
      getInitiatedWorkFlow();
    }
  }, [token, props.roles]);

  const getInitiatedWorkFlow = async () => {
    try {
      let response = await fetchGet(
        token,
        ApiName.getWorkFlow
        // props.roles === "admin"
        //   ? ApiName.getWorkFlow
        //   : ApiName.getAssignedWorkFlow
      );
      if (response.status === 0) {
        setWorkFlowData(response.response);
        if (JSON.parse(props.userId) !== 1) {
          fiterData(response.response);
        }
        setPageCount(Math.ceil(response.response.length / 9));
      }
    } catch (error) {
      console.log("getInitiatedWorkFlow", error);
    }
  };
  const onPageChange = (index: number) => {
    let startValue = index * entries;
    let endValue = startValue + entries;
    setStartIndex(startValue);
    setEndIndex(endValue);
    setCurrentPage(index);
  };

  const onSearchItem = (val: any) => {
    console.log("val", val);
    let tempFilterData = [...workFLowData];
    tempFilterData = tempFilterData.filter((item) => {
      const titleMatch = item.title.toLowerCase().includes(val.toLowerCase());
      const initiatorMatch = item.initiator.name
        .toLowerCase()
        .includes(val.toLowerCase());
      const dateMatch = item.created_at
        .toLowerCase()
        .includes(val.toLowerCase());
      const statusMatch = item.status.toLowerCase().includes(val.toLowerCase());

      // Check if any user in the users array has a name that matches the search value
      const userMatch = item.hierarchies.some((hierarchy: any) =>
        hierarchy.users.some((user: any) =>
          user.name.toLowerCase().includes(val.toLowerCase())
        )
      );

      return (
        titleMatch || initiatorMatch || dateMatch || statusMatch || userMatch
      );
    });

    console.log("tempFilterData", tempFilterData);
    if (val.length > 0) {
      if (tempFilterData.length > 0) {
        setFilterData(tempFilterData);
        setPageCount(Math.ceil(tempFilterData.length / 9));
        setErrorMessage("");
      } else {
        setErrorMessage("No Record Found");
        setFilterData([]);
      }
    } else {
      setFilterData([]);
      setErrorMessage("");
      setPageCount(Math.ceil(tempFilterData.length / 9));
    }
  };

  const onPreviewPdfPressed = (index: number, pdf: string) => {
    setPreviewPdf(true);
    setPreivewWorkFlowPdf(pdf);
  };

  const onClosePreviewPdf = () => {
    setPreviewPdf(false);
    setPageNumber(1);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const onEditPressed = (index: number) => {
    let _hierarchy: workFlowHierarchy = {
      hierarchyNumber: -1,
      user: {
        id: -1,
        name: "",
        email: "",
        mobile_number: "",
        role: {
          id: -1,
          name: "",
        },
      },
      actions: [
        {
          controlId: -1,
          mandatory: false,
        },
      ],
      status: false,
      completed_at: null,
    };
    console.log("onEditPressed", workFLowData[index].status, "index");
    if (workFLowData[index].status === "Complete") {
      setMessage("Work flow is completed");
      setInfo(true);
    } else if (workFLowData[index].isEditable === false) {
      console.log(workFLowData[index].isEditable);
      setMessage("Work flow is started you can not edit it now");
      setInfo(true);
    } else {
      console.log(props.roleId);
      updateTabIndex(1);
      navigate(CREATEWORKFLOW, {
        state: {
          originalPdfBase64String: workFLowData[index].original_pdf_url,
          fromScreen: "dashBoard",
          editableData: [workFLowData[index]],
        },
      });
      // if (props.roleId === 1) {
      //   navigate(CREATEWORKFLOW, {
      //     state: {
      //       originalPdfBase64String: workFLowData[index].original_pdf_url,
      //       fromScreen: "dashBoard",
      //       editableData: [workFLowData[index]],
      //     },
      //   });
      // } else
      //   navigate(EDITCONTROLS, {
      //     state: {
      //       data: [workFLowData[index]],
      //       roleId: props.roleId,
      //       userId: JSON.parse(props.userId),
      //       fromRoute: DASHBOARD,
      //     },
      //   });
    }
  };

  const onEditFilteredPressed = (index: number) => {
    let _hierarchy: workFlowHierarchy = {
      hierarchyNumber: -1,
      user: {
        id: -1,
        name: "",
        email: "",
        mobile_number: "",
        role: {
          id: -1,
          name: "",
        },
      },
      actions: [
        {
          controlId: -1,
          mandatory: false,
        },
      ],
      status: false,
      completed_at: null,
    };
    console.log("onEditPressed", filterData[index].status, "index", index);
    if (filterData[index].status === "Closed") {
      setMessage("Work flow is completed");
      setInfo(true);
    } else if (filterData[index].isEditable === false) {
      console.log(filterData[index].isEditable);
      setMessage("Work flow is started you can not edit it now");
      setInfo(true);
    } else {
      console.log(props.roleId);
      if (props.roleId === 1) {
        navigate(CREATEWORKFLOW, {
          state: {
            originalPdfBase64String: filterData[index].original_pdf_url,
            fromScreen: "dashBoard",
            editableData: [filterData[index]],
          },
        });
      } else
        navigate(EDITCONTROLS, {
          state: {
            data: [filterData[index]],
            roleId: props.roleId,
            userId: JSON.parse(props.userId),
            fromRoute: DASHBOARD,
          },
        });
    }
  };
  const fiterData = (data: WorkFlow[]) => {
    let tempData = [...data];
    let hireacrhyArray = [];
    let hireacrhyObject = {};

    for (let i = 0; i < tempData.length; i++) {
      for (let j = 0; j < tempData[i].hierarchies.length; j++) {
        if (tempData[i].hierarchies[j].status === false)
          hireacrhyObject = {
            hierarchyNumber: tempData[i].hierarchies[j].hierarchyNumber,
          };
        hireacrhyArray.push(hireacrhyObject);
      }
    }
    console.log("chal ja", hireacrhyObject);
  };

  const onInfoClosedPressed = () => {
    setInfo(false);
    setMessage("");
  };

  const onOkPressed = () => {
    setInfo(false);
    setMessage("");
  };
  const onToggleDrawer = () => {
    // setOpen(!open);
    updateMenuButton(!isOpen);
  };

  const onNextPagePressed = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const onPreviousePressed = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const onTitlePressed = (index: number) => {
    console.log("onTitlePressed", workFLowData[index]);
    setTrackingData(workFLowData[index]);
    setOpenModal(true);
    documentName = workFLowData[index].title;
  };
  const handleClose = () => {
    setPopIndex(0);
    setOpenModal(false);
  };

  const CloseTrackingModal = () => {
    setTrackingPopup(0);
    setOpenTrackingModal(false);
  };
  const handlePreviewInfo = async (id: any, title: string) => {
    const endPoint = ApiName.workFlowLog + id;
    try {
      // setOpenTrackingPopUp(true);\
      setIsLoading2(true);

      const response = await fetchGet(token, endPoint);
      if (response.status === 0) {
        setWorkFlowLog(response.response);
        if (response.status === 0) {
          documentName = title;

          setIsLoading2(false);
          setOpenTrackingModal(true);
        }
      }
    } catch (error) {
      console.log("handleTrackingInfo", error);
    }
  };
  const onDownloadPdf = async (_url: any) => {
    console.log("this is url", _url);
    try {
      const response = await axios.get(_url, { responseType: "arraybuffer" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded.pdf";

      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PDF", error);
    }
  };
  return (
    <DashBoard
      openTrackingModal={openTrackingModal}
      userList={userList}
      workFLowData={workFLowData}
      currentPage={currentPage}
      pageCount={pageCount}
      startIndex={startIndex}
      endIndex={endIndex}
      filterData={filterData}
      errorMessage={errorMessage}
      isPreviewPdf={isPreviewPdf}
      privewWorkFlowPdf={privewWorkFlowPdf}
      pageNumber={pageNumber}
      scale={1}
      isInfo={isInfo}
      message={message}
      isOpen={isOpen}
      roles={props.roles}
      trackingData={trackingData}
      openModal={openModal}
      documentName={documentName}
      workFlowLog={workFlowLog}
      handlePreviewInfo={handlePreviewInfo}
      onInfoClosedPressed={onInfoClosedPressed}
      onOkPressed={onOkPressed}
      onPageChange={onPageChange}
      onSearchItem={onSearchItem}
      onPreviewPdfPressed={onPreviewPdfPressed}
      onClosePreviewPdf={onClosePreviewPdf}
      onLoadSuccess={onDocumentLoadSuccess}
      onEditPressed={onEditPressed}
      onEditFilteredPressed={onEditFilteredPressed}
      onToggleDrawer={onToggleDrawer}
      onNextPagePressed={onNextPagePressed}
      onPreviousePressed={onPreviousePressed}
      onTitlePressed={onTitlePressed}
      handleClose={handleClose}
      CloseTrackingModal={CloseTrackingModal}
      onDownloadPdf={onDownloadPdf}
    />
  );
};

export default ReportsScreen;
