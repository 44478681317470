import React, { useEffect, useRef, useState, useContext, FC } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { PDFDocument, degrees, rgb } from "pdf-lib";

import {
  AllControlsList,
  AssignedWorkFlowControl,
  Control,
  Hierarchy,
  UserList,
  WorkFlow,
  WorkFlowControl,
  controls,
} from "../Types/Types";
import { ApiName } from "../Utils/ApiNames";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import UserContext from "../Utils/UserContext";
import CreateWorkFlow from "../Components/CreateWorkFlow";
import textInputImage from "../Assets/Text box-50x50.png";
import multiselectionImageColored from "../Assets/control-icons/multiSelectionColored20x20.png";
import imageBoxImage from "../Assets/Image box-50x50.png";
import signatureBoxImage from "../Assets/Signature Box-50x50.png";
import initialsBoxImage from "../Assets/Initials -50x50.png";
import radioBoxImageColored from "../Assets/control-icons/radioButtonSelected50x50.png";
import "../Styles/CreateWorkFlow.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CREATEPDF,
  DASHBOARD,
  LOGIN,
  REGISTER,
  WORKFLOWBYEMAIL,
} from "../Utils/Constants";
import EditControl from "../Components/EditControl";
import { arrayBufferToBase64 } from "base64-arraybuffer";
import { eventNames } from "process";
import { type } from "os";
import { act } from "react-dom/test-utils";
import * as faceapi from "face-api.js";
import { timeConvertor } from "../Utils/commonFunction";
import { string } from "yup";
import { sign } from "crypto";
import moment from "moment-timezone";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface EditControlScreen {
  elementRef: any;
}
let file =
  "https://digitaldoc.econceptions.ca/server/public/uploads/workflows/1/44/1690457417_modified.pdf";
let pdfWidth = 0;
let pdfHeight = 0;
let orignialPdf = "";
let roleId = -1;
let userId = -1;
let cameraIndex: any = -1;
let itemId: any = -1;
let data: any[] = [];
let ImageData: any = null;
let pdfBase64: any = null;
let workFlowTitle = "";
let isComplete = false;
let postControlArray: any = [];
let controlIndex = -1;
let signatureImage: any = null;
let initialImage: any = null;
let pdfUrl: any = null;
let comboImagedata: any = null;
let signFromText: any = false;
let initialFromText: any = false;
let signComboText: any = false;
// let serverDate: number = 0;

const loadModels = async () => {
  await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
  await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
  console.log("modal loaded");
};
const EditControlScreen: FC<EditControlScreen> = (props: EditControlScreen) => {
  const context = useContext(UserContext);
  const { token, userList, isOpen, isLoggedIn, updateMenuButton } = context;
  const { state } = useLocation();
  //hooks
  const [pdf, setPdf] = useState<any>();
  const [numPages, setNumPages] = useState<number>(-1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [zoom, setZoom] = useState(0.7);

  const [pdfLoading, setPdfLoading] = useState({
    loading: true,
    message: "loading PDF...",
  });
  const [allControlsList, setAllControlsList] = useState<AllControlsList[]>([]);
  const [modifiedPdfBase64, setModifiedPdfBase64] = useState("");
  const [pdfScale, setPdfScale] = useState(window.innerWidth > 992 ? 1 : 0.6);
  const [textInputChange, setTextInputChange] = useState("");
  const [message, setMessage] = useState("");
  const webcamRef = useRef<any>("");
  const [controls, setControls] = useState<AssignedWorkFlowControl[]>([]);
  const [activeControlIndex, setActiveControlIndex] = useState(-1);
  const [isSignaturePad, setIsSignaturePad] = useState(false);
  const [isSignatureComboPad, setIsSignatureComboPad] = useState(false);

  const fileInputRef = useRef<any>(null);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isSelectImage, setIsSelectImage] = useState(false);
  const [isInfo, setInfo] = useState(false);
  const [isInitialPad, setisInitialPad] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [isRadioButton, setIsRadio] = useState(false);
  const [signImage, setSignImage] = useState<any>("");
  const [signComboImage, setSignComboImage] = useState<any>("");
  const [initialsImage, setInitialImage] = useState<any>("");
  const [serverDate, setServerDate] = useState("");
  const [signatureBy, setSignatureBy] = useState<any>({
    option1: true,
    option2: false,
  });
  let signPad = useRef<any>(null);
  let initialPad = useRef<any>(null);
  let signComboPad = useRef<any>(null);
  let scale = 1;
  const signedImageRef = useRef<HTMLImageElement>(null);
  const ddssignedRef = useRef<HTMLImageElement>(null);
  const ddsinitialRef = useRef<HTMLImageElement>(null);
  let hireacrhyObject = {};

  const navigate = useNavigate();

  const zoomPercentage = Math.round(zoom * 100);

  useEffect(() => {
    console.log("broooo", isLoggedIn, isOpen);
    if (state !== null) {
      updateMenuButton(false);
      data = state.data;
      roleId = state.roleId;
      userId = state.userId;
      workFlowTitle = state.data[0].title;
      console.log(state);
      filterControls();
      setPdf(state.data[0].modified_pdf_url);
      pdfUrl = state.data[0].modified_pdf_url;
      getServerDate();
    } else {
      navigate(LOGIN);
    }
    console.log("this is inner widht ", window.innerWidth);
  }, []);

  const getServerDate = async () => {
    try {
      let res = await fetchGet(token, ApiName.serverDate);
      if (res.status === 0) {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const timeZoneAbbr = moment().tz(timeZone).zoneAbbr();
        setServerDate(timeConvertor(res.response) + `      ${timeZoneAbbr}`);
      } else {
      }
    } catch (error) {
      console.log("getServerDate error: " + error);
    }
  };
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(pageNumber);
  };

  const onNextPagePressed = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const onPreviousePressed = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const updateMessage = () => {
    setMessage("");
  };

  const onChangeTextControl = (val: string, id: number) => {
    let _tempControls = [...controls];
    let _index = _tempControls.findIndex(
      (item: AssignedWorkFlowControl) => item.id === id
    );
    if (_tempControls[_index].name === "dds-txt") {
      _tempControls[_index].txtValue = val;
    }
    setControls(_tempControls);
  };

  const base64ToUint8Array = (base64: any) => {
    const raw = window.atob(base64);
    const uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  };

  const captureImage = () => {
    if (webcamRef !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc);
    }
  };

  const filterControls = () => {
    let tempControls: any = [];
    let res: any = data[0];
    let actions: any = res.hierarchies[0].users[0].actions;

    const tempArray = [];

    for (const action of actions) {
      const { controlId, mandatory } = action;
      const matchingControl = res.controls.find(
        (control: any) => control.id === controlId
      );

      matchingControl.isSign = false;
      matchingControl.isInitial = false;
      matchingControl.isSignCombo = false;
      matchingControl.isDateCombo = false;
      matchingControl.isSignDate = false;
      // matchingControl.isTxt = false;
      matchingControl.disableTextField = false;
      matchingControl.image = "";
      matchingControl.txtValue = "";
      matchingControl.isRadio = false;
      matchingControl.isSelect = false;
      matchingControl.isDisableSignature = false;
      matchingControl.isDisableInitials = false;
      matchingControl.isRadioDisabled = false;
      matchingControl.isCheckBoxDisabled = false;
      // matchingControl.isSignCombo = false;
      matchingControl.mandatory = mandatory;

      if (matchingControl) {
        tempArray.push(matchingControl);
      }
    }
    setActiveControlIndex(0);
    console.log(tempArray);
    setPageNumber(tempArray[0].pageNum);
    setControls(tempArray);
  };
  const onSignatureButtonPressed = (index: number, id: number) => {
    cameraIndex = index;
    itemId = id;
    setIsSignaturePad(true);
  };

  const onInitailButtonPressed = (index: number, id: number) => {
    cameraIndex = index;
    itemId = id;
    setisInitialPad(true);
  };
  const onCloseSignaturePad = () => {
    let _tempoptions = {
      option1: true,
      option2: false,
    };
    setSignatureBy(_tempoptions);
    setIsSignaturePad(false);
  };
  const onCloseSignatureComboPad = () => {
    if (!isLoading) setIsSignatureComboPad(false);
  };
  const onCloseInitialPad = () => {
    let _tempoptions = {
      option1: true,
      option2: false,
    };
    setSignatureBy(_tempoptions);
    setisInitialPad(false);
  };
  const onClearSignaturePad = () => {
    if (signPad !== null) {
      signPad.current.clear();
    }
  };
  const onClearSignaturePadCombo = () => {
    if (signComboPad !== null) {
      signComboPad.current.clear();
    }
  };
  const onClearInitalPad = () => {
    if (initialPad !== null) {
      initialPad.current.clear();
    }
  };
  const handleSignature = (signature: any) => {
    console.log("handle signature", signature);
  };
  const handleInitials = (signature: any) => {};
  const handleStartCamera = (index: number, id: number) => {
    itemId = id;
    cameraIndex = index;
    setShowCamera(true);
  };

  const handleCaptureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    await loadModels();
    const resizedImage: any = await resizeImage(imageSrc, 100, 100); // Provide the desired width and height

    const img = new Image();
    img.src = imageSrc;

    img.onload = async () => {
      // const detections = await faceapi.detectAllFaces(img).withFaceLandmarks();
      // if (detections.length > 0) {
      //   console.log("Face detected. Likely a human.");
      let _updatedControls = [...controls];
      console.log(_updatedControls);
      if (_updatedControls[cameraIndex].name === "dds-img") {
        _updatedControls[cameraIndex].isImg = true;
        _updatedControls[cameraIndex].image = resizedImage;
      }
      setControls(_updatedControls);
      // }
      // else {
      //   setInfo(true);
      //   setMessage("Please make sure you are facing the camera");
      //   let _updatedControls = [...controls];
      //   _updatedControls[cameraIndex].isSign = false;
      //   console.log(_updatedControls[cameraIndex]);
      //   signatureImage = null;
      //   signPad.current = null;
      //   setSignImage("");
      //   // setControls(_updatedControls);
      // }
    };
    setImageSrc(resizedImage);
    // onControlActionPressed(id, index);
    setShowCamera(false);
    setIsSignaturePad(false);
    // ImageData = resizedImage;
    // onSubmitClick(index, id);
  };
  const handleCaptureComboImage = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      await loadModels();
      console.log("inside face detection mode");
      const resizedImage: any = await resizeImage(imageSrc, 100, 100); // Provide the desired width and height

      const img = new Image();
      img.src = imageSrc;

      img.onload = async () => {
        const detections = await faceapi
          .detectAllFaces(img)
          .withFaceLandmarks();
        if (detections.length > 0) {
          let _updatedControls = [...controls];
          console.log(_updatedControls[cameraIndex]);

          if ((_updatedControls[cameraIndex].name = "dds-sign-combo")) {
            _updatedControls[cameraIndex].isSignCombo = true;
            _updatedControls[cameraIndex].image = resizedImage;
            _updatedControls[cameraIndex + 1].isSignCombo = true;
            _updatedControls[cameraIndex + 2].isDateCombo = true;
            // _updatedControls[cameraIndex + 1].image = resizedImage;
            comboImagedata = resizedImage;
            for (let i = 0; i < _updatedControls.length; i++) {
              if (_updatedControls[i].name === "dds-img-combo") {
                _updatedControls[i].image = resizedImage;
              }
              if (_updatedControls[i].name === "dds-dnt-combo") {
                getServerDate();
              }
            }
            setControls(_updatedControls);
          }
          setIsLoading(false);
        } else {
          console.log("face not detected");
          setInfo(true);
          setMessage("Please make sure you are facing the camera");
          let _updatedControls = [...controls];
          _updatedControls[cameraIndex].isSign = false;
          _updatedControls[cameraIndex].isSignCombo = false;
          _updatedControls[cameraIndex].image = "";
          _updatedControls[cameraIndex + 1].isSignCombo = false;
          _updatedControls[cameraIndex + 2].isDateCombo = false;
          console.log(_updatedControls[cameraIndex]);
          // signatureImage = null;
          // signPad.current = null;
          setSignComboImage("");
          signComboPad.current = null;
          setIsLoading(false);
          setControls(_updatedControls);
          // setControls(_updatedControls);
        }
      };
      // setImageSrc(resizedImage);
      // onControlActionPressed(id, index);
      setShowCamera(false);
      setIsSignatureComboPad(false);
      // ImageData = resizedImage;
      // onSubmitClick(index, id);
    } catch (error) {
      console.log("handleCaptureComboImage", error);
    }
  };

  const handleFileInputChange = (event: any, index: number) => {
    console.log("here", event.target.files);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const resizedImage = await resizeImage(reader.result, 100, 100);
        setImageSrc(resizedImage);
        ImageData = resizedImage;
        // setShowCamera(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMobileCameraAccess = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        webcamRef.current.srcObject = stream;
        setShowCamera(true);
      })
      .catch((error) => console.error("Error accessing the camera:", error));
  };

  const resizeImage = (imageSrc: any, maxWidth: any, maxHeight: any) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = (maxWidth / width) * height;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width = (maxHeight / height) * width;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;
        if (ctx !== null) ctx.drawImage(img, 0, 0, width, height);
        const resizedImage = canvas.toDataURL("image/png", 1);
        resolve(resizedImage);
      };

      img.src = imageSrc;
    });
  };

  const onCloseCamera = () => {
    setShowCamera(false);
    setIsSelectImage(false);
  };
  const deleteImage = () => {
    setImageSrc("");
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const resizedImage = await resizeImage(reader.result, 100, 100);
        setImageSrc(resizedImage);
        ImageData = resizedImage;
        // setShowCamera(false);
        // onSubmitClick(cameraIndex, itemId);
      };
      reader.readAsDataURL(file);
    }
  };

  const onUploadImagePressed = (index: number) => {
    if (ImageData !== null) {
      // onSubmitClick(index, itemId);
      onControlActionPressed(itemId, index);
      setIsSelectImage(false);
    } else {
      console.log("it is numll");
    }
  };

  const onSelectImagePressed = (index: number, id: number) => {
    cameraIndex = index;
    itemId = id;
    setIsSelectImage(true);
  };

  const onSubmitClick = async () => {
    try {
      let error = false;
      let _tempControls = [...controls];
      const existingPdfBytes = await fetch(pdfUrl).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const radioButtonBackgroundColor = rgb(
        0xff / 255,
        0xff / 255,
        0xe0 / 255
      );
      for (let i = 0; i < controls.length; i++) {
        const currentPage = pages[controls[i].pageNum - 1];
        // currentPage.scale(0.7, 0.7);
        pdfWidth = currentPage.getWidth();
        pdfHeight = currentPage.getHeight();

        let pdfX = controls[i].x;
        let pdfY = controls[i].y;
        let ratio = 1 / scale;
        pdfX = pdfX * ratio;
        pdfY = pdfY * ratio;
        pdfY = pdfHeight - pdfY - controls[i].height * ratio; // image height
        if (controls[i].name === "dds-txt") {
          console.log("inside dds-txt");
          if (controls[i].mandatory && controls[i].disableTextField) {
            console.log("onSubmitClick", controls[i]);

            currentPage.drawText(controls[i].txtValue, {
              x: pdfX,
              // y: pdfY + controls[i].height / 1.3,
              y: pdfY,
              size: 11,
              color: rgb(0, 0, 0),
              maxWidth: controls[i].width,
              lineHeight: 17,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            // setIsLoading(false);
            // break;
          } else if (!controls[i].mandatory && controls[i].disableTextField) {
            currentPage.drawText(controls[i].txtValue, {
              x: pdfX,
              // y: pdfY + controls[i].height / 1.3,
              y: pdfY,
              size: 11,
              color: rgb(0, 0, 0),
              maxWidth: controls[i].width,
              lineHeight: 17,
            });
            console.log("non mandatory control");
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            //  setIsLoading(false);
          } else if (controls[i].mandatory && !controls[i].disableTextField) {
            console.log("mandatory is missing", controls[i]);
            setInfo(true);
            setMessage(`text box is mandatory on page ${controls[i].pageNum}`);
            error = true;
            // postControlArray.splice(
            //   postControlArray.indexOf(controls[i].id),
            //   1
            // );
            setIsLoading(false);
            break;
          }
        } else if (controls[i].name === "dds-select") {
          if (controls[i].mandatory && controls[i].isSelect) {
            const multiSelectionBytes = await fetch(
              multiselectionImageColored
            ).then((res) => res.arrayBuffer());
            const multiSelectionPng = await pdfDoc.embedPng(
              multiSelectionBytes
            );
            currentPage.drawImage(multiSelectionPng, {
              x: pdfX,
              y: pdfY,
              width: controls[i].width,
              height: controls[i].height,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            // setIsLoading(false);
          } else if (!controls[i].mandatory && controls[i].isSelect) {
            const multiSelectionBytes = await fetch(
              multiselectionImageColored
            ).then((res) => res.arrayBuffer());
            const multiSelectionPng = await pdfDoc.embedPng(
              multiSelectionBytes
            );
            currentPage.drawImage(multiSelectionPng, {
              x: pdfX,
              y: pdfY,
              width: controls[i].width,
              height: controls[i].height,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            // setIsLoading(false);
          } else if (controls[i].mandatory && !controls[i].isSelect) {
            setInfo(true);
            setMessage(
              `select box is mandatory on page ${controls[i].pageNum}`
            );
            error = true;
            setIsLoading(false);
            break;
          }
        } else if (controls[i].name === "dds-img") {
          if (controls[i].mandatory && controls[i].isImg) {
            ImageData = controls[i].image.replace(
              /^data:image\/\w+;base64,/,
              ""
            );
            const imageUint8Array = base64ToUint8Array(ImageData);
            const pngImage = await pdfDoc.embedPng(imageUint8Array);

            // Draw the image on the PDF

            currentPage.drawImage(pngImage, {
              x: pdfX, // Adjust the x-coordinate as needed
              y: pdfY, // Adjust the y-coordinate as needed
              width: controls[i].width * ratio,
              height: controls[i].height * ratio,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
          } else if (!controls[i].mandatory && controls[i].isImg) {
            ImageData = controls[i].image.replace(
              /^data:image\/\w+;base64,/,
              ""
            );
            const imageUint8Array = base64ToUint8Array(ImageData);
            const pngImage = await pdfDoc.embedPng(imageUint8Array);

            // Draw the image on the PDF

            currentPage.drawImage(pngImage, {
              x: pdfX, // Adjust the x-coordinate as needed
              y: pdfY, // Adjust the y-coordinate as needed
              width: controls[i].width * ratio,
              height: controls[i].height * ratio,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
          } else if (controls[i].mandatory && !controls[i].isImg) {
            setInfo(true);
            setMessage(`Image  is mandatory on page ${controls[i].pageNum}`);
            error = true;
            setIsLoading(false);
            break;
          }
        } else if (controls[i].name === "dds-sign") {
          if (
            controls[i].mandatory &&
            controls[i].isSign &&
            controls[i].isDisableSignature
          ) {
            let pngUrl = null;

            if (signPad.current !== null) {
              if (!signFromText) {
                pngUrl = signPad.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png");
              } else {
                pngUrl = signPad.current;
              }
              signatureImage = JSON.parse(JSON.stringify(pngUrl));
              setSignImage(signatureImage);
            } else {
              setSignImage(signatureImage);
            }

            const pngImageBytes = await fetch(
              pngUrl !== null ? pngUrl : signImage
            ).then((res) => res.arrayBuffer());
            const image = await pdfDoc.embedPng(pngImageBytes);

            if (
              ddssignedRef.current !== null &&
              ddssignedRef.current.complete
            ) {
              let width: any;
              if (controls[i].width > ddssignedRef.current.width) {
                width = ddssignedRef.current.width;
              } else {
                width = controls[i].width;
              }

              const height = ddssignedRef.current.height;

              const xAdjustment = (controls[i].width - width) / 2;
              const yAdjustment = (controls[i].height - height) / 2;

              currentPage.drawImage(image, {
                x: pdfX + xAdjustment,
                y: pdfY + yAdjustment,
                width: width,
                height: height,
              });
            }

            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            setIsSignaturePad(false);
          } else if (
            !controls[i].mandatory &&
            controls[i].isSign &&
            controls[i].isDisableSignature
          ) {
            let pngUrl = null;

            if (signPad.current !== null) {
              if (!signFromText) {
                pngUrl = signPad.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png");
              } else {
                pngUrl = signPad.current;
              }
              signatureImage = JSON.parse(JSON.stringify(pngUrl));
              setSignImage(signatureImage);
            } else {
              setSignImage(signatureImage);
            }

            const pngImageBytes = await fetch(
              pngUrl !== null ? pngUrl : signImage
            ).then((res) => res.arrayBuffer());
            const image = await pdfDoc.embedPng(pngImageBytes);

            if (
              ddssignedRef.current !== null &&
              ddssignedRef.current.complete
            ) {
              let width: any;
              if (controls[i].width > ddssignedRef.current.width) {
                width = ddssignedRef.current.width;
              } else {
                width = controls[i].width;
              }

              // const width = ddssignedRef.current.width;
              const height = ddssignedRef.current.height;

              const xAdjustment = (controls[i].width - width) / 2;
              const yAdjustment = (controls[i].height - height) / 2;

              currentPage.drawImage(image, {
                x: pdfX + xAdjustment,
                y: pdfY + yAdjustment,
                width: width,
                height: height,
              });
            }

            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            setIsSignaturePad(false);
          } else if (
            controls[i].mandatory &&
            !controls[i].isSign &&
            !controls[i].isDisableSignature
          ) {
            setInfo(true);
            setMessage(`Signature is mandatory on page ${controls[i].pageNum}`);
            error = true;
            setIsLoading(false);
            break;
          }
          // signPad.current.clear();
        } else if (controls[i].name === "dds-initials") {
          if (
            controls[i].mandatory &&
            controls[i].isInitial &&
            controls[i].isDisableInitials
          ) {
            let pngUrl = null;
            {
              if (initialPad.current !== null) {
                if (!initialFromText) {
                  pngUrl = initialPad.current
                    .getTrimmedCanvas()
                    .toDataURL("image/png");
                } else {
                  pngUrl = initialPad.current;
                }
                initialImage = JSON.parse(JSON.stringify(pngUrl));
                setInitialImage(initialImage);
              } else {
                setInitialImage(initialImage);
              }
            }
            const pngImageBytes = await fetch(
              pngUrl !== null ? pngUrl : initialImage
            ).then((res) => res.arrayBuffer());
            const image = await pdfDoc.embedPng(pngImageBytes);

            if (
              ddsinitialRef.current !== null &&
              ddsinitialRef.current.complete
            ) {
              let width: any;
              if (controls[i].width > ddsinitialRef.current.width) {
                width = ddsinitialRef.current.width;
              } else {
                width = controls[i].width;
              }
              // const width = ddsinitialRef.current.width;
              const height = ddsinitialRef.current.height;

              const xAdjustment = (controls[i].width - width) / 2;
              const yAdjustment = (controls[i].height - height) / 2;

              currentPage.drawImage(image, {
                x: pdfX + xAdjustment,
                y: pdfY + yAdjustment,
                width: width,
                height: height,
              });
            }
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            setisInitialPad(false);
          } else if (
            !controls[i].mandatory &&
            controls[i].isInitial &&
            controls[i].isDisableInitials
          ) {
            let pngUrl = null;
            {
              if (initialPad.current !== null) {
                if (!initialFromText) {
                  pngUrl = initialPad.current
                    .getTrimmedCanvas()
                    .toDataURL("image/png");
                } else {
                  pngUrl = initialPad.current;
                }

                initialImage = JSON.parse(JSON.stringify(pngUrl));
                setInitialImage(initialImage);
              } else {
                setInitialImage(initialImage);
              }
            }
            const pngImageBytes = await fetch(
              pngUrl !== null ? pngUrl : initialImage
            ).then((res) => res.arrayBuffer());
            const image = await pdfDoc.embedPng(pngImageBytes);

            if (
              ddsinitialRef.current !== null &&
              ddsinitialRef.current.complete
            ) {
              let width: any;
              if (controls[i].width > ddsinitialRef.current.width) {
                width = ddsinitialRef.current.width;
              } else {
                width = controls[i].width;
              }
              const height = ddsinitialRef.current.height;

              const xAdjustment = (controls[i].width - width) / 2;
              const yAdjustment = (controls[i].height - height) / 2;

              currentPage.drawImage(image, {
                x: pdfX + xAdjustment,
                y: pdfY + yAdjustment,
                width: width,
                height: height,
              });
            }
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
            setisInitialPad(false);
          } else if (
            controls[i].mandatory &&
            !controls[i].isInitial &&
            !controls[i].isDisableInitials
          ) {
            setInfo(true);
            setMessage(`Initials is mandatory on page ${controls[i].pageNum}`);
            error = true;
            setIsLoading(false);
            break;
          }
          // initialPad.current.clear();
        } else if (controls[i].name === "dds-radio") {
          console.log("inside radio1", controls[i]);
          if (
            controls[i].mandatory &&
            controls[i].isRadio &&
            controls[i].isRadioDisabled
          ) {
            console.log("inside radio2");
            const radioBoxBytes = await fetch(radioBoxImageColored).then(
              (res) => res.arrayBuffer()
            );
            const radioBoxPng = await pdfDoc.embedPng(radioBoxBytes);
            currentPage.drawImage(radioBoxPng, {
              x: pdfX,
              y: pdfY,
              width: controls[i].width,
              height: controls[i].height,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
          } else if (
            !controls[i].mandatory &&
            controls[i].isRadio &&
            controls[i].isRadioDisabled
          ) {
            const radioBoxBytes = await fetch(radioBoxImageColored).then(
              (res) => res.arrayBuffer()
            );
            const radioBoxPng = await pdfDoc.embedPng(radioBoxBytes);
            currentPage.drawImage(radioBoxPng, {
              x: pdfX,
              y: pdfY,
              width: controls[i].width,
              height: controls[i].height,
            });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
          } else if (
            controls[i].mandatory &&
            !controls[i].isRadio &&
            !controls[i].isRadioDisabled
          ) {
            console.log("inside radio2", controls[i]);
            setInfo(true);
            setMessage(`Radio is mandatory on page ${controls[i].pageNum}`);
            error = true;
            setIsLoading(false);
            break;
          }
        } else if (controls[i].name === "dds-sign-combo") {
          if (controls[i].mandatory && controls[i].isSignCombo) {
            let pngUrl = null;

            if (signComboPad.current !== null) {
              if (!signComboText) {
                pngUrl = signComboPad.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png");
              } else {
                pngUrl = signComboPad.current;
              }
              signatureImage = JSON.parse(JSON.stringify(pngUrl));
              setSignComboImage(signatureImage);
            } else {
              setSignComboImage(signatureImage);
            }

            const pngImageBytes = await fetch(
              pngUrl !== null ? pngUrl : signComboImage
            ).then((res) => res.arrayBuffer());
            const image = await pdfDoc.embedPng(pngImageBytes);

            if (
              signedImageRef.current !== null &&
              signedImageRef.current.complete
            ) {
              // Access the width and height of the image using the ref
              let width: any;
              if (controls[i].width > signedImageRef.current.width) {
                width = signedImageRef.current.width;
              } else {
                width = controls[i].width;
              }
              // const width = signedImageRef.current.width;
              const height = signedImageRef.current.height;

              // Calculate adjustments for x and y coordinates
              const xAdjustment = (controls[i].width - width) / 2;
              const yAdjustment = (controls[i].height - height) / 2;

              // Draw the image with adjusted width, height, x, and y
              currentPage.drawImage(image, {
                x: pdfX + xAdjustment,
                y: pdfY + yAdjustment, // Adjust the y-coordinate as needed
                width: width,
                height: height,
              });
            }
            // currentPage.drawText(serverDate, {
            //   x: pdfX,
            //   // y: pdfY + controls[i].height / 1.3,
            //   y: pdfY - 10,
            //   size: 10,
            //   color: rgb(0, 0, 0),
            // });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
          } else if (!controls[i].mandatory && controls[i].isSignCombo) {
            let pngUrl = null;

            if (signComboPad.current !== null) {
              if (!signComboText) {
                pngUrl = signComboPad.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png");
              } else {
                pngUrl = signComboPad.current;
              }

              signatureImage = JSON.parse(JSON.stringify(pngUrl));
              setSignComboImage(signatureImage);
            } else {
              setSignComboImage(signatureImage);
            }

            const pngImageBytes = await fetch(
              pngUrl !== null ? pngUrl : signComboImage
            ).then((res) => res.arrayBuffer());
            const image = await pdfDoc.embedPng(pngImageBytes);
            if (
              signedImageRef.current !== null &&
              signedImageRef.current.complete
            ) {
              // Access the width and height of the image using the ref
              let width: any;
              if (controls[i].width > signedImageRef.current.width) {
                width = signedImageRef.current.width;
              } else {
                width = controls[i].width;
              }
              const height = signedImageRef.current.height;

              // Calculate adjustments for x and y coordinates
              const xAdjustment = (controls[i].width - width) / 2;
              const yAdjustment = (controls[i].height - height) / 2;

              // Draw the image with adjusted width, height, x, and y
              currentPage.drawImage(image, {
                x: pdfX + xAdjustment,
                y: pdfY + yAdjustment, // Adjust the y-coordinate as needed
                width: width,
                height: height,
              });
            }
            // currentPage.drawText(serverDate, {
            //   x: pdfX,
            //   // y: pdfY + controls[i].height / 1.3,
            //   y: pdfY - 10,
            //   size: 10,
            //   color: rgb(0, 0, 0),
            // });
            if (!postControlArray.includes(controls[i].id)) {
              postControlArray.push(controls[i].id);
            }
          } else if (controls[i].mandatory && !controls[i].isSignCombo) {
            setInfo(true);
            setMessage(
              `Signature with image is mandatory on page ${controls[i].pageNum}`
            );
            error = true;
            setIsLoading(false);
            break;
          }
        } else if (
          controls[i].name === "dds-img-combo" &&
          controls[i].isSignCombo
        ) {
          ImageData = controls[i].image.replace(/^data:image\/\w+;base64,/, "");
          const imageUint8Array = base64ToUint8Array(ImageData);
          const pngImage = await pdfDoc.embedPng(imageUint8Array);

          // Draw the image on the PDF

          currentPage.drawImage(pngImage, {
            x: pdfX, // Adjust the x-coordinate as needed
            y: pdfY, // Adjust the y-coordinate as needed
            width: controls[i].width * ratio,
            height: controls[i].height * ratio,
          });
          if (!postControlArray.includes(controls[i].id)) {
            postControlArray.push(controls[i].id);
          }
        } else if (
          controls[i].name === "dds-dnt-combo" &&
          controls[i].isDateCombo
        ) {
          currentPage.drawText(serverDate, {
            x: pdfX,
            // y: pdfY + controls[i].height / 1.3,
            y: pdfY + controls[i].height / 2,
            size: 10,
            lineHeight: 10,
            color: rgb(0, 0, 0),
            maxWidth: controls[i].width,
          });
          //////
        } else if (
          controls[i].name === "dds-dnt-sign" &&
          controls[i].isSignDate
        ) {
          currentPage.drawText(serverDate, {
            x: pdfX,
            // y: pdfY + controls[i].height / 1.3,
            y: pdfY + controls[i].height / 2,
            size: 10,
            lineHeight: 10,
            color: rgb(0, 0, 0),
            maxWidth: controls[i].width,
          });
          //////
        }
      }
      var pdfBase = await pdfDoc.saveAsBase64();
      setModifiedPdfBase64("data:application/pdf;base64," + pdfBase);
      console.log("this is base64 pdf ::", pdfBase);
      // setPdf("data:application/pdf;base64," + pdfBase);
      pdfUrl = "data:application/pdf;base64," + pdfBase;
      pdfBase64 = pdfBase;
      // setIsLoading(false);
      if (error) {
        return error;
      } else {
        return pdfBase;
      }
    } catch (error) {
      console.log("submit", error);
      setIsLoading(false);
    }
  };

  const onSubmitWorkFlowPressed = async () => {
    try {
      // if (pdfBase64 !== null) {
      setIsLoading(true);
      let i = 0;

      let pdfBase64 = await onSubmitClick();
      console.log("postControlArray", postControlArray);
      console.log("onSubmitWorkFlowPressed", pdfBase64);
      if (typeof pdfBase64 === "string") {
        let postData = {
          uuid: state.uuid,
          updatedAt: data[0].updated_at_raw,
          controlIds: postControlArray,
          pdfBase64: pdfBase64,
        };
        console.log(postData);
        let response = await fetchPost(
          state.fromRoute === WORKFLOWBYEMAIL
            ? ApiName.submitEmailWorkFlow
            : ApiName.submitWorkFlow,
          null,
          postData
        );
        console.log("post ", response);
        if (response.status === 0) {
          if (state.fromRoute === WORKFLOWBYEMAIL) {
            setIsLoading(false);
            setInfo(true);
            setMessage("Thank you document submitted successfully");
            isComplete = true;
            // navigate(REGISTER);
          } else {
            setIsLoading(false);
            navigate(DASHBOARD);
          }
        } else if (response.status === 1) {
          setIsLoading(false);
          if (
            response.response ===
            "Make sure all mandatory actions have been performed before submitting"
          ) {
            setInfo(true);
            setMessage(
              "Make sure all mandatory actions have been performed before submitting"
            );
          } else if (
            response.response ===
            "This workflow has changed since you last accessed it, please reload and make your changes again"
          ) {
            setMessage(
              "This workflow has changed since you last accessed it, please reload and make your changes again"
            );
            setInfo(true);
            setIsLoading(false);
          } else {
            setMessage("something went wrong please try again");
            setInfo(true);
            setIsLoading(false);
          }
        }
      } else if (pdfBase64 === undefined) {
        setIsLoading(false);
        setMessage("please perform at least one action ");
        setInfo(true);
      } else {
        setIsLoading(false);
        setMessage("please perform one action at lease");
        setInfo(true);
      }
    } catch (error) {
      console.log("onSubmitWorkFlowPressed" + error);
      setIsLoading(false);
    }
  };
  const onInfoClosedPressed = () => {
    setInfo(false);
    setMessage("");
  };
  const onOkPressed = () => {
    setInfo(false);
    setMessage("");
    if (isComplete) {
      if (!isLoggedIn) {
        navigate(REGISTER);
      } else {
        updateMenuButton(true);
        navigate(DASHBOARD);
      }
    }
  };
  const onCheckBoxPressed = (id: number) => {
    setIsCheckBox(true);
  };
  const onRadioButtonPressed = (id: number) => {
    setIsRadio(true);
  };

  const onControlActionPressed = (id: number, index: number) => {
    let _tempControls = [...controls];
    let _index = _tempControls.findIndex(
      (item: AssignedWorkFlowControl) => item.id === id
    );
    if (_tempControls[_index].name === "dds-sign") {
      _tempControls[_index].isSign = true;
      _tempControls[_index].isDisableSignature =
        !_tempControls[_index].isDisableSignature;
      _tempControls[_index + 1].isSignDate = true;
      // setActiveControlIndex(_tempControls[_index].id);
      // onSubmitClick(index, id);
      console.log("signature pressed", _tempControls[_index]);
      setActiveControlIndex(_index);
    } else if (_tempControls[_index].name === "dds-initials") {
      console.log("dds-initials", _tempControls[_index].name);
      _tempControls[_index].isInitial = true;
      _tempControls[_index].isDisableInitials =
        !_tempControls[_index].isDisableInitials;
      // setActiveControlIndex(_tempControls[_index].id);
      // onSubmitClick(index, id);
      setActiveControlIndex(_index);
    } else if (_tempControls[_index].name === "dds-txt") {
      // _tempControls[_index].isTxt = true;
      _tempControls[_index].disableTextField =
        !_tempControls[_index].disableTextField;
      // setActiveControlIndex(_tempControls[_index].id);
      setActiveControlIndex(_index);
    } else if (_tempControls[_index].name === "dds-img") {
      _tempControls[_index].isImg = !_tempControls[_index].isImg;
      _tempControls[_index].image = ImageData;
      // setActiveControlIndex(_tempControls[_index].id);
      setActiveControlIndex(_index);
    } else if (_tempControls[_index].name === "dds-select") {
      _tempControls[_index].isSelect = !_tempControls[_index].isSelect;
      _tempControls[_index].isCheckBoxDisabled =
        !_tempControls[_index].isCheckBoxDisabled;
      // setActiveControlIndex(_tempControls[_index].id);
      setActiveControlIndex(_index);
    } else if (_tempControls[_index].name === "dds-radio") {
      _tempControls[_index].isRadio = !_tempControls[_index].isRadio;
      _tempControls[_index].isRadioDisabled =
        !_tempControls[_index].isRadioDisabled;
      // setActiveControlIndex(_tempControls[_index].id);
      setActiveControlIndex(_index);
    } else if (_tempControls[_index].name === "dds-sign-combo") {
      _tempControls[_index].isSignCombo = !_tempControls[_index].isSignCombo;
      _tempControls[_index + 1].isSignCombo =
        !_tempControls[_index + 1].isSignCombo;
      _tempControls[_index + 1].image = comboImagedata;
      _tempControls[_index + 2].isDateCombo =
        !_tempControls[_index + 2].isDateCombo;
      // _tempControls[_index].isDisableSignature =
      //   !_tempControls[_index].isDisableSignature;
      // setActiveControlIndex(_tempControls[_index].id);
      setActiveControlIndex(_index);
    }
    console.log("temControls", _tempControls);
    setControls(_tempControls);
  };
  const onfocus = (id: number, pageNumber: number, index: number) => {
    setActiveControlIndex(index);
    setPageNumber(pageNumber);
  };

  const onsaveSignaturePressed = async (
    val: any,
    index: number,
    option: number
  ) => {
    try {
      // console.log(
      //   "onsaveSignaturePressed",
      //   signPad.current.getTrimmedCanvas().toDataURL("image/png"),
      //   "index",
      //   index
      // );
      let pngUrl = null;
      let _tempControls = [...controls];
      console.log(
        "onsaveSignaturePressed",
        "index",
        _tempControls[index],
        signPad.current
      );
      console.log("abc :::", "index", _tempControls);
      {
        if (signPad.current !== null) {
          if (option === 1) {
            pngUrl = signPad.current.getTrimmedCanvas().toDataURL("image/png");
            console.log("in case of option 1", pngUrl);
          } else {
            signFromText = true;
            pngUrl = signPad.current;
            console.log("in case of option 2", pngUrl);
          }

          signatureImage = JSON.parse(JSON.stringify(pngUrl));
          setSignImage(signatureImage);
          // await handleCaptureImage();
          // setIsSignaturePad(false);
          setShowCamera(false);
          setIsSignaturePad(false);
          _tempControls[index].isSign = true;
          _tempControls[index + 1].isSignDate = true;
          _tempControls[index].isDisableSignature =
            !_tempControls[index].isDisableSignature;
          setControls(_tempControls);
          let _tempoptions = {
            option1: true,
            option2: false,
          };
          setSignatureBy(_tempoptions);
        } else {
          setSignImage(signatureImage);
          // await handleCaptureImage();
          // setIsSignaturePad(false);
          setShowCamera(false);
          setIsSignaturePad(false);
          _tempControls[index].isSign = true;
          _tempControls[index + 1].isSignDate = true;
          _tempControls[index].isDisableSignature =
            !_tempControls[index].isDisableSignature;
          setControls(_tempControls);
          let _tempoptions = {
            option1: true,
            option2: false,
          };
          setSignatureBy(_tempoptions);
        }
      }
    } catch (error) {
      console.log("onSubmitPressed2" + error);
      setIsSignaturePad(false);
    }
  };
  const onsaveSignatureComboPressed = async (
    val: any,
    index: number,
    option: number
  ) => {
    try {
      let pngUrl = null;
      {
        if (signComboPad.current !== null) {
          if (option === 1) {
            pngUrl = signComboPad.current
              .getTrimmedCanvas()
              .toDataURL("image/png");
            console.log("in case of option 1", pngUrl);
          } else {
            signComboText = true;
            pngUrl = signComboPad.current;
            console.log("in case of option 2", pngUrl);
          }

          signatureImage = JSON.parse(JSON.stringify(pngUrl));
          setSignComboImage(signatureImage);
          setIsLoading(true);
          await handleCaptureComboImage();
          // setIsSignaturePad(false);
        } else {
          setSignComboImage(signatureImage);
          setIsLoading(true);
          await handleCaptureComboImage();
          // setIsSignaturePad(false);
        }
      }
    } catch (error) {
      console.log("onSubmitPressed2" + error);
      setIsSignaturePad(false);
    }
  };

  const onsaveInitialPressed = (val: any, index: number, option: number) => {
    try {
      console.log("onsaveInitialPressed", initialPad.current, option);
      let pngUrl = null;
      let _tempControls = [...controls];
      {
        if (initialPad.current !== null) {
          if (option === 1) {
            pngUrl = initialPad.current
              .getTrimmedCanvas()
              .toDataURL("image/png");
            console.log("in case of option 1", pngUrl);
          } else {
            initialFromText = true;
            pngUrl = initialPad.current;
            console.log("in case of option 2", pngUrl);
          }
          initialImage = JSON.parse(JSON.stringify(pngUrl));
          setInitialImage(initialImage);
          setisInitialPad(false);
          _tempControls[index].isInitial = true;
          _tempControls[index].isDisableInitials =
            !_tempControls[index].isDisableInitials;
          setControls(_tempControls);
          let _tempoptions = {
            option1: true,
            option2: false,
          };
          setSignatureBy(_tempoptions);
        } else {
          setInitialImage(initialImage);
          setisInitialPad(false);
          _tempControls[index].isInitial = true;
          _tempControls[index].isDisableInitials =
            !_tempControls[index].isDisableInitials;
          setControls(_tempControls);
          let _tempoptions = {
            option1: true,
            option2: false,
          };
          setSignatureBy(_tempoptions);
        }
      }
    } catch (error) {
      console.log("onsaveInitialPressed" + error);
      setIsSignaturePad(false);
      setisInitialPad(false);
    }
  };

  const onNextControlPressed = () => {
    let next = activeControlIndex;
    console.log("onNextControlPressed", controls.length);
    // if (next < controls[controls.length - 1].id) {
    if (next < controls.length - 1) {
      next = next + 1;
      // let _foundIndex = controls.findIndex(
      //   (item: AssignedWorkFlowControl) => item.id === next
      // );
      console.log("onNextControlPressed next", next);

      setActiveControlIndex(next);
      setPageNumber(controls[next].pageNum);
    }
  };
  const onPreviouseControlPressed = () => {
    let previous = activeControlIndex;
    if (previous > 0) {
      previous = previous - 1;
      // let _foundIndex = controls.findIndex(
      //   (item: AssignedWorkFlowControl) => item.id === previous
      // );
      setActiveControlIndex(previous);
      setPageNumber(controls[previous].pageNum);
    }
  };

  const onSignatureComboButtonPressed = (index: number, id: number) => {
    cameraIndex = index;
    itemId = id;
    setIsSignatureComboPad(true);
  };

  const onChangeOption = (option: number) => {
    if (option === 1) {
      // setRadioButton(!radioButton.radioButton1);
      let _tempoptions = {
        option1: true,
        option2: false,
      };
      setSignatureBy(_tempoptions);
    } else if (option === 2) {
      let _tempoptions = {
        option1: false,
        option2: true,
      };
      setSignatureBy(_tempoptions);
    }
  };
  return (
    <>
      <EditControl
        isLoading={isLoading}
        pdfLoading={pdfLoading}
        // elementRef={elementRef}
        pdfUrl={pdf}
        pdfScale={pdfScale}
        pageNumber={pageNumber}
        pdfWidth={pdfWidth}
        numPages={numPages}
        controls={controls}
        webcamRef={webcamRef}
        imageSrc={imageSrc}
        roleId={roleId}
        activeControlIndex={activeControlIndex}
        signPad={signPad}
        initialPad={initialPad}
        fileInputRef={fileInputRef}
        showCamera={showCamera}
        cameraIndex={cameraIndex}
        isDragging={isDragging}
        isSelectImage={isSelectImage}
        message={message}
        isInfo={isInfo}
        itemId={itemId}
        isSignaturePad={isSignaturePad}
        isInitialPad={isInitialPad}
        isCheckBox={isCheckBox}
        isRadioButton={isRadioButton}
        workFlowTitle={workFlowTitle}
        signImage={signImage}
        initialsImage={initialsImage}
        signComboImage={signComboImage}
        signComboPad={signComboPad}
        isSignatureComboPad={isSignatureComboPad}
        serverDate={serverDate}
        signatureBy={signatureBy}
        onClearSignaturePadCombo={onClearSignaturePadCombo}
        onLoadSuccess={onDocumentLoadSuccess}
        onPreviousePressed={onPreviousePressed}
        onNextPagePressed={onNextPagePressed}
        // savePdfData={savePdfData}
        updateMessage={updateMessage}
        onChangeTextControl={onChangeTextControl}
        onSubmitClick={onSubmitClick}
        captureImage={captureImage}
        handleSignature={handleSignature}
        onClearSignaturePad={onClearSignaturePad}
        onCloseSignaturePad={onCloseSignaturePad}
        onCloseSignatureComboPad={onCloseSignatureComboPad}
        handleCaptureImage={handleCaptureImage}
        handleFileInputChange={handleFileInputChange}
        handleStartCamera={handleStartCamera}
        onCloseCamera={onCloseCamera}
        deleteImage={deleteImage}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onUploadImagePressed={onUploadImagePressed}
        onSelectImagePressed={onSelectImagePressed}
        handleInitials={handleInitials}
        onClearInitalPad={onClearInitalPad}
        onSubmitWorkFlowPressed={onSubmitWorkFlowPressed}
        onInfoClosedPressed={onInfoClosedPressed}
        onOkPressed={onOkPressed}
        onSignatureButtonPressed={onSignatureButtonPressed}
        onInitailButtonPressed={onInitailButtonPressed}
        onCloseInitialPad={onCloseInitialPad}
        onCheckBoxPressed={onCheckBoxPressed}
        onRadioButtonPressed={onRadioButtonPressed}
        onControlActionPressed={onControlActionPressed}
        onfocus={onfocus}
        onsaveSignaturePressed={onsaveSignaturePressed}
        onsaveInitialPressed={onsaveInitialPressed}
        onNextControlPressed={onNextControlPressed}
        onPreviouseControlPressed={onPreviouseControlPressed}
        onSignatureComboButtonPressed={onSignatureComboButtonPressed}
        onsaveSignatureComboPressed={onsaveSignatureComboPressed}
        onChangeOption={onChangeOption}
        signedImageRef={signedImageRef}
        ddssignedRef={ddssignedRef}
        ddsinitialRef={ddsinitialRef}
      />
    </>
  );
};

export default EditControlScreen;
