import React, { FC } from "react";
import {
  CameraAlt,
  CheckBox,
  FontDownload,
  TranslateSharp,
  RadioButtonCheckedSharp,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import signControl from "../../Assets/sign-control.png";
import initalsImage from "../../Assets/Initials -50x50.png";
import radioImage from "../../Assets/Radio Button -50x50.png";
interface ToolBar {
  isTemplate: boolean;
  isUpdate: boolean;
  toHide: boolean;
  handleMouseDown: (id: number) => void;
  openTemplateClick: () => void;
  handleMouseMovementForTemplate: (event: any) => void;
}
const ToolBar: FC<ToolBar> = (props: ToolBar) => {
  return (
    <div className="toolBoxMainContainer">
      <div className="toolBoxSubContainer">
        {/*text box container */}

        <div className="textBoxContainer">
          <IconButton
            onMouseDown={() => props.handleMouseDown(1)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            <FontDownload />
          </IconButton>
          <div style={{ fontSize: 10 }}>Text</div>
        </div>
        {/*Multi selection container */}

        <div className="mulitSelectionConatiner">
          <IconButton
            onMouseDown={() => props.handleMouseDown(2)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            <CheckBox />
          </IconButton>
          <div style={{ fontSize: 10 }}>Selection</div>
        </div>
        <div className="signatureBoxContainer">
          <IconButton
            onMouseDown={() => props.handleMouseDown(4)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            {/* <FontDownload /> */}
            <>
              <TranslateSharp />
            </>
          </IconButton>
          <div style={{ fontSize: 10 }}>Signature</div>
        </div>
        {/*image box container */}

        <div className="imageBoxContainer">
          <IconButton
            onMouseDown={() => props.handleMouseDown(3)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            <CameraAlt />
          </IconButton>
          <div style={{ fontSize: 12 }}>Image</div>
        </div>
        {/*signature box container */}

        {/*initals box container */}
        <div className="signatureBoxContainer">
          <IconButton
            onMouseDown={() => props.handleMouseDown(5)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            {/* <img src={initalsImage} style={{ width: 20, height: 20 }} /> */}
            <TranslateSharp />
          </IconButton>
          <div style={{ fontSize: 10, marginTop: 5 }}>Initials</div>
        </div>
        {/*radio box container */}
        <div className="signatureBoxContainer">
          <IconButton
            onMouseDown={() => props.handleMouseDown(6)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            {/* <img src={radioImage} style={{ width: 20, height: 20 }} /> */}
            <RadioButtonCheckedSharp />
          </IconButton>
          <div style={{ fontSize: 10, marginTop: 5 }}>radio</div>
        </div>

        {/*Signature box with image box container */}
        <div className="signatureBoxContainer">
          <IconButton
            onMouseDown={() => props.handleMouseDown(7)}
            style={{
              borderRadius: 10,
              fontSize: 12,
              color: "#7A86A1",
              border: "1px solid #DEE1E9",
            }}
          >
            {/* <img src={signControl} style={{ width: 20, height: 20 }} /> */}
            <TranslateSharp />
          </IconButton>
          <div style={{ fontSize: 10, marginTop: 5 }}>Signature with image</div>
        </div>
        {/*template box container */}

        {/* {!props.toHide && (
          <div className="signatureBoxContainer">
            <IconButton
              onClick={props.openTemplateClick}
              style={{
                borderRadius: 10,
                fontSize: 12,
                color: "#7A86A1",
                border: "1px solid #DEE1E9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 20,
                  height: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                T
              </p>
            </IconButton>
            <div style={{ fontSize: 10, marginTop: 5 }}>Template</div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ToolBar;
