import React, { FC, useEffect, useRef, useState } from "react";
import {
  FontDownload,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CameraAlt,
  CheckBoxOutlineBlank,
  CheckBox,
  RadioButtonUnchecked,
  RadioButtonChecked,
  CloseFullscreen,
} from "@mui/icons-material";
import {
  AllControlsList,
  IReceipentList,
  ITemplateConrolsList,
  IZoneList,
  UserList,
} from "../Types/Types";
import {
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import textImage from "../Assets/text.png";
import camera from "../Assets/camera.png";
import tick from "../Assets/tick.png";
import signControl from "../Assets/sign-control.png";
import sign from "../Assets/sign.png";
import textInputImage from "../Assets/Text box-50x50.png";
import multiSelectionImage from "../Assets/Multi selection-50x50.png";
import imageBoxImage from "../Assets/Image box-50x50.png";
import signatureBoxIamge from "../Assets/Signature Box-50x50.png";
import initalsBoxImage from "../Assets/initials.png";
import radioButtonImage from "../Assets/radio.png";
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup";

interface Configuration {
  allControlsList: AllControlsList[];
  userList: UserList[];
  activeItem: number;
  activeControlIndex: number;
  smsNotification: boolean;
  viewControl: boolean;
  emailNotification: boolean;
  documentDetailsShow: boolean;
  hireacrhyList: any;
  emailError: string;
  showEmailError: boolean;
  EmailSuggestionArray: any;
  nameSuggestionArray: any;
  instructions: string;
  activeHirearchyNumber: number;
  workFlowTitle: string;
  workFlowDescription: string;
  isLoading: boolean;
  isUpdate: boolean;
  emailMissing: number;
  listContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  receipentList: IReceipentList[];
  filterSelectList: IReceipentList[];
  isEditUser: boolean;
  expiry: boolean;
  timezone: string;
  dateTime: string;
  zoneList: IZoneList[];
  selectedZone: string;
  templateCreationControlsList: ITemplateConrolsList[];
  toHide: boolean;
  onSelectZonePressed: (val: string) => void;
  onControlClicked: (
    index: number,
    controlId: number,
    pageNumber: number,
    id: number
  ) => void;
  // onChangeUserId: (id: any, index: number) => void;
  onHireacrhyDropDownPressed: (id: any, index: number) => void;
  onMandatoryPressed: (index: number) => void;
  savePdfData: (workFlowTitle: string, workFlowDescription: string) => void;
  onEmailNotificationPressed: () => void;
  onSmsNotificationPressed: () => void;
  onDoucmentDetailsPressed: () => void;
  onCreateTemplatePressed: () => void;
  onChangeControlUserName: (val: string, index: number, email: string) => void;
  onChangeControlUserEmail: (val: string, index: number) => void;
  onLeaveFocusEmail: () => void;
  onChangeInstructions: (value: string) => void;
  onDoucmentDetailsPressed2: () => void;
  onAddHierarchyButton: (index: number) => void;
  onSubtractHierarchyButton: (index: number) => void;
  onSelectRecipientPressed: (_userEmail: any, _controlId: number) => void;
  onFilterListPressed: (_email: any) => void;
  onAddReceipentPressed: (_isEditUser: boolean) => void;
  onExpiryPressed: (_val: boolean) => void;
  onSetDateTimeValue: (val: string) => void;
  onSaveCreatedTemplate: (title: string) => Promise<void>;
  setSelectedEmail: React.Dispatch<React.SetStateAction<string>>;
}
let color_rgb: any;
let newSelectedEmail: any;
const Configuration: FC<Configuration> = (props: Configuration) => {
  const inputRef = useRef(null);

  const [selectedEmail, setSelectedEmail] = useState("");
  const [color, setColor] = useState(false);
  const timezones = ["GMT", "EST", "PST", "CST"];

  const handleFilterChange = (event: any) => {
    newSelectedEmail = event.target.value;
    setColor(true);
    const matchingObject = props.filterSelectList.find(
      (obj) => obj.email === newSelectedEmail
    );
    color_rgb = matchingObject ? matchingObject.color : null;
    setSelectedEmail(newSelectedEmail);
    setColor(true);
    // props.onFilterListPressed(newSelectedEmail);
    props.setSelectedEmail(event.target.value);

    // props.onFilterListPressed(newSelectedEmail);
  };
  const validationSchema = Yup.object().shape({
    workFlowTitle: Yup.string()
      .required("title is required")
      .min(2, "title must contain at least 2 characters")
      .max(50, "title must not exceed 50 characters"),
    workFlowDescription: Yup.string()
      .min(2, "discription must contain at least 2 characters")
      .max(250, "discription must not exceed 50 characters"),
  });

  const formik = useFormik({
    initialValues: {
      workFlowTitle: "",
      workFlowDescription: "",
      email: [""],
      name: [""],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.toHide) {
        props.onSaveCreatedTemplate(values.workFlowTitle);
      } else {
        props.savePdfData(values.workFlowTitle, values.workFlowDescription);
        console.log("values", values);
      }
    },
  });
  useEffect(() => {
    formik.setFieldValue("workFlowTitle", props.workFlowTitle, false);
    formik.setFieldValue(
      "workFlowDescription",
      props.workFlowDescription,
      false
    );
  }, [props.workFlowTitle, props.workFlowDescription]);

  useEffect(() => {
    if (props.filterSelectList.length > 0) {
      setSelectedEmail(props.filterSelectList[0].email);
    }
  }, [props.filterSelectList]);
  return (
    <>
      <Grid item sm={3} md={3} lg={3} xl={3} style={{ background: "#F5F6F6" }}>
        <div className="pdf-right-controls">
          {!props.toHide && <div className="main-title">Quick Controls</div>}
          <div className="quick-controls">
            {!props.toHide && (
              <div className="select-filter">
                {/* <select name="" id="">
                <option value="">Filters</option>
                <option value="">Option 1</option>
                <option value="">Option 2</option>
              </select> */}
                <FormControl fullWidth className="filter__text">
                  {/* Filter */}
                  <Select
                    className="receipent-select"
                    labelId="user-dropdown-label"
                    id="user-dropdown"
                    value={selectedEmail}
                    onChange={handleFilterChange}
                    style={{
                      backgroundColor: color
                        ? `rgba(${color_rgb}, 0.5)`
                        : `rgba(${props.filterSelectList[0]?.color}, 0.5)`,
                      margin: 0,
                    }}
                  >
                    {props.filterSelectList.length > 0 &&
                      props.filterSelectList.map((recipient) => (
                        <MenuItem
                          key={recipient.id}
                          value={recipient.email}
                          style={{
                            backgroundColor: `rgba(${
                              recipient.color + ", 0.5"
                            })`,
                            margin: 0,
                          }}
                        >
                          {recipient.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {!props.toHide && (
              <div className="new-user-btn">
                <Button
                  onClick={() => props.onAddReceipentPressed(false)}
                  variant="contained"
                >
                  Add New User
                </Button>
                <Button
                  onClick={() => props.onAddReceipentPressed(true)}
                  variant="contained"
                  className="edit__user__btn"
                >
                  Edit User
                </Button>
              </div>
            )}
          </div>

          <div className="main-title" style={{}}>
            Configuration
            {/* Document detail */}
            <div className="document-detail-section">
              <div
                className="dd-title"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Document Details{" "}
              </div>
              {props.documentDetailsShow && (
                <>
                  {/* first section */}
                  <div className="dd-first-sec">
                    <div className="dd-item">
                      <input
                        type="text"
                        name="workFlowTitle"
                        placeholder="Enter work flow title"
                        value={formik.values.workFlowTitle}
                        maxLength={50}
                        onChange={formik.handleChange}
                      />
                      <div className="error-msg">
                        {formik.errors.workFlowTitle}
                      </div>
                    </div>
                    {!props.toHide && (
                      <div className="dd-item">
                        <input
                          type="text"
                          placeholder="Enter work flow description"
                          name="workFlowDescription"
                          value={formik.values.workFlowDescription}
                          maxLength={250}
                          onChange={formik.handleChange}
                        />
                        <div className="error-msg">
                          {formik.errors.workFlowDescription}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* third section */}
                  {!props.toHide && (
                    <div className="dd-third-sec">
                      <div className="dd-item">
                        <div className="title">Instructions</div>
                        <textarea
                          name=""
                          id=""
                          value={props.instructions}
                          placeholder="Type your instructions here.."
                          onChange={(val) =>
                            props.onChangeInstructions(val.target.value)
                          }
                          maxLength={250}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  {/* second section */}
                  {/* {!props.toHide && (
                    <div className="dd-second-sec">
                      <div className="notify-btn">
                        <IconButton
                          onClick={() => props.onEmailNotificationPressed()}
                          style={{
                            borderRadius: 10,
                            fontSize: 18,
                            color: "#A89BB9",
                            height: 20,
                            width: 20,
                          }}
                          disabled={true}
                          size="small"
                        >
                          {props.emailNotification === false ? (
                            <RadioButtonUnchecked className="radio-btn" />
                          ) : (
                            <RadioButtonChecked className="radio-btn" />
                          )}
                        </IconButton>
                        Notify by Email
                      </div>
                    </div>
                  )} */}

                  {!props.toHide && (
                    <div className="expiry__flow">
                      <FormControlLabel
                        control={
                          <Radio
                            checked={props.expiry}
                            onChange={() =>
                              props.onExpiryPressed(!props.expiry)
                            }
                            onClick={() => {
                              props.onExpiryPressed(!props.expiry);
                            }}
                          />
                        }
                        label="Document Expiry"
                        className="expiry__label"
                      />

                      {props.expiry ? (
                        <div>
                          <FormControl className="expiry__select__field">
                            {/* <Select
                              value={props.selectedZone}
                              onChange={(e) =>
                                props.onSelectZonePressed(e.target.value)
                              }
                              className="expiry__select__option"
                            >
                              {props.zoneList.map(
                                (zone: IZoneList, index: number) => (
                                  <MenuItem key={index} value={zone.value}>
                                    {zone.value}
                                  </MenuItem>
                                )
                              )}
                            </Select> */}
                            <select
                              className="expiry__select__option"
                              value={props.selectedZone}
                              onChange={(e) =>
                                props.onSelectZonePressed(e.target.value)
                              }
                            >
                              {props.zoneList.map(
                                (zone: IZoneList, index: number) => (
                                  <option key={index} value={zone.value}>
                                    {zone.value}
                                  </option>
                                )
                              )}
                            </select>
                          </FormControl>

                          <TextField
                            className="expiry__date__select"
                            id="datetime-local"
                            label="Document Expiry"
                            type="datetime-local"
                            defaultValue="2017-05-24T10:30"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={props.dateTime}
                            onChange={(e) => {
                              const date = new Date(e.target.value);
                              const formattedDate = `${date.getFullYear()}-${String(
                                date.getMonth() + 1
                              ).padStart(2, "0")}-${String(
                                date.getDate()
                              ).padStart(2, "0")} ${String(
                                date.getHours()
                              ).padStart(2, "0")}:${String(
                                date.getMinutes()
                              ).padStart(2, "0")}:${String(
                                date.getSeconds()
                              ).padStart(2, "0")}`;
                              props.onSetDateTimeValue(formattedDate);
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            style={{ backgroundColor: "#ffffff" }}
            className="controllersDetailsContainer"
            ref={props.listContainerRef}
          >
            {props.allControlsList.length > 0 &&
              props.allControlsList.map(
                (item: AllControlsList, index: number) => (
                  <>
                    {item.name !== "dds-img-combo" &&
                      item.name !== "dds-dnt-combo" &&
                      item.name !== "dds-dnt-sign" && (
                        <div
                          key={item.id}
                          className={
                            item.id === props.activeItem
                              ? "activeitemcontainer"
                              : "textInputContainer"
                          }
                        >
                          <div key={item.id}>
                            <div
                              className={
                                item.id === props.activeItem &&
                                props.emailMissing === -1
                                  ? "nonActiveContainer activeContainer"
                                  : item.id === props.activeItem &&
                                    props.emailMissing === item.id
                                  ? "emailErrorContainer"
                                  : "nonActiveContainer"
                              }
                              style={{
                                backgroundColor: `rgba(${
                                  item.color + ", 0.5"
                                })`,
                                border:
                                  item.id === props.activeItem
                                    ? `solid 4px ${item.borderColor}`
                                    : "",
                              }}
                            >
                              <div className="leftContainer">
                                <div
                                  style={{
                                    display: "flex",
                                    alignSelf: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      item.name === "dds-txt"
                                        ? textImage
                                        : item.name === "dds-select"
                                        ? tick
                                        : item.name === "dds-img"
                                        ? camera
                                        : item.name === "dds-sign" ||
                                          item.name === "dds-sign-combo" ||
                                          item.name === "dds-dnt-combo" ||
                                          item.name === "dds-dnt-sign"
                                        ? sign
                                        : item.name === "dds-initials"
                                        ? initalsBoxImage
                                        : radioButtonImage
                                    }
                                    style={{ width: 30, height: 30 }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "#191a1d",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    padding: 5,
                                    marginLeft: 10,
                                  }}
                                >
                                  <p style={{ fontSize: 14 }}>
                                    {item.name === "dds-txt"
                                      ? "Text"
                                      : item.name === "dds-select"
                                      ? "Multi selection"
                                      : item.name === "dds-img"
                                      ? "Image Box "
                                      : item.name === "dds-sign"
                                      ? "Signature Box"
                                      : item.name === "dds-initials"
                                      ? "Initials Box"
                                      : item.name === "dds-sign-combo"
                                      ? "Signature with Image"
                                      : "Radio Button"}
                                  </p>
                                  <p
                                    style={{
                                      color: "black",
                                      fontSize: 10,
                                    }}
                                  >
                                    {item.name === "dds-txt"
                                      ? " Single line or multiline text are"
                                      : item.name === "dds-select"
                                      ? "Select one or multiple options using a checkbox"
                                      : item.name === "dds-img"
                                      ? "Upload Image using device camera or upload from local device"
                                      : item.name === "dds-sign"
                                      ? "Draw a signature on screen"
                                      : item.name === "dds-initials"
                                      ? "Draw a initals on screen"
                                      : item.name === "dds-sign-combo"
                                      ? "Signature with image"
                                      : "Select radio buttons"}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <IconButton
                                  style={{
                                    borderRadius: 10,
                                    fontSize: 18,
                                    color: "#A89BB9",
                                  }}
                                  onClick={() =>
                                    props.onControlClicked(
                                      index,
                                      0,
                                      item.pageNum,
                                      item.id
                                    )
                                  }
                                >
                                  {props.activeControlIndex === item.id &&
                                  props.viewControl === true ? (
                                    <KeyboardArrowUp />
                                  ) : (
                                    <KeyboardArrowDown />
                                  )}
                                </IconButton>
                              </div>
                            </div>

                            {props.viewControl === true &&
                              props.activeControlIndex === item.id && (
                                <div className="control-options">
                                  <div className="mainControlParent">
                                    <div className="mainControlConatiner control-third-section">
                                      <div className="info">
                                        PDF Page
                                        <span style={{ color: "#7A86A1" }}>
                                          {item.pageNum}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <FormControl fullWidth>
                                    Recipient
                                    <Select
                                      className="receipent-select"
                                      labelId="user-dropdown-label"
                                      id="user-dropdown"
                                      value={item.userEmail}
                                      onChange={(event) => {
                                        const selectedEmail =
                                          event.target.value;
                                        props.onSelectRecipientPressed(
                                          selectedEmail,
                                          item.id
                                        );
                                      }}
                                      style={{
                                        backgroundColor: `rgba(${
                                          item.color + ", 0.5"
                                        })`,
                                        margin: 0,
                                      }}
                                    >
                                      {props.receipentList.map(
                                        (recipient: IReceipentList) => (
                                          <MenuItem
                                            key={recipient.id}
                                            value={recipient.email}
                                            style={{
                                              backgroundColor: `rgba(${
                                                recipient.color + ", 0.5"
                                              })`,
                                              margin: 0,
                                            }}
                                          >
                                            {recipient.name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>

                                  <div className="control-second-section">
                                    <div className="cs-section-item">
                                      <div className="mandatory-btn">
                                        <IconButton
                                          onClick={() =>
                                            props.onMandatoryPressed(index)
                                          }
                                          style={{
                                            borderRadius: 10,
                                            fontSize: 18,
                                            color: "#A89BB9",
                                            height: 20,
                                            width: 20,
                                          }}
                                          size="small"
                                        >
                                          {item.mandatory === false ? (
                                            <CheckBoxOutlineBlank className="radio-btn" />
                                          ) : (
                                            <CheckBox className="radio-btn" />
                                          )}
                                        </IconButton>
                                        Mandatory
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                  </>
                )
              )}

            {/**template control List */}
            {props.templateCreationControlsList.length > 0 &&
              props.templateCreationControlsList.map(
                (item: ITemplateConrolsList, index: number) => (
                  <>
                    {item.name !== "dds-img-combo" &&
                      item.name !== "dds-dnt-combo" &&
                      item.name !== "dds-dnt-sign" && (
                        <div
                          key={item.id}
                          className={
                            item.id === props.activeItem
                              ? "activeitemcontainer"
                              : "textInputContainer"
                          }
                        >
                          <div key={item.id}>
                            <div
                              className={
                                item.id === props.activeItem &&
                                props.emailMissing === -1
                                  ? "nonActiveContainer activeContainer"
                                  : item.id === props.activeItem &&
                                    props.emailMissing === item.id
                                  ? "emailErrorContainer"
                                  : "nonActiveContainer"
                              }
                              style={{
                                backgroundColor: `rgb(236, 238, 242)`,
                                border:
                                  item.id === props.activeItem
                                    ? `solid 4px #6149cd`
                                    : "",
                              }}
                            >
                              <div className="leftContainer">
                                <div
                                  style={{
                                    display: "flex",
                                    alignSelf: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      item.name === "dds-txt"
                                        ? textImage
                                        : item.name === "dds-select"
                                        ? tick
                                        : item.name === "dds-img"
                                        ? camera
                                        : item.name === "dds-sign" ||
                                          item.name === "dds-sign-combo" ||
                                          item.name === "dds-dnt-combo" ||
                                          item.name === "dds-dnt-sign"
                                        ? sign
                                        : item.name === "dds-initials"
                                        ? initalsBoxImage
                                        : radioButtonImage
                                    }
                                    style={{ width: 30, height: 30 }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "#191a1d",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    padding: 5,
                                    marginLeft: 10,
                                  }}
                                >
                                  <p style={{ fontSize: 14 }}>
                                    {item.name === "dds-txt"
                                      ? "Text"
                                      : item.name === "dds-select"
                                      ? "Multi selection"
                                      : item.name === "dds-img"
                                      ? "Image Box "
                                      : item.name === "dds-sign"
                                      ? "Signature Box"
                                      : item.name === "dds-initials"
                                      ? "Initials Box"
                                      : item.name === "dds-sign-combo"
                                      ? "Signature with Image"
                                      : "Radio Button"}
                                  </p>
                                  <p
                                    style={{
                                      color: "black",
                                      fontSize: 10,
                                    }}
                                  >
                                    {item.name === "dds-txt"
                                      ? " Single line or multiline text are"
                                      : item.name === "dds-select"
                                      ? "Select one or multiple options using a checkbox"
                                      : item.name === "dds-img"
                                      ? "Upload Image using device camera or upload from local device"
                                      : item.name === "dds-sign"
                                      ? "Draw a signature on screen"
                                      : item.name === "dds-initials"
                                      ? "Draw a initals on screen"
                                      : item.name === "dds-sign-combo"
                                      ? "Signature with image"
                                      : "Select radio buttons"}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <IconButton
                                  style={{
                                    borderRadius: 10,
                                    fontSize: 18,
                                    color: "#A89BB9",
                                  }}
                                  onClick={() =>
                                    props.onControlClicked(
                                      index,
                                      0,
                                      item.pageNum,
                                      item.id
                                    )
                                  }
                                >
                                  {props.activeControlIndex === item.id &&
                                  props.viewControl === true ? (
                                    <KeyboardArrowUp />
                                  ) : (
                                    <KeyboardArrowDown />
                                  )}
                                </IconButton>
                              </div>
                            </div>

                            {props.viewControl === true &&
                              props.activeControlIndex === item.id && (
                                <div className="control-options">
                                  <div className="mainControlParent">
                                    <div className="mainControlConatiner control-third-section">
                                      <div className="info">
                                        PDF Page
                                        <span style={{ color: "#7A86A1" }}>
                                          {item.pageNum}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                  </>
                )
              )}
          </div>
        </div>
      </Grid>
      <div className="bottom-buttons">
        {!props.toHide && (
          <Button
            disabled={props.allControlsList.length > 0 ? false : true}
            variant="contained"
            onClick={() => props.onCreateTemplatePressed()}
          >
            Save as Template
          </Button>
        )}

        <Button
          variant="contained"
          style={{ color: "#fff", textDecoration: "none", marginLeft: 10 }}
          // className="next-btn"
          onClick={() => {
            formik.handleSubmit();
            // props.onDoucmentDetailsPressed2();
          }}
          disabled={props.isLoading === true ? true : false}
        >
          {!props.isLoading ? (
            props.isUpdate === true ? (
              "Update Document"
            ) : (
              "Next"
            )
          ) : (
            <CircularProgress
              style={{ width: 25, height: 25, color: "white" }}
            />
          )}
        </Button>
        {/* <div onClick={() => props.savePdfData("asad", "ullah")}>Next</div> */}
      </div>
    </>
  );
};

export default Configuration;
