//routes name
export const LOGIN = "/Login";
export const FORGOTPASSWORD = "/ForgotPassword";
export const HOME = "/Home";
export const CREATEUSER = "/Createuser";
export const DASHBOARD = "/Dashboard";
export const MANAGEMENT = "/Management";
export const EDITUSER = "/EditUser";
export const CREATEWORKFLOW = "/CreateWorkFlow";
export const USERS = "/Users";
export const CREATEPDF = "/CreatePDF";
export const REPORTS = "/Reports";
export const REGISTER = "/Register";
export const EDITCONTROLS = "/Edit";
export const WORKFLOWBYEMAIL = "/workflowActions";
export const EMAILVERIFICATION = "/emailVerification";
export const PROFILE = "/profile";
export const ASSIGNED = "/assigned";
export const TEMPLATE = "/createTemplate";
export const APPLYTEMPLATE = "/applyTemplate";
//colors
export const primaryButton = "#6149CD";
export const primaryHeading = "#6149CD";
