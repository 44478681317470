import React, { FC, useEffect } from "react";

//types
import {
  AllControlsList,
  IReceipentList,
  ITemplateConrolsList,
  IZoneList,
  TemplateList,
  UserList,
} from "../Types/Types";

//libraries
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import { Button, Container, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { pdfjs, Document, Page } from "react-pdf";

//mateial ui icons
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
//sass
import "../Styles/CreateWorkFlow.scss";
//libraries css
import "react-resizable/css/styles.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ToolBar from "./Common/ToolBar";

//assets
import textInputImage from "../Assets/Text box-50x50.png";
import multiSelectionImage from "../Assets/Multi selection-50x50.png";
import imageBoxImage from "../Assets/Image box-50x50.png";
import signatureBoxIamge from "../Assets/Signature Box-50x50.png";
import initalsBoxImage from "../Assets/Initials -50x50.png";
import radioButtonImage from "../Assets/Radio Button -50x50.png";
import AddTemplateName from "./Common/PopUp/AddTemplateName";
import InfoPopUp from "./Common/PopUp/InfoPopUp";
import Configuration from "./Configuration";
import PreviewPdf from "./Common/PopUp/PreviewPdf";
import PdfDocument from "./Common/PdfDocument";
import SelectTemplatePopUp from "./Common/PopUp/SelectTemplatePopUp";
import AddReciepent from "./Common/PopUp/AddReciepent";

//pdf library settings
let file = require("../sample.pdf");
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface CreateWorkFlow {
  pdfLoading: any;
  // elementRef: any;
  pdfUrl: string;
  mouseDrag: number;
  pageNumber: number;
  pdfWidth: number;
  numPages: number;
  viewControl: boolean;
  controlId: number;
  activeControlIndex: number;
  allControlsList: AllControlsList[];
  templateControlsList: AllControlsList[];
  hireacrhyList: any;
  userList: UserList[];
  isTemplatePopUp: boolean;
  message: string;
  emailNotification: boolean;
  smsNotification: boolean;
  isInfo: boolean;
  documentDetailsShow: boolean;
  activeItem: number;
  isPrivewPdf: boolean;
  modifiedPdfBase64: any;
  pdfScale: number;
  emailError: string;
  showEmailError: boolean;
  EmailSuggestionArray: any;
  nameSuggestionArray: any;
  instructions: string;
  activeHirearchyNumber: number;
  isTemplate: boolean;
  templateList: TemplateList[];

  activeTemplateIndex: number;
  workFlowTitle: string;
  workFlowDescription: string;
  isLoading: boolean;
  isUpdate: boolean;
  emailMissing: number;
  listContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  receipentList: IReceipentList[];
  filterSelectList: IReceipentList[];
  isRecepient: boolean;
  isEditUser: boolean;
  zoomLevel: number;
  formik: any;
  editingRecipientId: string | null;
  editingRecipient: number;
  // filtereList: AllControlsList[];
  expiry: boolean;
  timezone: string;
  dateTime: string;
  zoneList: IZoneList[];
  selectedZone: string;
  toHide: boolean;
  templateCreationControlsList: ITemplateConrolsList[];
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  handleMouseDown: (id: number) => void;
  handleMouseMovement: (event: any) => void;
  onPreviousePressed: () => void;
  onNextPagePressed: () => void;
  onControlClicked: (
    index: number,
    controlId: number,
    pageNumber: number,
    id: number
  ) => void;
  onDragControlPressed: (
    e: any,
    data: { x: number; y: number },
    index: number
  ) => void;
  onResizeControlsPressed: (
    event: any,
    { size }: { size: { width: number; height: number } },
    index: number
  ) => void;
  onMandatoryPressed: (index: number) => void;

  savePdfData: (workFlowTitle: string, workFlowDescription: string) => void;
  onHireacrhyDropDownPressed: (id: any, index: number) => void;
  onSubmitTemplateName: (name: string) => void;
  onAddTemplatePressed: () => void;
  updateMessage: () => void;
  onEmailNotificationPressed: () => void;
  onSmsNotificationPressed: () => void;
  onCreateTemplatePressed: () => void;
  onOkPressed: () => void;
  onInfoClosedPressed: () => void;
  onDoucmentDetailsPressed: () => void;
  handleStop: () => void;
  onHidePreview: () => void;
  onChangeControlUserName: (val: string, index: number, email: string) => void;
  onChangeControlUserEmail: (val: string, index: number) => void;
  onLeaveFocusEmail: () => void;
  onChangeInstructions: (value: string) => void;
  onDoucmentDetailsPressed2: () => void;
  onAddHierarchyButton: (index: number) => void;
  onSubtractHierarchyButton: (index: number) => void;
  openTemplateClick: () => void;
  onCloseTemplate: () => void;
  onClickTemplateItem: (index: number) => void;
  onTemplateItemClick: (index: number) => void;
  onApplyTemplate: () => void;
  onDeleteControlPressed: (id: number) => void;
  onControlPdfClicked: (index: number) => void;
  onSelectRecipientPressed: (_userEmail: any, _controlId: number) => void;
  onFilterListPressed: (_email: any) => void;
  onAddReceipentPressed: (_isEditUser: boolean) => void;
  onIncreaseZoom: () => void;
  onDecreaseZoom: () => void;
  onSubmitReciepent: (name: string, email: string) => void;
  onEditRecipentPressed: (recipientId: number) => void;
  handleCancelEdit: () => void;
  handleDelete: (recipientId: number, _email: string) => void;
  onExpiryPressed: (_val: boolean) => void;
  onSetDateTimeValue: (val: string) => void;
  onSelectZonePressed: (val: string) => void;
  handleMouseMovementForTemplate: (event: any) => void;
  onSaveCreatedTemplate: (title: string) => Promise<void>;
  setSelectedEmail: React.Dispatch<React.SetStateAction<string>>;
}

const CreateWorkFlow: FC<CreateWorkFlow> = (props: CreateWorkFlow) => {
  return (
    <Container style={{}}>
      <AddReciepent
        isRecepient={props.isRecepient}
        message={props.message}
        isEditUser={props.isEditUser}
        receipentList={props.receipentList}
        formik={props.formik}
        editingRecipient={props.editingRecipient}
        onReciepentClosedPressed={props.onAddReceipentPressed}
        onOkPressed={props.onOkPressed}
        onSubmitReciepent={props.onSubmitReciepent}
        updateMessage={props.updateMessage}
        handleCancelEdit={props.handleCancelEdit}
        handleDelete={props.handleDelete}
        onEditRecipentPressed={props.onEditRecipentPressed}
      />
      {/* <SelectTemplatePopUp
        isTemplate={props.isTemplate}
        templateList={props.templateList}
        pdfUrl={props.pdfUrl}
        pdfScale={props.pdfScale}
        pageNumber={props.pageNumber}
        allControlList={props.templateControlsList}
        activeTemplateIndex={props.activeTemplateIndex}
        isPrivewPdf={false}
        onLoadSuccess={props.onLoadSuccess}
        onCloseTemplate={props.onCloseTemplate}
        onClickTemplateItem={props.onClickTemplateItem}
        onTemplateItemClick={props.onTemplateItemClick}
        onApplyTemplate={props.onApplyTemplate}
        onNextPagePressed={props.onNextPagePressed}
        onPreviousePressed={props.onPreviousePressed}
      /> */}
      <AddTemplateName
        isTemplate={props.isTemplatePopUp}
        message={props.message}
        onAddTemplatePressed={props.onAddTemplatePressed}
        onSubmitPressed={props.onSubmitTemplateName}
        updateMessage={props.updateMessage}
      />
      <InfoPopUp
        isInfo={props.isInfo}
        message={props.message}
        onInfoClosedPressed={props.onInfoClosedPressed}
        onOkPressed={props.onOkPressed}
      />
      <div style={{ position: "relative" }}>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              width: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
              height: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
              borderRadius: "20px",
              backgroundColor: "white",
              borderWidth: "10px",
              border: "2px solid #F0F2F6",
            }}
          >
            <Grid container direction="row" style={{ padding: 5 }}>
              <Grid
                item
                sm={9}
                md={9}
                lg={9}
                xl={9}
                style={{ padding: "10px" }}
              >
                {!props.isPrivewPdf && (
                  <ToolBar
                    isTemplate={props.isTemplate}
                    isUpdate={props.isUpdate}
                    toHide={props.toHide}
                    handleMouseDown={props.handleMouseDown}
                    openTemplateClick={props.openTemplateClick}
                    handleMouseMovementForTemplate={
                      props.handleMouseMovementForTemplate
                    }
                  />
                )}
                <div style={{ backgroundColor: "#FBFDFF" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      id="pdfContainer"
                      style={{
                        maxWidth: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        overflow: "scroll",
                      }}
                    >
                      {!props.isPrivewPdf ? (
                        <PdfDocument
                          pdfLoading={props.pdfLoading}
                          pdfUrl={props.pdfUrl}
                          mouseDrag={props.mouseDrag}
                          allControlsList={props.allControlsList}
                          templateCreationControlsList={
                            props.templateCreationControlsList
                          }
                          pageNumber={props.pageNumber}
                          activeItem={props.activeItem}
                          isPrivewPdf={props.isPrivewPdf}
                          pdfScale={props.pdfScale}
                          zoomLevel={props.zoomLevel}
                          tohide={props.toHide}
                          handleMouseMovementForTemplate={
                            props.handleMouseMovementForTemplate
                          }
                          onLoadSuccess={props.onLoadSuccess}
                          handleMouseMovement={props.handleMouseMovement}
                          onDragControlPressed={props.onDragControlPressed}
                          onResizeControlsPressed={
                            props.onResizeControlsPressed
                          }
                          handleStop={props.handleStop}
                          onDeleteControlPressed={props.onDeleteControlPressed}
                          onControlPdfClicked={props.onControlPdfClicked}
                        />
                      ) : (
                        <PreviewPdf
                          isPrivewPdf={props.isPrivewPdf}
                          modifiedPdfBase64={props.modifiedPdfBase64}
                          pageNumber={props.pageNumber}
                          allControlsList={props.allControlsList}
                          templateCreationControlsList={
                            props.templateCreationControlsList
                          }
                          pdfLoading={props.pdfLoading}
                          pdfUrl={props.pdfUrl}
                          mouseDrag={props.mouseDrag}
                          activeItem={props.activeItem}
                          pdfScale={props.pdfScale}
                          numPages={props.numPages}
                          activeControlIndex={props.activeControlIndex}
                          viewControl={props.viewControl}
                          zoomLevel={props.zoomLevel}
                          toHidePdf={props.toHide}
                          handleMouseMovementForTemplate={
                            props.handleMouseMovementForTemplate
                          }
                          onLoadSuccess={props.onLoadSuccess}
                          handleMouseMovement={props.handleMouseMovement}
                          onDragControlPressed={props.onDragControlPressed}
                          onResizeControlsPressed={
                            props.onResizeControlsPressed
                          }
                          handleStop={props.handleStop}
                          onHidePreview={props.onHidePreview}
                          onNextPagePressed={props.onNextPagePressed}
                          onPreviousePressed={props.onPreviousePressed}
                          onControlClicked={props.onControlClicked}
                          onControlPdfClicked={props.onControlPdfClicked}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#ECEEF2",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={props.onPreviousePressed}
                      style={{
                        borderRadius: 10,
                        fontSize: 18,
                        color: "#A89BB9",
                      }}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    {(
                      <p
                        style={{
                          padding: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          backgroundColor: " rgba(122, 134, 161, 0.3)",
                          color: "#ffffff",
                          marginRight: 5,
                        }}
                      >
                        {props.pageNumber}
                      </p>
                    ) || (props.numPages ? 1 : "--")}{" "}
                    / {props.numPages || "--"}
                    <IconButton
                      onClick={props.onNextPagePressed}
                      style={{
                        borderRadius: 10,
                        fontSize: 18,
                        color: "#A89BB9",
                      }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </div>
                  <div className="zoombtnWraper">
                    <button
                      className="zomms-btns"
                      onClick={() => props.onIncreaseZoom()}
                    >
                      +
                    </button>
                    <button
                      className="zomms-btns"
                      onClick={() => props.onDecreaseZoom()}
                    >
                      -
                    </button>
                  </div>
                </div>
              </Grid>
              {/**configurations settings */}
              {!props.isPrivewPdf && (
                <Configuration
                  allControlsList={props.allControlsList}
                  templateCreationControlsList={
                    props.templateCreationControlsList
                  }
                  activeItem={props.activeItem}
                  smsNotification={props.smsNotification}
                  viewControl={props.viewControl}
                  activeControlIndex={props.activeControlIndex}
                  userList={props.userList}
                  emailNotification={props.emailNotification}
                  documentDetailsShow={props.documentDetailsShow}
                  hireacrhyList={props.hireacrhyList}
                  emailError={props.emailError}
                  EmailSuggestionArray={props.EmailSuggestionArray}
                  nameSuggestionArray={props.nameSuggestionArray}
                  instructions={props.instructions}
                  activeHirearchyNumber={props.activeHirearchyNumber}
                  workFlowTitle={props.workFlowTitle}
                  workFlowDescription={props.workFlowDescription}
                  isLoading={props.isLoading}
                  isUpdate={props.isUpdate}
                  emailMissing={props.emailMissing}
                  listContainerRef={props.listContainerRef}
                  receipentList={props.receipentList}
                  filterSelectList={props.filterSelectList}
                  isEditUser={props.isEditUser}
                  dateTime={props.dateTime}
                  expiry={props.expiry}
                  timezone={props.timezone}
                  zoneList={props.zoneList}
                  selectedZone={props.selectedZone}
                  toHide={props.toHide}
                  onExpiryPressed={props.onExpiryPressed}
                  onSetDateTimeValue={props.onSetDateTimeValue}
                  onControlClicked={props.onControlClicked}
                  // onChangeUserId={props.onChangeUserId}
                  onHireacrhyDropDownPressed={props.onHireacrhyDropDownPressed}
                  onMandatoryPressed={props.onMandatoryPressed}
                  onEmailNotificationPressed={props.onEmailNotificationPressed}
                  onSmsNotificationPressed={props.onSmsNotificationPressed}
                  onDoucmentDetailsPressed={props.onDoucmentDetailsPressed}
                  savePdfData={props.savePdfData}
                  onCreateTemplatePressed={props.onCreateTemplatePressed}
                  onChangeControlUserName={props.onChangeControlUserName}
                  onChangeControlUserEmail={props.onChangeControlUserEmail}
                  onLeaveFocusEmail={props.onLeaveFocusEmail}
                  showEmailError={props.showEmailError}
                  onChangeInstructions={props.onChangeInstructions}
                  onDoucmentDetailsPressed2={props.onDoucmentDetailsPressed2}
                  onAddHierarchyButton={props.onAddHierarchyButton}
                  onSubtractHierarchyButton={props.onSubtractHierarchyButton}
                  onSelectRecipientPressed={props.onSelectRecipientPressed}
                  onFilterListPressed={props.onFilterListPressed}
                  onAddReceipentPressed={props.onAddReceipentPressed}
                  onSelectZonePressed={props.onSelectZonePressed}
                  onSaveCreatedTemplate={props.onSaveCreatedTemplate}
                  setSelectedEmail={props.setSelectedEmail}
                />
              )}
            </Grid>
          </Box>
        </Grid>
      </div>
    </Container>
  );
};
export default CreateWorkFlow;
