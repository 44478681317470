const baseUrl = process.env.REACT_APP_BASEURL;
export const fetchGet = async (token: string | null, endPoint: string) => {
  try {
    let response = await fetch(`${baseUrl}${endPoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? "Bearer " + token : "",
      },
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.log("fetchGet error: " + error);
  }
};

interface Object {
  username: string;
  password: string;
}
export const fetchPost = async (
  endPoint: string,
  token: String | null,
  data: any
) => {
  try {
    let response = await fetch(`${baseUrl}${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? "Bearer " + token : "",
      },
      body: JSON.stringify(data),
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.log("fetchPost error: " + error);
  }
};
export const fetchPostFormData = async (
  endPoint: string,
  token: String | null,
  data: any
) => {
  for (var pair of data.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
  try {
    let response = await fetch(`${baseUrl}${endPoint}`, {
      method: "POST",
      body: data,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.log("fetchPost error: " + error);
  }
};

export const fetchPut = async (
  token: string | null,
  endPoint: string,
  data: any
) => {
  try {
    let response = await fetch(`${baseUrl}${endPoint}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? "Bearer " + token : "",
      },
      body: JSON.stringify(data),
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.log("fetchGet error: " + error);
  }
};
