export const ApiName = {
  login: "login",
  authenticateToken: "authenticateToken",
  refreshToken: "refreshToken",
  createUser: "user",
  forgotPassword: "forgotPassword",
  changePassword: "changePassword",
  checkPWResetKey: "checkPWResetKey",
  recoverAccount: "recoverAccount",
  userList: "user/users",
  rolesList: "permission/roles",
  allData: "permission/all",
  addRole: "permission/role",
  updateControls: "permission/control",
  editUser: "user",
  allScreens: "permission/screens",
  uploadPdf: "document/initiate",
  getDocument: "document",
  initiateWorkFlow: "workflow/initiate",
  getWorkFlow: "workflow/initiated",
  getAssignedWorkFlow: "workflow/assigned",
  registerUser: "register",
  contactList: "contacts",
  submitWorkFlow: "workflow/submit",
  workFlowEmail: "workflow/details",
  submitEmailWorkFlow: "workflow/emailSubmit",
  templateList: "template",
  editWorkFlow: "workflow/edit",
  verifyEmail: "verifyEmail",
  reports: "workflow/stats",
  serverDate: "currentDate",
  workFlowId: "workflow?id=",
  workFlowLog: "workflow/activityHistory?id=",
  resendPin: "resendPin",
  changeEmail: "changeEmail",
  getProfile: "profile",
  updateProfile: "profile",
  updateEmail: "emailChangeRequest",
  saveTemplate: "template",
  zoneList: "timeZoneList",
  createTemplate: "template/withFile",
};
