import React, { useEffect, useState, useContext } from "react";
import SignUp from "../Components/SignUp";
import { useSearchParams, useNavigate } from "react-router-dom";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import {
  DASHBOARD,
  EMAILVERIFICATION,
  FORGOTPASSWORD,
  REGISTER,
} from "../Utils/Constants";
import UserContext from "../Utils/UserContext";
let _userEmail: string = "";
let _userPassword: string = "";
const SignUpScreen = () => {
  const context = useContext(UserContext);
  const { userName, updateUserName, token, updateToken } = context;
  // const [name, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerificiation, setIsVerification] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "SignUp";
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const onSignInPressed = async (userEmail: string, userPassword: string) => {
    try {
      console.warn("onSignInPressed", userEmail, userPassword);
      if (userEmail && userPassword) {
        _userEmail = userEmail;
        _userPassword = userPassword;
        let data = {
          username: userEmail,
          password: userPassword,
        };
        let response = await fetchPost(ApiName.login, "", data);
        console.warn("response", response);
        if (response.status === 0) {
          const currentTime = new Date();
          const targetTime = new Date(currentTime.getTime() + 20 * 60 * 1000);

          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("token", response.response.jwttoken);
          localStorage.setItem("userName", response.response.name);
          localStorage.setItem("userEmail", response.response.email);
          localStorage.setItem("mobileNumber", response.response.mobileNumber);
          let roles = [response.response.role];
          localStorage.setItem("role", JSON.stringify(roles));
          localStorage.setItem("userId", JSON.stringify(response.response.id));
          localStorage.setItem("loginTime", targetTime.toISOString());
          updateUserName(response.response.name);
          updateToken(response.response.jwttoken);
          navigate(DASHBOARD);
          // window.location.reload();
        } else if (response.status === 1) {
          if (
            response.response.toLowerCase() === "email verification pending"
          ) {
            // setErrorMessage("Email verification is pending");
            // setIsVerification(true);
            navigate(EMAILVERIFICATION, {
              state: {
                email: userEmail,
                password: userPassword,
              },
            });
          } else {
            setErrorMessage("Invalid credentials or accound doesn't exist");
          }
        }
      }
    } catch (error) {
      console.log("onSignInPressed", error);
    }
  };
  const onForgotPasswordClick = () => {
    console.warn("pressed");
    navigate(FORGOTPASSWORD);
  };
  const onRegisterClick = () => {
    console.warn("pressed");
    navigate(REGISTER);
  };
  const updateErrorMessage = () => {
    setErrorMessage("");
    setMessage("");
  };

  const verifyEmail = async (pin: string) => {
    try {
      let data = {
        email: _userEmail,
        pin: pin,
      };
      console.log("this is verifyEmail payload", data);
      setMessage("");
      let response = await fetchPost(ApiName.verifyEmail, "", data);
      if (response.status === 0) {
        await onSignInPressed(_userEmail, _userPassword);
      } else if (response.status === 1) {
        if (response.response === "Invalid") {
          setErrorMessage("Please enter valid pin");
        } else if (response.response === "Expired") {
          setErrorMessage("Verification code expired");
        }
      }
    } catch (error) {
      console.log("verifyEmail", error);
      setMessage("");
    }
  };

  const onResendPinPressed = async () => {
    try {
      let data = {
        email: _userEmail,
        password: _userPassword,
      };
      console.log("onResendPinPressed", data);
      setErrorMessage("");
      let response = await fetchPost(ApiName.resendPin, "", data);
      if (response.status === 0) {
        setMessage("A verification code is sent to your email");
      } else if (response.status === 1) {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      console.log("onResendPinPressed", error);
    }
  };
  return (
    <SignUp
      showPassword={showPassword}
      errorMessage={errorMessage}
      isVerificiation={isVerificiation}
      message={message}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      onSignInPressed={onSignInPressed}
      onForgotPasswordClick={onForgotPasswordClick}
      onRegisterClick={onRegisterClick}
      updateErrorMessage={updateErrorMessage}
      verifyEmail={verifyEmail}
      onResendPinPressed={onResendPinPressed}
    />
  );
};

export default SignUpScreen;
