import React, { useState, useEffect, useContext, FC } from "react";
import AssignedWorkflow from "../Components/AssignedWorkflow";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import { UserList, WorkFlow, workFlowHierarchy } from "../Types/Types";
import UserContext from "../Utils/UserContext";
import { useNavigate } from "react-router-dom";
import { CREATEWORKFLOW, DASHBOARD, EDITCONTROLS } from "../Utils/Constants";

interface AssignedWorkFlowScreen {
  roles: string;
  roleId: number;
  userId: any;
}
let documentName: string = "";
const AssignedWorkFlowScreen: FC<AssignedWorkFlowScreen> = (
  props: AssignedWorkFlowScreen
) => {
  const context = useContext(UserContext);
  const {
    token,
    userName,
    userList,
    isOpen,
    updateUserName,
    updateTabIndex,
    updateUserList,
    updateMenuButton,
  } = context;

  const [workFLowData, setWorkFlowData] = useState<WorkFlow[]>([]);
  const [filterData, setFilterData] = useState<WorkFlow[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPreviewPdf, setPreviewPdf] = useState(false);
  const [privewWorkFlowPdf, setPreivewWorkFlowPdf] = useState("");
  const [numPages, setNumPages] = useState<number>(-1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isInfo, setInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [trackingData, setTrackingData] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [popIndex, setPopIndex] = useState<number>();
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [tackingPopup, setTrackingPopup] = useState<number>();
  const [workFlowLog, setWorkFlowLog] = useState<any[]>();
  const [isLoading2, setIsLoading2] = useState(false);

  const entries = 9;
  const navigate = useNavigate();
  useEffect(() => {
    updateTabIndex(9);
    document.title = "Assigned";
    if (token.length > 0) {
      getAssginedWorkFlow();
    }
  }, [token]);

  const getAssginedWorkFlow = async () => {
    try {
      let response = await fetchGet(token, ApiName.getAssignedWorkFlow);
      if (response.status === 0) {
        setWorkFlowData(response.response);
        fiterData(response.response);
        setPageCount(Math.ceil(response.response.length / 9));
      }
    } catch (error) {
      console.log("getInitiatedWorkFlow", error);
    }
  };

  const onPageChange = (index: number) => {
    let startValue = index * entries;
    let endValue = startValue + entries;
    setStartIndex(startValue);
    setEndIndex(endValue);
    setCurrentPage(index);
  };

  const onSearchItem = (val: any) => {
    let tempFilterData = [...workFLowData];
    tempFilterData = tempFilterData.filter((item) =>
      item.title.includes(val.toLowerCase())
    );
    console.log("tempFilterData", tempFilterData);
    if (val.length > 0) {
      if (tempFilterData.length > 0) {
        setFilterData(tempFilterData);
        setPageCount(Math.ceil(tempFilterData.length / 9));
        setErrorMessage("");
      } else {
        setErrorMessage("No Record Found");
        setFilterData([]);
      }
    } else {
      setFilterData([]);
      setErrorMessage("");
      setPageCount(Math.ceil(tempFilterData.length / 9));
    }
  };

  const onPreviewPdfPressed = (index: number, pdf: string) => {
    setPreviewPdf(true);
    setPreivewWorkFlowPdf(pdf);
  };

  const onClosePreviewPdf = () => {
    setPreviewPdf(false);
    setPageNumber(1);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const onEditPressed = async (index: number) => {
    let targetEmail = await localStorage.getItem("userEmail");
    let targetUrl: string | null = null;
    console.log("onEditPressed", workFLowData[index].hierarchies, "index");
    if (workFLowData[index].status === "Complete") {
      setMessage("Work flow is completed");
      setInfo(true);
    } else {
      let data: any = {
        hierarchies: workFLowData[index].hierarchies,
      };
      for (const hierarchy of data.hierarchies) {
        for (const user of hierarchy.users) {
          if (user.email === targetEmail && user.status === false) {
            targetUrl = user.actionUrl;
            break;
          } else if (user.email === targetEmail && user.status) {
            setMessage(
              "Dear user you have completed required actions this workflow is complete. This link is no longer available"
            );
            setInfo(true);
            break;
          }
        }
      }

      if (targetUrl) {
        console.log(
          `The URL for userEmail ${targetEmail} with status=false is: ${targetUrl}`
        );
        window.open(targetUrl, "_blank");
      } else {
        console.log(
          `No URL found for userEmail ${targetEmail} with status=false`
        );
      }
    }
  };

  const onEditFilteredPressed = async (index: number) => {
    console.log("onEditPressed", filterData[index].hierarchies, "index", index);
    let targetEmail = await localStorage.getItem("userEmail");
    let targetUrl: string | null = null;
    console.log("onEditPressed", workFLowData[index].hierarchies, "index");
    if (filterData[index].status === "Complete") {
      setMessage("Work flow is completed");
      setInfo(true);
    } else {
      let data: any = {
        hierarchies: filterData[index].hierarchies,
      };
      for (const hierarchy of data.hierarchies) {
        for (const user of hierarchy.users) {
          if (user.email === targetEmail && user.status === false) {
            targetUrl = user.actionUrl;
            break;
          } else if (user.email === targetEmail && user.status) {
            setMessage(
              "Dear user you have completed required actions this workflow is complete. This link is no longer available"
            );
            setInfo(true);
            break;
          }
        }
      }

      if (targetUrl) {
        console.log(
          `The URL for userEmail ${targetEmail} with status=false is: ${targetUrl}`
        );
        window.open(targetUrl, "_blank");
      } else {
        console.log(
          `No URL found for userEmail ${targetEmail} with status=false`
        );
      }
    }
  };
  const fiterData = (data: WorkFlow[]) => {
    let tempData = [...data];
    let hireacrhyArray = [];
    let hireacrhyObject = {};

    for (let i = 0; i < tempData.length; i++) {
      for (let j = 0; j < tempData[i].hierarchies.length; j++) {
        if (tempData[i].hierarchies[j].status === false)
          hireacrhyObject = {
            hierarchyNumber: tempData[i].hierarchies[j].hierarchyNumber,
          };
        hireacrhyArray.push(hireacrhyObject);
      }
    }
  };

  const onInfoClosedPressed = () => {
    setInfo(false);
    setMessage("");
  };

  const onOkPressed = () => {
    setInfo(false);
    setMessage("");
  };
  const onToggleDrawer = () => {
    // setOpen(!open);
    updateMenuButton(!isOpen);
  };

  const onNextPagePressed = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const onPreviousePressed = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const onTitlePressed = (index: number) => {
    console.log("onTitlePressed", workFLowData[index]);
    setTrackingData(workFLowData[index]);
    setOpenModal(true);
    documentName = workFLowData[index].title;
  };
  const handleClose = () => {
    setPopIndex(0);
    setOpenModal(false);
  };

  const CloseTrackingModal = () => {
    setTrackingPopup(0);
    setOpenTrackingModal(false);
  };
  const handlePreviewInfo = async (id: any, title: string) => {
    const endPoint = ApiName.workFlowLog + id;
    try {
      // setOpenTrackingPopUp(true);\
      setIsLoading2(true);

      const response = await fetchGet(token, endPoint);
      if (response.status === 0) {
        setWorkFlowLog(response.response);
        if (response.status === 0) {
          documentName = title;

          setIsLoading2(false);
          setOpenTrackingModal(true);
        }
      }
    } catch (error) {
      console.log("handleTrackingInfo", error);
    }
  };
  return (
    <div>
      <AssignedWorkflow
        openTrackingModal={openTrackingModal}
        userList={userList}
        workFLowData={workFLowData}
        currentPage={currentPage}
        pageCount={pageCount}
        startIndex={startIndex}
        endIndex={endIndex}
        filterData={filterData}
        errorMessage={errorMessage}
        isPreviewPdf={isPreviewPdf}
        privewWorkFlowPdf={privewWorkFlowPdf}
        pageNumber={pageNumber}
        scale={1}
        isInfo={isInfo}
        message={message}
        isOpen={isOpen}
        roles={props.roles}
        trackingData={trackingData}
        openModal={openModal}
        documentName={documentName}
        workFlowLog={workFlowLog}
        handlePreviewInfo={handlePreviewInfo}
        onInfoClosedPressed={onInfoClosedPressed}
        onOkPressed={onOkPressed}
        onPageChange={onPageChange}
        onSearchItem={onSearchItem}
        onPreviewPdfPressed={onPreviewPdfPressed}
        onClosePreviewPdf={onClosePreviewPdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onEditPressed={onEditPressed}
        onEditFilteredPressed={onEditFilteredPressed}
        onToggleDrawer={onToggleDrawer}
        onNextPagePressed={onNextPagePressed}
        onPreviousePressed={onPreviousePressed}
        onTitlePressed={onTitlePressed}
        handleClose={handleClose}
        CloseTrackingModal={CloseTrackingModal}
      />
    </div>
  );
};

export default AssignedWorkFlowScreen;
