import React, { FC, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
  Typography,
  Icon,
  TextField,
  InputAdornment,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { PropaneSharp, Visibility, VisibilityOff } from "@mui/icons-material";

interface Profile {
  isOpen: boolean;
  role: string;
  userName: string;
  updateUserName: string;
  userEmail: string;
  editEmail: boolean;
  editName: boolean;
  editPhone: boolean;
  showBox: boolean;
  mobileNumber: any;
  message: string;
  editPassword: boolean;
  showPassword: boolean;
  showPassword1: boolean;

  showPassword2: boolean;

  handleClickShowPassword: () => void;
  handleClickShowPassword1: () => void;
  handleClickShowPassword2: () => void;

  onToggleDrawer: () => void;
  onEditEmailPressed: () => void;
  onEditNumberPressed: () => void;
  onEditNamePressed: () => void;
  onChangeName: (name: string) => void;
  onCancelName: () => void;
  onSubmitName: () => void;
  onSubmitEmail: (updatedEmail: string) => void;
  onCancelEmail: () => void;
  updateMessage: () => void;
  onSubmitPin: (pin: any) => void;
  onEditPassword: () => void;
  onChangePassword: (currentPassword: string, newPassword: string) => void;
}

const Profile: FC<Profile> = (props: Profile) => {
  useEffect(() => {
    formik.setFieldValue("email", props.userEmail);
  }, [props.userEmail]);
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
    // phone: Yup.string().matches(
    //   /^\+1[0-9]{10}$/,
    //   "Please enter a 10-digit phone number"
    // ),
  });
  const validationSchema2 = Yup.object().shape({
    pin: Yup.string()
      .matches(/^[0-9]*$/, "Please enter numbers only")
      .max(4, "Maximum length is 4 characters"),
  });

  const validationSchema3 = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)"
      )
      .required("Password Required"),
    confirmPassword: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)"
      )
      .required("Password Required"),
    cofirmPassword2: Yup.string()
      .oneOf([Yup.ref("confirmPassword")], "Passwords must match")
      .required("Password Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: props.userEmail,
      pin: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.warn("values", values);
      props.onSubmitEmail(values.email);
      formik.resetForm();
    },
  });

  const formik2 = useFormik({
    initialValues: {
      pin: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      console.warn("values", values);
      props.onSubmitPin(values.pin);
      formik2.resetForm();
    },
  });

  const formik3 = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      cofirmPassword2: "",
    },
    validationSchema: validationSchema3,
    onSubmit: (values) => {
      console.warn("values", values);
      //  props.onSubmitEmail(values.email);
      props.onChangePassword(values.password, values.confirmPassword);
      formik3.resetForm();
    },
  });

  return (
    <Container style={{ display: "block" }}>
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <div className="flex">
          <IconButton
            className="menuBar"
            color="inherit"
            aria-label="open drawer"
            onClick={props.onToggleDrawer}
            edge="start"
            sx={{
              mr: 2,
            }}
          >
            {props.isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            fontSize={25}
            fontWeight={300}
            marginTop={2}
            marginBottom={2}
            color={"#7A86A1"}
            className="d-head"
          >
            Profile
          </Typography>
        </div>

        <div className="flex" style={{ columnGap: "12px" }}>
          <div className="rolename">Role:</div>
          <span className="roleadmin">{props.role.toUpperCase()}</span>
        </div>
        <div className="roleWraper">
          {/**role section */}

          {/**name section */}
          <div className="plist">
            {!props.editName ? (
              <div className="p-username">
                <div className="ist-column">Name:</div>
                <div className="secnd-column"> {props.userName}</div>
                <div
                  className="third-column editsaveBtn"
                  onClick={() => {
                    props.onEditNamePressed();
                    props.updateMessage();
                  }}
                >
                  Edit
                </div>
              </div>
            ) : (
              <div className="p-username">
                <div className="ist-column"> Name:</div>
                <div className="secnd-column">
                  <input
                    type="text"
                    maxLength={50}
                    value={props.updateUserName}
                    onChange={(event) => props.onChangeName(event.target.value)}
                  />
                </div>
                <div className="flex cansubbtn third-column">
                  <div
                    className="cancelbtn"
                    onClick={() => props.onCancelName()}
                  >
                    Cancel
                  </div>
                  <div
                    onClick={() => props.onSubmitName()}
                    className="editsaveBtn"
                  >
                    Submit
                  </div>
                </div>
              </div>
            )}
          </div>

          {/*email section */}
          <div>
            {!props.editEmail ? (
              <div className="p-username">
                <div className="ist-column">Email:</div>
                <div className="secnd-column">{props.userEmail}</div>
                <div
                  className="third-column editsaveBtn"
                  onClick={() => {
                    props.onEditEmailPressed();
                    props.updateMessage();
                  }}
                >
                  Edit
                </div>
              </div>
            ) : props.editEmail && !props.showBox ? (
              <div className="p-username">
                <div className="ist-column">Email:</div>
                <div className="secnd-column">
                  <TextField
                    style={{
                      // width: "100%",
                      marginTop: 5,
                      borderRadius: "50px",
                    }}
                    className="textfield"
                    InputProps={{
                      style: {
                        borderRadius: "20px",
                        // height: 50,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      placeholder: formik.values.email
                        ? formik.values.email
                        : "Tonynguyen@example.com",
                    }}
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    name="email"
                    // placeholder={"Tonynguyen@example.com"}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      // props.updateMessage();
                    }}
                    value={formik.values.email}
                    inputMode="text"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                    onFocus={() => props.updateMessage()}
                    onBlur={formik.handleBlur}
                    error={
                      (formik.errors.email && formik.touched.email) || false
                    }
                    helperText={
                      formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email
                    }
                  />
                </div>
                <div className="flex cansubbtn third-column">
                  <div
                    onClick={() => {
                      props.onCancelEmail();
                      // formik.resetForm();
                      formik.setFieldValue("email", props.userEmail);
                    }}
                    className="cancelbtn"
                  >
                    Cancel
                  </div>
                  <div
                    onClick={() => formik.handleSubmit()}
                    className="editsaveBtn"
                  >
                    Submit
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div>A verification code is sent to your email</div>
                <div>
                  Pin{" "}
                  <input
                    type="tel"
                    id="pin"
                    name="pin"
                    maxLength={4}
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    value={formik2.values.pin}
                  />
                  {formik2.touched.pin && formik2.errors.pin ? (
                    <div className="error">{formik2.errors.pin}</div>
                  ) : null}
                </div>
                <div>
                  <div
                    onClick={() => formik2.handleSubmit()}
                    className="editsaveBtn"
                  >
                    Submit
                  </div>

                  <div
                    onClick={() => props.onCancelEmail()}
                    className="cancelbtn"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            )}
          </div>

          {!props.editPassword ? (
            <div
              className="p-username"
              style={{ justifyContent: "space-between", marginBottom: "0px" }}
            >
              <div className="ist-column"> Password</div>
              <div className="secnd-column">********</div>
              <div
                className="third-column editsaveBtn"
                onClick={() => props.onEditPassword()}
              >
                Edit
              </div>
            </div>
          ) : (
            <div>
              <div className="p-username" style={{ marginBottom: "0px" }}>
                <div className="ist-column"> Password</div>
                <div
                  className="secnd-column flex"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="pass-col">
                    <div className="passlabe">Current Password</div>
                    <div>
                      <TextField
                        type={props.showPassword ? "text" : "password"}
                        style={{
                          width: "90%",
                          marginTop: 5,
                          zIndex: 1,
                        }}
                        name="password"
                        // onChange={formik.handleChange}
                        onChange={(event) => {
                          formik3.handleChange(event);
                          props.updateMessage();
                        }}
                        onBlur={formik3.handleBlur}
                        value={formik3.values.password}
                        inputMode="text"
                        error={
                          (formik3.errors.password &&
                            formik3.touched.password) ||
                          false
                        }
                        helperText={
                          formik3.errors.password &&
                          formik3.touched.password &&
                          formik3.errors.password
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            console.warn("password enetered");
                            formik3.handleSubmit();
                          }
                        }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                          style: {
                            borderRadius: "50px",
                            height: 35,
                          },
                          endAdornment: (
                            <InputAdornment position="end" style={{}}>
                              <IconButton
                                aria-placeholder="toggle password visibility"
                                onClick={props.handleClickShowPassword}
                                // onMouseDown={props.handleMouseDownPassword}
                                edge="end"
                              >
                                {props.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="pass-col">
                    <div className="passlabe">New Password</div>
                    <div>
                      <TextField
                        className="c-input"
                        type={props.showPassword1 ? "text" : "password"}
                        style={{ width: "90%", marginTop: 5, zIndex: 1 }}
                        name="confirmPassword"
                        onChange={(event) => {
                          formik3.handleChange(event);
                          props.updateMessage();
                        }}
                        onBlur={formik3.handleBlur}
                        value={formik3.values.confirmPassword}
                        inputMode="text"
                        error={
                          (formik3.errors.confirmPassword &&
                            formik3.touched.confirmPassword) ||
                          false
                        }
                        helperText={
                          formik3.errors.confirmPassword &&
                          formik3.touched.confirmPassword &&
                          formik3.errors.confirmPassword
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            console.warn("password enetered");
                            formik.handleSubmit();
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "50px",
                            height: 35,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-placeholder="toggle password visibility"
                                onClick={props.handleClickShowPassword1}
                                edge="end"
                              >
                                {props.showPassword1 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="pass-col">
                    <div className="passlabe">Confirm Password</div>
                    <div>
                      <TextField
                        className="c-input"
                        type={props.showPassword2 ? "text" : "password"}
                        style={{ width: "90%", marginTop: 5, zIndex: 1 }}
                        name="cofirmPassword2"
                        onChange={(event) => {
                          formik3.handleChange(event);
                          props.updateMessage();
                        }}
                        onBlur={formik3.handleBlur}
                        value={formik3.values.cofirmPassword2}
                        inputMode="text"
                        error={
                          (formik3.errors.cofirmPassword2 &&
                            formik3.touched.cofirmPassword2) ||
                          false
                        }
                        helperText={
                          formik3.errors.cofirmPassword2 &&
                          formik3.touched.cofirmPassword2 &&
                          formik3.errors.cofirmPassword2
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            console.warn("password enetered");
                            formik.handleSubmit();
                          }
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "50px",
                            height: 35,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-placeholder="toggle password visibility"
                                onClick={props.handleClickShowPassword2}
                                edge="end"
                              >
                                {props.showPassword2 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex cansubbtn third-column">
                  <div
                    className="cancelbtn"
                    onClick={() => {
                      props.onEditPassword();
                      formik3.resetForm();
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    onClick={() => formik3.handleSubmit()}
                    className="editsaveBtn"
                  >
                    Submit
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.message.length > 0 && (
            <div>
              <p>{props.message}</p>
            </div>
          )}
        </div>
      </Grid>
    </Container>
  );
};

export default Profile;
