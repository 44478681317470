import React from "react";
import "../../../Styles/TrackingInfoPopup.scss";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { themeCustomizer } from "../ButtonTheme";
import {
  FontDownload,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CameraAlt,
  CheckBoxOutlineBlank,
  CheckBox,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Close,
  Edit,
  Delete,
} from "@mui/icons-material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { IReceipentList } from "../../../Types/Types";
interface InfoPopUp {
  isRecepient: boolean;
  message: string;
  isEditUser: boolean;
  receipentList: IReceipentList[];
  formik: any;
  editingRecipient: number;
  onReciepentClosedPressed: (_isEditUser: boolean) => void;
  onOkPressed: () => void;
  onSubmitReciepent: (name: string, email: string) => void;
  updateMessage: () => void;
  onEditRecipentPressed: (recipientId: number) => void;
  handleCancelEdit: () => void;
  handleDelete: (recipientId: number, _email: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 991 ? 700 : "80%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AddReciepent = (props: InfoPopUp) => {
  const formik = props.formik;
  const handleCancel = () => {
    props.handleCancelEdit();
    formik.resetForm(); // Reset the form to clear any validation errors
  };
  return (
    <div>
      {" "}
      <Modal
        open={props.isRecepient}
        onClose={() => props.onReciepentClosedPressed(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
          }}
          className="edit_trackingBox"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={() => props.onReciepentClosedPressed(false)}
          >
            <Close style={{ height: 20, width: 20 }} />
          </div>
          <Typography
            fontSize={24}
            align="center"
            fontWeight={700}
            color={"#4a4a4a"}
            className="trackingInfo new-user"
          >
            {/* <span>{props.documentName}</span> */}
            {props.isEditUser ? "Edit Recipient" : "Add Recipient"}
          </Typography>
          {!props.isEditUser ? (
            <div className="trackingwraper new-user-tw">
              <div className="input-field-wraper">
                <Typography fontSize={12} align="left" fontWeight={700}>
                  Enter Recipient Name
                </Typography>
                <TextField
                  style={{ width: "100%", marginTop: 5 }}
                  size="small"
                  name="name"
                  placeholder={"Recipient Name"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    props.updateMessage();
                  }}
                  value={formik.values.name}
                  inputMode="text"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit();
                    }
                  }}
                  inputProps={{ maxLength: 50 }}
                  onBlur={formik.handleBlur}
                  error={(formik.errors.name && formik.touched.name) || false}
                  helperText={
                    formik.errors.name &&
                    formik.touched.name &&
                    formik.errors.name
                  }
                />
              </div>
              <div className="input-field-wraper">
                <Typography fontSize={12} align="left" fontWeight={700}>
                  Enter Recipient Email
                </Typography>
                <TextField
                  style={{ width: "100%", marginTop: 5 }}
                  size="small"
                  name="email"
                  placeholder={"Recipient Email"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    props.updateMessage();
                  }}
                  value={formik.values.email}
                  inputMode="text"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit();
                    }
                  }}
                  inputProps={{ maxLength: 50 }}
                  onBlur={formik.handleBlur}
                  error={(formik.errors.email && formik.touched.email) || false}
                  helperText={
                    formik.errors.email &&
                    formik.touched.email &&
                    formik.errors.email
                  }
                />
              </div>
              <ThemeProvider theme={themeCustomizer}>
                {!props.isEditUser && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      zIndex: 1,
                    }}
                    onClick={() => formik.handleSubmit()}
                  >
                    Add Recipient
                  </Button>
                )}
              </ThemeProvider>
              {props.message === "Recipient Successfully Added" ? (
                <>
                  {" "}
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "15px",
                      color: "#21a32d",
                      marginTop: 5,
                      marginLeft: 5,
                    }}
                  >
                    {props.message}
                  </p>
                </>
              ) : (
                <>
                  {" "}
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "15px",
                      color: "rgb(253, 60, 79)",
                      marginTop: 5,
                      marginLeft: 5,
                    }}
                  >
                    {props.message}
                  </p>
                </>
              )}
            </div>
          ) : (
            <>
              <div>
                {props.receipentList.map((recipient: IReceipentList) =>
                  props.editingRecipient !== recipient.id ? (
                    <Paper
                      key={recipient.id}
                      elevation={3}
                      className="edit__recipient"
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            {recipient.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {recipient.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <div
                            style={{
                              backgroundColor: `rgb(${recipient.color})`,
                              border: `solid 4px ${recipient.borderColor}`,
                              height: "30px",
                              width: "30px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className="iconList">
                          <IconButton
                            onClick={() =>
                              props.onEditRecipentPressed(recipient.id)
                            }
                            aria-label="edit"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              props.handleDelete(recipient.id, recipient.email)
                            }
                            aria-label="delete"
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  ) : (
                    <form onSubmit={formik.handleSubmit}>
                      <Paper
                        key={recipient.id}
                        elevation={3}
                        className="edit_receipentListRow"
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={6}>
                            <TextField
                              style={{ width: "100%", marginTop: 5 }}
                              size="small"
                              name="name"
                              placeholder={"Role name"}
                              onChange={(e) => {
                                formik.handleChange(e);
                                props.updateMessage();
                              }}
                              value={formik.values.name}
                              inputMode="text"
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  formik.handleSubmit();
                                }
                              }}
                              inputProps={{ maxLength: 50 }}
                              onBlur={formik.handleBlur}
                              error={
                                (formik.errors.name && formik.touched.name) ||
                                false
                              }
                              helperText={
                                formik.errors.name &&
                                formik.touched.name &&
                                formik.errors.name
                              }
                            />
                            <TextField
                              style={{ width: "100%", marginTop: 5 }}
                              size="small"
                              name="email"
                              placeholder={"Role name"}
                              onChange={(e) => {
                                formik.handleChange(e);
                                props.updateMessage();
                              }}
                              value={formik.values.email}
                              inputMode="text"
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  formik.handleSubmit();
                                }
                              }}
                              inputProps={{ maxLength: 50 }}
                              onBlur={formik.handleBlur}
                              error={
                                (formik.errors.email && formik.touched.email) ||
                                false
                              }
                              helperText={
                                formik.errors.email &&
                                formik.touched.email &&
                                formik.errors.email
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <div
                              style={{
                                backgroundColor: `rgb(${recipient.color})`,
                                border: `solid 4px ${recipient.borderColor}`,
                                height: "30px",
                                width: "30px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} className="iconList">
                            <IconButton
                              onClick={() => formik.handleSubmit()}
                              aria-label="edit"
                            >
                              <CheckBox style={{ color: "#6149CD" }} />
                            </IconButton>
                            <IconButton onClick={handleCancel} color="error">
                              <Close />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  )
                )}
                <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "15px",
                    color: "rgb(253, 60, 79)",
                    marginTop: 5,
                    marginLeft: 5,
                  }}
                >
                  {props.message}
                </p>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AddReciepent;
