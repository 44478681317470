import React, { useState, useEffect, useContext, FC } from "react";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import {
  UserList,
  WorkFlow,
  workFlowHierarchy,
  IStatsCount,
} from "../Types/Types";
import UserContext from "../Utils/UserContext";
import { useNavigate } from "react-router-dom";
import {
  CREATEPDF,
  DASHBOARD,
  EDITCONTROLS,
  CREATEWORKFLOW,
  WORKFLOWBYEMAIL,
} from "../Utils/Constants";
import Reports from "../Components/Reports";
import { setIn } from "formik";
import demo from "../Utils/demo.json";

interface DashBoardScreen {
  roles: string;
  roleId: number;
  userId: any;
}
let documentName: string = "";
const DashBoardScreen: FC<DashBoardScreen> = (props: DashBoardScreen) => {
  const context = useContext(UserContext);
  const {
    token,
    userName,
    userList,
    isOpen,
    updateUserName,
    updateTabIndex,
    updateUserList,
    updateMenuButton,
  } = context;
  const [workFLowData, setWorkFlowData] = useState<WorkFlow[]>([]);
  const [filterData, setFilterData] = useState<WorkFlow[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPreviewPdf, setPreviewPdf] = useState(false);
  const [privewWorkFlowPdf, setPreivewWorkFlowPdf] = useState("");
  const [numPages, setNumPages] = useState<number>(-1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isInfo, setInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [stats, setStats] = useState<IStatsCount>({
    openCount: 0,
    closedCount: 0,
  });
  const [data1, setData1] = useState<any>([
    { name: "total", count: 0, fill: "#f8bf34" },
    { name: "complete", count: 0, fill: "#e5e4e2" },
    { name: "incomplete", count: 0, fill: "#808080" },
  ]);
  const [openTrackingPopUp, setOpenTrackingPopUp] = useState(false);
  const [popIndex, setPopIndex] = useState<number>();
  const [trackingInfoData, setTrackingInfoData] = useState<any>(demo);
  const [trackingData, setTrackingData] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [workFlowLog, setWorkFlowLog] = useState<any[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [tackingPopup, setTrackingPopup] = useState<number>();
  const [totalUsers, setTotalUsers] = useState(0);
  const handleOpen = (index: number) => {
    if (index === popIndex) {
    } else {
      setOpenModal(false);
    }
  };

  const handleClose = () => {
    setPopIndex(0);
    setOpenModal(false);
  };

  const OpenTrackingModal = (index: number) => {
    if (index === popIndex) {
    } else {
      setOpenTrackingModal(false);
    }
  };
  const CloseTrackingModal = () => {
    setTrackingPopup(0);
    setOpenTrackingModal(false);
  };

  const entries = 9;

  useEffect(() => {
    //    updateTabIndex(0);
    document.title = "Dashboard";
    if (token.length > 0) {
      getReports();
      getInitiatedWorkFlow();
    }
  }, [token, props.roles]);

  const getReports = async () => {
    try {
      let response = await fetchGet(token, ApiName.reports);
      if (response.status === 0) {
        setStats(response.response);
        let res = response.response;
        let _data = data1;
        _data[0].count = res.openCount + res.closedCount;
        _data[1].count = res.closedCount;
        _data[2].count = res.openCount;
        // setData1(_data);
        setTotalUsers(res.userCount);
        setData1([
          {
            name: "total",
            count: res.openCount + res.closedCount,
            fill: "rgb(97, 73, 205)",
          },
          {
            name: "complete",
            count: res.closedCount,
            fill: "rgb(248, 191, 52)",
          },
          {
            name: "incomplete",
            count: res.openCount,
            fill: "rgb(116, 206, 115)",
          },
        ]);
      } else {
        setMessage("Something went wrong please try again later");
        setInfo(true);
      }
    } catch (error) {
      console.log("getReports error: " + error);
    }
  };
  const getInitiatedWorkFlow = async () => {
    try {
      let response = await fetchGet(
        token,
        ApiName.getWorkFlow
        // props.roles === "admin"
        //   ? ApiName.getWorkFlow
        //   : ApiName.getAssignedWorkFlow
      );
      if (response.status === 0) {
        setWorkFlowData(response.response);
        console.log(response.response);
        if (JSON.parse(props.userId) !== 1) {
          // fiterData(response.response);
        }
        setPageCount(Math.ceil(response.response.length / 9));
      }
    } catch (error) {
      console.log("getInitiatedWorkFlow", error);
    }
  };
  const fiterData = (data: WorkFlow[]) => {
    let tempData = [...data];
    let hireacrhyArray = [];
    let hireacrhyObject = {};

    for (let i = 0; i < tempData.length; i++) {
      for (let j = 0; j < tempData[i].hierarchies.length; j++) {
        if (tempData[i].hierarchies[j].status === false)
          hireacrhyObject = {
            hierarchyNumber: tempData[i].hierarchies[j].hierarchyNumber,
          };
        hireacrhyArray.push(hireacrhyObject);
      }
    }
    console.log("chal ja", hireacrhyObject);
  };

  const onInfoClosedPressed = () => {
    setInfo(false);
    setMessage("");
  };

  const onOkPressed = () => {
    setInfo(false);
    setMessage("");
  };
  const onToggleDrawer = () => {
    // setOpen(!open);
    updateMenuButton(!isOpen);
  };

  const onSearchItem = (val: any) => {
    console.log("val", val);
    let tempFilterData = [...workFLowData];
    tempFilterData = tempFilterData.filter((item) => {
      const titleMatch = item.title.includes(val.toLowerCase());
      const initiatorMatch = item.initiator.name
        .toLowerCase()
        .includes(val.toLowerCase());
      // const isEditable =
      //   item.isEditable === true && val.toLowerCase() === "pending";
      // console.log("isEditable: " + isEditable);
      const isDateMatch = item.created_at.includes(val);
      console.log("isDateMatch: " + isDateMatch);

      // return titleMatch || initiatorMatch || isDateMatch;
      return titleMatch;
    });
    console.log("tempFilterData", tempFilterData);
    if (val.length > 0) {
      if (tempFilterData.length > 0) {
        setFilterData(tempFilterData);
        setPageCount(Math.ceil(tempFilterData.length / 9));
        setErrorMessage("");
      } else {
        setErrorMessage("No Record Found");
        setFilterData([]);
      }
    } else {
      setFilterData([]);
      setErrorMessage("");
      setPageCount(Math.ceil(tempFilterData.length / 9));
    }
  };

  const onPageChange = (index: number) => {
    let startValue = index * entries;
    let endValue = startValue + entries;
    setStartIndex(startValue);
    setEndIndex(endValue);
    setCurrentPage(index);
  };
  const onClosePressed = () => {
    setOpenTrackingPopUp(false);
  };
  const handleTrackingInfo = async (id: any, title: string) => {
    const endPoint = ApiName.workFlowId + id;

    try {
      // setOpenTrackingPopUp(true);\
      setIsLoading(true);
      const response = await fetchGet(token, endPoint);

      if (response.status === 0) {
        setTrackingData(response.response);
        setOpenModal(true);
        setIsLoading(false);
        documentName = title;
      }
    } catch (error) {
      console.log("handleTrackingInfo", error);
    }
  };

  const handlePreviewInfo = async (id: any, title: string) => {
    const endPoint = ApiName.workFlowLog + id;
    try {
      // setOpenTrackingPopUp(true);\
      setIsLoading2(true);

      const response = await fetchGet(token, endPoint);
      if (response.status === 0) {
        setWorkFlowLog(response.response);
        if (response.status === 0) {
          documentName = title;

          setIsLoading2(false);
          setOpenTrackingModal(true);
        }
      }
    } catch (error) {
      console.log("handleTrackingInfo", error);
    }
  };

  return (
    <Reports
      userList={userList}
      workFLowData={workFLowData}
      currentPage={currentPage}
      pageCount={pageCount}
      startIndex={startIndex}
      endIndex={endIndex}
      filterData={filterData}
      errorMessage={errorMessage}
      isPreviewPdf={isPreviewPdf}
      privewWorkFlowPdf={privewWorkFlowPdf}
      pageNumber={pageNumber}
      scale={1}
      isInfo={isInfo}
      message={message}
      isOpen={isOpen}
      roles={props.roles}
      stats={stats}
      data1={data1}
      isLoading={isLoading}
      totalUsers={totalUsers}
      onInfoClosedPressed={onInfoClosedPressed}
      onOkPressed={onOkPressed}
      onPageChange={onPageChange}
      onSearchItem={onSearchItem}
      onToggleDrawer={onToggleDrawer}
      openTrackingPopUp={openTrackingPopUp}
      setOpenTrackingPopUp={setOpenTrackingPopUp}
      onClosePressed={onClosePressed}
      popIndex={popIndex}
      setPopIndex={setPopIndex}
      trackingInfoData={trackingInfoData}
      handleTrackingInfo={handleTrackingInfo}
      trackingData={trackingData}
      openModal={openModal}
      handleOpen={handleOpen}
      handleClose={handleClose}
      workFlowLog={workFlowLog}
      handlePreviewInfo={handlePreviewInfo}
      openTrackingModal={openTrackingModal}
      OpenTrackingModal={OpenTrackingModal}
      CloseTrackingModal={CloseTrackingModal}
      tackingPopup={tackingPopup}
      setTrackingPopup={setTrackingPopup}
      isLoading2={isLoading2}
      documentName={documentName}
    />
  );
};

export default DashBoardScreen;
