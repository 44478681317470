import React, { useState, FC } from "react";
import searchicon from "../Assets/search-icon.png";
import { timeConvertor } from "../Utils/commonFunction";

import {
  AllControlsList,
  ITemplateList,
  SelectTemplateControl,
} from "../Types/Types";
import SelectTemplatePopUp from "./Common/PopUp/SelectTemplatePopUp";
interface SelectTemplate {
  templateList: ITemplateList[];
  searchQuery: string;
  filteredResponse: any;
  isTemplate: boolean;
  // allControlList: AllControlsList[];
  pdfUrl: string;
  pdfScale: number;
  pageNumber: number;
  isPrivewPdf: boolean;
  activeTemplateIndex: number;
  templateControlsList: AllControlsList[];
  selectedTemplate: SelectTemplateControl[];
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onCloseTemplate: () => void;
  onClickTemplateItem: (index: number) => void;
  onTemplateItemClick: (index: number) => void;
  onApplyTemplate: () => void;
  onNextPagePressed: () => void;
  onPreviousePressed: () => void;
  onChangeSearchQuery: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRowClick: (template: ITemplateList) => void;
}
const SelectTemplate: FC<SelectTemplate> = (props: SelectTemplate) => {
  return (
    <div className="tableInnerWrapper applytemplate">
      <SelectTemplatePopUp
        isTemplate={props.isTemplate}
        templateList={props.templateList}
        pdfUrl={props.pdfUrl}
        pdfScale={props.pdfScale}
        pageNumber={props.pageNumber}
        allControlList={props.templateControlsList}
        activeTemplateIndex={props.activeTemplateIndex}
        isPrivewPdf={false}
        selectedTemplate={props.selectedTemplate}
        onLoadSuccess={props.onLoadSuccess}
        onCloseTemplate={props.onCloseTemplate}
        onClickTemplateItem={props.onClickTemplateItem}
        onTemplateItemClick={props.onTemplateItemClick}
        onApplyTemplate={props.onApplyTemplate}
        onNextPagePressed={props.onNextPagePressed}
        onPreviousePressed={props.onPreviousePressed}
      />
      <div className="search-wraper">
        <input
          className="search__input"
          type="text"
          placeholder="Search here"
          value={props.searchQuery}
          onChange={(e) => props.onChangeSearchQuery(e)}
        />
        <img src={searchicon} alt="search icon" className="search-icon" />
      </div>

      <div className="table-info">
        <table>
          <thead>
            <tr>
              <th>Number</th>
              <th>Name</th>
              <th>Total Pages</th>
              <th>Created At</th>
              <th>Initiator</th>
            </tr>
          </thead>
          <tbody>
            {props.filteredResponse.map((template: any, index: number) => (
              <tr key={template.id} onClick={() => props.onRowClick(template)}>
                <td>
                  <div className="td-name">Number:</div>
                  {/* {template.id} */}
                  {index + 1}
                </td>
                <td>
                  <div className="td-name">Name:</div>
                  {template.name}
                </td>
                <td>
                  <div className="td-name">Total Pages:</div>
                  {template.maxPages}
                </td>
                <td>
                  <div className="td-name">Created At:</div>
                  {timeConvertor(template.created_at)}
                </td>
                <td>
                  <div className="td-name">Initiator:</div>
                  {template.initiator.name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SelectTemplate;
