import React, { FC, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { themeCustomizer } from "../ButtonTheme";
import { pdfjs, Document, Page } from "react-pdf";
import { AllControlsList, ITemplateConrolsList } from "../../../Types/Types";
import PdfDocument from "../PdfDocument";
import textImage from "../../../Assets/text.png";
import camera from "../../../Assets/camera.png";
import tick from "../../../Assets/tick.png";
import signControl from "../../../Assets/sign-control.png";
import sign from "../../../Assets/sign.png";
import textInputImage from "../../../Assets/Text box-50x50.png";
import multiSelectionImage from "../../../Assets/Multi selection-50x50.png";
import imageBoxImage from "../../../Assets/Image box-50x50.png";
import signatureBoxIamge from "../../../Assets/Signature Box-50x50.png";
import initalsBoxImage from "../../../Assets/initials.png";
import radioButtonImage from "../../../Assets/radio.png";
import "../../../Styles/PdfPreviewModal.scss";
import {
  FontDownload,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CameraAlt,
  CheckBoxOutlineBlank,
  CheckBox,
  RadioButtonUnchecked,
  RadioButtonChecked,
} from "@mui/icons-material";
interface PreviewPdf {
  isPrivewPdf: boolean;
  modifiedPdfBase64: any;
  pageNumber: number;
  allControlsList: AllControlsList[];
  pdfLoading: any;
  pdfUrl: string;
  mouseDrag: number;
  activeItem: number;
  pdfScale: number;
  numPages: number;
  activeControlIndex: number;
  viewControl: boolean;
  zoomLevel: number;
  toHidePdf: boolean;
  templateCreationControlsList: ITemplateConrolsList[];
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  handleMouseMovement: (event: any) => void;
  handleMouseMovementForTemplate: (event: any) => void;
  onDragControlPressed: (
    e: any,
    data: { x: number; y: number },
    index: number
  ) => void;
  onResizeControlsPressed: (
    event: any,
    { size }: { size: { width: number; height: number } },
    index: number
  ) => void;
  handleStop: () => void;
  onHidePreview: () => void;
  onPreviousePressed: () => void;
  onNextPagePressed: () => void;
  onControlClicked: (
    index: number,
    controlId: number,
    pageNumber: number,
    id: number
  ) => void;
  onControlPdfClicked: (index: number) => void;
}
const onDeleteControlPressed = (id: number) => {};
const PreviewPdf: FC<PreviewPdf> = (props: PreviewPdf) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 700,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "90vh",
    overflowY: "scroll",
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Modal
      className="pdf-preview-modal"
      open={props.isPrivewPdf}
      onClose={props.onHidePreview}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <div>Preview</div>
        <div style={{ display: "flex", borderBottom: "1px solid #c1c1c1" }}>
          <div>
            <div style={{ backgroundColor: "#F5F6F6" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  id="pdfContainer"
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "scroll",
                  }}
                >
                  <PdfDocument
                    pdfLoading={props.pdfLoading}
                    pdfUrl={props.pdfUrl}
                    mouseDrag={props.mouseDrag}
                    allControlsList={props.allControlsList}
                    pageNumber={props.pageNumber}
                    activeItem={props.activeItem}
                    isPrivewPdf={props.isPrivewPdf}
                    pdfScale={props.pdfScale}
                    zoomLevel={props.zoomLevel}
                    tohide={props.toHidePdf}
                    templateCreationControlsList={
                      props.templateCreationControlsList
                    }
                    handleMouseMovementForTemplate={
                      props.handleMouseMovementForTemplate
                    }
                    onLoadSuccess={props.onLoadSuccess}
                    handleMouseMovement={props.handleMouseMovement}
                    onDragControlPressed={props.onDragControlPressed}
                    onResizeControlsPressed={props.onResizeControlsPressed}
                    handleStop={props.handleStop}
                    onDeleteControlPressed={onDeleteControlPressed}
                    onControlPdfClicked={props.onControlPdfClicked}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ECEEF2",
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={props.onPreviousePressed}
                  style={{
                    borderRadius: 10,
                    fontSize: 18,
                    color: "#A89BB9",
                  }}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                {(
                  <p
                    style={{
                      padding: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      backgroundColor: " rgba(122, 134, 161, 0.3)",
                      color: "#ffffff",
                      marginRight: 5,
                    }}
                  >
                    {props.pageNumber}
                  </p>
                ) || (props.numPages ? 1 : "--")}{" "}
                / {props.numPages || "--"}
                <IconButton
                  onClick={props.onNextPagePressed}
                  style={{
                    borderRadius: 10,
                    fontSize: 18,
                    color: "#A89BB9",
                  }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{ backgroundColor: "#ffffff" }}
              className="controllersDetailsContainer"
            >
              {props.allControlsList.length > 0 &&
                props.allControlsList.map(
                  (item: AllControlsList, index: number) => (
                    <>
                      {item.name !== "dds-dnt-combo" &&
                        item.name !== "dds-dnt-sign" && (
                          <div className={"textInputContainer"}>
                            <div key={index}>
                              <div
                                className={
                                  item.id === props.activeItem
                                    ? "nonActiveContainer activeContainer"
                                    : "nonActiveContainer"
                                }
                              >
                                {/* <div className="nonActiveContainer"> */}
                                <div className="leftContainer">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        item.name === "dds-txt"
                                          ? textImage
                                          : item.name === "dds-select"
                                          ? tick
                                          : item.name === "dds-img"
                                          ? camera
                                          : item.name === "dds-sign"
                                          ? sign
                                          : item.name === "dds-initials"
                                          ? initalsBoxImage
                                          : item.name === "dds-sign-combo"
                                          ? sign
                                          : item.name === "dds-img-combo"
                                          ? camera
                                          : radioButtonImage
                                      }
                                      style={{ width: 30, height: 30 }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      color: "#191a1d",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      padding: 5,
                                      marginLeft: 10,
                                    }}
                                  >
                                    <p style={{ fontSize: 14 }}>
                                      {item.name === "dds-txt"
                                        ? "Text"
                                        : item.name === "dds-select"
                                        ? "Multi selection"
                                        : item.name === "dds-img"
                                        ? "Image Box "
                                        : item.name === "dds-sign"
                                        ? "Signature Box"
                                        : item.name === "dds-initials"
                                        ? "Initials Box"
                                        : item.name === "dds-sign-combo"
                                        ? "Signature with image"
                                        : item.name === "dds-img-combo"
                                        ? "Image with signature"
                                        : "Radio Button"}
                                    </p>
                                    {/* <p
                                style={{
                                  color: "#C3C9D5",
                                  fontSize: 10,
                                }}
                              >
                                {item.name === "dds-txt"
                                  ? " Single line or multiline text are"
                                  : item.name === "dds-select"
                                  ? "Select one or multiple options using a checkbox"
                                  : item.name === "dds-img"
                                  ? "Upload Image using device camera or upload from local device"
                                  : item.name === "dds-sign"
                                  ? "Draw a signature on screen"
                                  : item.name === "dds-initials"
                                  ? "Draw a initals on screen"
                                  : "Select radio buttons"}
                              </p> */}
                                  </div>
                                </div>
                                <div>
                                  {/* <IconButton
                              style={{
                                borderRadius: 10,
                                fontSize: 18,
                                color: "#A89BB9",
                              }}
                              onClick={() => props.onControlClicked(index, 0)}
                            >
                              {props.activeControlIndex === index &&
                              props.viewControl === true ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton> */}
                                </div>
                              </div>

                              <div className="control-options">
                                {/* third section */}
                                <div className="mainControlConatiner control-third-section">
                                  <div className="info">
                                    <div className="page-num">
                                      PDF Page
                                      <span style={{ color: "#7A86A1" }}>
                                        {item.pageNum}
                                      </span>
                                    </div>
                                    {item.mandatory && (
                                      <div className="mandatory-btn">
                                        <IconButton
                                          // onClick={() => props.onMandatoryPressed(index)}
                                          style={{
                                            borderRadius: 10,
                                            fontSize: 18,
                                            color: "#A89BB9",
                                            height: 20,
                                            width: 20,
                                          }}
                                          size="small"
                                        >
                                          <CheckBox className="radio-btn" />
                                        </IconButton>
                                        Mandatory
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {/* second section */}
                                <div className="control-second-section">
                                  <div className="cs-section-item">
                                    <div className="title">Email:</div>
                                    <div className="desc">{item.userEmail}</div>
                                  </div>
                                  <div className="cs-section-item">
                                    <div className="title">Name:</div>
                                    <div className="desc">{item.userName}</div>
                                  </div>
                                  {/* <div className="cs-section-item">
                              <div className="title">Order#:</div>
                              <div className="desc">{item.hierarchyNumber}</div>
                            </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  )
                )}

              {/**template Creation  */}
              {props.templateCreationControlsList.length > 0 &&
                props.templateCreationControlsList.map(
                  (item: ITemplateConrolsList, index: number) => (
                    <>
                      {item.name !== "dds-dnt-combo" &&
                        item.name !== "dds-dnt-sign" && (
                          <>
                            {" "}
                            <div className={"textInputContainer"}>
                              <div key={index}>
                                <div
                                  className={
                                    item.id === props.activeItem
                                      ? "nonActiveContainer activeContainer"
                                      : "nonActiveContainer"
                                  }
                                >
                                  {/* <div className="nonActiveContainer"> */}
                                  <div className="leftContainer">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignSelf: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          item.name === "dds-txt"
                                            ? textImage
                                            : item.name === "dds-select"
                                            ? tick
                                            : item.name === "dds-img"
                                            ? camera
                                            : item.name === "dds-sign"
                                            ? sign
                                            : item.name === "dds-initials"
                                            ? initalsBoxImage
                                            : item.name === "dds-sign-combo"
                                            ? sign
                                            : item.name === "dds-img-combo"
                                            ? camera
                                            : radioButtonImage
                                        }
                                        style={{ width: 30, height: 30 }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        color: "#191a1d",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        padding: 5,
                                        marginLeft: 10,
                                      }}
                                    >
                                      <p style={{ fontSize: 14 }}>
                                        {item.name === "dds-txt"
                                          ? "Text"
                                          : item.name === "dds-select"
                                          ? "Multi selection"
                                          : item.name === "dds-img"
                                          ? "Image Box "
                                          : item.name === "dds-sign"
                                          ? "Signature Box"
                                          : item.name === "dds-initials"
                                          ? "Initials Box"
                                          : item.name === "dds-sign-combo"
                                          ? "Signature with image"
                                          : item.name === "dds-img-combo"
                                          ? "Image with signature"
                                          : "Radio Button"}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="control-options">
                                  {/* third section */}
                                  <div className="mainControlConatiner control-third-section">
                                    <div className="info">
                                      <div className="page-num">
                                        PDF Page
                                        <span style={{ color: "#7A86A1" }}>
                                          {item.pageNum}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </>
                        )}
                    </>
                  )
                )}
            </div>
          </div>
        </div>

        <ThemeProvider theme={themeCustomizer}>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              marginTop: 10,
              maxWidth: 170,
              zIndex: 1,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            onClick={props.onHidePreview}
          >
            Ok
          </Button>
        </ThemeProvider>
      </Box>
    </Modal>
  );
};

export default PreviewPdf;
