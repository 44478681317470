import React, { FC, useState } from "react";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import { pdfjs, Document, Page } from "react-pdf";
import { AllControlsList, ITemplateConrolsList } from "../../Types/Types";
//mateial ui icons
import {
  Camera,
  Close,
  CameraAlt,
  CheckBox,
  FontDownload,
  TranslateSharp,
  RadioButtonCheckedSharp,
} from "@mui/icons-material";

import textInputImage from "../../Assets/Text box-50x50.png";
import multiSelectionImage from "../../Assets/Multi selection-50x50.png";
import imageBoxImage from "../../Assets/Image box-50x50.png";
import signatureBoxIamge from "../../Assets/Signature Box-50x50.png";
import initalsBoxImage from "../../Assets/Initials -50x50.png";
import radioButtonImage from "../../Assets/Radio Button -50x50.png";
import { IconButton } from "@mui/material";

interface PdfDocument {
  pdfLoading: any;
  pdfUrl: string;
  mouseDrag: number;
  allControlsList: AllControlsList[];
  pageNumber: number;
  activeItem: number;
  isPrivewPdf: boolean;
  pdfScale: number;
  zoomLevel: number;
  tohide: boolean;
  templateCreationControlsList: ITemplateConrolsList[];

  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  handleMouseMovement: (event: any) => void;
  onDragControlPressed: (
    e: any,
    data: { x: number; y: number },
    index: number
  ) => void;
  onResizeControlsPressed: (
    event: any,
    { size }: { size: { width: number; height: number } },
    index: number
  ) => void;
  handleStop: () => void;
  onDeleteControlPressed: (id: number) => void;
  onControlPdfClicked: (index: number) => void;
  handleMouseMovementForTemplate: (event: any) => void;
}
const PdfDocument: FC<PdfDocument> = (props: PdfDocument) => {
  return (
    <>
      {props.pdfLoading.loading === false ? (
        <div
          style={{
            transform: `scale(${props.zoomLevel})`,
            transformOrigin: "0 0",
          }}
        >
          <Document
            file={props.pdfUrl}
            onLoadSuccess={props.onLoadSuccess}
            className={
              props.mouseDrag === 1
                ? "textBoxDrag"
                : props.mouseDrag === 2
                ? "multiSelectionDrag"
                : props.mouseDrag === 3
                ? "imageBoxDrag"
                : props.mouseDrag === 4
                ? "signatureBoxDrag"
                : props.mouseDrag === 5
                ? "initialBoxDrag"
                : props.mouseDrag === 6
                ? "radioButtonDrag"
                : props.mouseDrag === 7
                ? "signatureImageDrag"
                : ""
            }
            style={{ position: "relative" }}
            onMouseUp={
              props.tohide
                ? props.handleMouseMovementForTemplate
                : props.handleMouseMovement
            }
          >
            {/** workflow creation listing */}
            {props.allControlsList.length > 0 &&
              props.allControlsList.map(
                (item: AllControlsList, index: number) => (
                  <>
                    {item.pageNum === props.pageNumber && (
                      <Draggable
                        disabled={props.isPrivewPdf}
                        key={index}
                        onDrag={(e, data) =>
                          props.onDragControlPressed(e, data, index)
                        }
                        onStop={props.handleStop}
                        cancel=".react-resizable-handle"
                        position={{
                          x: item.x,
                          y: item.y,
                        }}
                        bounds="parent"
                      >
                        <div
                          key={index}
                          style={{
                            zIndex: 1,
                            position: "absolute",
                          }}
                        >
                          {props.isPrivewPdf === false ? (
                            <>
                              {item.name !== "dds-select" &&
                              item.name !== "dds-radio" &&
                              item.name !== "dds-img" &&
                              item.name !== "dds-img-combo" &&
                              item.name !== "dds-dnt-combo" &&
                              item.name !== "dds-dnt-sign" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  // style={{ height: height, width: width }}
                                  // className={locked ? "" : "react-resizable-handle"}
                                  // draggableOpts={{}}
                                  minConstraints={[10, 10]}
                                  // maxConstraints={[150, 150]}
                                >
                                  <div style={{ position: "relative" }}>
                                    <div
                                      style={{
                                        width: item.width,
                                        height: item.height,
                                        // borderRadius: 10,
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border:
                                          item.id === props.activeItem &&
                                          !props.isPrivewPdf
                                            ? `4px solid ${item.borderColor}`
                                            : "",
                                        // backgroundColor: `rgba(255, 214, 91,0.4)`,
                                        backgroundColor: `rgba(${
                                          item.color + ", 0.5"
                                        })`,
                                        textAlign: "center",
                                      }}
                                      onClick={() => {
                                        props.onControlPdfClicked(index);
                                      }}
                                    ></div>
                                    <button
                                      className="cancel_btn"
                                      onClick={() =>
                                        props.onDeleteControlPressed(item.id)
                                      }
                                    >
                                      <Close />
                                    </button>
                                  </div>
                                </ResizableBox>
                              ) : item.name === "dds-select" ? (
                                <div
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    <img
                                      src={multiSelectionImage}
                                      // onClick={}
                                      style={{
                                        borderRadius: 10,
                                        fontSize: 18,
                                        color: "black",
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border:
                                          item.id === props.activeItem &&
                                          !props.isPrivewPdf
                                            ? "2px solid #6149cd"
                                            : "",
                                        height: item.height,
                                        width: item.width,
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="cancel_btn"
                                    onClick={() =>
                                      props.onDeleteControlPressed(item.id)
                                    }
                                  >
                                    <Close />
                                  </button>
                                </div>
                              ) : item.name === "dds-radio" ? (
                                <div
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    <img
                                      src={radioButtonImage}
                                      // onClick={}
                                      style={{
                                        borderRadius: 10,
                                        fontSize: 18,
                                        color: "black",
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border:
                                          item.id === props.activeItem &&
                                          !props.isPrivewPdf
                                            ? "2px solid #6149cd"
                                            : "",
                                        height: item.height,
                                        width: item.width,
                                      }}
                                    />
                                    <button
                                      className="cancel_btn"
                                      onClick={() =>
                                        props.onDeleteControlPressed(item.id)
                                      }
                                    >
                                      <Close />
                                    </button>
                                  </div>
                                </div>
                              ) : item.name === "dds-img-combo" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  // style={{ height: height, width: width }}
                                  // className={locked ? "" : "react-resizable-handle"}
                                  // draggableOpts={{}}
                                  minConstraints={[50, 50]}
                                  // maxConstraints={[150, 150]}
                                >
                                  <img
                                    src={imageBoxImage}
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                    }}
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  />
                                </ResizableBox>
                              ) : item.name === "dds-dnt-combo" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  minConstraints={[80, 20]}
                                >
                                  <div
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      backgroundColor: `rgba(${
                                        item.color + ", 0.5"
                                      })`,
                                      textAlign: "center",
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    date and time
                                  </div>
                                </ResizableBox>
                              ) : item.name === "dds-img" ? (
                                <>
                                  <img
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                    src={imageBoxImage}
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                    }}
                                  />
                                  <button
                                    className="cancel_btn"
                                    onClick={() =>
                                      props.onDeleteControlPressed(item.id)
                                    }
                                  >
                                    <Close />
                                  </button>
                                </>
                              ) : item.name === "dds-dnt-sign" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  minConstraints={[80, 20]}
                                >
                                  <div
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      backgroundColor: `rgba(${
                                        item.color + ", 0.5"
                                      })`,
                                      textAlign: "center",
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    date and time
                                  </div>
                                </ResizableBox>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {item.name !== "dds-select" ? (
                                <>
                                  <div>
                                    <IconButton
                                      style={{
                                        width: item.width,
                                        height: item.height,
                                        borderRadius: 10,
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border: "2px solid #6149cd",
                                        zIndex: 1,
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {item.name === "dds-txt" ? (
                                        <FontDownload
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-img" ? (
                                        <CameraAlt
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-sign" ? (
                                        <TranslateSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-initials" ? (
                                        <TranslateSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-sign-combo" ? (
                                        <TranslateSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-dnt-combo" ? (
                                        <ResizableBox
                                          width={item.width}
                                          height={item.height}
                                          onResize={(e: any, data: any) =>
                                            props.onResizeControlsPressed(
                                              e,
                                              data,
                                              index
                                            )
                                          }
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          minConstraints={[80, 20]}
                                        >
                                          <div
                                            style={{
                                              width: item.width,
                                              height: item.height,
                                              // borderRadius: 10,
                                              cursor: !props.isPrivewPdf
                                                ? "move"
                                                : "default",
                                              border:
                                                item.id === props.activeItem &&
                                                !props.isPrivewPdf
                                                  ? "2px solid #6149cd"
                                                  : "2px solid #191a1d",
                                              fontSize: "12px",
                                            }}
                                            onClick={() => {
                                              props.onControlPdfClicked(index);
                                            }}
                                          >
                                            date and time
                                          </div>
                                        </ResizableBox>
                                      ) : item.name === "dds-dnt-sign" ? (
                                        <ResizableBox
                                          width={item.width}
                                          height={item.height}
                                          onResize={(e: any, data: any) =>
                                            props.onResizeControlsPressed(
                                              e,
                                              data,
                                              index
                                            )
                                          }
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          minConstraints={[80, 20]}
                                        >
                                          <div
                                            style={{
                                              width: item.width,
                                              height: item.height,
                                              backgroundColor: `rgba(${
                                                item.color + ", 0.5"
                                              })`,
                                              textAlign: "center",
                                              // borderRadius: 10,
                                              cursor: !props.isPrivewPdf
                                                ? "move"
                                                : "default",
                                              border:
                                                item.id === props.activeItem &&
                                                !props.isPrivewPdf
                                                  ? "2px solid #6149cd"
                                                  : "2px solid #191a1d",
                                              fontSize: "12px",
                                            }}
                                            onClick={() => {
                                              props.onControlPdfClicked(index);
                                            }}
                                          >
                                            date and time
                                          </div>
                                        </ResizableBox>
                                      ) : item.name === "dds-img-combo" ? (
                                        <CameraAlt
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : (
                                        <RadioButtonCheckedSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                >
                                  <div>
                                    <IconButton>
                                      <CheckBox
                                        style={{
                                          width: item.width,
                                          height: item.height,
                                          borderRadius: 10,
                                          cursor: !props.isPrivewPdf
                                            ? "move"
                                            : "default",
                                          border: "2px solid #6149cd",
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </Draggable>
                    )}
                  </>
                )
              )}

            {/** template creation listing */}
            {props.templateCreationControlsList.length > 0 &&
              props.templateCreationControlsList.map(
                (item: ITemplateConrolsList, index: number) => (
                  <>
                    {item.pageNum === props.pageNumber && (
                      <Draggable
                        disabled={props.isPrivewPdf}
                        key={index}
                        onDrag={(e, data) =>
                          props.onDragControlPressed(e, data, index)
                        }
                        onStop={props.handleStop}
                        cancel=".react-resizable-handle"
                        position={{
                          x: item.x,
                          y: item.y,
                        }}
                        bounds="parent"
                      >
                        <div
                          key={index}
                          style={{
                            zIndex: 1,
                            position: "absolute",
                          }}
                        >
                          {props.isPrivewPdf === false ? (
                            <>
                              {item.name !== "dds-select" &&
                              item.name !== "dds-radio" &&
                              item.name !== "dds-img" &&
                              item.name !== "dds-img-combo" &&
                              item.name !== "dds-dnt-combo" &&
                              item.name !== "dds-dnt-sign" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  // style={{ height: height, width: width }}
                                  // className={locked ? "" : "react-resizable-handle"}
                                  // draggableOpts={{}}
                                  minConstraints={[10, 10]}
                                  // maxConstraints={[150, 150]}
                                >
                                  <div style={{ position: "relative" }}>
                                    <div
                                      style={{
                                        width: item.width,
                                        height: item.height,
                                        // borderRadius: 10,
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border:
                                          item.id === props.activeItem &&
                                          !props.isPrivewPdf
                                            ? `4px solid #6149cd`
                                            : "",
                                        backgroundColor: `rgba(255, 214, 91,0.4)`,
                                        textAlign: "center",
                                        // backgroundColor: `rgba(${
                                        //   item.color + ", 0.5"
                                        // })`
                                      }}
                                      onClick={() => {
                                        props.onControlPdfClicked(index);
                                      }}
                                    ></div>
                                    <button
                                      className="cancel_btn"
                                      onClick={() =>
                                        props.onDeleteControlPressed(item.id)
                                      }
                                    >
                                      <Close />
                                    </button>
                                  </div>
                                </ResizableBox>
                              ) : item.name === "dds-select" ? (
                                <div
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    <img
                                      src={multiSelectionImage}
                                      // onClick={}
                                      style={{
                                        borderRadius: 10,
                                        fontSize: 18,
                                        color: "black",
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border:
                                          item.id === props.activeItem &&
                                          !props.isPrivewPdf
                                            ? "2px solid #6149cd"
                                            : "",
                                        height: item.height,
                                        width: item.width,
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="cancel_btn"
                                    onClick={() =>
                                      props.onDeleteControlPressed(item.id)
                                    }
                                  >
                                    <Close />
                                  </button>
                                </div>
                              ) : item.name === "dds-radio" ? (
                                <div
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    <img
                                      src={radioButtonImage}
                                      // onClick={}
                                      style={{
                                        borderRadius: 10,
                                        fontSize: 18,
                                        color: "black",
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border:
                                          item.id === props.activeItem &&
                                          !props.isPrivewPdf
                                            ? "2px solid #6149cd"
                                            : "",
                                        height: item.height,
                                        width: item.width,
                                      }}
                                    />
                                    <button
                                      className="cancel_btn"
                                      onClick={() =>
                                        props.onDeleteControlPressed(item.id)
                                      }
                                    >
                                      <Close />
                                    </button>
                                  </div>
                                </div>
                              ) : item.name === "dds-img-combo" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  minConstraints={[50, 50]}
                                >
                                  <img
                                    src={imageBoxImage}
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                    }}
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  />
                                </ResizableBox>
                              ) : item.name === "dds-dnt-combo" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  minConstraints={[80, 20]}
                                >
                                  <div
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    date and time
                                  </div>
                                </ResizableBox>
                              ) : item.name === "dds-dnt-sign" ? (
                                <ResizableBox
                                  width={item.width}
                                  height={item.height}
                                  onResize={(e: any, data: any) =>
                                    props.onResizeControlsPressed(
                                      e,
                                      data,
                                      index
                                    )
                                  }
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  minConstraints={[80, 20]}
                                >
                                  <div
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                  >
                                    date and time
                                  </div>
                                </ResizableBox>
                              ) : item.name === "dds-img" ? (
                                <>
                                  <img
                                    onClick={() => {
                                      props.onControlPdfClicked(index);
                                    }}
                                    src={imageBoxImage}
                                    style={{
                                      width: item.width,
                                      height: item.height,
                                      // borderRadius: 10,
                                      cursor: !props.isPrivewPdf
                                        ? "move"
                                        : "default",
                                      border:
                                        item.id === props.activeItem &&
                                        !props.isPrivewPdf
                                          ? "2px solid #6149cd"
                                          : "2px solid #191a1d",
                                    }}
                                  />
                                  <button
                                    className="cancel_btn"
                                    onClick={() =>
                                      props.onDeleteControlPressed(item.id)
                                    }
                                  >
                                    <Close />
                                  </button>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {item.name !== "dds-select" ? (
                                <>
                                  <div>
                                    <IconButton
                                      style={{
                                        width: item.width,
                                        height: item.height,
                                        borderRadius: 10,
                                        cursor: !props.isPrivewPdf
                                          ? "move"
                                          : "default",
                                        border: "2px solid #6149cd",
                                        zIndex: 1,
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {item.name === "dds-txt" ? (
                                        <FontDownload
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-img" ? (
                                        <CameraAlt
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-sign" ? (
                                        <TranslateSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-initials" ? (
                                        <TranslateSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-sign-combo" ? (
                                        <TranslateSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-img-combo" ? (
                                        <CameraAlt
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      ) : item.name === "dds-dnt-combo" ? (
                                        <ResizableBox
                                          width={item.width}
                                          height={item.height}
                                          onResize={(e: any, data: any) =>
                                            props.onResizeControlsPressed(
                                              e,
                                              data,
                                              index
                                            )
                                          }
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          minConstraints={[80, 20]}
                                        >
                                          <div
                                            style={{
                                              width: item.width,
                                              height: item.height,
                                              // borderRadius: 10,
                                              cursor: !props.isPrivewPdf
                                                ? "move"
                                                : "default",
                                              border:
                                                item.id === props.activeItem &&
                                                !props.isPrivewPdf
                                                  ? "2px solid #6149cd"
                                                  : "2px solid #191a1d",
                                              fontSize: "12px",
                                            }}
                                            onClick={() => {
                                              props.onControlPdfClicked(index);
                                            }}
                                          >
                                            date and time
                                          </div>
                                        </ResizableBox>
                                      ) : item.name === "dds-dnt-sign" ? (
                                        <ResizableBox
                                          width={item.width}
                                          height={item.height}
                                          onResize={(e: any, data: any) =>
                                            props.onResizeControlsPressed(
                                              e,
                                              data,
                                              index
                                            )
                                          }
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          minConstraints={[80, 20]}
                                        >
                                          <div
                                            style={{
                                              width: item.width,
                                              height: item.height,
                                              // borderRadius: 10,
                                              cursor: !props.isPrivewPdf
                                                ? "move"
                                                : "default",
                                              border:
                                                item.id === props.activeItem &&
                                                !props.isPrivewPdf
                                                  ? "2px solid #6149cd"
                                                  : "2px solid #191a1d",
                                              fontSize: "12px",
                                            }}
                                            onClick={() => {
                                              props.onControlPdfClicked(index);
                                            }}
                                          >
                                            date and time
                                          </div>
                                        </ResizableBox>
                                      ) : (
                                        <RadioButtonCheckedSharp
                                          style={{
                                            width: item.width,
                                            height: item.height,
                                            borderRadius: 10,
                                            cursor: !props.isPrivewPdf
                                              ? "move"
                                              : "default",
                                            border:
                                              item.id === props.activeItem &&
                                              !props.isPrivewPdf
                                                ? "2px solid #6149cd"
                                                : "",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    zIndex: 1,
                                    position: "absolute",
                                  }}
                                >
                                  <div>
                                    <IconButton>
                                      <CheckBox
                                        style={{
                                          width: item.width,
                                          height: item.height,
                                          borderRadius: 10,
                                          cursor: !props.isPrivewPdf
                                            ? "move"
                                            : "default",
                                          border: "2px solid #6149cd",
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </Draggable>
                    )}
                  </>
                )
              )}
            <Page scale={props.pdfScale} pageNumber={props.pageNumber} />
          </Document>
        </div>
      ) : (
        <div>{props.pdfLoading.message}</div>
      )}
    </>
  );
};

export default PdfDocument;
