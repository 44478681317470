import React, { FC } from "react";
import backgroundImage from "../Assets/SingUp/background.webp";
import {
  Card,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import logo from "../Assets/SingUp/logo.webp";
import leftCircle from "../Assets/SingUp/leftCircle.webp";
import rightCircle from "../Assets/SingUp/rightCircle.webp";
import { LOGIN, primaryButton, primaryHeading } from "../Utils/Constants";
import "../Styles/SignUp.scss";
import Loader from "./Common/Loader";
import { useNavigate } from "react-router-dom";

interface ForgotPassword {
  isLoading: boolean;
  showPassword: boolean;
  isInvalid: boolean;
  message: string;
  handleClickShowPassword: () => void;
  onConfirmPasswordPressed: (password: string) => void;
  onSubmitEmail: (email: string, formik2: any) => void;
  updateMessage: () => void;
}

const ForgotPassword: FC<ForgotPassword> = (props: ForgotPassword) => {
  const navigate = useNavigate();
  const passwordValidationSchema =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  const validationSchema2 = Yup.object().shape({
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
  });

  const validationSchema = Yup.object().shape({
    passwordOne: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)"
      )
      .required("Password Required"),
    passwordTwo: Yup.string()
      .oneOf([Yup.ref("passwordOne")], "Passwords must match")
      .required("Password Required"),
  });

  const formik = useFormik({
    initialValues: {
      passwordOne: "",
      passwordTwo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // props.onSignInPressed(values.email, values.password);
      {
        props.onConfirmPasswordPressed(values.passwordOne);
      }
    },
  });
  const formik2 = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      // props.onSignInPressed(values.email, values.password);
      {
        props.onSubmitEmail(values.email, formik2);
      }
    },
  });
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryButton, // Replace with your desired color
      },
    },
  });
  return (
    <div>
      <div>
        <div className="background-Image">
          <Grid container xl={12}>
            <Grid
              item
              md={6}
              xl={6}
              lg={6}
              sm={12}
              style={{ position: "relative" }}
              className="yellowBg"
            >
              <div style={{ position: "absolute", top: 30, left: 20 }}>
                <img src={logo} alt="logo" className="logo" />
              </div>
              <div className="leftCircle">
                <img src={leftCircle} />
              </div>
              <div style={{ position: "absolute", bottom: 30, left: 20 }}>
                <p className="copyright">©Copyright 2024</p>
              </div>
            </Grid>
            <Grid item md={6} xl={6} className="rightSection">
              <form className="form" onSubmit={formik.handleSubmit}>
                <div className="formDiv">
                  <div>
                    <div className="innerForm">
                      <Typography
                        fontSize={20}
                        align="left"
                        fontWeight={700}
                        color={"#000000"}
                        // marginTop={2}
                      >
                        {props.isInvalid === true
                          ? "Forgot Password?"
                          : "Update Password"}
                        <div
                          style={{
                            width: "25px",
                            height: 1,
                            backgroundColor: "#C0BDCC",
                          }}
                        />
                      </Typography>

                      {props.isInvalid === true && (
                        <Typography
                          className="sub-heading"
                          fontSize={11}
                          align="left"
                          fontWeight={300}
                          color={"#7A86A1"}
                          // marginTop={4}
                        >
                          Enter the email address you used when you joined and
                          we'll send you instructions to reset your password.
                        </Typography>
                      )}

                      <Typography
                        className="c-input"
                        fontSize={12}
                        align="left"
                        fontWeight={700}
                        marginTop={2}
                      >
                        {props.isInvalid ? "Enter Email" : "New Password"}
                      </Typography>

                      {props.isInvalid ? (
                        <>
                          <TextField
                            style={{
                              width: "100%",
                              marginTop: 5,
                              borderRadius: "50px",
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "20px",
                              },
                            }}
                            size="small"
                            name="email"
                            placeholder={"Tonynguyen@example.com"}
                            onChange={(event) => {
                              formik2.handleChange(event);
                              props.updateMessage();
                            }}
                            value={formik2.values.email}
                            inputMode="text"
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                formik2.handleSubmit();
                              }
                            }}
                            onBlur={formik2.handleBlur}
                            error={
                              (formik2.errors.email && formik2.touched.email) ||
                              false
                            }
                            helperText={
                              formik2.errors.email &&
                              formik2.touched.email &&
                              formik2.errors.email
                            }
                          />
                          {props.message.length > 0 && (
                            <div className="alert alert-primary">
                              {/* <p
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "15px",
                                  color: "rgb(253, 60, 79)",
                                }}
                              > */}
                              {props.message}
                              {/* </p> */}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <TextField
                            className="c-input"
                            type={props.showPassword ? "text" : "password"}
                            style={{
                              width: "100%",
                              marginTop: 5,
                              borderRadius: "50px",
                              zIndex: 1,
                            }}
                            name="passwordOne"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passwordOne}
                            inputMode="text"
                            error={
                              (formik.errors.passwordOne &&
                                formik.touched.passwordOne) ||
                              false
                            }
                            helperText={
                              formik.errors.passwordOne &&
                              formik.touched.passwordOne &&
                              formik.errors.passwordOne
                            }
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                console.warn("password enetered");
                                formik.handleSubmit();
                              }
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "50px",
                                height: 45,
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-placeholder="toggle password visibility"
                                    onClick={props.handleClickShowPassword}
                                    edge="end"
                                  >
                                    {props.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Typography
                            fontSize={12}
                            align="left"
                            fontWeight={700}
                            marginTop={2}
                          >
                            Confirm Password
                          </Typography>
                          <TextField
                            className="c-input"
                            type={props.showPassword ? "text" : "password"}
                            style={{ width: "100%", marginTop: 5, zIndex: 1 }}
                            name="passwordTwo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passwordTwo}
                            inputMode="text"
                            error={
                              (formik.errors.passwordTwo &&
                                formik.touched.passwordTwo) ||
                              false
                            }
                            helperText={
                              formik.errors.passwordTwo &&
                              formik.touched.passwordTwo &&
                              formik.errors.passwordTwo
                            }
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                console.warn("password enetered");
                                formik.handleSubmit();
                              }
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "50px",
                                height: 45,
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-placeholder="toggle password visibility"
                                    onClick={props.handleClickShowPassword}
                                    edge="end"
                                  >
                                    {props.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />{" "}
                        </>
                      )}
                      <ThemeProvider theme={theme}>
                        {props.isInvalid ? (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: "100%",
                                borderRadius: "50px",
                                padding: "10px",
                                marginTop: 20,
                                zIndex: 1,
                              }}
                              onClick={() => {
                                formik2.handleSubmit();
                                props.updateMessage();
                              }}
                            >
                              Send Email
                            </Button>

                            <Button
                              // variant="contained"
                              color="primary"
                              style={{
                                marginTop: "5px",
                                zIndex: 1,
                                letterSpacing: "0px",
                                color: `${primaryHeading}`,
                                fontSize: "12px",
                              }}
                              onClick={() => navigate(LOGIN)}
                            >
                              Back to sign in
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: "100%",
                              borderRadius: "50px",
                              padding: "10px",
                              marginTop: 20,
                              zIndex: 1,
                            }}
                            onClick={() => formik.handleSubmit()}
                          >
                            Update Password
                          </Button>
                        )}
                      </ThemeProvider>
                    </div>

                    <img src={rightCircle} className="rightCircle" />
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
