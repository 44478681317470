import React, { useState, useEffect } from "react";
import ForgotPassword from "../Components/ForgotPassword";
import { useSearchParams, useNavigate } from "react-router-dom";
import { fetchGet, fetchPost, fetchPostFormData } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import Swal from "sweetalert2";
import { LOGIN } from "../Utils/Constants";
const ForgotPasswordScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInvalid, setIsInvalid] = useState(false);
  const [message, setMessage] = useState("");
  const resetKey = searchParams.get("resetKey")
    ? searchParams.get("resetKey")
    : "";
  const navigate = useNavigate();
  useEffect(() => {
    if (resetKey) {
      confirmResetKey(resetKey);
      // setTimeout(() => {
      //   console.warn("yes");
      // }, 500);
    } else {
      setIsLoading(false);
      setIsInvalid(true);
    }
  }, []);

  const confirmResetKey = async (resetKey: string) => {
    try {
      let endPoint = `${ApiName.checkPWResetKey}?resetKey=${resetKey}`;
      console.warn("reset key", endPoint);
      const response = await fetchGet(null, endPoint);
      if (response.status === 0) {
        if (response.response.toLowerCase() === "valid") {
          setIsLoading(false);
          setIsInvalid(false);
        }
      } else if (response.status === 1) {
        if (response.response.toLowerCase() === "invalid") {
          // alert("Invalid Key ");
          setMessage("Invalid link");
          setIsInvalid(true);
          // Swal.fire({
          //   title: `${response.response}`,
          //   confirmButtonText: "Ok",
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     setIsInvalid(true);
          //     setIsLoading(false);
          //   }
          // });
          setErrorMessage("Invalid Key");
        }
      }
    } catch (error) {}
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onConfirmPasswordPressed = async (password: string) => {
    try {
      if (password && resetKey) {
        let data = {
          resetKey: resetKey,
          newPassword: password,
        };
        console.table(data);
        let response = await fetchPost(`${ApiName.recoverAccount}`, null, data);
        console.warn("response", response);
        if (response.status === 0) {
          if (response.response.toLowerCase() === "success") {
            navigate(LOGIN);
          }
        } else if (response.status === 1) {
          if (response.response.toLowerCase() === "link expired") {
            // alert("Link Expired");
            setErrorMessage("Link Expired");
          }
        }
      }
    } catch (error) {}
  };

  const onSubmitEmail = async (email: string, formik2: any) => {
    console.log("onSubmitEmail", email, formik2);
    try {
      if (email) {
        setIsLoading(true);
        var formdata = new FormData();
        formdata.append("email", email);

        let response = await fetchPostFormData(
          `${ApiName.forgotPassword}`,
          null,
          formdata
        );
        if (response.status === 0) {
          // Swal.fire({
          //   title: `${response.response}`,
          //   // confirmButtonText: "Ok",
          // });
          setMessage("Password reset link has been sent to your email");
          formik2.resetForm();
        } else {
          setMessage("Something went wrong please try again");
        }
        console.warn("response of send ", response);
      }
    } catch (error) {
      console.log("onSubmitEmail error: ", error);
      setMessage("Something went wrong please try again");
    }
  };
  const updateMessage = () => {
    console.log("called");
    setMessage("");
  };
  return (
    <div>
      <ForgotPassword
        isLoading={isLoading}
        showPassword={showPassword}
        isInvalid={isInvalid}
        message={message}
        handleClickShowPassword={handleClickShowPassword}
        onConfirmPasswordPressed={onConfirmPasswordPressed}
        onSubmitEmail={onSubmitEmail}
        updateMessage={updateMessage}
      />
    </div>
  );
};

export default ForgotPasswordScreen;
