import React, { useState, useEffect, useContext } from "react";
import SelectTemplate from "../Components/SelectTemplate";
import {
  AllControlsList,
  ITemplateList,
  SelectTemplateControl,
} from "../Types/Types";
import { fetchGet, fetchPost, fetchPut } from "../FetchApi/FetchApi";
import UserContext from "../Utils/UserContext";
import { ApiName } from "../Utils/ApiNames";
import { useLocation, useNavigate } from "react-router-dom";
import { CREATEPDF } from "../Utils/Constants";
const SelectTemplateScreen = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { token, userList, updateTabIndex } = context;
  const [templateList, setTemplateList] = useState<ITemplateList[]>([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [pdfScale, setPdfScale] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPrivewPdf, setIsPrivewPdf] = useState(false);
  const [numPages, setNumPages] = useState<number>(-1);
  const [message, setMessage] = useState("");
  const [isInfo, setInfo] = useState(false);
  const [activeTemplateIndex, setActiveTemplateIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [templateControlsList, setTemplateControlsList] = useState<
    AllControlsList[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    SelectTemplateControl[]
  >([]);
  const [templateName, setTemplateName] = useState<string>("");
  let response = [
    {
      id: 26,
      name: "template 50",
      pdf_url:
        "https://digitaldoc.econceptions.ca/server/public/uploads/templates/26.pdf",
      controls: [
        {
          x: 345.25,
          y: 166,
          id: 1,
          name: "dds-sign",
          width: 150,
          height: 150,
          pageNum: 1,
        },
      ],
      maxPages: 1,
      created_at: "December 13, 2023 3:43 PM",
      updated_at: "December 13, 2023 3:43 PM",
      initiator: {
        id: 1,
        email: "asadullah@econceptions.mobi",
        name: "Admin",
        mobile_number: null,
        role: {
          id: 1,
          name: "ADMIN",
        },
      },
    },
    {
      id: 25,
      name: "Template2",
      pdf_url:
        "https://digitaldoc.econceptions.ca/server/public/uploads/templates/25.pdf",
      controls: [
        {
          x: 328.75,
          y: 152,
          id: 1,
          name: "dds-sign",
          width: 150,
          height: 150,
          pageNum: 1,
        },
        {
          x: 177.75,
          y: 240,
          id: 2,
          name: "dds-select",
          width: 30,
          height: 30,
          pageNum: 1,
        },
      ],
      maxPages: 1,
      created_at: "December 13, 2023 3:38 PM",
      updated_at: "December 13, 2023 3:38 PM",
      initiator: {
        id: 1,
        email: "asadullah@econceptions.mobi",
        name: "Admin",
        mobile_number: null,
        role: {
          id: 1,
          name: "ADMIN",
        },
      },
    },
    {
      id: 24,
      name: "ahmad",
      pdf_url:
        "https://digitaldoc.econceptions.ca/server/public/uploads/templates/24.pdf",
      controls: [
        {
          x: 316.25,
          y: 134,
          id: 1,
          name: "dds-sign",
          width: 150,
          height: 150,
          pageNum: 1,
        },
      ],
      maxPages: 1,
      created_at: "December 13, 2023 3:33 PM",
      updated_at: "December 13, 2023 3:33 PM",
      initiator: {
        id: 1,
        email: "asadullah@econceptions.mobi",
        name: "Admin",
        mobile_number: null,
        role: {
          id: 1,
          name: "ADMIN",
        },
      },
    },
  ];
  interface Template {
    id: number;
    name: string;
    pdf_url: string;
    controls: {
      x: number;
      y: number;
      id: number;
      name: string;
      width: number;
      height: number;
      pageNum: number;
    }[];
    maxPages: number;
    created_at: string;
    updated_at: string;
    initiator: {
      id: number;
      email: string;
      name: string;
      mobile_number: string | null;
      role: {
        id: number;
        name: string;
      };
    };
  }
  useEffect(() => {
    if (token.length > 0) {
      getTemplateList();
    }
  }, [token]);

  const getTemplateList = async () => {
    try {
      let response = await fetchGet(token, ApiName.templateList);
      let updateAllControlsList: ITemplateList[] = [];
      if (response.status === 0) {
        updateAllControlsList = [...response.response];
        setTemplateList(updateAllControlsList);
      }
    } catch (error) {
      console.log("getTemplateList error" + error);
    }
  };

  const handleRowClick = (template: ITemplateList) => {
    console.log("Clicked on template:", template.controls);
    setPdfUrl(template.pdf_url);
    setTemplateName(template.name);
    setTemplateControls(template.controls);
    setIsTemplate(true);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  const onCloseTemplate = () => {
    // setAllControlsList([]);
    setIsTemplate(false);
    // setActiveTemplateIndex(-1);
    setPageNumber(1);
  };

  const onClickTemplateItem = (index: number) => {
    let _foundTemplate = templateList[index].controls;
    console.log("foundTemplate", _foundTemplate);
    if (templateList[index].maxPages > numPages) {
      setMessage(
        "The document contains fewer pages than the template's specified page count."
      );
      setInfo(true);
    } else {
      setTemplateControls(_foundTemplate);

      // setIsTemplate(false);
    }
  };

  const setTemplateControls = (_foundTemplate: any) => {
    console.log("setTemplateControls", _foundTemplate);
    let updateAllControlsList = [];
    for (let i = 0; i < _foundTemplate.length; i++) {
      if (_foundTemplate[i].name === "dds-txt") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-txt",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-select") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-select",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-img") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-img",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-sign") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-sign",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-dnt-sign") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-dnt-sign",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-initials") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-initials",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-radio") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-radio",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-sign-combo") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-sign-combo",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-img-combo") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-img-combo",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      } else if (_foundTemplate[i].name === "dds-dnt-combo") {
        updateAllControlsList.push({
          id: _foundTemplate[i].id,
          name: "dds-dnt-combo",
          x: _foundTemplate[i].x,
          y: _foundTemplate[i].y,
          width: _foundTemplate[i].width,
          height: _foundTemplate[i].height,
          pageNum: _foundTemplate[i].pageNum,
        });
        setSelectedTemplate(updateAllControlsList);
        //  setAllControlsList(updateAllControlsList);
      }
    }
  };

  const onTemplateItemClick = (index: number) => {
    setActiveTemplateIndex(index);
    onClickTemplateItem(index);
  };

  const onApplyTemplate = () => {
    setIsTemplate(false);
    setPageNumber(1);
    navigate(CREATEPDF, {
      state: {
        pdfUrl: pdfUrl,
        fromScreen: "SelectTemplateScreen",
        controlsList: selectedTemplate,
        templateName: templateName,
      },
    });

    // setAllControlsList(templateControlsList);
  };
  const onNextPagePressed = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  const onPreviousePressed = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredResponse = templateList.filter((template) => {
    const searchTerms =
      `${template.name} ${template.created_at} ${template.initiator.name}`.toLowerCase();
    return searchTerms.includes(searchQuery.toLowerCase());
  });
  return (
    <div>
      <SelectTemplate
        templateList={templateList}
        searchQuery={searchQuery}
        filteredResponse={filteredResponse}
        isTemplate={isTemplate}
        activeTemplateIndex={activeTemplateIndex}
        isPrivewPdf={isPrivewPdf}
        pageNumber={pageNumber}
        pdfScale={pdfScale}
        pdfUrl={pdfUrl}
        templateControlsList={templateControlsList}
        selectedTemplate={selectedTemplate}
        onCloseTemplate={onCloseTemplate}
        onLoadSuccess={onDocumentLoadSuccess}
        onNextPagePressed={onNextPagePressed}
        onPreviousePressed={onPreviousePressed}
        onTemplateItemClick={onTemplateItemClick}
        onClickTemplateItem={onClickTemplateItem}
        onApplyTemplate={onApplyTemplate}
        onChangeSearchQuery={onChangeSearchQuery}
        onRowClick={handleRowClick}
        //  response={response}
        //  onRowClick={handleRowClick}
      />
    </div>
  );
};

export default SelectTemplateScreen;
