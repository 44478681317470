import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { primaryButton } from "../../Utils/Constants";

// const ButtonTheme = () => {
export const themeCustomizer = createTheme({
  palette: {
    primary: {
      main: primaryButton, // Replace with your desired color
    },
  },
});

// };

// export default ButtonTheme;
