import React, { useState, useEffect, useContext } from "react";
import CreateUser from "../Components/CreateUser";
import { ApiName } from "../Utils/ApiNames";
import { fetchGet, fetchPost } from "../FetchApi/FetchApi";
import Swal from "sweetalert2";
import UserContext from "../Utils/UserContext";

import { rolesList } from "../Types/Types";
import { useNavigate } from "react-router-dom";
import { USERS } from "../Utils/Constants";
const CreateUserScreen = () => {
  const context = useContext(UserContext);
  const { token,isOpen,updateMenuButton } = context;
  const [showPassword, setShowPassword] = useState(false);
  const [rolesList, setRolesList] = useState<rolesList[]>([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Create User";
    if (token.length > 0) getPermissionRoles();
  }, [token]);

  const getPermissionRoles = async () => {
    try {
      let response = await fetchGet(token, ApiName.rolesList);
      if (response.status === 0) {
        setRolesList(response.response);
      } else if (response.status === 401) {
        if (response.response.toLowerCase() === "invalid or expired token") {
          // let refreshResponse = await refreshToken();
        }
      }
    } catch (error) {}
  };
  const onCreateUserPressed = async (
    name: string,
    email: string,
    password: string,
    role_id: string,
    mobile_number: string
  ) => {
    try {
      if (name && email && password && role_id && mobile_number) {
        // console.warn("name", name, email, password);
        let data = {
          name: name,
          email: email,
          password: password,
          role_id: role_id,
          mobile_number: mobile_number,
        };
        let response = await fetchPost(ApiName.createUser, token, data);
        if (response.status === 1) {
          if (response.response === "Provided email is already in use") {
            // Swal.fire({
            //   title: `${response.response}`,
            // });
            setMessage("Provided email is already in use");
          }
        } else if (response.status === 0) {
          if (response.response === "Success")
            // Swal.fire({
            //   title: `${response.response}`,
            // });
            // setMessage("User created successfully");
            navigate(USERS);
        }
        console.warn("response", response);
      }
    } catch (error) {
      console.log("onCreateUserPressed error: ", error);
      setMessage("something went wrong please try again");
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const updateMessage = () => {
    setMessage("");
  };
    const onToggleDrawer = () => {
      // setOpen(!open);
      updateMenuButton(!isOpen);
    };
  return (
    <CreateUser
      rolesList={rolesList}
      showPassword={showPassword}
      message={message}
      isOpen={isOpen}
      onCreateUserPressed={onCreateUserPressed}
      handleClickShowPassword={handleClickShowPassword}
      updateMessage={updateMessage}
      onToggleDrawer={onToggleDrawer}
    />
  );
};

export default CreateUserScreen;
