import React, { FC } from "react";
import AddRolePopUp from "./Common/PopUp/AddRolePopUp";
import AddScreenPopUp from "./Common/PopUp/AddScreenPopUp";
import {
  ArrowBack,
  InfoOutlined,
  Refresh,
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
  Typography,
} from "@mui/material";
import "../Styles/Management.scss";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import icon from "../Assets/SideMenu/icon.png";
import screenImage from "../Assets/screen.png";
import camera from "../Assets/camera.png";
import tick from "../Assets/tick.png";
import text from "../Assets/text.png";
import sign from "../Assets/sign.png";
import {
  allPermissionsData,
  allScreensList,
  controls,
  rolesList,
  screenData,
} from "../Types/Types";
import { themeCustomizer } from "./Common/ButtonTheme";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { primaryButton } from "../Utils/Constants";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
interface Management {
  rolesList: rolesList[];
  activeRoleButtons: number | null;
  isAddRole: boolean;
  modalLoading: boolean;
  // allScreenList: allPermissionsData[];
  allScreenList: allScreensList[];

  isActiveScreenIndex: boolean[];
  isAddScreen: boolean;
  isActiveScreen: number;
  controlsList: controls[];
  updateSelectedScreen: any;
  allData: any;
  message: string;
  isOpen: boolean;
  updateMessage: () => void;
  onRoleButtonPressed: (id: number, index: number) => void;
  onAddRolePressed: () => void;
  onSubmitPressed: (roleName: string) => void;
  onScreenPressed: (index: number, screenId: number) => void;
  onAddScreenPressed: () => void;
  onSubmitScreenPressed: (
    screenName: string,
    screenDescription: string
  ) => void;
  onIndividualItemPressed: (item: any, index: number) => void;
  onSelectAllPressed: () => void;
  onUpdateControls: () => void;
  onCreateUserPressed: () => void;
  onEditUserPressed: () => void;
  onSaveScreenPressed: () => void;
  onToggleDrawer:()=>void;
}

const settings = {
  // dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Mangement: FC<Management> = (props: Management) => {
  // console.log("arrya of boolean", props.isActiveScreenIndex);

  return (
    <Container maxWidth="lg" className="pdRight">
      <AddRolePopUp
        isAddRole={props.isAddRole}
        message={props.message}
        onAddRolePressed={props.onAddRolePressed}
        onSubmitPressed={props.onSubmitPressed}
        updateMessage={props.updateMessage}
      />
      {/* <AddScreenPopUp
        isAddScreen={props.isAddScreen}
        onAddScreenPressed={props.onAddScreenPressed}
        onSubmitScreenPressed={props.onSubmitScreenPressed}
      /> */}
      <div>
        <Grid
          // container
          spacing={2}
          item
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="flex">
            <IconButton
              className="menuBar"
              color="inherit"
              aria-label="open drawer"
              onClick={props.onToggleDrawer}
              edge="start"
              sx={{
                mr: 2,
                // , ...(open && { display: "none" })
              }}
            >
              {props.isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              fontSize={30}
              fontWeight={300}
              marginTop={2}
              marginBottom={3}
              color={"#7A86A1"}
              className="d-head"
            >
              Role Management
            </Typography>
          </div>
          <Grid item sm={12} lg={12} md={12} xl={12} style={{}}>
            <Stack direction="row">
              <Box
                sx={{
                  width: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
                  height: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
                  borderRadius: "20px",
                  backgroundColor: "white",
                  borderWidth: "10px",
                  border: "2px solid #F0F2F6",
                  // marginLeft: { sm: 10, md: 5, lg: 4, xl: 4 },
                }}
                style={{ width: "100%" }}
              >
                {/*role selection areas */}

                <div style={{ height: "100%", padding: 10 }}>
                  <div className="displayRoleContainer">
                    <Typography fontSize={20} color={"#4A4A4A"}>
                      List of Roles
                    </Typography>
                    <IconButton
                      style={{
                        borderRadius: 10,
                        fontSize: 18,
                        color: "#A89BB9",
                      }}
                      onClick={props.onAddRolePressed}
                    >
                      <Add /> Add Role
                    </IconButton>
                  </div>
                  <div className="displayRoleContainer roleContainerList">
                    <div className="roleListContainer">
                      {props.rolesList.length > 0 ? (
                        props.rolesList.map((item, index) => (
                          <ThemeProvider theme={themeCustomizer} key={index}>
                            <Button
                              className="roles-slide"
                              variant={
                                props.activeRoleButtons === item.id
                                  ? "contained"
                                  : "outlined"
                              }
                              disabled={props.allData.length > 0 ? false : true}
                              color="primary"
                              // style={{
                              //   borderRadius: "10px",
                              //   marginTop: 10,
                              //   zIndex: 1,
                              //   marginLeft: 5,
                              // }}
                              onClick={() =>
                                props.onRoleButtonPressed(item.id, index)
                              }
                            >
                              <span style={{}}>{item.name}</span>
                            </Button>
                          </ThemeProvider>
                        ))
                      ) : (
                        <div>
                          <span>Currently no roles added</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*screen selection areas */}
                  <div style={{ marginTop: 10, padding: "0 10px" }}>
                    <Typography fontSize={15} color="#7A86A1" marginBottom={1}>
                      Select Screen
                    </Typography>
                    <div className="screenContainer">
                      <Typography
                        fontSize={12}
                        color="#7A86A1"
                        marginBottom={2}
                      >
                        Choose a screen to set permissions for the selected role
                      </Typography>
                      {/* <IconButton onClick={props.onAddScreenPressed}>
                      <Add /> Screen
                    </IconButton> */}
                    </div>
                    <Slider {...settings}>
                      {props.allScreenList.length > 0 ? (
                        props.allScreenList.map(
                          (item: allScreensList, index: number) => (
                            <div
                              onClick={() =>
                                props.onScreenPressed(index, item.id)
                              }
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              {props.isActiveScreenIndex[index] === true ? (
                                <div
                                  style={{
                                    backgroundColor: "#F6F4FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    borderRadius: 10,
                                    padding: 15,
                                    marginLeft: 15,
                                  }}
                                >
                                  <img
                                    src={screenImage}
                                    style={{ width: "150px" }}
                                  />
                                  <p className="d-text">{item.name}</p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: "transparent",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    borderRadius: 10,
                                    padding: 15,
                                    marginLeft: 15,
                                  }}
                                >
                                  <img
                                    src={screenImage}
                                    style={{ width: "150px" }}
                                  />
                                  <p className="d-text">{item.name}</p>
                                </div>
                              )}
                            </div>
                          )
                        )
                      ) : (
                        <div style={{ marginLeft: 10 }}>
                          <span> Currently no screens exists</span>
                        </div>
                      )}
                    </Slider>
                    {props.updateSelectedScreen.screenList.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "end",
                          marginTop: 10,
                          width: "auto",
                        }}
                      >
                        <ThemeProvider theme={themeCustomizer}>
                          <Button
                            variant={"contained"}
                            color="primary"
                            style={{
                              borderRadius: "10px",
                              zIndex: 1,
                              padding: "8px 30px",
                            }}
                            onClick={props.onSaveScreenPressed}
                          >
                            <span style={{}}>Save</span>
                          </Button>
                        </ThemeProvider>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Mangement;
