import React, { FC, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { themeCustomizer } from "../ButtonTheme";
import "../../../Styles/DragDrop.scss";
import { AssignedWorkFlowControl } from "../../../Types/Types";
interface DragDropImage {
  isCamera: boolean;
  fileInputRef: any;
  index: number;
  imageSrc: any;
  isDragging: boolean;
  itemId: number;
  controls: AssignedWorkFlowControl[];
  //   message: string;
  onCloseCamera: () => void;
  onSubmitPressed: (index: number, id: number) => void;
  handleFileInputChange: (val: any, index: number) => void;
  deleteImage: () => void;
  //   updateMessage: () => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onUploadImagePressed: (index: number) => void;
}

const DragDropImage: FC<DragDropImage> = (props: DragDropImage) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const selectFile = () => {
    props.fileInputRef.current.click();
  };

  return (
    <Modal
      open={props.isCamera}
      onClose={props.onCloseCamera}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: 480,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="card">
          <div className="top">
            <p>Drag & Drop uploading</p>
          </div>
          <div
            className="drag-area"
            onDragOver={props.onDragOver}
            onDragLeave={props.onDragLeave}
            onDrop={props.onDrop}
          >
            {props.isDragging ? (
              <span className="select">Drag image here</span>
            ) : (
              <>
                Drag & Drop image here or
                <span className="select" role="button" onClick={selectFile}>
                  Browse
                </span>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              className="file"
              onChange={(val) => props.handleFileInputChange(val, props.index)}
              // ref={props.fileInputRef}
              ref={props.fileInputRef}
            />
          </div>
          {/* <div className="container">
            {props.imageSrc && (
              <div className="image">
                <span className="delete" onClick={() => props.deleteImage()}>
                  &times;
                </span>
                <img src={props.imageSrc} alt="" />
              </div>
            )}
          </div> */}
          <button
            type="button"
            // disabled={props.fileInputRef.current !== null ? false : true}
            style={{
              marginTop: 10,
              // backgroundColor:
              //   props.fileInputRef.current !== null ? "#6149cd" : "lightgrey",
            }}
            onClick={() => props.onUploadImagePressed(props.index)}
          >
            Upload
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default DragDropImage;
