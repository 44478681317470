import React, { useState, useEffect, useContext } from "react";
import EditUser from "../Components/Edit";
import UserContext from "../Utils/UserContext";
import { fetchGet } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import { useLocation } from "react-router-dom";

const EditScreen = () => {
  const context = useContext(UserContext);
  const { state } = useLocation();
  const { token } = context;
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    console.log("state: " + state);
    if (state.userList !== null) {
      getUserList();
      setRoleList(state.rolesList);
    }
    document.title = "Edit User";
  }, []);
  const getUserList = async () => {
    try {
      let resp = await fetchGet(token, ApiName.userList);
      if (resp.status === 0) {
        setUserList(resp.response);
      }
    } catch (error) {
      console.log("getUserList error: ", error);
    }
  };
  return <EditUser userList={userList} roleList={roleList} />;
};

export default EditScreen;
