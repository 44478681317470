import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { fetchGet, fetchPost, fetchPostFormData } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import Loader from "../Components/Common/Loader";
import InfoPopUp from "../Components/Common/PopUp/InfoPopUp";
import { DASHBOARD, FORGOTPASSWORD, LOGIN, REGISTER } from "../Utils/Constants";
import VeirfyEmail from "../Components/VeirfyEmail";
import UserContext from "../Utils/UserContext";
import Swal from "sweetalert2";
// let _userEmail: string = "";
let _userPassword: string = "";
const VerifyEmailScreen = () => {
  const context = useContext(UserContext);
  const { userName, updateUserName, token, updateToken } = context;
  // const [name, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerificiation, setIsVerification] = useState(false);
  const [message, setMessage] = useState("");
  const [_userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    document.title = "Verification";
    if (state !== null) {
      console.log("states", state);
      // _userEmail = state.email;
      setUserEmail(state.email);
      _userPassword = state.password;
      setErrorMessage("");
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const onSignInPressed = async (userEmail: string, userPassword: string) => {
    try {
      console.warn("onSignInPressed", userEmail, userPassword);
      if (userEmail && userPassword) {
        // _userEmail = userEmail;
        setUserEmail(userEmail);
        _userPassword = userPassword;
        let data = {
          username: userEmail,
          password: userPassword,
        };
        let response = await fetchPost(ApiName.login, "", data);
        console.warn("response", response);
        if (response.status === 0) {
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("token", response.response.jwttoken);
          localStorage.setItem("userName", response.response.name);
          localStorage.setItem("userEmail", response.response.email);
          localStorage.setItem("mobileNumber", response.response.mobileNumber);
          let roles = [response.response.role];
          localStorage.setItem("role", JSON.stringify(roles));
          localStorage.setItem("userId", JSON.stringify(response.response.id));
          updateUserName(response.response.name);
          updateToken(response.response.jwttoken);
          navigate(DASHBOARD);
          // window.location.reload();
        } else if (response.status === 1) {
          if (
            response.response.toLowerCase() === "email verification pending"
          ) {
            // setErrorMessage("Email verification is pending");
            setIsVerification(true);
          } else {
            setErrorMessage("Invalid credentials or accound doesn't exist");
          }
        }
      }
    } catch (error) {
      console.log("onSignInPressed", error);
    }
  };
  const onForgotPasswordClick = () => {
    console.warn("pressed");
    navigate(FORGOTPASSWORD);
  };
  const onRegisterClick = () => {
    console.warn("pressed");
    navigate(REGISTER);
  };
  const updateErrorMessage = () => {
    setErrorMessage("");
    setMessage("");
  };

  const verifyEmail = async (pin: string) => {
    try {
      let data = {
        email: _userEmail,
        pin: pin,
      };
      console.log("this is verifyEmail payload", data);
      setMessage("");
      let response = await fetchPost(ApiName.verifyEmail, "", data);
      if (response.status === 0) {
        await onSignInPressed(_userEmail, _userPassword);
      } else if (response.status === 1) {
        if (response.response === "Invalid") {
          setErrorMessage("Please enter valid pin");
        } else if (response.response === "Expired") {
          setErrorMessage("Verification code expired");
        }
      }
    } catch (error) {
      console.log("verifyEmail", error);
      setMessage("");
    }
  };

  const onResendPinPressed = async () => {
    try {
      let data = {
        email: _userEmail,
        password: _userPassword,
      };
      console.log("onResendPinPressed", data);
      setErrorMessage("");
      let response = await fetchPost(ApiName.resendPin, "", data);
      if (response.status === 0) {
        setMessage("A verification code is sent to your email");
      } else if (response.status === 1) {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      console.log("onResendPinPressed", error);
    }
  };

  const onChangeEmailPressed = async (_newEmail: string) => {
    try {
      updateErrorMessage();
      let data = {
        email: _userEmail,
        password: _userPassword,
        newEmail: _newEmail,
      };
      console.log("onChangeEmailPressed data", data);
      let response = await fetchPost(ApiName.changeEmail, "", data);
      if (response.status === 0) {
        // _userEmail = _newEmail;
        setUserEmail(_newEmail);
        setMessage("A verification code is sent to your email");
      } else if (response.status === 1) {
        if (response.response === "Provided email is already in use")
          setErrorMessage("Provided email is already in use");
        else if ((response.response = "Invalid credentials")) {
          setErrorMessage("Invalid credentials");
        }
      }
    } catch (error) {
      console.log("onChangeEmailPressed", error);
    }
  };
  const onChangeEmail = () => {
    Swal.fire({
      title: "Enter Your New Mail",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Save",
      showLoaderOnConfirm: true,

      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        onChangeEmailPressed(result.value);
      }
    });
  };
  return (
    <>
      <VeirfyEmail
        userEmail={_userEmail}
        showPassword={showPassword}
        errorMessage={errorMessage}
        isVerificiation={isVerificiation}
        message={message}
        handleClickShowPassword={handleClickShowPassword}
        handleMouseDownPassword={handleMouseDownPassword}
        onSignInPressed={onSignInPressed}
        onForgotPasswordClick={onForgotPasswordClick}
        onRegisterClick={onRegisterClick}
        updateErrorMessage={updateErrorMessage}
        verifyEmail={verifyEmail}
        onResendPinPressed={onResendPinPressed}
        onChangeEmailPressed={onChangeEmailPressed}
        onChangeEmail={onChangeEmail}
      />
    </>
  );
};

export default VerifyEmailScreen;
