import React, { FC, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { themeCustomizer } from "./Common/ButtonTheme";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Edit, Delete, Done, Save, Cancel } from "@mui/icons-material";
import { CREATEUSER, primaryButton, primaryHeading } from "../Utils/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { rolesList } from "../Types/Types";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
interface User {
  userList: string[];
  edit: boolean;
  isOpen: boolean;
  currentItemIndex: number;
  editableData: any;
  rolesList: rolesList[];
  onEditPressed: (index: number) => void;
  onDiscradRequestPressed: () => void;
  onUpdatePressed: (value: any) => void;
  onToggleDrawer: () => void;
}

const Users: FC<User> = (props: User) => {
  // console.warn("editable", props.editableData);
  const navigate = useNavigate();
  interface FormValue {
    email: string;
    name: string;
    role: string;
    id: number;
    mobile_number: string;
  }
  const validate2 = Yup.object().shape({
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters"),
    role: Yup.string().required("Please select a new  role"),
    mobile_number: Yup.string()
      .matches(
        /^\+1[0-9]{10}$/,
        "phone number must start with +1 and contains 10 digits after"
      )
      .required("Please add a phone number"),
  });
  const formik2 = useFormik({
    initialValues: {
      name: props.editableData.name,
      email: props.editableData.email,
      mobile_number: props.editableData.mobile_number,
      role: props.editableData.role.name,
      id: props.editableData.role.id,
    },
    validationSchema: validate2,
    onSubmit: (values: FormValue) => {
      props.onUpdatePressed(values);
    },
  });

  useEffect(() => {
    formik2.setFieldValue("email", props.editableData.email, false);
    formik2.setFieldValue("name", props.editableData.name, false);
    formik2.setFieldValue("role", props.editableData.role.name, false);
    formik2.setFieldValue("id", props.editableData.role.id, false);
    formik2.setFieldValue(
      "mobile_number",
      props.editableData.mobile_number,
      false
    );

    console.log("brooo", props.editableData.role.id);
  }, [props.editableData]);

  return (
    <Container maxWidth="lg" className="pdRight">
      <div>
        <Grid
          // container
          spacing={2}
          item
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="flex">
            <IconButton
              className="menuBar"
              color="inherit"
              aria-label="open drawer"
              onClick={props.onToggleDrawer}
              edge="start"
              sx={{
                mr: 2,
                // , ...(open && { display: "none" })
              }}
            >
              {props.isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              fontSize={30}
              fontWeight={300}
              marginTop={2}
              marginBottom={3}
              color={"#7A86A1"}
              className="d-head"
            >
              Users
            </Typography>
          </div>
        </Grid>

        <Grid item sm={12} lg={12} md={12} xl={12}>
          <Stack direction="row" spacing={2}>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "100%" }}
            >
              <div style={{ textAlign: "right" }}>
                <ThemeProvider theme={themeCustomizer}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: "50px",
                      padding: "8px 25px",
                      marginBottom: 20,
                      zIndex: 1,
                    }}
                    onClick={() => navigate(CREATEUSER)}
                  >
                    <p> Add User</p>
                  </Button>
                </ThemeProvider>
              </div>
              <div className="tableInnerWrapper table-info">
                <table className="d-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th>Role</th>
                      <th className="t-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.userList.length > 0
                      ? props.userList.map((user: any, index: number) => {
                          return props.currentItemIndex === index ? (
                            <>
                              <tr>
                                <td>
                                  <div className="td-name">Name</div>
                                  <input
                                    name="name"
                                    onChange={formik2.handleChange}
                                    value={formik2.values.name}
                                    type="text"
                                    placeholder="name"
                                    // className={
                                    //   props.error ? "error_td" : "td"
                                    // }
                                  />
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      lineHeight: "15px",
                                      color: "rgb(253, 60, 79)",
                                    }}
                                  >
                                    {formik2.errors.name}
                                  </p>
                                </td>
                                <td>
                                  <div className="td-name">Email</div>
                                  <input
                                    name="email"
                                    onChange={formik2.handleChange}
                                    value={formik2.values.email}
                                    type="text"
                                    placeholder="email"
                                    // className={
                                    //   props.error ? "error_td" : "td"
                                    // }
                                  />
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      lineHeight: "15px",
                                      color: "rgb(253, 60, 79)",
                                    }}
                                  >
                                    {formik2.errors.email}
                                  </p>
                                </td>
                                <td>
                                  <div className="td-name">Phone No</div>
                                  <input
                                    name="mobile_number"
                                    onChange={formik2.handleChange}
                                    value={formik2.values.mobile_number}
                                    type="text"
                                    placeholder="mobile_number"
                                    maxLength={12}
                                    // className={
                                    //   props.error ? "error_td" : "td"
                                    // }
                                  />
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      lineHeight: "15px",
                                      color: "rgb(253, 60, 79)",
                                    }}
                                  >
                                    {formik2.errors.mobile_number}
                                  </p>
                                </td>

                                <td>
                                  <div className="td-name">Role</div>

                                  <TextField
                                    style={{ width: "100%", marginTop: 5 }}
                                    select
                                    size="small"
                                    value={formik2.values.id}
                                    name="id"
                                    placeholder="Select role"
                                    onChange={formik2.handleChange}
                                    error={
                                      formik2.errors.role &&
                                      formik2.touched.role
                                        ? true
                                        : false
                                    }
                                    InputProps={{
                                      style: {
                                        borderRadius: "20px",
                                        zIndex: 1,
                                      },
                                    }}
                                  >
                                    <MenuItem value={0} disabled selected>
                                      Select
                                    </MenuItem>

                                    {props.rolesList.length > 0 &&
                                      props.rolesList
                                        .filter(
                                          (item) =>
                                            item.id !==
                                            props.editableData.role.id
                                        )
                                        .map((item) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                  </TextField>

                                  <p
                                    style={{
                                      fontSize: "10px",
                                      lineHeight: "15px",
                                      color: "rgb(253, 60, 79)",
                                    }}
                                  >
                                    {formik2.errors.role}
                                  </p>
                                </td>
                                <td>
                                  <div className="td-name">Action</div>
                                  <a>
                                    <ListItemIcon
                                      className="icon"
                                      onClick={() => formik2.handleSubmit()}
                                    >
                                      <Done
                                        style={{
                                          color: "#6149cd",
                                        }}
                                      />
                                    </ListItemIcon>
                                  </a>
                                  <a>
                                    <ListItemIcon
                                      className="icon"
                                      onClick={() =>
                                        props.onDiscradRequestPressed()
                                      }
                                    >
                                      <Cancel
                                        style={{
                                          color: "#ff3030",
                                        }}
                                      />
                                    </ListItemIcon>
                                  </a>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td>
                                <div className="td-name">Name</div>
                                {user.name}
                              </td>
                              <td>
                                <div className="td-name">Email</div>
                                {user.email}
                              </td>
                              <td>
                                <div className="td-name">Phone No</div>
                                {user.mobile_number}
                              </td>
                              <td>
                                <div className="td-name">Role</div>
                                {user.role.name}
                              </td>
                              <td>
                                <div className="td-name">Action</div>
                                <div className="actions">
                                  <a>
                                    <ListItemIcon
                                      className="icon"
                                      onClick={() => props.onEditPressed(index)}
                                    >
                                      <Edit
                                        style={{
                                          color: "#6149cd",
                                        }}
                                      />
                                    </ListItemIcon>
                                  </a>
                                  <a>
                                    <ListItemIcon className="icon">
                                      <Delete
                                        style={{
                                          color: "#ff3030",
                                        }}
                                      />
                                    </ListItemIcon>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Stack>
        </Grid>
      </div>
    </Container>
  );
};

export default Users;
