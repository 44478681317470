import React, { useContext, useEffect, useState } from "react";
import Mangement from "../Components/Mangement";
import { ApiName } from "../Utils/ApiNames";
import { fetchGet, fetchPost, fetchPut } from "../FetchApi/FetchApi";
import {
  allPermissionsData,
  controls,
  rolesList,
  allScreensList,
} from "../Types/Types";
import Swal from "sweetalert2";
import UserContext from "../Utils/UserContext";
import { useNavigate } from "react-router-dom";
import { CREATEUSER, EDITUSER } from "../Utils/Constants";
import { TempleHinduSharp } from "@mui/icons-material";
const MangementScreen = () => {
  const context = useContext(UserContext);
  const { token,isOpen,updateMenuButton } = context;
  const [rolesList, setRolesList] = useState<rolesList[]>([]);
  const [activeRoleButtons, setActiveRoleButtons] = useState<number | null>(
    null
  );
  const [screenList, setScreenList] = useState([]);
  const [isAddRole, setIsAddRole] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [signedInRole, setSignedInRole] = useState([]);
  // const [allScreenList, setAllScreenList] = useState<allPermissionsData[]>([]);
  const [isActiveScreenIndexList, setIsActiveScreenIndexList] = useState<
    boolean[]
  >([]);
  const [roleId, setActiveRoleId] = useState(-1);
  const [isAddScreen, setAddScreen] = useState(false);
  const [controlsList, setControlsList] = useState<controls[]>([]);
  const [isActiveScreen, setIsActiveScreen] = useState<number>(-1);
  const [updateData, setUpdateData] = useState<any>([]);
  const [allScreenList, setAllScreenList] = useState<allScreensList[]>([]);
  const [allData, setAllData] = useState<allPermissionsData[]>([]);
  const [updateSelectedScreen, setUpdateSelectedScreen] = useState<any>({
    roleId: -1,
    screenList: [],
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (token.length > 0) {
      getPermissionRoles();
    }
    // getPremissionScreenList();
  }, [token]);

  useEffect(() => {
    console.log("updateSelectedScreen", updateSelectedScreen);
  }, [updateSelectedScreen]);

  const getPermissionRoles = async () => {
    try {
      let response = await fetchGet(token, ApiName.rolesList);
      if (response.status === 0) {
        setRolesList(response.response);
        setActiveRoleButtons(response.response[0].id);
        // getPremissionScreenList();
        getAllScreensList(response.response[0].id);
      } else if (response.status === 401) {
        if (response.response.toLowerCase() === "invalid or expired token") {
          // let refreshResponse = await refreshToken();
        }
      }
    } catch (error) {}
  };

  const getAllScreensList = async (id: number) => {
    try {
      let response = await fetchGet(token, ApiName.allScreens);
      if (response.status === 0) {
        setAllScreenList(response.response);
        let tempActiveIndexList = [];
        for (let i = 0; i < response.response.length; i++) {
          tempActiveIndexList.push(false);
        }
        setIsActiveScreenIndexList(tempActiveIndexList);
        getAllData(response.response, tempActiveIndexList, id);
      }
    } catch (error) {
      console.log("getAllScreensList error", error);
    }
  };
  const getAllData = async (
    allScreenList: allScreensList[],
    activeScreens: any,
    id: number
  ) => {
    try {
      let response = await fetchGet(token, ApiName.allData);
      if (response.status === 0) {
        setAllData(response.response);
        let temp = [...activeScreens];
        let tempScreens = response.response[1].screens;
        let tempUpdateOject: { roleId: number; screenList: number[] } = {
          roleId: -1,
          screenList: [],
        };
        tempUpdateOject.roleId = id;
        for (let i = 0; i < tempScreens.length; i++) {
          for (let j = 0; j < allScreenList.length; j++) {
            if (tempScreens[i].id === allScreenList[j].id) {
              temp[j] = true;
              tempUpdateOject.screenList.push(allScreenList[j].id);
            }
          }
        }
        setUpdateSelectedScreen(tempUpdateOject);
        setIsActiveScreenIndexList(temp);
      }
    } catch (error) {
      console.log("getAllData error", error);
    }
  };

  const refreshToken = async () => {
    try {
      let response = await fetchGet(token, "");
    } catch (error) {
      console.log("refreshToken error: " + error);
    }
  };

  const onRoleButtonPressed = async (id: number, index: number) => {
    if (activeRoleButtons !== id) {
      setActiveRoleButtons(id);
      // filterArray(id);
      let temp = [...isActiveScreenIndexList];
      for (let i = 0; i < isActiveScreenIndexList.length; i++) {
        temp[i] = false;
      }

      console.log("tempactiveScreenIndexList", temp);
      let tempUpdateOject: { roleId: number; screenList: number[] } = {
        roleId: -1,
        screenList: [],
      };
      // let tempScreens = allData[index + 1].screens;
      let _index = allData.findIndex((item) => item.id === id);
      let tempScreens = allData[_index].screens;
      tempUpdateOject.roleId = id;
      console.log("tempScreens", tempScreens, "allScreenList", allScreenList);
      for (let i = 0; i < tempScreens.length; i++) {
        for (let j = 0; j < allScreenList.length; j++) {
          if (tempScreens[i].id === allScreenList[j].id) {
            console.log("id", tempScreens[i].id, allScreenList[j].id);
            temp[j] = true;
            tempUpdateOject.screenList.push(allScreenList[j].id);
          }
        }
      }
      console.log("tempUpdateOject", tempUpdateOject);
      setUpdateSelectedScreen(tempUpdateOject);
      setIsActiveScreenIndexList(temp);
    }
    // setControlsList([]);
    // setUpdateData([]);
    // setIsActiveScreen(-1);
  };

  const onAddRolePressed = () => {
    setIsAddRole(!isAddRole);
    setMessage("");
  };

  const onSubmitPressed = async (roleName: string) => {
    try {
      // onAddRolePressed();
      if (roleName) {
        let data = {
          name: roleName,
        };
        let response = await fetchPost(`${ApiName.addRole}`, token, data);
        if (response.status === 0) {
          getPermissionRoles();
          // Swal.fire("Role added");
          setMessage("Role added successfully");
        } else if (response.status === 1) {
          if (response.response === "Role already exists with provided name") {
            // Swal.fire(`${response.response}`);
            setMessage("Role already exists");
          }
        } else {
          // Swal.fire("Something went wrong please try again");
          setMessage("Something went wrong please try again");
        }
      }
    } catch (error) {
      console.log("onSubmitPressed error: " + error);
      // Swal.fire("Something went wrong please try again");
      setMessage("Something went wrong please try again");
    }
  };

  const onScreenPressed = (index: number, screenId: number) => {
    let _temp = [...isActiveScreenIndexList];
    _temp[index] = !isActiveScreenIndexList[index];
    setIsActiveScreenIndexList(_temp);
    let tempUpdateOject = updateSelectedScreen;
    let res = tempUpdateOject.screenList.findIndex(
      (x: number) => x === screenId
    );
    if (res !== -1) {
      tempUpdateOject.screenList.splice(res, 1);
    } else {
      tempUpdateOject.screenList.push(screenId);
    }
    setUpdateSelectedScreen(tempUpdateOject);
  };

  const onAddScreenPressed = () => {
    setAddScreen(!isAddScreen);
  };

  const onSubmitScreenPressed = (
    screenName: string,
    screenDescription: string
  ) => {};

  const onIndividualItemPressed = (item: any, index: number) => {
    let tempArray = [...controlsList];
    let temp = {
      id: item.id,
      name: item.name,
      description: item.description,
      enabled: !item.enabled,
    };
    tempArray[index] = item;
    setControlsList(tempArray);
    tempArray[index] = temp;
    let tempUpdateData = [...updateData];
    if (tempUpdateData.length > 0) {
      let res = tempUpdateData.findIndex((x) => x.controlId === item.id);
      console.log("res", res);
      if (res !== -1) {
        tempUpdateData[res].enabled = !tempUpdateData[res].enabled;
      } else {
        tempUpdateData.push({
          controlId: tempArray[index].id,
          enabled: tempArray[index].enabled,
        });
      }
    } else {
      tempUpdateData.push({
        controlId: tempArray[index].id,
        enabled: tempArray[index].enabled,
      });
    }

    setUpdateData(tempUpdateData);
  };

  const onSelectAllPressed = () => {
    let tempArray = JSON.parse(JSON.stringify(controlsList));
    let tempUpdateData = [];
    for (let i = 0; i < tempArray.length; i++) {
      tempArray[i].enabled = true;
      tempUpdateData[i] = { controlId: tempArray[i].id, enabled: true };
    }
    console.warn(tempArray);
    setControlsList(tempArray);
    setUpdateData(tempUpdateData);
  };

  const onUpdateControls = async () => {
    try {
      console.log("onUpdateControls", updateData);
      let response = await fetchPut(token, ApiName.updateControls, updateData);
      if (response.status === 0) {
        if (response.response === "Success") {
          let tempArray = JSON.parse(JSON.stringify(allScreenList));
          if (roleId !== -1) {
            let res = tempArray.findIndex((x: any) => x.id === roleId);
            if (res !== -1) {
              let res2 = tempArray[res].screens.findIndex(
                (x: any) => x.id === isActiveScreen
              );
              if (res2 !== -1) {
                console.log("res2", tempArray[res].screens[res2].controls);
                tempArray[res].screens[res2].controls = controlsList;
              }
            }
          }
          console.warn("tempArray", tempArray);
          setAllScreenList(tempArray);
        }
      }
      console.log("response", response);
    } catch (e) {
      console.log("onUpdateControls error", e);
    }
  };

  const onCreateUserPressed = () => {
    navigate(CREATEUSER);
  };

  const onEditUserPressed = () => {
    navigate(EDITUSER, {
      state: {
        rolesList: rolesList,
      },
    });
  };
  const onSaveScreenPressed = async () => {
    try {
      let data = {
        role_id: updateSelectedScreen.roleId,
        screen_ids: updateSelectedScreen.screenList,
      };
      let response = await fetchPut(token, `permission/linkRoleScreens`, data);
      if (response.status === 0) {
        setUpdateSelectedScreen({
          roleId: -1,
          screenList: [],
        });
        getPermissionRoles();
      }
    } catch (error) {
      console.log("onSaveScreenPressed", error);
    }
  };
  const updateMessage = () => {
    setMessage("");
  };
 const onToggleDrawer = () => {
   // setOpen(!open);
   updateMenuButton(!isOpen);
 };
  return (
    <Mangement
      rolesList={rolesList}
      activeRoleButtons={activeRoleButtons}
      isAddRole={isAddRole}
      modalLoading={modalLoading}
      allScreenList={allScreenList}
      isActiveScreenIndex={isActiveScreenIndexList}
      isAddScreen={isAddScreen}
      isActiveScreen={isActiveScreen}
      controlsList={controlsList}
      updateSelectedScreen={updateSelectedScreen}
      allData={allData}
      message={message}
      isOpen={isOpen}
      onRoleButtonPressed={onRoleButtonPressed}
      onAddRolePressed={onAddRolePressed}
      onSubmitPressed={onSubmitPressed}
      onScreenPressed={onScreenPressed}
      onAddScreenPressed={onAddScreenPressed}
      onSubmitScreenPressed={onSubmitScreenPressed}
      onIndividualItemPressed={onIndividualItemPressed}
      onSelectAllPressed={onSelectAllPressed}
      onUpdateControls={onUpdateControls}
      onCreateUserPressed={onCreateUserPressed}
      onEditUserPressed={onEditUserPressed}
      onSaveScreenPressed={onSaveScreenPressed}
      updateMessage={updateMessage}
      onToggleDrawer={onToggleDrawer}
    />
  );
};

export default MangementScreen;
