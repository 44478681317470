import React, { FC, useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { themeCustomizer } from "./Common/ButtonTheme";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Edit, Delete } from "@mui/icons-material";
import { primaryButton, primaryHeading } from "../Utils/Constants";
import { IStatsCount, WorkFlow } from "../Types/Types";
import InfoPopUp from "./Common/PopUp/InfoPopUp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import searchicon from "../Assets/search-icon.png";
import prevnext from "../Assets/prev-next.png";
import ReactPaginate from "react-paginate";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import { Visibility } from "@mui/icons-material";
import PreviewInfo from "./Common/PopUp/PreviewInfo";
import TrackingInfo from "./Common/PopUp/TrackingInfo";
import { timeConvertor } from "../Utils/commonFunction";
import "../Styles/Dashboard.scss";

interface Reports {
  userList: any;
  workFLowData: WorkFlow[];
  pageCount: number;
  currentPage: number;
  startIndex: number;
  endIndex: number;
  filterData: WorkFlow[];
  errorMessage: string;
  isPreviewPdf: boolean;
  privewWorkFlowPdf: string;
  pageNumber: number;
  scale: number;
  isInfo: boolean;
  message: string;
  isOpen: boolean;
  roles: string;
  stats: IStatsCount;
  data1: any;
  totalUsers: number;
  onPageChange: (index: number) => void;
  onSearchItem: (val: string) => void;
  //   onPreviewPdfPressed: (index: number, pdf: string) => void;
  //   onClosePreviewPdf: () => void;
  //   onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  //   onEditPressed: (index: number) => void;
  onOkPressed: () => void;
  onInfoClosedPressed: () => void;
  onToggleDrawer: () => void;
  //   onNextPagePressed: () => void;
  //   onPreviousePressed: () => void;
  openTrackingPopUp: boolean;
  setOpenTrackingPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  onClosePressed: () => void;
  popIndex: number | undefined;
  setPopIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  trackingInfoData: any;
  handleTrackingInfo: (id: any, title: string) => Promise<void>;
  trackingData: any;
  openModal: boolean;
  handleOpen: (index: number) => void;
  handleClose: () => void;
  workFlowLog: any[] | undefined;
  isLoading: boolean;
  handlePreviewInfo: (id: any, title: string) => Promise<void>;
  openTrackingModal: boolean;
  OpenTrackingModal: (index: number) => void;
  CloseTrackingModal: () => void;
  tackingPopup: number | undefined;
  setTrackingPopup: React.Dispatch<React.SetStateAction<number | undefined>>;
  isLoading2: boolean;
  documentName: string;
}
const Reports: FC<Reports> = (props: Reports) => {
  return (
    <Container maxWidth="lg" className="nopadding">
      <div>
        <Grid
          // container
          spacing={2}
          item
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <div className="flex">
            <IconButton
              className="menuBar"
              color="inherit"
              aria-label="open drawer"
              onClick={props.onToggleDrawer}
              edge="start"
              sx={{
                mr: 2,
                // , ...(open && { display: "none" })
              }}
            >
              {props.isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              fontSize={25}
              fontWeight={300}
              marginTop={2}
              marginBottom={2}
              color={"#7A86A1"}
              className="d-head"
            >
              Dashboard
            </Typography>
          </div>
        </Grid>

        <Grid item sm={12} lg={12} md={12} xl={12}>
          <div className="row" style={{ columnGap: "12px" }}>
            {props.roles === "admin" && (
              <Grid item sm={12} md={12} lg={4} xl={4}>
                <div className="d-cart">
                  <div className="title">Total Users</div>
                  <div className="number">{props.totalUsers}</div>
                </div>
              </Grid>
            )}

            <Grid item sm={12} md={12} lg={4} xl={4}>
              <div className="d-cart">
                <div className="title">Total Workflows Created</div>
                <div className="number">
                  {props.stats.closedCount + props.stats.openCount}
                </div>
              </div>
            </Grid>

            <Grid item sm={12} md={12} lg={4} xl={4}>
              <div className="d-cart">
                <div className="title">Pending Workflows</div>
                <div className="number">{props.stats.openCount}</div>
              </div>
            </Grid>

            <Grid item sm={12} md={12} lg={4} xl={4}>
              <div className="d-cart">
                <div className="title">Completed Workflows</div>
                <div className="number"> {props.stats.closedCount}</div>
              </div>
            </Grid>
          </div>

          {/* <Stack direction="row" className="row-rev"> */}
          <div className="row row-rev">
            <Grid item sm={12} md={12} lg={8} xl={8}>
              <Typography
                fontSize={25}
                fontWeight={300}
                marginTop={2}
                marginBottom={2}
                color={"#7A86A1"}
              >
                Recent Work flows
              </Typography>
              <div className="tableInnerWrapper">
                <div className="search-wraper">
                  <input
                    type="text"
                    placeholder="Search"
                    className="search__input"
                    onChange={(val) => props.onSearchItem(val.target.value)}
                  />
                  <img src={searchicon} alt="" className="search-icon" />
                </div>

                <div className="table-info">
                  <table className="d-table">
                    <thead>
                      <tr>
                        <th style={{ width: "170px" }}>Title</th>
                        <th>Initiator</th>
                        <th>Last Updated</th>
                        <th>Tracking</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {props.workFLowData.length === 0 &&
                    props.filterData.length === 0 &&
                    props.errorMessage.length === 0 ? (
                      <>
                        {" "}
                        <tbody>
                          <tr className="norecord">
                            <td colSpan={7} style={{ textAlign: "center" }}>
                              No Record Found
                            </td>
                          </tr>
                        </tbody>
                      </>
                    ) : (
                      <>
                        {" "}
                        <tbody>
                          {props.workFLowData.length > 0 &&
                            props.filterData.length === 0 &&
                            props.errorMessage.length === 0 &&
                            props.workFLowData
                              .slice(props.startIndex, props.endIndex)
                              .map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td className="itemtitle">
                                      <div className="td-name">Title:</div>
                                      {!props.isLoading ? (
                                        <span
                                          className="tittleSpan"
                                          onClick={() => {
                                            if (props.openModal === false) {
                                              props.handleTrackingInfo(
                                                item.id,
                                                item.title
                                              );
                                              props.setPopIndex(index);
                                            }
                                          }}
                                        >
                                          {item.title}
                                          {props.popIndex === index &&
                                          props.openModal ? (
                                            <PreviewInfo
                                              trackingData={props.trackingData}
                                              handleClose={props.handleClose}
                                              openModal={props.openModal}
                                              documentName={props.documentName}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      ) : props.popIndex === index ? (
                                        <CircularProgress
                                          style={{
                                            width: 25,
                                            height: 25,
                                            color: "purple",
                                          }}
                                        />
                                      ) : (
                                        item.title
                                      )}
                                    </td>
                                    <td>
                                      <div className="td-name">Initiator:</div>
                                      {item.initiator.name}
                                    </td>
                                    <td>
                                      <div className="td-name">Updated At:</div>
                                      {timeConvertor(item.updated_at)}
                                    </td>
                                    <td>
                                      <div className="td-name">Tracking</div>{" "}
                                      {!props.isLoading2 ? (
                                        <button
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={() => {
                                            if (
                                              props.openTrackingModal === false
                                            ) {
                                              props.handlePreviewInfo(
                                                item.id,
                                                item.title
                                              );
                                              props.setTrackingPopup(index);
                                            }
                                          }}
                                        >
                                          <Visibility className="btn-img" />
                                          {props.tackingPopup === index &&
                                          props.openTrackingModal ? (
                                            <TrackingInfo
                                              openTrackingModal={
                                                props.openTrackingModal
                                              }
                                              CloseTrackingModal={
                                                props.CloseTrackingModal
                                              }
                                              documentName={props.documentName}
                                              workFlowLog={props.workFlowLog}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </button>
                                      ) : props.tackingPopup === index ? (
                                        <CircularProgress
                                          style={{
                                            width: 25,
                                            height: 25,
                                            color: "purple",
                                          }}
                                        />
                                      ) : (
                                        <Visibility className="btn-img" />
                                      )}
                                    </td>
                                    <td>
                                      <div className="td-name">Status</div>{" "}
                                      {/* {item.status === "Closed"
                                    ? "Complete"
                                    : !item.isEditable &&
                                      item.status !== "Closed"
                                    ? "In Progress"
                                    : "Pending"} */}
                                      {item.status}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          {props.filterData.length > 0 &&
                            props.filterData
                              .slice(props.startIndex, props.endIndex)
                              .map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td className="itemtitle">
                                      <div className="td-name">Title:</div>
                                      {!props.isLoading ? (
                                        <span
                                          className="tittleSpan"
                                          onClick={() => {
                                            if (props.openModal === false) {
                                              props.handleTrackingInfo(
                                                item.id,
                                                item.title
                                              );
                                              props.setPopIndex(index);
                                            }
                                          }}
                                        >
                                          {item.title}
                                          {props.popIndex === index &&
                                          props.openModal ? (
                                            <PreviewInfo
                                              trackingData={props.trackingData}
                                              handleClose={props.handleClose}
                                              openModal={props.openModal}
                                              documentName={props.documentName}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      ) : props.popIndex === index ? (
                                        <CircularProgress
                                          style={{
                                            width: 25,
                                            height: 25,
                                            color: "purple",
                                          }}
                                        />
                                      ) : (
                                        item.title
                                      )}
                                    </td>
                                    <td>
                                      <div className="td-name">Initiator:</div>
                                      {item.initiator.name}
                                    </td>
                                    <td>
                                      <div className="td-name">Updated At:</div>
                                      {timeConvertor(item.updated_at)}
                                    </td>
                                    <td>
                                      <div className="td-name">Tracking</div>{" "}
                                      {!props.isLoading2 ? (
                                        <button
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "transparent",
                                          }}
                                          onClick={() => {
                                            if (
                                              props.openTrackingModal === false
                                            ) {
                                              props.handlePreviewInfo(
                                                item.id,
                                                item.title
                                              );
                                              props.setTrackingPopup(index);
                                            }
                                          }}
                                        >
                                          <Visibility className="btn-img" />
                                          {props.tackingPopup === index &&
                                          props.openTrackingModal ? (
                                            <TrackingInfo
                                              openTrackingModal={
                                                props.openTrackingModal
                                              }
                                              CloseTrackingModal={
                                                props.CloseTrackingModal
                                              }
                                              documentName={props.documentName}
                                              workFlowLog={props.workFlowLog}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </button>
                                      ) : props.tackingPopup === index ? (
                                        <CircularProgress
                                          style={{
                                            width: 25,
                                            height: 25,
                                            color: "purple",
                                          }}
                                        />
                                      ) : (
                                        <Visibility className="btn-img" />
                                      )}
                                    </td>
                                    <td>
                                      <div className="td-name">Status:</div>
                                      {/* {item.status === "Closed"
                                    ? "Complete"
                                    : !item.isEditable &&
                                      item.status !== "Closed"
                                    ? "In Progress"
                                    : "Pending"} */}
                                      {item.status}
                                    </td>
                                  </tr>
                                </>
                              ))}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  {" "}
                  {props.errorMessage.length > 0 && props.errorMessage}
                </p>
                {/* PAGINATION */}
                <ReactPaginate
                  className="pagination"
                  activeClassName="active"
                  pageClassName="pageclassList li.active"
                  breakLabel={window.innerWidth >= 425 ? "..." : null}
                  nextLabel={<img src={prevnext} alt="" />}
                  onPageChange={(event) => props.onPageChange(event.selected)}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  pageCount={props.pageCount}
                  forcePage={props.currentPage}
                  previousLabel={<img src={prevnext} alt="" />}
                  renderOnZeroPageCount={null}
                />
              </div>
            </Grid>
            <Grid item sm={12} md={12} lg={4} xl={4}>
              <div
                style={{ position: "relative" }}
                className="piechartcontainer"
              >
                <ResponsiveContainer
                  className="ResponsiveContainer "
                  width="100%"
                  height={360}
                >
                  <PieChart>
                    <Pie
                      data={props.data1}
                      dataKey="count"
                      outerRadius={150}
                      innerRadius={70}
                      fill="green"
                    />
                  </PieChart>
                </ResponsiveContainer>
                <div className="PieChartData">
                  <ul>
                    <li>
                      <div className="pending"></div>Total
                    </li>
                    <li>
                      <div className="signed"></div>Completed
                    </li>
                    <li>
                      <div className="conpleted"></div>
                      Pending
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            {/* </Stack> */}
          </div>
        </Grid>
      </div>
    </Container>
  );
};

export default Reports;
