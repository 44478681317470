// import React from "react";

// const AddTemplateName = () => {
//   return <div>AddTemplateName</div>;
// };

// export default AddTemplateName;
import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { themeCustomizer } from "../ButtonTheme";
interface InfoPopUp {
  isInfo: boolean;
  message: string;
  onInfoClosedPressed: () => void;
  onOkPressed: () => void;
}

const InfoPopUp: FC<InfoPopUp> = (props: InfoPopUp) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 991 ? 400 : "80%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal
      open={props.isInfo}
      onClose={props.onInfoClosedPressed}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          //  width: 400
          // width: { sm: "100%", md: "50%", lg: "30%", xl: "30%" },
        }}
      >
        <Typography
          fontSize={14}
          align="center"
          fontWeight={700}
          color={"#4a4a4a"}
        >
          Information Alert
        </Typography>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Typography
            fontSize={12}
            align="center"
            fontWeight={700}
            color={"#4a4a4a"}
          >
            {props.message}
          </Typography>
        </div>
        <ThemeProvider theme={themeCustomizer}>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "100%",
              marginTop: 10,
              zIndex: 1,
            }}
            onClick={props.onOkPressed}
          >
            Ok
          </Button>
        </ThemeProvider>
      </Box>
    </Modal>
  );
};

export default InfoPopUp;
