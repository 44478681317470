import React, { FC } from "react";
import backgroundImage from "../Assets/SingUp/background.webp";
import {
  Card,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import logo from "../Assets/SingUp/logo.webp";
import leftCircle from "../Assets/SingUp/leftCircle.webp";
import rightCircle from "../Assets/SingUp/rightCircle.webp";
import { primaryButton, primaryHeading } from "../Utils/Constants";
import "../Styles/SignUp.scss";

interface VerifyEmail {
  // passwordValidationSchema: any;
  showPassword: boolean;
  errorMessage: string;
  isVerificiation: boolean;
  message: string;
  userEmail: string;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: any) => void;
  onSignInPressed: (userEmail: string, userPassword: string) => void;
  onForgotPasswordClick: () => void;
  onRegisterClick: () => void;
  updateErrorMessage: () => void;
  verifyEmail: (pin: string) => void;
  onResendPinPressed: () => void;
  onChangeEmailPressed: (_newEmail: string) => void;
  onChangeEmail: () => void;
}

const VerifyEmail: FC<VerifyEmail> = (props: VerifyEmail) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryButton, // Replace with your desired color
      },
    },
  });

  const passwordValidationSchema =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email address is required."),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
      .required("Email Required"),
    // password: Yup.string().matches(passwordValidationSchema, "invalidPassword"),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)"
      )
      .required("Password Required"),
  });
  const validationSchema2 = Yup.object().shape({
    pin: Yup.string()
      .matches(/^[0-9]+$/, "Please enter numbers only")
      .required("This field is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      pin: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // loginAction(values);
      props.onSignInPressed(values.email, values.password);
      // console.warn("values", values);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      pin: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {
      // loginAction(values);
      // props.onSignInPressed(values.email, values.password);
      props.verifyEmail(values.pin);
      console.warn("values", values);
    },
  });
  return (
    <div className="background-Image">
      <Grid container xl={12} lg={12} md={12}>
        <Grid
          item
          md={6}
          xl={6}
          lg={6}
          sm={12}
          className="yellowBg"
          style={{ position: "relative" }}
        >
          <div style={{ position: "absolute", top: 30, left: 20 }}>
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="leftCircle">
            <img src={leftCircle} />
          </div>
          <div style={{ position: "absolute", bottom: 30, left: 20 }}>
            <p className="copyright">©Copyright 2024</p>
          </div>
        </Grid>
        <Grid item md={6} xl={6} lg={6} sm={6} className="rightSection">
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="formDiv">
              <div className="innerForm">
                <Typography
                  className="heading"
                  fontSize={35}
                  align="left"
                  fontWeight={500}
                  color={primaryHeading}
                  // marginBottom={25}
                >
                  User Information!
                </Typography>

                {/* <Typography
                  fontSize={20}
                  align="left"
                  fontWeight={700}
                  color={"#000000"}
                  // marginTop={2}
                >
                  Email:{props.userEmail}
                  <div
                    style={{
                      width: "25px",
                      height: 1,
                      backgroundColor: "#C0BDCC",
                    }}
                  />
                </Typography> */}

                <Typography
                  className="sub-heading"
                  fontSize={11}
                  align="left"
                  fontWeight={300}
                  color={"#7A86A1"}
                  // marginTop={4}
                >
                  Please enter verification code
                </Typography>
                {/* <div
                  style={{
                    width: 20,
                    height: 5,
                    borderBottom: 5,
                    borderWidth: 5,
                    color: "black",
                    borderRadius: 10,
                  }}
                /> */}

                <div
                  className="c-input"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* <Typography fontSize={12} align="left" fontWeight={700}>
                      Your Email
                    </Typography> */}
                  <TextField
                    style={{
                      width: "100%",
                      marginTop: 5,
                      borderRadius: "50px",
                    }}
                    disabled={true}
                    InputProps={{
                      style: {
                        borderRadius: "20px",
                        // height: 50,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#e9e7f1",
                        fontSize: "13px",
                        marginBottom: "5px",
                      },
                    }}
                    inputProps={{ maxLength: 4 }}
                    size="small"
                    name="pin"
                    placeholder={"Email"}
                    value={props.userEmail}
                    inputMode="text"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        formik2.handleSubmit();
                      }
                    }}
                  />
                  <TextField
                    style={{
                      width: "100%",
                      marginTop: 5,
                      borderRadius: "50px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "20px",
                        // height: 50,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                    inputProps={{ maxLength: 4 }}
                    size="small"
                    name="pin"
                    placeholder={"Please enter verification code"}
                    onChange={(event) => {
                      formik2.handleChange(event);
                      props.updateErrorMessage();
                    }}
                    value={formik2.values.pin}
                    inputMode="text"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        formik2.handleSubmit();
                      }
                    }}
                    onBlur={formik2.handleBlur}
                    error={(formik2.errors.pin && formik2.touched.pin) || false}
                    helperText={
                      formik2.errors.pin &&
                      formik2.touched.pin &&
                      formik2.errors.pin
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    // marginTop: 10,
                    // justifyContent: "space-between",
                  }}
                >
                  {props.errorMessage.length > 0 && (
                    <span className="errorMessage">{props.errorMessage}</span>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      // variant="contained"
                      color="primary"
                      style={{
                        // width: "50%",
                        // borderRadius: "50px",
                        // padding: "10px",
                        // marginTop: 10,
                        zIndex: 1,
                        letterSpacing: "0px",
                        color: `${primaryHeading}`,
                        fontSize: "12px",
                      }}
                      // onClick={() =>
                      //   props.onChangeEmailPressed("jehanzebbutt@gmail.com")
                      // }
                      onClick={() => props.onChangeEmail()}
                    >
                      Change Email
                    </Button>
                    <Button
                      // variant="contained"
                      color="primary"
                      style={{
                        zIndex: 1,
                        letterSpacing: "0px",
                        color: `${primaryHeading}`,
                        fontSize: "12px",
                      }}
                      onClick={() => props.onResendPinPressed()}
                    >
                      Resend Pin
                    </Button>
                  </div>

                  {props.message.length > 0 && (
                    <div className="alert alert-primary">
                      {/* <p
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "15px",
                                  color: "rgb(253, 60, 79)",
                                }}
                              > */}
                      {props.message}
                      {/* </p> */}
                    </div>
                  )}
                  <ThemeProvider theme={theme}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: "100%",
                        borderRadius: "50px",
                        padding: "10px",
                        marginTop: 10,
                        zIndex: 1,
                      }}
                      onClick={() => formik2.handleSubmit()}
                    >
                      Verify
                    </Button>
                  </ThemeProvider>
                </div>
                <img src={rightCircle} className="rightCircle" />
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default VerifyEmail;
