import React, { useRef, FC } from "react";
import { Editor } from "@tinymce/tinymce-react";
import html2pdf from "html2pdf.js";
import {
  AddReaction,
  EditNotifications,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Edit,
  Delete,
  Done,
  Save,
  Cancel,
} from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
  Button,
  ThemeProvider,
  Container,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../Styles/CreatePdf.scss";
import { themeCustomizer } from "./Common/ButtonTheme";
import AddReciepent from "./Common/PopUp/AddReciepent";
import { IReceipentList } from "../Types/Types";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

interface CreatePdf {
  //   contentRef: any;
  //   onUpdateCanvasData: (data: any) => void;
  //   onSavePdfPressed: (editor: any) => void;
  radioButton: any;
  fileSelectedButton: boolean;
  message: string;
  editorRef: any;
  fileInputRef: any;
  fileName: any;
  isRecepient: boolean;
  receipentList: IReceipentList[];
  editingRecipient: number;
  formik: any;
  pdfBase64String: null | undefined | string;
  disableFiled: boolean;
  onAddReceipentPressed: () => void;
  onRadioButtonPressed: (radioButtonId: number) => void;
  onPdfFileSelect: (event: any) => void;
  onSavePdfPressed: () => void;
  onCreateWorkFlowPressed: () => void;
  handleFileInputChange: (event: any) => void;
  onOkPressed: () => void;
  onSubmitReciepent: (name: string, email: string) => void;
  onEditRecipentPressed: (recipientId: number) => void;
  handleCancelEdit: () => void;
  handleDelete: (recipientId: number) => void;
  onUpdateMessage: () => void;
  onGeneratedNextPdfPressed: () => void;
}
const CreatePdfs: FC<CreatePdf> = (props: CreatePdf) => {
  const formik = props.formik;
  // const editorRef = useRef<any>(null);
  const log = () => {
    if (props.editorRef.current) {
      console.log(props.editorRef.current.getContent());
      const htmlContent = props.editorRef.current.getContent(); // Replace this with your actual HTML content
      const element = document.createElement("div");
      element.innerHTML = htmlContent;
      const listItems = element.querySelectorAll("li");
      listItems.forEach((item) => {
        // item.style.listStyleType = "disc";
        item.style.marginLeft = "50px";
        item.style.padding = "5px";
      });

      html2pdf().from(element).save("digital-document.pdf");
    }
  };
  const createWorkFlow = () => {
    if (props.editorRef.current) {
      console.log(props.editorRef.current.getContent());
      const htmlContent = props.editorRef.current.getContent(); // Replace this with your actual HTML content
      const element = document.createElement("div");
      element.innerHTML = htmlContent;
      const listItems = element.querySelectorAll("li");
      listItems.forEach((item) => {
        // item.style.listStyleType = "disc";
        item.style.marginLeft = "50px";
        item.style.padding = "5px";
      });

      html2pdf()
        .from(element)
        .output("blob")
        .then((pdfBlob: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result?.toString()?.split(",")[1];
            console.log(base64String); // Base64 string of the generated PDF
            // Use the base64 string as needed
          };
          reader.readAsDataURL(pdfBlob);
        });
    }
  };
  const handleCancel = () => {
    props.handleCancelEdit();
    formik.resetForm(); // Reset the form to clear any validation errors
  };
  const selectFile = () => {
    console.log("called");
    props.fileInputRef.current.click();
  };
  const style = {
    overflowY: "scroll",
    maxHeight: "300px",
    width: "90%",
    marginLeft: "2px",
  };
  return (
    <>
      {/* <AddReciepent
        isRecepient={props.isRecepient}
        message={props.message}
        onReciepentClosedPressed={props.onAddReceipentPressed}
        onOkPressed={props.onOkPressed}
        onSubmitReciepent={props.onSubmitReciepent}
      /> */}
      <Grid spacing={2} item sm={12} md={12} lg={12} xl={12}>
        {!props.disableFiled && (
          <Typography
            fontSize={30}
            fontWeight={300}
            marginTop={2}
            marginBottom={3}
            color={"#7A86A1"}
          >
            Create PDF
          </Typography>
        )}

        {!props.disableFiled && (
          <div className="uploadpdf-wraper">
            <div className="uploadpdf">
              <div className="inpdf">
                <IconButton
                  style={{
                    borderRadius: 10,
                    fontSize: 18,
                    color: "#A89BB9",
                  }}
                  onClick={() => props.onRadioButtonPressed(1)}
                >
                  {props.radioButton.radioButton1 === true ? (
                    <div className="activeRadioButtonContainer">
                      <RadioButtonChecked />
                    </div>
                  ) : (
                    <div className="notactiveRadioButton">
                      <RadioButtonUnchecked />
                    </div>
                  )}
                </IconButton>
                <div className="upload-inner">
                  <p>Upload PDF</p>
                  <span>Browser (10MB Max)</span>
                </div>
              </div>
              <div className="uploadbtn">
                <button
                  disabled={props.radioButton.radioButton2}
                  onClick={selectFile}
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_598_2104)">
                      <path
                        d="M5.46206 0.938999C5.60136 0.799609 5.76676 0.689034 5.94881 0.613592C6.13087 0.53815 6.326 0.499319 6.52306 0.499319C6.72013 0.499319 6.91526 0.53815 7.09731 0.613592C7.27936 0.689034 7.44476 0.799609 7.58406 0.938999L9.18956 2.5445C9.27564 2.6397 9.32182 2.76436 9.31852 2.89267C9.31523 3.02098 9.26272 3.1431 9.17186 3.23376C9.08101 3.32442 8.95877 3.37667 8.83046 3.3797C8.70215 3.38272 8.57758 3.33628 8.48256 3.25L7.01956 1.7865L7.02306 9.5C7.02306 9.63261 6.97038 9.75979 6.87662 9.85355C6.78285 9.94732 6.65567 10 6.52306 10V10C6.39045 10 6.26328 9.94732 6.16951 9.85355C6.07574 9.75979 6.02306 9.63261 6.02306 9.5L6.01856 1.796L4.56356 3.25C4.46974 3.34375 4.34252 3.3964 4.20989 3.39635C4.07725 3.3963 3.95007 3.34357 3.85631 3.24975C3.76256 3.15593 3.70991 3.02871 3.70996 2.89607C3.71001 2.76344 3.76274 2.63625 3.85656 2.5425L5.46206 0.938999Z"
                        fill="#7A86A1"
                      />
                      <path
                        d="M12.0234 8.5C11.8908 8.5 11.7637 8.55268 11.6699 8.64645C11.5761 8.74021 11.5234 8.86739 11.5234 9V11C11.5234 11.1326 11.4708 11.2598 11.377 11.3535C11.2832 11.4473 11.156 11.5 11.0234 11.5H2.02344C1.89083 11.5 1.76365 11.4473 1.66988 11.3535C1.57612 11.2598 1.52344 11.1326 1.52344 11V9C1.52344 8.86739 1.47076 8.74021 1.37699 8.64645C1.28322 8.55268 1.15605 8.5 1.02344 8.5C0.890829 8.5 0.763652 8.55268 0.669884 8.64645C0.576116 8.74021 0.523438 8.86739 0.523438 9L0.523438 11C0.523438 11.3978 0.681473 11.7793 0.962777 12.0607C1.24408 12.342 1.62561 12.5 2.02344 12.5H11.0234C11.4213 12.5 11.8028 12.342 12.0841 12.0607C12.3654 11.7793 12.5234 11.3978 12.5234 11V9C12.5234 8.86739 12.4708 8.74021 12.377 8.64645C12.2832 8.55268 12.156 8.5 12.0234 8.5Z"
                        fill="#7A86A1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_598_2104">
                        <rect
                          width="12"
                          height="12"
                          fill="white"
                          transform="translate(0.523438 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Upload
                </button>
              </div>
            </div>
            <div className="uploadpdf">
              <div className="inpdf">
                <IconButton
                  style={{
                    borderRadius: 10,
                    fontSize: 18,
                    color: "#A89BB9",
                  }}
                  onClick={() => props.onRadioButtonPressed(2)}
                >
                  {props.radioButton.radioButton2 === true ? (
                    <div className="activeRadioButtonContainer">
                      <RadioButtonChecked />
                    </div>
                  ) : (
                    <div className="notactiveRadioButton">
                      <RadioButtonUnchecked />
                    </div>
                  )}
                </IconButton>
                <div className="upload-inner">
                  <p>Open Editor</p>
                  <span>Create new document</span>
                </div>
              </div>
              <div className="uploadbtn">
                <button onClick={() => props.onRadioButtonPressed(2)}>
                  New Document
                </button>
              </div>
            </div>
          </div>
        )}
        <Grid item sm={12} lg={12} md={12} xl={12} style={{}}>
          {/* <div className="create-pdf-box"> */}
          <div
            className={`create-pdf-box ${
              props.radioButton.radioButton1 === true ? "upload-pdf-styles" : ""
            }`}
          >
            {props.radioButton.radioButton1 === true && !props.disableFiled ? (
              <div className="chooseFile">
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="application/pdf"
                  className="file"
                  onChange={props.onPdfFileSelect}
                  // ref={props.fileInputRef}
                  ref={props.fileInputRef}
                />
                {props.fileName === "" ? (
                  <span style={{ margin: "30px 0" }}>Please Upload File</span>
                ) : (
                  <>
                    <span style={{ marginTop: "30px" }}>
                      <b>File Name:</b> {props.fileName}
                    </span>
                  </>
                )}

                {props.message.length > 0 ? (
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "15px",
                      color: "rgb(253, 60, 79)",
                      marginTop: 10,
                      background: "rgba(253, 60, 79, 0.1)",
                      padding: "10px 20px",
                      border: "solid 1px rgb(253, 60, 79)",
                    }}
                  >
                    {props.message}
                  </p>
                ) : null}
              </div>
            ) : (
              <div className="chooseFile">
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="application/pdf"
                  className="file"
                  onChange={props.onPdfFileSelect}
                  // ref={props.fileInputRef}
                  ref={props.fileInputRef}
                />
                {/* {props.fileName === "" ? (
                  <span style={{ margin: "30px 0" }}>Please Upload File</span>
                ) : (
                  <>
                    <span style={{ marginTop: "30px" }}>
                      <b>File Name:</b> {props.fileName}
                    </span>
                  </>
                )} */}

                {props.message.length > 0 ? (
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "15px",
                      color: "rgb(253, 60, 79)",
                      marginTop: 10,
                      background: "rgba(253, 60, 79, 0.1)",
                      padding: "10px 20px",
                      border: "solid 1px rgb(253, 60, 79)",
                    }}
                  >
                    {props.message}
                  </p>
                ) : null}
              </div>
            )}
            {props.radioButton.radioButton2 === true && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {!props.pdfBase64String && (
                    <>
                      <div style={{ marginTop: 10 }}>
                        <Editor
                          apiKey="33gpdc2h9k5rnh126s3xzpb5ijl3m72nbviywjso2hgij36a"
                          onInit={(evt, editor) =>
                            (props.editorRef.current = editor)
                          }
                          // initialValue="<p>This is the initial content of the editor.</p>"
                          init={{
                            height: 500,
                            menubar: false,

                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist outdent indent | " +
                              "removeformat ",
                            content_style:
                              "body {padding:50px; font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                          onEditorChange={(newValue, editor) => {
                            // console.warn("newValue :: ", newValue);
                            // props.contentRef = newValue;
                            //   props.onUpdateCanvasData(newValue);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <ThemeProvider theme={themeCustomizer}>
                          <Button
                            variant={"contained"}
                            color="primary"
                            style={{
                              width: "20%",
                              borderRadius: 10,
                              marginTop: 10,
                            }}
                            onClick={props.onCreateWorkFlowPressed}
                          >
                            <span style={{}}>Create PDF</span>
                          </Button>
                        </ThemeProvider>
                      </div>
                    </>
                  )}

                  {props.pdfBase64String && (
                    <div>
                      <Box>
                        <form onSubmit={formik.handleSubmit}>
                          <div className="recipient-form-wraper">
                            <TextField
                              className="rec-input-field"
                              label="Recipient Name"
                              name="name"
                              onChange={formik.handleChange}
                              onFocus={() => props.onUpdateMessage()}
                              value={formik.values.name}
                              error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                              }
                              helperText={
                                formik.touched.name && formik.errors.name
                              }
                            />
                            <TextField
                              className="rec-input-field"
                              label="Recipient Email"
                              name="email"
                              onChange={formik.handleChange}
                              onFocus={() => props.onUpdateMessage()}
                              value={formik.values.email}
                              error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                              }
                              helperText={
                                formik.touched.email && formik.errors.email
                              }
                            />
                          </div>
                          <div className="recp-btn-wraper">
                            <Button variant="contained" type="submit">
                              {props.editingRecipient !== -1
                                ? "Update Recipient"
                                : "Add Recipient"}
                            </Button>
                            {props.editingRecipient !== -1 && (
                              // <Button variant="contained" onClick={()=>props.handleCancelEdit()}>
                              //   Cancel
                              // </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            )}
                          </div>
                        </form>
                        <Box
                          className="receipentListBox"
                          mt={2}
                          sx={{
                            ...style,
                          }}
                        >
                          {props.receipentList.map(
                            (recipient: IReceipentList) =>
                              props.editingRecipient !== recipient.id && (
                                <Paper
                                  key={recipient.id}
                                  elevation={3}
                                  className="receipentListRow"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={6}>
                                      <Typography variant="subtitle1">
                                        {recipient.name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {recipient.email}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div
                                        style={{
                                          backgroundColor: `rgb(${recipient.color})`,
                                          border: `solid 4px ${recipient.borderColor}`,
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={3} className="iconList">
                                      <IconButton
                                        onClick={() =>
                                          props.onEditRecipentPressed(
                                            recipient.id
                                          )
                                        }
                                        aria-label="edit"
                                      >
                                        <Edit />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          props.handleDelete(recipient.id)
                                        }
                                        aria-label="delete"
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              )
                          )}
                        </Box>
                      </Box>
                    </div>
                  )}
                  {props.receipentList.length > 0 && (
                    <ThemeProvider theme={themeCustomizer}>
                      <div className="nextBtn">
                        <Button
                          className="roles-slide"
                          variant={"contained"}
                          color="primary"
                          disabled={
                            props.receipentList.length > 0 ? false : true
                          }
                          onClick={props.onGeneratedNextPdfPressed}
                        >
                          <span style={{}}>Next</span>
                        </Button>
                      </div>
                    </ThemeProvider>
                  )}
                </div>
              </>
            )}

            {props.fileSelectedButton && (
              <div>
                <Box>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="recipient-form-wraper">
                      <TextField
                        className="rec-input-field"
                        label="Recipient Name"
                        name="name"
                        onChange={formik.handleChange}
                        onFocus={() => props.onUpdateMessage()}
                        value={formik.values.name}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <TextField
                        className="rec-input-field"
                        label="Recipient Email"
                        name="email"
                        onChange={formik.handleChange}
                        onFocus={() => props.onUpdateMessage()}
                        value={formik.values.email}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                    <div className="recp-btn-wraper">
                      <Button variant="contained" type="submit">
                        {props.editingRecipient !== -1
                          ? "Update Recipient"
                          : "Add Recipient"}
                      </Button>
                      {props.editingRecipient !== -1 && (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  </form>
                  <Box
                    className="receipentListBox"
                    mt={2}
                    sx={{
                      ...style,
                    }}
                  >
                    {props.receipentList.map(
                      (recipient: IReceipentList) =>
                        props.editingRecipient !== recipient.id && (
                          <Paper
                            key={recipient.id}
                            elevation={3}
                            className="receipentListRow"
                          >
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={6}>
                                <Typography variant="subtitle1">
                                  {recipient.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {recipient.email}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <div
                                  style={{
                                    backgroundColor: `rgb(${recipient.color})`,
                                    border: `solid 4px ${recipient.borderColor}`,
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} className="iconList">
                                <IconButton
                                  onClick={() =>
                                    props.onEditRecipentPressed(recipient.id)
                                  }
                                  aria-label="edit"
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    props.handleDelete(recipient.id)
                                  }
                                  aria-label="delete"
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        )
                    )}
                  </Box>
                </Box>
              </div>
            )}
            {props.receipentList.length > 0 && props.fileSelectedButton && (
              <ThemeProvider theme={themeCustomizer}>
                <div className="nextBtn">
                  <Button
                    className="roles-slide"
                    variant={"contained"}
                    color="primary"
                    disabled={props.receipentList.length > 0 ? false : true}
                    onClick={props.onSavePdfPressed}
                  >
                    <span style={{}}>Next</span>
                  </Button>
                </div>
              </ThemeProvider>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CreatePdfs;
