import React, { createContext } from "react";
import { IReceipentList, UserList, permissionsRolesList } from "../Types/Types";
interface UserContextType {
  userName: string;
  isLoggedIn: boolean;
  token: string;
  tabIndex: number;
  permissionsRolesList: permissionsRolesList[];
  userList: UserList[];
  isOpen: boolean;
  receipentList: IReceipentList[];
  updateUserName: (name: string) => void;
  updateIsLogged: (value: boolean) => void;
  updateToken: (value: string) => void;
  updateTabIndex: (value: number) => void;
  updateCurrentUserPremissios: (val: any) => void;
  updateUserList: (val: any) => void;
  updateMenuButton: (val: boolean) => void;
  updateReciepientList: (val: any) => void;
}

const UserContext = createContext<UserContextType>({
  userName: "",
  isLoggedIn: true,
  token: "",
  tabIndex: 0,
  permissionsRolesList: [{ id: -1, name: "", description: "", controls: [] }],
  userList: [
    {
      id: -1,
      email: "",
      name: "",
      mobile_number: "",
      role: {
        id: -1,
        name: "",
      },
      status: true,
      created_at: "",
      updated_at: "",
    },
  ],
  receipentList: [{ id: -1, email: "", name: "", color: "", borderColor: "" }],
  isOpen: true,
  updateUserName: () => {},
  updateIsLogged: () => {},
  updateToken: () => {},
  updateTabIndex: () => {},
  updateCurrentUserPremissios: () => {},
  updateUserList: () => {},
  updateMenuButton: () => {},
  updateReciepientList: () => {},
});
export default UserContext;
