import React, { useEffect, useState, useContext } from "react";
import Users from "../Components/Users";
import UserContext from "../Utils/UserContext";
import { fetchGet, fetchPut } from "../FetchApi/FetchApi";
import { ApiName } from "../Utils/ApiNames";
import { rolesList } from "../Types/Types";
const UserScreen = () => {
  const context = useContext(UserContext);
  const { token, isOpen,updateMenuButton } = context;
  const [userList, setUserList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(-1);
  const [editableData, setEditAbleData] = useState({
    id: "",
    name: "",
    email: "",
    mobile_number: "",
    role: {
      name: "",
      id: -1,
    },
  });
  const [rolesList, setRolesList] = useState<rolesList[]>([]);

  useEffect(() => {
    if (token.length > 0) {
      getAllUsersList();
      getPermissionRoles();
    }
  }, [token]);

  const getPermissionRoles = async () => {
    try {
      let response = await fetchGet(token, ApiName.rolesList);
      if (response.status === 0) {
        setRolesList(response.response);
        setEditAbleData({
          id: "",
          name: "",
          email: "",
          mobile_number: "",
          role: {
            name: response.response[0].name,
            id: response.response[0].id,
          },
        });
      } else if (response.status === 401) {
        if (response.response.toLowerCase() === "invalid or expired token") {
          // let refreshResponse = await refreshToken();
        }
      }
    } catch (error) {}
  };

  const getAllUsersList = async () => {
    try {
      let response = await fetchGet(token, ApiName.userList);
      if (response.status === 0) {
        setUserList(response.response);
      }
    } catch (error) {
      console.log("getAllUserList", error);
    }
  };

  const onEditPressed = (index: number) => {
    try {
      if (index > -1) {
        console.log(userList[index]);
        setCurrentItemIndex(index);
        // console.log("item ::", userList[index]);
        setEditAbleData(userList[index]);
      }
    } catch (error) {
      console.log("onEditPressed", error);
    }
  };
  const onDiscradRequestPressed = () => {
    setEditAbleData({
      id: "",
      name: "",
      email: "",
      mobile_number: "",
      role: {
        name: "",
        id: -1,
      },
    });
    setCurrentItemIndex(-1);
  };

  const onUpdatePressed = async (values: any) => {
    try {
      let data = {
        id: editableData.id,
        role_id: values.id,
        name: values.name,
        email: values.email,
        mobile_number: values.mobile_number,
      };
      let response = await fetchPut(token, ApiName.editUser, data);
      if (response.status === 0) {
        if (response.response === "Success") {
          await getAllUsersList();
          setCurrentItemIndex(-1);
        }
      }
    } catch (error) {
      console.log("onUpdatePressed", error);
    }
  };
   const onToggleDrawer = () => {
     // setOpen(!open);
     updateMenuButton(!isOpen);
   };
  return (
    <Users
      userList={userList}
      edit={edit}
      currentItemIndex={currentItemIndex}
      editableData={editableData}
      rolesList={rolesList}
      isOpen={isOpen}
      onEditPressed={onEditPressed}
      onDiscradRequestPressed={onDiscradRequestPressed}
      onUpdatePressed={onUpdatePressed}
      onToggleDrawer={onToggleDrawer}
    />
  );
};

export default UserScreen;
