import React, { FC, useRef, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  FontDownload,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CameraAlt,
  CheckBoxOutlineBlank,
  CheckBox,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Edit,
  Delete,
  Download,
  Collections,
  Done,
  AutoFixHigh,
} from "@mui/icons-material";
import Configuration from "./Configuration";

import {
  Button,
  Container,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import PreviewPdf from "./Common/PopUp/PreviewPdf";
import PdfDocument from "./Common/PdfDocument";
import ToolBar from "./Common/ToolBar";
import {
  AllControlsList,
  AssignedWorkFlowControl,
  UserList,
  WorkFlow,
  WorkFlowControl,
  workFlowHierarchy,
} from "../Types/Types";
import Webcam from "react-webcam";
import textImage from "../Assets/text.png";
import camera from "../Assets/camera.png";
import tick from "../Assets/tick.png";
import signControl from "../Assets/sign-control.png";
import sign from "../Assets/sign.png";
import textInputImage from "../Assets/Text box-50x50.png";
import multiSelectionImage from "../Assets/Multi selection-50x50.png";
import imageBoxImage from "../Assets/Image box-50x50.png";
import signatureBoxIamge from "../Assets/Signature Box-50x50.png";
import initalsBoxImage from "../Assets/initials.png";
import radioButtonImage from "../Assets/radio.png";
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import OpenCamera from "./Common/PopUp/OpenCamera";
import DragDropImage from "./Common/PopUp/DragDropImage";
import InfoPopUp from "./Common/PopUp/InfoPopUp";
import SignaturePopUp from "./Common/PopUp/SignaturePopUp";
import InitialBoxPopUp from "./Common/PopUp/InitialBoxPopUp";
import Draggable from "react-draggable";
import logo from "../Assets/SingUp/logo.webp";
import "../Styles/EditiControl.scss";
import SignatureComboPopUp from "./Common/PopUp/SignatureComboPopUp";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface EditControl {
  pdfLoading: any;
  // elementRef: any;
  pdfUrl: string;
  pageNumber: number;
  pdfWidth: number;
  numPages: number;
  pdfScale: number;
  controls: AssignedWorkFlowControl[];
  webcamRef: any;
  roleId: number;
  activeControlIndex: number;
  signPad: any;
  imageSrc: any;
  fileInputRef: any;
  showCamera: any;
  cameraIndex: number;
  isDragging: boolean;
  isSelectImage: boolean;
  initialPad: any;
  message: string;
  isInfo: boolean;
  itemId: number;
  isSignaturePad: boolean;
  isInitialPad: boolean;
  isCheckBox: boolean;
  isRadioButton: boolean;
  isLoading: boolean;
  workFlowTitle: string;
  signImage: string;
  initialsImage: string;
  signComboImage: string;
  signComboPad: any;
  isSignatureComboPad: boolean;
  serverDate: any;
  signatureBy: any;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onPreviousePressed: () => void;
  onNextPagePressed: () => void;
  updateMessage: () => void;
  onChangeTextControl: (val: string, id: number) => void;
  onSubmitClick: () => void;
  captureImage: () => void;
  handleSignature: (signature: any) => void;
  onClearSignaturePad: () => void;
  onCloseSignaturePad: () => void;
  onClearSignaturePadCombo: () => void;
  onCloseSignatureComboPad: () => void;
  handleStartCamera: (index: number, id: number) => void;
  handleFileInputChange: (event: any, index: number) => void;
  handleCaptureImage: () => void;
  onCloseCamera: () => void;
  deleteImage: () => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onUploadImagePressed: (index: number) => void;
  onSelectImagePressed: (index: number, id: number) => void;
  handleInitials: (signature: any) => void;
  onClearInitalPad: () => void;
  onSubmitWorkFlowPressed: () => void;
  onOkPressed: () => void;
  onInfoClosedPressed: () => void;
  onSignatureButtonPressed: (index: number, id: number) => void;
  onSignatureComboButtonPressed: (index: number, id: number) => void;
  onInitailButtonPressed: (index: number, id: number) => void;
  onCloseInitialPad: () => void;
  onCheckBoxPressed: (id: number) => void;
  onRadioButtonPressed: (id: number) => void;
  onControlActionPressed: (id: number, index: number) => void;
  onfocus: (id: number, pageNumber: number, index: number) => void;
  onsaveSignaturePressed: (val: any, index: number, option: number) => void;
  onsaveSignatureComboPressed: (
    val: any,
    index: number,
    option: number
  ) => void;
  onsaveInitialPressed: (val: any, index: number, option: number) => void;
  onNextControlPressed: () => void;
  onPreviouseControlPressed: () => void;
  onChangeOption: (option: number) => void;
  // imageRef: React.MutableRefObject<any>;
  signedImageRef: React.RefObject<HTMLImageElement>;
  ddssignedRef: React.RefObject<HTMLImageElement>;
  ddsinitialRef: React.RefObject<HTMLImageElement>;
}
const EditControl: FC<EditControl> = (props: EditControl) => {
  return (
    <>
      <InfoPopUp
        isInfo={props.isInfo}
        message={props.message}
        onInfoClosedPressed={props.onInfoClosedPressed}
        onOkPressed={props.onOkPressed}
      />
      <OpenCamera
        isCamera={props.showCamera}
        webcamRef={props.webcamRef}
        itemId={props.itemId}
        onCloseCamera={props.onCloseCamera}
        onSubmitPressed={props.handleCaptureImage}
        index={props.cameraIndex}
      />
      <DragDropImage
        isCamera={props.isSelectImage}
        fileInputRef={props.fileInputRef}
        index={props.cameraIndex}
        imageSrc={props.imageSrc}
        isDragging={props.isDragging}
        itemId={props.itemId}
        controls={props.controls}
        onCloseCamera={props.onCloseCamera}
        onSubmitPressed={props.handleCaptureImage}
        handleFileInputChange={props.handleFileInputChange}
        deleteImage={props.deleteImage}
        onDragOver={props.onDragOver}
        onDragLeave={props.onDragLeave}
        onDrop={props.onDrop}
        onUploadImagePressed={props.onUploadImagePressed}
      />
      <SignaturePopUp
        isSignature={props.isSignaturePad}
        index={props.cameraIndex}
        itemId={props.itemId}
        signPad={props.signPad}
        webcamRef={props.webcamRef}
        // onSubmitPressed={props.onS}
        signatureBy={props.signatureBy}
        handleSignature={props.handleSignature}
        onClearSignaturePad={props.onClearSignaturePad}
        // onSubmitClick={props.onSubmitClick}
        onCloseSignaturePad={props.onCloseSignaturePad}
        onsaveSignaturePressed={props.onsaveSignaturePressed}
        onChangeOption={props.onChangeOption}
      />
      <SignatureComboPopUp
        isSignature={props.isSignatureComboPad}
        index={props.cameraIndex}
        itemId={props.itemId}
        signPad={props.signComboPad}
        webcamRef={props.webcamRef}
        isLoading={props.isLoading}
        signatureBy={props.signatureBy}
        handleSignature={props.handleSignature}
        onClearSignaturePad={props.onClearSignaturePadCombo}
        onCloseSignaturePad={props.onCloseSignatureComboPad}
        onsaveSignaturePressed={props.onsaveSignatureComboPressed}
        onChangeOption={props.onChangeOption}
      />
      <InitialBoxPopUp
        initialPad={props.initialPad}
        isInitialPad={props.isInitialPad}
        index={props.cameraIndex}
        itemId={props.itemId}
        signatureBy={props.signatureBy}
        // onSubmitClick={props.onSubmitClick}
        handleSignature={props.handleInitials}
        onClearSignaturePad={props.onClearInitalPad}
        onCloseSignaturePad={props.onCloseInitialPad}
        onsaveInitialPressed={props.onsaveInitialPressed}
        onChangeOption={props.onChangeOption}
      />
      <Container className="ec-parent" style={{}}>
        <div className="miisign-logo">
          <img src={logo} />
        </div>
        <Typography fontSize={30} fontWeight={300} color={"#7A86A1"}>
          {props.workFlowTitle}
        </Typography>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid item sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                width: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
                height: { sm: "100%", md: "100%", lg: "100%", xl: "100%" },
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                backgroundColor: "white",
                borderWidth: "10px",
                border: "2px solid #F0F2F6",
              }}
            >
              <Grid
                className="pdfscroll"
                container
                direction="row"
                style={{ padding: 5 }}
              >
                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ padding: "10px" }}
                >
                  <div style={{ backgroundColor: "#FBFDFF" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        id="pdfContainer"
                        style={{
                          maxWidth: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Document
                          file={props.pdfUrl}
                          onLoadSuccess={props.onLoadSuccess}
                          style={{ position: "relative" }}
                        >
                          {/* controls*/}

                          {props.controls.map(
                            (item: AssignedWorkFlowControl, index: number) =>
                              item.pageNum === props.pageNumber && (
                                <Draggable
                                  disabled={true}
                                  key={index}
                                  position={{
                                    x: item.x * props.pdfScale,
                                    y:
                                      item.y * props.pdfScale +
                                      (1 - props.pdfScale) * (item.height / 4),
                                  }}
                                  bounds="parent"
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: `${item.y}`,
                                      zIndex: 1,
                                    }}
                                  >
                                    {item.name === "dds-txt" &&
                                    item.disableTextField === false ? (
                                      <div
                                        style={{
                                          height:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                          width:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          backgroundColor:
                                            "rgba(102, 255, 153,0.5)",
                                          borderColor:
                                            props.activeControlIndex === index
                                              ? "#36802d"
                                              : "transparent",
                                          borderWidth:
                                            props.activeControlIndex === index
                                              ? 3
                                              : 0,
                                          // borderRadius: 1,
                                          // borderStyle: "dotted",
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {props.pdfScale === 1 ? (
                                          <textarea
                                            style={{
                                              resize: "none",
                                              height: item.height,
                                              width: item.width,
                                              padding: 0,
                                              backgroundColor:
                                                "rgba(102, 255, 153,0.4)",
                                              color: "black",
                                            }}
                                            onChange={(val) =>
                                              props.onChangeTextControl(
                                                val.target.value,
                                                item.id
                                              )
                                            }
                                            onFocus={() => {
                                              props.onfocus(
                                                item.id,
                                                item.pageNum,
                                                index
                                              );
                                            }}
                                            value={item.txtValue}
                                            placeholder="Enter Text "
                                            // maxLength={10}
                                            onBlur={() => {
                                              if (item.txtValue)
                                                props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                );
                                            }}
                                          />
                                        ) : (
                                          <input
                                            style={{
                                              resize: "none",
                                              height: item.height / 2,
                                              width: item.width / 2,
                                              padding: 0,
                                              backgroundColor:
                                                "rgba(102, 255, 153,0.4)",
                                              color: "black",
                                              fontSize: "11",
                                            }}
                                            onChange={(val) =>
                                              props.onChangeTextControl(
                                                val.target.value,
                                                item.id
                                              )
                                            }
                                            onFocus={() => {
                                              props.onfocus(
                                                item.id,
                                                item.pageNum,
                                                index
                                              );
                                            }}
                                            value={item.txtValue}
                                            placeholder="Text "
                                            // maxLength={10}
                                            onBlur={() => {
                                              if (item.txtValue)
                                                props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                );
                                            }}
                                            className="mb-pdfInput"
                                          />
                                        )}
                                        {item.mandatory && (
                                          <span
                                            style={{
                                              color: "red",
                                              position: "absolute",
                                              top: -10,
                                              left:
                                                props.activeControlIndex ===
                                                index
                                                  ? -8
                                                  : -4,
                                            }}
                                          >
                                            *
                                          </span>
                                        )}
                                      </div>
                                    ) : item.name === "dds-txt" ? (
                                      <>
                                        <div
                                          style={{
                                            position: "relative",
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            // width: item.width,
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            display: "flex",
                                            alignItems: "end",
                                            // display: "none",
                                          }}
                                          onClick={() =>
                                            props.onControlActionPressed(
                                              item.id,
                                              index
                                            )
                                          }
                                        >
                                          <p
                                            style={{
                                              fontSize: 11,
                                              color: "black",
                                              marginBottom: -2.7,
                                              // top: props.pdfScale === 1 ? 13 : 1,
                                              // maxWidth: item.width,
                                            }}
                                          >
                                            {item.txtValue}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.name === "dds-img" && (
                                      <div
                                        style={{
                                          height:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                          width:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          backgroundColor:
                                            "rgba(102, 255, 153,0.5)",
                                          borderColor:
                                            props.activeControlIndex === index
                                              ? "#36802d"
                                              : "transparent",
                                          borderWidth:
                                            props.activeControlIndex === index
                                              ? 3
                                              : 0,
                                          borderRadius: 1,
                                          borderStyle: "dotted",
                                          position: "relative",
                                        }}
                                        onClick={() =>
                                          props.onfocus(
                                            item.id,
                                            item.pageNum,
                                            index
                                          )
                                        }
                                      >
                                        {item.isImg ? (
                                          <div
                                            onClick={() => {
                                              props.onControlActionPressed(
                                                item.id,
                                                index
                                              );
                                            }}
                                          >
                                            <img
                                              src={item.image}
                                              style={{
                                                height:
                                                  props.pdfScale === 1
                                                    ? item.height
                                                    : item.height / 2,
                                                width:
                                                  props.pdfScale === 1
                                                    ? item.width
                                                    : item.width / 2,
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            <button
                                              onClick={() =>
                                                props.onSelectImagePressed(
                                                  index,
                                                  item.id
                                                )
                                              }
                                              style={{
                                                background: "transparent",
                                                position: "absolute",
                                                right: "-10px",
                                                top: "-1px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <Collections
                                                style={{
                                                  height:
                                                    props.pdfScale === 1
                                                      ? 20
                                                      : 10,
                                                  width: 20,
                                                  color: "#6149cd",
                                                }}
                                              />
                                            </button>
                                            <button
                                              style={{
                                                background: "transparent",
                                                position: "absolute",
                                                left: "-10px",
                                                top: "-1px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                props.handleStartCamera(
                                                  index,
                                                  item.id
                                                )
                                              }
                                            >
                                              <CameraAlt
                                                style={{
                                                  height:
                                                    props.pdfScale === 1
                                                      ? 20
                                                      : 10,
                                                  width: 20,
                                                  color: "#6149cd",
                                                }}
                                              />
                                            </button>
                                            {item.mandatory && (
                                              <span
                                                style={{
                                                  color: "red",
                                                  position: "absolute",
                                                  top: -10,
                                                  left:
                                                    props.activeControlIndex ===
                                                    index
                                                      ? -8
                                                      : -4,
                                                }}
                                              >
                                                *
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}

                                    {item.name === "dds-sign" &&
                                    item.isDisableSignature === false ? (
                                      <div style={{ position: "relative" }}>
                                        <button
                                          style={{
                                            background: "transparent",
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: "-20px",
                                            top: "-18px",
                                          }}
                                          onClick={() =>
                                            props.signImage
                                              ? props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                )
                                              : props.onSignatureButtonPressed(
                                                  index,
                                                  item.id
                                                )
                                          }
                                        >
                                          {props.signImage && (
                                            <Download
                                              style={{ height: 20, width: 20 }}
                                            />
                                          )}
                                        </button>
                                        {props.signImage &&
                                          !item.isDisableSignature && (
                                            <button
                                              style={{
                                                background: "transparent",
                                                position: "absolute",
                                                left: "-20px",
                                                top: "-18px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                props.onSignatureButtonPressed(
                                                  index,
                                                  item.id
                                                )
                                              }
                                            >
                                              <AutoFixHigh
                                                style={{
                                                  height: 20,
                                                  width: 20,
                                                }}
                                              />
                                            </button>
                                          )}
                                        <div
                                          onClick={() => {
                                            props.onfocus(
                                              item.id,
                                              item.pageNum,
                                              index
                                            );
                                            props.signImage
                                              ? props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                )
                                              : props.onSignatureButtonPressed(
                                                  index,
                                                  item.id
                                                );
                                          }}
                                          style={{
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                          }}
                                        >
                                          {item.mandatory &&
                                            !props.signImage && (
                                              <span
                                                style={{
                                                  color: "red",
                                                  position: "absolute",
                                                  top: -10,
                                                  left:
                                                    props.activeControlIndex ===
                                                    index
                                                      ? -8
                                                      : -4,
                                                }}
                                              >
                                                *
                                              </span>
                                            )}

                                          <div>
                                            {item.isSign ? (
                                              <>
                                                <img
                                                  src={props.signImage}
                                                  style={{
                                                    height:
                                                      props.pdfScale === 1
                                                        ? item.height
                                                        : item.height / 2,
                                                    width:
                                                      props.pdfScale === 1
                                                        ? item.width
                                                        : item.width / 2,
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <div>
                                                <p
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: "normal",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  sign here
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : item.isSign ? (
                                      <div
                                        style={{
                                          position: "relative",
                                          height:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                          width:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          maxWidth:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,

                                          maxHeight:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                        }}
                                        className="sign-with-image"
                                        onClick={() =>
                                          props.onControlActionPressed(
                                            item.id,
                                            index
                                          )
                                        }
                                      >
                                        <img
                                          className="sign-img"
                                          ref={props.ddssignedRef}
                                          src={props.signImage}
                                          style={{
                                            objectFit: "contain",
                                            // height:
                                            //   props.pdfScale === 1
                                            //     ? item.height
                                            //     : item.height / 2,
                                            // width: "auto",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                          }}
                                        />
                                        {/* <p
                                          style={{
                                            fontSize:
                                              window.innerWidth > 997 ? 10 : 4,
                                          }}
                                        >
                                          {props.serverDate}
                                        </p> */}
                                      </div>
                                    ) : null}

                                    {item.name === "dds-initials" &&
                                    item.isDisableInitials === false ? (
                                      <div>
                                        <button
                                          style={{
                                            background: "transparent",
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: "-20px",
                                            top: "-18px",
                                          }}
                                          onClick={() =>
                                            props.initialsImage
                                              ? props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                )
                                              : props.onInitailButtonPressed(
                                                  index,
                                                  item.id
                                                )
                                          }
                                        >
                                          {props.initialsImage && (
                                            <Download
                                              style={{ height: 20, width: 20 }}
                                            />
                                          )}
                                        </button>
                                        <div
                                          onClick={() => {
                                            props.onfocus(
                                              item.id,
                                              item.pageNum,
                                              index
                                            );
                                            props.initialsImage
                                              ? props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                )
                                              : props.onInitailButtonPressed(
                                                  index,
                                                  item.id
                                                );
                                          }}
                                          style={{
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                          }}
                                        >
                                          {item.mandatory &&
                                            !props.initialsImage && (
                                              <span
                                                style={{
                                                  color: "red",
                                                  position: "absolute",
                                                  top: -10,
                                                  left:
                                                    props.activeControlIndex ===
                                                    index
                                                      ? -8
                                                      : -4,
                                                }}
                                              >
                                                *
                                              </span>
                                            )}
                                          {props.initialsImage &&
                                            !item.isDisableInitials && (
                                              <button
                                                style={{
                                                  background: "transparent",
                                                  position: "absolute",
                                                  left: "-20px",
                                                  top: "-18px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  props.onInitailButtonPressed(
                                                    index,
                                                    item.id
                                                  )
                                                }
                                              >
                                                <AutoFixHigh
                                                  style={{
                                                    height: 20,
                                                    width: 20,
                                                  }}
                                                />
                                              </button>
                                            )}

                                          <div>
                                            {item.isInitial ? (
                                              <img
                                                src={props.initialsImage}
                                                style={{
                                                  height:
                                                    props.pdfScale === 1
                                                      ? item.height
                                                      : item.height / 2,
                                                  width:
                                                    props.pdfScale === 1
                                                      ? item.width
                                                      : item.width / 2,
                                                }}
                                              />
                                            ) : (
                                              <p
                                                style={{
                                                  fontSize: 10,
                                                  fontWeight: "normal",
                                                  textAlign: "center",
                                                }}
                                              >
                                                initials here
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : item.isInitial ? (
                                      <div
                                        className="sign-with-image"
                                        style={{
                                          position: "relative",
                                          height:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                          width:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          maxWidth:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          maxHeight:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                        }}
                                        onClick={() =>
                                          props.onControlActionPressed(
                                            item.id,
                                            index
                                          )
                                        }
                                      >
                                        <img
                                          src={props.initialsImage}
                                          ref={props.ddsinitialRef}
                                          className="sign-img"
                                          style={{
                                            objectFit: "contain",
                                            // height:
                                            //   props.pdfScale === 1
                                            //     ? item.height
                                            //     : item.height / 2,
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            // width:
                                            //   props.pdfScale === 1
                                            //     ? item.width
                                            //     : item.width / 2,
                                          }}
                                        />
                                      </div>
                                    ) : null}

                                    {item.name === "dds-radio" &&
                                    !item.isRadioDisabled ? (
                                      <div>
                                        <button
                                          onClick={() =>
                                            props.onControlActionPressed(
                                              item.id,
                                              index
                                            )
                                          }
                                          style={{
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {item.isRadio === false && (
                                            <RadioButtonUnchecked
                                              style={{
                                                color: "#6149CD",
                                                padding: 0,
                                                margin: 0,
                                                height: 20,
                                                width: 20,
                                              }}
                                            />
                                          )}
                                        </button>
                                        {item.mandatory && (
                                          <span
                                            style={{
                                              color: "red",
                                              position: "absolute",
                                              top: -8,
                                              left:
                                                props.activeControlIndex ===
                                                index
                                                  ? -8
                                                  : -4,
                                            }}
                                          >
                                            *
                                          </span>
                                        )}
                                      </div>
                                    ) : item.isRadioDisabled ? (
                                      <button
                                        style={{ position: "relative" }}
                                        onClick={() =>
                                          props.onControlActionPressed(
                                            item.id,
                                            item.pageNum
                                          )
                                        }
                                      >
                                        <RadioButtonChecked
                                          style={{
                                            color: "#6149CD",
                                            padding: 0,
                                            margin: 0,
                                            height: 20,
                                            width: 20,
                                          }}
                                        />
                                      </button>
                                    ) : null}

                                    {item.name === "dds-select" &&
                                    !item.isCheckBoxDisabled ? (
                                      <div>
                                        <button
                                          onClick={() =>
                                            props.onControlActionPressed(
                                              item.id,
                                              index
                                            )
                                          }
                                          style={{
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {item.mandatory && (
                                            <span
                                              style={{
                                                color: "red",
                                                position: "absolute",
                                                top: -8,
                                                left:
                                                  props.activeControlIndex ===
                                                  index
                                                    ? -8
                                                    : -4,
                                              }}
                                            >
                                              *
                                            </span>
                                          )}
                                          {item.isSelect === false && (
                                            <CheckBoxOutlineBlank
                                              style={{
                                                color: "#6149CD",
                                                padding: 0,
                                                margin: 0,
                                                height: 20,
                                                width: 20,
                                              }}
                                            />
                                          )}
                                        </button>
                                      </div>
                                    ) : item.isCheckBoxDisabled === true ? (
                                      <button
                                        style={{
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() =>
                                          props.onControlActionPressed(
                                            item.id,
                                            item.pageNum
                                          )
                                        }
                                      >
                                        <CheckBox
                                          style={{
                                            // position: "absolute",
                                            color: "#6149CD",
                                            padding: 0,
                                            margin: 0,
                                            height: 20,
                                            width: 20,
                                          }}
                                        />
                                      </button>
                                    ) : null}

                                    {item.name === "dds-sign-combo" &&
                                    !item.isSignCombo ? (
                                      <div>
                                        <button
                                          style={{
                                            background: "transparent",
                                            position: "absolute",
                                            left: "-20px",
                                            top: "-18px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            props.signComboImage
                                              ? props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                )
                                              : props.onSignatureComboButtonPressed(
                                                  index,
                                                  item.id
                                                )
                                          }
                                        >
                                          {props.signComboImage && (
                                            <Download
                                              style={{
                                                height: 20,
                                                width: 20,
                                              }}
                                            />
                                          )}
                                        </button>
                                        <div
                                          onClick={() => {
                                            props.onfocus(
                                              item.id,
                                              item.pageNum,
                                              index
                                            );
                                            props.signComboImage
                                              ? props.onControlActionPressed(
                                                  item.id,
                                                  index
                                                )
                                              : props.onSignatureComboButtonPressed(
                                                  index,
                                                  item.id
                                                );
                                          }}
                                          style={{
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                          }}
                                        >
                                          <div>
                                            {item.mandatory &&
                                              !props.signComboImage && (
                                                <span
                                                  style={{
                                                    color: "red",
                                                    position: "absolute",
                                                    top: -10,
                                                    left:
                                                      props.activeControlIndex ===
                                                      index
                                                        ? -8
                                                        : -4,
                                                  }}
                                                >
                                                  *
                                                </span>
                                              )}
                                            {props.signComboImage &&
                                              !item.isSignCombo && (
                                                <button
                                                  style={{
                                                    background: "transparent",
                                                    position: "absolute",
                                                    right: "-20px",
                                                    top: "-18px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    props.onSignatureComboButtonPressed(
                                                      index,
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <AutoFixHigh
                                                    style={{
                                                      height: 20,
                                                      width: 20,
                                                    }}
                                                  />
                                                </button>
                                              )}
                                            {/* {item.isSignCombo ? (
                                            <img
                                              src={props.signComboImage}
                                              style={{
                                                height: item.height,
                                                width: item.width,
                                              }}
                                            />
                                          ) : ( */}

                                            <div>
                                              <p
                                                style={{
                                                  fontSize: 10,
                                                  fontWeight: "normal",
                                                  textAlign: "center",
                                                }}
                                              >
                                                sign here
                                              </p>
                                            </div>
                                            {/* )} */}
                                          </div>
                                        </div>
                                      </div>
                                    ) : item.isSignCombo &&
                                      item.name !== "dds-img-combo" ? (
                                      <div
                                        className="sign-with-image"
                                        style={{
                                          position: "relative",
                                          height:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                          width:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          maxWidth:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          maxHeight:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                        }}
                                        onClick={() =>
                                          props.onControlActionPressed(
                                            item.id,
                                            index
                                          )
                                        }
                                      >
                                        <img
                                          className="sign-img"
                                          ref={props.signedImageRef}
                                          src={props.signComboImage}
                                          style={{
                                            objectFit: "contain",
                                            // height:
                                            //   props.pdfScale === 1
                                            //     ? item.height
                                            //     : item.height / 2,
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            // width:
                                            //   props.pdfScale === 1
                                            //     ? item.width
                                            //     : item.width / 2,
                                          }}
                                        />
                                        {/* <p
                                          style={{
                                            fontSize:
                                              window.innerWidth > 997 ? 10 : 4,
                                          }}
                                        >
                                          {props.serverDate}
                                        </p> */}
                                      </div>
                                    ) : null}
                                    {item.name === "dds-dnt-combo" &&
                                      item.isDateCombo && (
                                        <div
                                          onClick={() => {
                                            props.onfocus(
                                              item.id,
                                              item.pageNum,
                                              index
                                            );
                                          }}
                                          style={{
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                          }}
                                        >
                                          <div
                                            // src={
                                            //   item.isSignCombo && item.isSignCombo
                                            //     ? item.image
                                            //     : camera
                                            // }
                                            style={{
                                              height:
                                                props.pdfScale === 1
                                                  ? item.height
                                                  : item.height / 2,
                                              width:
                                                props.pdfScale === 1
                                                  ? item.width
                                                  : item.width / 2,
                                              borderColor:
                                                props.activeControlIndex ===
                                                index
                                                  ? "#36802d"
                                                  : "transparent",
                                              borderWidth:
                                                props.activeControlIndex ===
                                                index
                                                  ? 3
                                                  : 0,
                                              display: "flex",
                                              alignItems: "end",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize:
                                                  window.innerWidth > 997
                                                    ? 10
                                                    : 4,
                                              }}
                                            >
                                              {props.serverDate}
                                            </p>
                                          </div>
                                        </div>
                                      )}

                                    {item.name === "dds-dnt-sign" &&
                                      item.isSignDate && (
                                        <div
                                          onClick={() => {
                                            props.onfocus(
                                              item.id,
                                              item.pageNum,
                                              index
                                            );
                                          }}
                                          style={{
                                            borderRadius: 1,
                                            borderStyle: "dotted",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            backgroundColor:
                                              "rgba(102, 255, 153,0.5)",
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                          }}
                                        >
                                          <div
                                            // src={
                                            //   item.isSignCombo && item.isSignCombo
                                            //     ? item.image
                                            //     : camera
                                            // }
                                            style={{
                                              height:
                                                props.pdfScale === 1
                                                  ? item.height
                                                  : item.height / 2,
                                              width:
                                                props.pdfScale === 1
                                                  ? item.width
                                                  : item.width / 2,
                                              borderColor:
                                                props.activeControlIndex ===
                                                index
                                                  ? "#36802d"
                                                  : "transparent",
                                              borderWidth:
                                                props.activeControlIndex ===
                                                index
                                                  ? 3
                                                  : 0,
                                              display: "flex",
                                              alignItems: "end",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize:
                                                  window.innerWidth > 997
                                                    ? 10
                                                    : 4,
                                              }}
                                            >
                                              {props.serverDate}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    {item.name === "dds-img-combo" && (
                                      <div
                                        onClick={() => {
                                          props.onfocus(
                                            item.id,
                                            item.pageNum,
                                            index
                                          );
                                        }}
                                        style={{
                                          borderRadius: 1,
                                          borderStyle: "dotted",
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height:
                                            props.pdfScale === 1
                                              ? item.height
                                              : item.height / 2,
                                          width:
                                            props.pdfScale === 1
                                              ? item.width
                                              : item.width / 2,
                                          backgroundColor:
                                            "rgba(102, 255, 153,0.5)",
                                          borderColor:
                                            props.activeControlIndex === index
                                              ? "#36802d"
                                              : "transparent",
                                          borderWidth:
                                            props.activeControlIndex === index
                                              ? 3
                                              : 0,
                                        }}
                                      >
                                        <img
                                          src={
                                            item.isSignCombo && item.isSignCombo
                                              ? item.image
                                              : camera
                                          }
                                          style={{
                                            height:
                                              props.pdfScale === 1
                                                ? item.height
                                                : item.height / 2,
                                            width:
                                              props.pdfScale === 1
                                                ? item.width
                                                : item.width / 2,
                                            borderColor:
                                              props.activeControlIndex === index
                                                ? "#36802d"
                                                : "transparent",
                                            borderWidth:
                                              props.activeControlIndex === index
                                                ? 3
                                                : 0,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Draggable>
                              )
                          )}
                          <Page
                            scale={props.pdfScale}
                            pageNumber={props.pageNumber}
                          />
                        </Document>
                      </div>
                    </div>
                  </div>
                </Grid>
                {/*Assigned Contols section */}
                {/* <Grid
                  item
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{ background: "#F5F6F6" }}
                >
                  <div className="pdf-right-controls">
                    <div className="main-title" style={{}}>
                      Controls
                    </div>
                    <div
                      style={{ backgroundColor: "#ffffff" }}
                      className="controllersDetailsContainer"
                    >
                      {props.controls.map(
                        (item: AssignedWorkFlowControl, index: number) => (
                          <>
                            <div
                              className={"activeitemcontainer"}
                              key={index}
                              onClick={() =>
                                props.onfocus(item.id, item.pageNum)
                              }
                            >
                              <div key={index}>
                                <div
                                  className={
                                    "nonActiveContainer activeContainer"
                                  }
                                  style={{
                                    borderColor:
                                      props.activeControlIndex === item.id
                                        ? "#36802d"
                                        : "transparent",
                                  }}
                                >
                                  <div className="leftContainer">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignSelf: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          item.name === "dds-txt"
                                            ? textImage
                                            : item.name === "dds-select"
                                            ? tick
                                            : item.name === "dds-img"
                                            ? camera
                                            : item.name === "dds-sign"
                                            ? sign
                                            : item.name === "dds-initials"
                                            ? initalsBoxImage
                                            : radioButtonImage
                                        }
                                        style={{ width: 30, height: 30 }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        color: "#191a1d",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        padding: 5,
                                        marginLeft: 10,
                                      }}
                                    >
                                      <p style={{ fontSize: 14 }}>
                                        {item.name === "dds-txt"
                                          ? "Text"
                                          : item.name === "dds-select"
                                          ? "Multi selection"
                                          : item.name === "dds-img"
                                          ? "Image Box "
                                          : item.name === "dds-sign"
                                          ? "Signature Box"
                                          : item.name === "dds-initials"
                                          ? "Initials Box"
                                          : "Radio Button"}
                                      </p>
                                      <p
                                        style={{
                                          color: "#C3C9D5",
                                          fontSize: 10,
                                        }}
                                      >
                                        {item.name === "dds-txt"
                                          ? " Single line or multiline text are"
                                          : item.name === "dds-select"
                                          ? "Select one or multiple options using a checkbox"
                                          : item.name === "dds-img"
                                          ? "Upload Image using device camera or upload from local device"
                                          : item.name === "dds-sign"
                                          ? "Draw a signature on screen"
                                          : item.name === "dds-initials"
                                          ? "Draw a initals on screen"
                                          : "Select radio buttons"}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="page-num"
                                    style={{ fontSize: 13 }}
                                  >
                                    Page #{" "}
                                    <span style={{ color: "#7A86A1" }}>
                                      {item.pageNum}
                                    </span>
                                  </div>
                                </div>

                                <div className="control-options"></div>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </Box>
            <div className="editpagecon">
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={props.onPreviousePressed}
                  style={{
                    borderRadius: 10,
                    fontSize: 18,
                    color: "#A89BB9",
                  }}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                {(
                  <p
                    style={{
                      padding: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      backgroundColor: " rgba(122, 134, 161, 0.3)",
                      color: "#ffffff",
                      marginRight: 5,
                    }}
                  >
                    {props.pageNumber}
                  </p>
                ) || (props.numPages ? 1 : "--")}{" "}
                / {props.numPages || "--"}
                <IconButton
                  onClick={props.onNextPagePressed}
                  style={{
                    borderRadius: 10,
                    fontSize: 18,
                    color: "#A89BB9",
                  }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </div>
            </div>
            <div className="editcntrl">
              {/* <div
                  style={{
                    width: "65%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                > */}
              <Button
                // variant="contained"
                className="next-btn"
                onClick={() => {
                  props.onPreviouseControlPressed();
                }}
              >
                Previous
              </Button>
              <Button
                // variant="contained"
                className="next-btn"
                onClick={() => {
                  props.onNextControlPressed();
                }}
              >
                Next
              </Button>
              {/* </div> */}
              <Button
                variant="contained"
                className="next-submit-btn"
                onClick={() => {
                  props.onSubmitWorkFlowPressed();
                }}
              >
                {props.isLoading === false ? (
                  "Submit"
                ) : (
                  <CircularProgress
                    style={{ width: 25, height: 25, color: "white" }}
                  />
                )}
              </Button>
            </div>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default EditControl;
